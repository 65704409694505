import React, { Component } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { deleteScenario } from "../../../services/RequestService";
import { connect } from "react-redux";
import { alertActions } from "../../../store/actions/AlertActions";

class ModalScenarioDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  deleteScenario = () => {
    const { id } = this.props;
    this.setState((state) => ({ ...state, isLoading: true }));
    deleteScenario({ id: id }).then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, isLoading: false }));
        this.props.onAddAlert(res.data.log, "success", false);
        this.props.onHide(true);
      } else {
        this.props.onAddAlert(res.data.message, "danger", false);
        this.setState((state) => ({ ...state, isLoading: false }));
      }
    });
  };
  render() {
    const { isLoading } = this.state;
    const { show, onHide, name } = this.props;
    return (
      <div>
        <Modal show={show} onHide={onHide} backdrop="static" size="md">
          <Modal.Header>
            <Modal.Title>{"Excluir cenário"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="m-1">{"Deseja excluir o cenário com o nome: "}</p>
            <p className="m-1">{name}</p>
          </Modal.Body>
          <Modal.Footer className="p-0">
            {isLoading ? (
              <Button
                className={"w-100 button-without-rounded m-0"}
                disabled={true}
              >
                {"Excluindo..."}
              </Button>
            ) : (
              <Row className={"w-100 m-0"}>
                <Col className={"p-0"}>
                  <Button
                    className={"w-100 button-without-rounded"}
                    variant={"danger"}
                    onClick={() => this.deleteScenario()}
                  >
                    {"Sim"}
                  </Button>
                </Col>
                <Col className={"p-0"}>
                  <Button
                    className={"w-100 button-without-rounded"}
                    onClick={() => onHide()}
                  >
                    {"Não"}
                  </Button>
                </Col>
              </Row>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalScenarioDelete);
