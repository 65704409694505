import React from "react";

import Chart from "chart.js";
const fontFamily =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"';

function ProposalsChart({ chartData }) {
  const chartRef = React.useRef(null);

  const data = React.useMemo(() => {
    return {
      type: "doughnut",
      data: {
        // labels: ["App", "Web", "Credenciados", "SAC"],
        datasets: [
          {
            backgroundColor: ["#3498DB", "#FFC107", "#2ECC71", "#F0166D"],
            data: chartData,
            barPercentage: 0.6,
            borderWidth: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },

        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          align: "start",
          position: "bottom",
        },
        tooltips: {
          enabled: false,
        },

        scales: {
          xAxes: [
            {
              display: false,
              fontFamily: fontFamily,
              scaleLabel: {
                fontFamily: fontFamily,
              },

              ticks: {
                fontFamily: fontFamily,
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              fontFamily: fontFamily,
              scaleLabel: {
                fontFamily: fontFamily,
              },

              ticks: {
                fontFamily: fontFamily,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  }, [chartData]);

  React.useEffect(() => {
    new Chart(chartRef.current, data);
  }, [data]);

  return (
    <canvas
      style={{ minHeight: 150, maxHeight: 150, minWidth: 150, maxWidth: 150 }}
      ref={chartRef}
    />
  );
}

export default ProposalsChart;
