import React from "react";
import { Row } from "react-bootstrap";
import { Checkbox, RadioGroup } from "../../../genericComponents";
import { epiNames, epiValues } from "../models/formArrayNames";

function CustomerSurvey6({ serverData }) {
  const [usedEpi, setUsedEpi] = React.useState(false);

  function onCheckboxChange(event, value, ffHandler) {
    ffHandler.testFieldError("optionEPI");
  }

  const optionEPI = serverData?.optionEPI;
  React.useEffect(() => {
    if (optionEPI === "Sim") {
      setUsedEpi(true);
    }
  }, [optionEPI, setUsedEpi]);

  return (
    <div>
      <b>
        <hr />
      </b>
      <Row>
        <RadioGroup
          label="Você faz/fazia o uso de EPI (Equipamento de Proteção Individual)?"
          name="optionEPI"
          options={[
            {
              value: "Sim",
              label: "Sim",
            },
            {
              value: "Não",
              label: "Não",
            },
          ]}
          required='O campo "Você faz/fazia o uso de EPI (Equipamento de Proteção Individual)?" é obrigatório.'
          inline
          onChange={(event, value) => {
            setUsedEpi(value === "Sim");
          }}
          validation={(value, ffHandler) => {
            const isValid =
              value === "Não" ||
              Boolean(epiNames.find((name) => ffHandler.getValue(name)));
            return isValid
              ? null
              : "Um ou mais equipamentos devem ser selecionados";
          }}
        />
      </Row>

      {usedEpi ? (
        <Row>
          <Checkbox
            colProps={{ xs: 12, md: 6, xl: 4, className: "m-0" }}
            label={epiValues[0]}
            name="earProtectors"
            maskToSubmit={(value) => (value ? epiValues[0] : null)}
            onChange={onCheckboxChange}
          />
          <Checkbox
            colProps={{ xs: 12, md: 6, xl: 4, className: "m-0" }}
            label={epiValues[1]}
            name="maskAndFilter"
            maskToSubmit={(value) => (value ? epiValues[1] : null)}
            onChange={onCheckboxChange}
          />

          <Checkbox
            colProps={{ xs: 12, md: 6, xl: 4, className: "m-0" }}
            label={epiValues[2]}
            name="gloves"
            maskToSubmit={(value) => (value ? epiValues[2] : null)}
            onChange={onCheckboxChange}
          />
          <Checkbox
            colProps={{ xs: 12, md: 6, xl: 4, className: "m-0" }}
            label={epiValues[3]}
            name="helmet"
            maskToSubmit={(value) => (value ? epiValues[3] : null)}
            onChange={onCheckboxChange}
          />

          <Checkbox
            colProps={{ xs: 12, md: 6, xl: 4, className: "m-0" }}
            label={epiValues[4]}
            name="belts"
            maskToSubmit={(value) => (value ? epiValues[4] : null)}
            onChange={onCheckboxChange}
          />
          <Checkbox
            colProps={{ xs: 12, md: 6, xl: 4, className: "m-0" }}
            label={epiValues[5]}
            name="glasses"
            maskToSubmit={(value) => (value ? epiValues[5] : null)}
            onChange={onCheckboxChange}
          />
          <Checkbox
            colProps={{ xs: 12, md: 6, xl: 4, className: "m-0" }}
            label={epiValues[6]}
            name="boots"
            maskToSubmit={(value) => (value ? epiValues[6] : null)}
            onChange={onCheckboxChange}
          />
        </Row>
      ) : null}
      <hr />
    </div>
  );
}

export default React.memo(CustomerSurvey6);
