import React from "react";
import { Button } from "react-bootstrap";
import styles from "../styles/SideMenu.module.css";
import { useAlert } from "react-alert";
import { getMenu } from "./menu";

function SideMenu(props) {
  const alert = useAlert();
  const buttons = getMenu({ ...props, alert, origin: 'side' });

  return (
    <div className={styles.container}>
      {buttons.map((item, index) => {
        if (!item) return null;
        const {
          title,
          icon,
          label,
          variant = "primary",
          className,
          ...itemProps
        } = item;

        if (title)
          return (
            <span key={index}>
              <h2 className={styles.title}>{title}</h2>
              <hr className={styles.divider} />
            </span>
          );

        return (
          <Button
            key={index}
            className={styles.button}
            variant={variant}
            {...itemProps}
          >
            {icon ? <i className={`${icon} mr-2`} /> : null}
            <span>{label}</span>
          </Button>
        );
      })}
    </div>
  );
}

export default SideMenu;
