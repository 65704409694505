import React from "react";
import { Row } from "react-bootstrap";
import { RadioGroup } from "../../../genericComponents";

function CustomerSurvey2() {
  return (
    <Row>
      <RadioGroup
        label="Já teve processo trabalhista, para reconhecimento de algum vínculo?"
        name="workProcess"
        options={[
          {
            value: "Sim",
            label: "Sim",
          },
          {
            value: "Não",
            label: "Não",
          },
          {
            value: "Não sei responder",
            label: "Não sei responder",
          },
        ]}
        required='O campo "Já teve processo trabalhista, para reconhecimento de algum vínculo?" é obrigatório.'
        inline
      />
    </Row>
  );
}

export default React.memo(CustomerSurvey2);
