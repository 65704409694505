import { parse } from "date-fns";
import React from "react";
import { Row } from "react-bootstrap";
import { InputDate, RadioGroup } from "../../../genericComponents";
import { validations } from "../../../utils";

function CustomerSurvey3({ serverData }) {
  const [served, setServed] = React.useState(false);

  const militaryService = serverData?.militaryService;
  React.useEffect(() => {
    if (militaryService === "Sim") {
      setServed(true);
    }
  }, [militaryService, setServed]);

  return (
    <Row>
      <RadioGroup
        colProps={{ xs: 12 }}
        label="Já prestou serviço militar?"
        name="militaryService"
        options={[
          {
            value: "Sim",
            label: "Sim",
          },
          {
            value: "Não",
            label: "Não",
          },
        ]}
        required='O campo "Já prestou serviço militar?" é obrigatório.'
        inline
        onChange={(event, value) => {
          setServed(value === "Sim");
        }}
      />
      {served ? (
        <>
          <InputDate
            colProps={{ md: 6 }}
            label="Data de início"
            name="militaryYearStart"
            required='O campo "Data de início" é obrigatório.'
            inputMaskProps={{
              placeholder: "Data de inicio do serviço militar",
            }}
            minDate={new Date("01/01/1900")}
            maxDate={new Date()}
            onBlur={(event, value, ffHandler) => {
              const dateToCompare = ffHandler.getValue("militaryYearEnd");
              if (dateToCompare !== "__/__/____") {
                ffHandler.testFieldError("militaryYearEnd");
              }
            }}
            validation={(value, ffHandler) => {
              const dateToCompare = ffHandler.getValue("militaryYearEnd");
              const result = validations.isDATE(
                parse(value, "dd/MM/yyyy", new Date()),
                null,
                parse(dateToCompare, "dd/MM/yyyy", new Date())
              );

              return result;
            }}
          />
          <InputDate
            colProps={{ md: 6 }}
            label="Data de término"
            name="militaryYearEnd"
            required='O campo "Data de término" é obrigatório.'
            inputMaskProps={{
              placeholder: "Data de término do serviço militar",
            }}
            minDate={new Date("01/01/1900")}
            maxDate={new Date()}
            onBlur={(event, value, ffHandler) => {
              const dateToCompare = ffHandler.getValue("militaryYearStart");
              if (dateToCompare !== "__/__/____") {
                ffHandler.testFieldError("militaryYearStart");
              }
            }}
            validation={(value, ffHandler) => {
              const dateToCompare = ffHandler.getValue("militaryYearStart");
              const result = validations.isDATE(
                parse(value, "dd/MM/yyyy", new Date()),

                parse(dateToCompare, "dd/MM/yyyy", new Date())
              );

              return result;
            }}
          />
        </>
      ) : null}
    </Row>
  );
}

export default React.memo(CustomerSurvey3);
