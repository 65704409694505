import React from "react";
import { Row } from "react-bootstrap";
import { InputRG, Select, InputDate } from "../../../genericComponents";

function BasicData2({ disabled }) {
  return (
    <Row>
      <InputRG
        colProps={{ xs: 12, sm: 4 }}
        name="rg"
        label="RG"
        maxLength={30}
        disabled={disabled}
      />

      <InputDate
        colProps={{ xs: 12, sm: 4 }}
        label="Data de nascimento"
        name="birthday"
        required="O campo deve conter 8 números e uma data válida."
        disabled={disabled}
        minDate={new Date("01/01/1900")}
        maxDate={new Date()}
      />

      <Select
        colProps={{ xs: 12, sm: 4 }}
        label="Gênero"
        name="gender"
        required="O valor do campo gênero deve ser Masculino ou Feminino."
        disabled={disabled}
        placeholder="Selecione um gênero"
        options={[
          {
            label: "Masculino",
            value: "Masculino",
          },
          {
            label: "Feminino",
            value: "Feminino",
          },
        ]}
      />
    </Row>
  );
}
export default React.memo(BasicData2);
