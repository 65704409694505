/* eslint-disable react/style-prop-object */
import React from "react";
import { Modal, Row } from "react-bootstrap";

function CustomModal({
  show,
  onHide,
  title,
  children,
  fullHeight,
  footer,
  size,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size={size}
      className="loading-auto-height d-flex align-items-stretch"
      dialogClassName={fullHeight ? "content-h-100" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer ? (
        <Modal.Footer className="px-0 justify-content-center">
          <Row className="mx-n3 w-100">{footer}</Row>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
}
export default CustomModal;
