import React, { Component } from "react";
import { Row, Col, Tabs, Tab, Modal, Button } from "react-bootstrap";
import "./SingleCustomerComp.css";
import { connect } from "react-redux";
import { alertActions } from "../../store/actions/AlertActions";
import BasicDataComp from "./basicData/BasicDataComp";
import CustomerSurveyComp from "./survey/CustomerSurveyComp";
import registerUserRequest from "./requests/registerUserRequest";
import PensionRegisterForm from "./pension/PensionRegisterForm";
import getCustomer from "./requests/getCustomer";
import CustomerDocumentComp from "./document/CustomerDocumentComp";
import { pensionFormNames } from "./models/pensionFormNames";
import { Header, Loading, PageContainer } from "../../genericComponents";
import { location } from "../../utils";
import DiamondCard from "./diamond/DiamondCard";

function renderTabContent(eventKey, title, disabled, children) {
  return (
    <Tab eventKey={eventKey} title={title} disabled={disabled} className="mt-3">
      <Row>
        <Col>
          <h6 className="is-required">
            Campos marcados com * são obrigatórios o preenchimento
          </h6>
        </Col>
      </Row>
      {children}
    </Tab>
  );
}

class SingleCustomerComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabKey: "BasicData",
      customerId: null,
      basicData: null,
      surveyData: null,
      serverData: null,
      isFetchingData: props.id,
      isFetching: false,
      isPension: false,
      registerFeedback: null,
    };

    this.basicFormRef = null;
    this.surveyFormRef = null;
  }

  handleSelectTab = (tabKey) => {
    this.setState({ tabKey });
  };

  setSurveyFormRef = (ref) => {
    this.surveyFormRef = ref;
  };

  setBasicFormRef = (ref) => {
    this.basicFormRef = ref;
  };

  componentDidMount = () => {
    const { id, from } = this.props;
    if (id !== undefined) {
      getCustomer(id, from === "processo", this.props.onAddAlert, (state) =>
        this.setState(state)
      );
    }
  };

  setBasicData = (basicData) => {
    const register = Boolean(this.state.surveyData);
    const surveyData = this.surveyFormRef.getValues();
    this.setState(
      (state) => ({
        basicData,
        surveyData:
          surveyData.isRetired || surveyData[pensionFormNames.degreeOfKinship]
            ? surveyData
            : null,
        tabKey: register ? state.tabKey : "Survey",
      }),
      register ? () => this.registerUser() : null
    );
  };

  setSurveyData = (surveyData) => {
    const register = Boolean(this.state.basicData);
    const basicData = this.basicFormRef.getValues();
    this.setState(
      (state) => ({
        surveyData,
        basicData,
        tabKey: register ? state.tabKey : "BasicData",
      }),
      register ? () => this.registerUser() : null
    );
  };

  registerUser = () => {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true }, () =>
        registerUserRequest(this.props, this.state, (registerFeedback) => {
          this.setState({ isFetching: false, registerFeedback });
        })
      );
    }
  };

  getDisabledField = () => {
    const { from, id, privileges } = this.props;
    if (from === "login" || from === "processo") return false;
    if (from === "clientes") {
      if (Boolean(id)) {
        if (privileges?.clientes?.update) return false;
      } else if (privileges?.clientes?.create) return false;
    }
    return true;
  };

  getSurveyComponent = () => {
    const { category } = this.props;
    if (category) {
      if (!category.includes("pensao")) return CustomerSurveyComp;
      else return PensionRegisterForm;
    } else {
      if (this.state.isPension) return PensionRegisterForm;
      else return CustomerSurveyComp;
    }
  };

  render() {
    const { registerFeedback, isFetchingData, isFetching } = this.state;
    const { id, from } = this.props;

    const disabledField = this.getDisabledField();

    const SurveyComponent = this.getSurveyComponent();

    return (
      <Loading
        loading={isFetchingData || isFetching}
        loadingText={isFetchingData ? null : "Carregando..."}
      >
        <Modal show={Boolean(registerFeedback)} centered>
          <Modal.Header>
            <Modal.Title>
              {id ? "Atualização concluída" : "Cadastro concluído"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{registerFeedback}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                if (from === "login") {
                  window.location.href = "/";
                } else if (from === "clientes" && !id) {
                  window.location.href = "/clientes";
                } else {
                  this.setState({ registerFeedback: null });
                }
              }}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Header
          logged={from !== "login"}
          navigation={[
            {
              href: from === "login" ? "/" : "/clientes",
              label: from === "login" ? "Login" : "Clientes",
            },
          ]}
          active={
            from === "login" || !id
              ? "Cadastro"
              : this.state?.serverData?.fullname
          }
        />
        <PageContainer fixedHeight className="p-3">
          <div className="h-100 overflow-hidden d-flex flex-column">
            <Tabs
              activeKey={this.state.tabKey}
              onSelect={this.handleSelectTab}
              className="teste"
            >
              {renderTabContent(
                "BasicData",
                "Dados básicos",
                false,
                <BasicDataComp
                  id={this.props.id}
                  serverData={this.state.serverData}
                  setInfos={this.setBasicData}
                  invalidateInfos={() => {
                    if (!id && this.state.basicData) {
                      this.setState({ basicData: null });
                    }
                  }}
                  setRef={this.setBasicFormRef}
                  states={location.states}
                  allCitiesFromStates={location.allCitiesFromStates}
                  disabledField={disabledField}
                  getFormHandler={() => this.basicFormRef}
                />
              )}
              {renderTabContent(
                "Survey",
                "Questionário",
                !this.props.id && !this.state.basicData,
                <SurveyComponent
                  isAdmin={this.props.isAdmin}
                  id={this.props.id}
                  serverData={this.state.serverData}
                  setInfos={this.setSurveyData}
                  invalidateInfos={() => {
                    if (!id && this.state.surveyData) {
                      this.setState({ surveyData: null });
                    }
                  }}
                  setRef={this.setSurveyFormRef}
                  states={location.states}
                  allCitiesFromStates={location.allCitiesFromStates}
                  disabledField={disabledField}
                  from={from}
                />
              )}
              {from === "clientes" && this.props.id && (
                <Tab
                  eventKey="documents"
                  title="Documentos"
                  disabled={false}
                  className="mt-3 h-100"
                >
                  <div className="h-100 d-flex flex-column">
                    <CustomerDocumentComp id={this.props.id} />
                  </div>
                </Tab>
              )}
              {from === "clientes" && this.props.id && (
                <Tab
                  eventKey="diamondCard"
                  title="Cartão Diamante"
                  disabled={false}
                  className="mt-3 h-100"
                >
                  <div className="h-100 d-flex flex-column">
                    <DiamondCard customerId={this.state.customerId} />
                  </div>
                </Tab>
              )}
            </Tabs>
          </div>
        </PageContainer>
      </Loading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(SingleCustomerComp);
