/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Col, Table, Row } from "react-bootstrap";
import PaginationComp from "../../../components/pagination/paginationComp";

import styles from "./TableComponent.module.css";

const PAGE_ITEM_SIZE = 20;

function TableComponent({
  header,
  list,
  forEach,
  countLabel,
  countLabelSingular,
  onItemClick,
  currentList,
  setCurrentList,
  setItemClassName,
  containerClassName,
  loading,
  startSorted,
  emptyPlaceholder = "Nenhum valor encontrado.",
}) {
  const [listSize, setListSize] = React.useState();
  const [currentNumberPage, setCurrentNumberPage] = React.useState(0);
  const [currentShowPages, setCurrentShowPages] = React.useState();
  const [pages, setPages] = React.useState(undefined);
  const [sorts, setSorts] = React.useState(null);

  function sortField(field, sorts, currentList) {
    const newSorts = {
      [field]: !sorts?.[field],
    };

    const ascending = Boolean(newSorts[field]);
    const newSliceList = [...currentList];
    newSliceList[currentNumberPage].sort((a, b) => {
      if (a[field] > b[field]) {
        return ascending ? 1 : -1;
      }
      if (a[field] < b[field]) {
        return ascending ? -1 : 1;
      }
      return 0;
    });
    setSorts(newSorts);
    setCurrentList(newSliceList);
  }

  function getPageSize(listLength) {
    return (
      parseInt(list.length / PAGE_ITEM_SIZE) +
      (list.length % PAGE_ITEM_SIZE === 0 ? 0 : 1)
    );
  }

  function getPageNumbers(size) {
    const newPages = [];
    for (let i = 0; i < size; i++) {
      newPages.push(i);
    }
    return newPages;
  }

  React.useEffect(() => {
    if (list) {
      const numberPageSize = getPageSize(list.length);
      const newPages = getPageNumbers(numberPageSize);
      const sliceList = [];

      for (let i = 0; i < list.length; i += PAGE_ITEM_SIZE) {
        sliceList.push(list.slice(i, i + PAGE_ITEM_SIZE));
      }
      let currentShowPages = newPages.slice(0, 10);

      if (!loading && !sorts && startSorted && list.length) {
        sortField(startSorted, sorts, sliceList);
      } else {
        setCurrentList(sliceList);
      }
      setListSize(list.length);
      setCurrentNumberPage(0);
      setCurrentShowPages(currentShowPages);
      if (pages?.length !== newPages?.length) {
        setPages(newPages);
      }
    }
  }, [list?.length, loading]);

  React.useEffect(() => {
    if (list && pages) {
      const numberPageSize = getPageSize(list.length);
      if (numberPageSize < pages.length) {
        const newPages = getPageNumbers(numberPageSize);
        setPages(newPages);
      }
    }
  }, [list?.length, pages]);

  function getHeaderIcon(field, sorts) {
    const value = sorts?.[field];
    if (value === undefined) {
      return "fas fa-sort";
    } else if (Boolean(value)) {
      return "fas fa-sort-up";
    }
    return "fas fa-sort-down";
  }

  function itemClassName(item, onItemClick) {
    let className = onItemClick ? styles.trClickable : styles.tr;
    if (setItemClassName) {
      className += ` ${setItemClassName(item)}`;
    }

    return className;
  }

  return (
    <>
      <div
        className={`w-100 flex-grow-1 flex-shrink-1 overflow-auto ${containerClassName}`}
      >
        {loading ? null : !list?.length ? (
          <p className={styles.emptyTable}>{emptyPlaceholder}</p>
        ) : (
          <Table
            striped
            bordered
            className={`table-bordered bms-color-text my-0 ${styles.table}`}
          >
            <thead>
              <tr>
                {header.map((item, index) =>
                  !item ? null : (
                    <th
                      key={index}
                      onClick={
                        item.sortProperty
                          ? () =>
                              sortField(item.sortProperty, sorts, currentList)
                          : null
                      }
                      className={
                        item.sortProperty ? styles.thClickable : styles.th
                      }
                    >
                      <Row>
                        <Col xs>{item.label}</Col>
                        {item.sortProperty ? (
                          <Col xs={1.5} className={styles.thIcon}>
                            <i
                              className={getHeaderIcon(
                                item.sortProperty,
                                sorts
                              )}
                            />
                          </Col>
                        ) : null}
                      </Row>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {currentList?.[currentNumberPage]?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={itemClassName(item, onItemClick)}
                    onClick={
                      onItemClick ? () => onItemClick(item, index) : null
                    }
                  >
                    {forEach(item, index).map((value, jIndex) =>
                      !value ? null : (
                        <td
                          key={jIndex}
                          className={`${
                            value.className ? value.className : ""
                          } ${value.onClick ? styles.tdClickable : ""}`}
                          onClick={(e) => {
                            if (value.onClick) {
                              e.stopPropagation();
                              value.onClick(e, jIndex);
                            }
                          }}
                        >
                          {value.label}
                        </td>
                      )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
      {!list?.length ? null : (
        <Row className="p-2">
          {countLabel ? (
            <Col lg={6} xs={12}>
              {listSize}{" "}
              {listSize === 1 ? countLabelSingular ?? countLabel : countLabel}
            </Col>
          ) : null}
          {pages?.length > 1 ? (
            <Col lg={6} xs={12}>
              <PaginationComp
                pages={pages}
                currentShowPages={currentShowPages}
                currentNumberPage={currentNumberPage}
                refreshTable={setCurrentNumberPage}
              />
            </Col>
          ) : null}
        </Row>
      )}
    </>
  );
}

export default TableComponent;
