import React from "react";
import { CheckboxGroup } from "../../../genericComponents";
import { contactNames } from "../models/formArrayNames";

function PensionRegister19() {
  return (
    <CheckboxGroup
      name="contactWay"
      label="Quais os métodos preferenciais para entrarmos em contato?"
      options={contactNames.map((name) => ({
        label: name,
        value: name,
        checked: true,
      }))}
    />
  );
}

export default React.memo(PensionRegister19);
