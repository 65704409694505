import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Table,
} from "react-bootstrap";
import {
  getOutsourceCnpjCpf,
  hasCpfAvailable,
  hasEmailAvailable,
  putCellInsertWorkGroup,
  updateOutsource,
} from "../../../services/RequestService";
import { alertActions } from "../../../store/actions/AlertActions";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import CEP from "cep-promise/dist/cep-promise-browser.min";
import "./ModalPartner.css";
import { masks } from "../../../utils";
import { modalPartnerMount } from "./controllers/ModalPartnerControllers";
import { parse } from "date-fns";
import { validations } from "../../../utils";

class ModalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledField: false,
      disabledPartnerField: false,
      allUsers: [],
      cellName: props.data !== undefined ? props.data.cellName : "",

      cpf:
        props.data !== undefined
          ? props.data.physicalPerson === "Pessoa Física"
            ? props.data.cpfOrCnpj
            : ""
          : "",
      cnpj:
        props.data !== undefined
          ? props.data.physicalPerson === "Pessoa Jurídica"
            ? props.data.cpfOrCnpj
            : ""
          : "",
      email: props.data !== undefined ? props.data.email : "",
      name: props.data !== undefined ? props.data.fullNameOrCompanyName : "",
      cellphone: props.data !== undefined ? props.data.phone : "",
      cep: props.data !== undefined ? props.data.addressZipCode : "",
      street: props.data !== undefined ? props.data.addressStreet : "",
      placeNumber: props.data !== undefined ? props.data.addressNumber : "",
      neighborhood: props.data !== undefined ? props.data.addressDistrict : "",
      city: props.data !== undefined ? props.data.addressCity : "",
      state: props.data !== undefined ? props.data.addressState : "",
      bankData: props.data !== undefined ? JSON.parse(props.data.bankData) : {},

      cpfUser: undefined,
      nameUser: undefined,
      emailUser: undefined,
      password: "",
      isOutsource: props?.data
        ? props.data.isFinder
          ? "Indicador"
          : props.data.isOutsource
          ? "Terceirizado"
          : "Credenciado"
        : "",
      physicalPerson:
        props.data !== undefined
          ? props.data.physicalPerson
          : "Pessoa Jurídica",
      additionalInformation:
        props.data !== undefined ? props.data.additionalInformation : "",
      disabledSubmitBtn: false,
      type: "password",

      privileges: {},
      privilegesChoise: [],

      //Contract data
      prospectName: props?.data?.prospector?.name ?? "",
      beginDate: props?.data?.beginDate ?? "",
      endDate: props?.data?.endDate ?? "",
      participationValue: props?.data?.participationValue ?? "",

      //Service Value
      discount: props?.data?.discount ?? 0,
      ticketParcels: [],
      isTicketParcel: props?.data?.useTicket ?? false,
      ticketParcel: props?.data?.ticketParcel
        ? props.data.ticketParcel !== null
          ? props.data.ticketParcel
          : ""
        : "",
      creditCardParcels: [],
      isCreditCard: props?.data?.useCreditCard ?? false,
      creditCardParcel: props?.data?.creditCardParcel
        ? props.data.creditCardParcel !== null
          ? props.data.creditCardParcel
          : ""
        : "",

      validFields: {
        isCPFValid:
          props?.data?.physicalPerson === "Pessoa Física"
            ? props.data !== undefined
            : undefined,
        isCPFUnique:
          props?.data?.physicalPerson === "Pessoa Física"
            ? props.data !== undefined
            : undefined,
        isCNPJValid:
          props?.data?.physicalPerson === "Pessoa Jurídica"
            ? props.data !== undefined
            : undefined,
        isCNPJUnique:
          props?.data?.physicalPerson === "Pessoa Jurídica"
            ? props.data !== undefined
            : undefined,
        isEmailValid: props.data !== undefined ? true : undefined,
        isEmailUnique: props.data !== undefined ? true : undefined,
        isNameValid: props.data !== undefined ? true : undefined,
        isCellphoneValid: props.data !== undefined ? true : undefined,
        isCepValid: props.data !== undefined ? true : undefined,
        isCityValid: props.data !== undefined ? true : undefined,
        isStateValid: props.data !== undefined ? true : undefined,
        isNeighborhoodValid: props.data !== undefined ? true : undefined,
        isPlaceNumberValid: props.data !== undefined ? true : undefined,
        isStreetValid: props.data !== undefined ? true : undefined,
        isAgencyValid: props.data !== undefined ? true : undefined,
        isOperationValid: props.data !== undefined ? true : undefined,
        isAccountValid: props.data !== undefined ? true : undefined,
        isBankValid: props.data !== undefined ? true : undefined,
        isCPFUserValid: undefined,
        isCPFUserUnique: undefined,
        isEmailUserValid: undefined,
        isEmailUserUnique: undefined,
        isNameUserValid: undefined,
        isRoleValid: undefined,
        isRoleCell: undefined,
        isPrivilegesValid: undefined,
        isPasswordValid: undefined,
        isProspectNameValid: props?.data?.prospector?.name ? true : undefined,
        isBeginDateValid: props?.data?.beginDate ? true : undefined,
        isEndDateValid: props?.data?.endDate ? true : undefined,
        isParticipationValueValid: props?.data?.participationValue
          ? true
          : undefined,
        isDiscountValid: props?.data?.discount ? true : undefined,
        isTicketParcelValid: props?.data?.ticketParcel ? true : undefined,
        isCreditCardValid: props?.data?.creditCardParcel ? true : undefined,
      },
      states: [
        { id: "AC", name: "Acre" },
        { id: "AL", name: "Alagoas" },
        { id: "AP", name: "Amapá" },
        { id: "AM", name: "Amazonas" },
        { id: "BA", name: "Bahia" },
        { id: "CE", name: "Ceará" },
        { id: "ES", name: "Espírito Santo" },
        { id: "GO", name: "Goiás" },
        { id: "MA", name: "Maranhão" },
        { id: "MT", name: "Mato Grosso" },
        { id: "MS", name: "Mato Grosso do Sul" },
        { id: "MG", name: "Minas Gerais" },
        { id: "PA", name: "Pará" },
        { id: "PB", name: "Paraíba" },
        { id: "PR", name: "Paraná" },
        { id: "PE", name: "Pernambuco" },
        { id: "PI", name: "Piauí" },
        { id: "RJ", name: "Rio de Janeiro" },
        { id: "RN", name: "Rio Grande do Norte" },
        { id: "RS", name: "Rio Grande do Sul" },
        { id: "RO", name: "Rondônia" },
        { id: "RR", name: "Roraima" },
        { id: "SC", name: "Santa Catarina" },
        { id: "SP", name: "São Paulo" },
        { id: "SE", name: "Sergipe" },
        { id: "TO", name: "Tocantins" },
        { id: "DF", name: "Distrito Federal" },
      ],
    };
  }

  componentDidMount = () => {
    modalPartnerMount(this.props, (state) => this.setState(state));
  };

  passwordHandleClick = () =>
    this.setState(({ type }) => ({
      type: type === "password" ? "text" : "password",
    }));

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "placeNumber") {
      this.setState((state) => ({
        ...state,
        [name]: value.replace(/\D/g, ""),
      }));
    } else if (name === "nameUser" || name === "name") {
      this.setState({ [name]: value.toUpperCase() });
    } else {
      this.setState((state) => ({ ...state, [name]: value }));
    }
  };
  handleIsOutsourceChange = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;
    this.setState((state) => ({
      ...state,
      isOutsource: value,
      participationValue: "",
      validFields: { ...validFields, isParticipationValueValid: undefined },
    }));
  };

  handleOutsourceChoise = (e) => {
    e.persist();
    const { name, value } = e.target;
    const { validFields } = this.state;
    if (this.props.from === "partner") {
      this.setState((state) => ({
        [name]: value,
        cpf: "",
        cnpj: "",
        validFields: {
          ...validFields,
          isCPFValid: undefined,
          isCPFUnique: undefined,
          isCNPJValid: undefined,
          isCNPJUnique: undefined,
        },
      }));
    } else {
      let bankData = { agency: "", operation: "", account: "", bank: "" };
      this.setState((state) => ({
        ...state,
        [name]: value,
        cpf: "",
        cnpj: "",
        email: "",
        name: "",
        cellphone: "",
        cep: "",
        street: "",
        placeNumber: "",
        neighborhood: "",
        city: "",
        state: "",
        bankData,
        additionalInformation: "",
        validFields: {
          ...validFields,
          isCPFValid: undefined,
          isCPFUnique: undefined,
          isCNPJValid: undefined,
          isCNPJUnique: undefined,
          isEmailValid: undefined,
          isEmailUnique: undefined,
          isNameValid: undefined,
          isCellphoneValid: undefined,
          isPlaceNumberValid: undefined,
          isCepValid: undefined,
          isStreetValid: undefined,
          isNeighborhoodValid: undefined,
          isCityValid: undefined,
          isStateValid: undefined,
          isAgencyValid: undefined,
          isOperationValid: undefined,
          isAccountValid: undefined,
          isBankValid: undefined,
        },
      }));
    }
  };

  handlePrivileges = (e) => {
    e.persist();
    const { privileges } = this.state;
    const { value } = e.target;
    let privilegesChoise = this.state.privilegesChoise;
    if (
      value === "configuracoes/grupo_de_trabalho" ||
      value === "configuracoes/parceiros" ||
      value === "configuracoes/usuarios"
    ) {
      Object.values(privileges).forEach((element) => {
        if (
          element.name === "configuracoes/grupo_de_trabalho" ||
          element.name === "configuracoes/parceiros" ||
          element.name === "configuracoes/usuarios"
        ) {
          privilegesChoise.push(element);
          delete privileges[element.name];
        }
      });
    } else {
      Object.values(privileges).forEach((element) => {
        if (element.name === value) {
          if (!privilegesChoise.some((priv) => priv.name === value)) {
            privilegesChoise.push(element);
            delete privileges[element.name];
          }
        }
      });
    }
    this.setState({ privilegesChoise });
  };

  changePrivileges = (item, value) => {
    const { privilegesChoise } = this.state;
    item[value] = !item[value];
    this.setState({ privilegesChoise });
  };

  removePrivilegeChoise = (item) => {
    const { validFields, privilegesChoise, privileges } = this.state;
    if (
      item.name === "configuracoes/grupo_de_trabalho" ||
      item.name === "configuracoes/parceiros" ||
      item.name === "configuracoes/usuarios"
    ) {
      privileges["configuracoes/grupo_de_trabalho"] = privilegesChoise.find(
        (element) => element.name === "configuracoes/grupo_de_trabalho"
      );
      privileges["configuracoes/parceiros"] = privilegesChoise.find(
        (element) => element.name === "configuracoes/parceiros"
      );
      privileges["configuracoes/usuarios"] = privilegesChoise.find(
        (element) => element.name === "configuracoes/usuarios"
      );
      privilegesChoise.splice(
        privilegesChoise.indexOf("configuracoes/grupo_de_trabalho"),
        1
      );
      privilegesChoise.splice(
        privilegesChoise.indexOf("configuracoes/parceiros"),
        1
      );
      privilegesChoise.splice(
        privilegesChoise.indexOf("configuracoes/usuarios"),
        1
      );
    } else {
      privileges[item.name] = item;
      privilegesChoise.splice(privilegesChoise.indexOf(item), 1);
    }
    validFields.isPrivilegesValid = privilegesChoise?.length > 0;
    this.setState({ privilegesChoise, validFields, privileges });
  };

  handleBankData = (e) => {
    e.persist();
    const { name, value } = e.target;
    const { bankData } = this.state;
    this.setState((state) => ({
      ...state,
      bankData: { ...bankData, [name]: value },
    }));
  };

  handleParticipationValue = (e) => {
    e.persist();
    let { value } = e.target;
    const { isOutsource } = this.state;
    if (isOutsource === "Indicador") {
      value = value < 0 ? 0 : value;
    } else {
      value = value < 0 ? 0 : value > 100 ? 100 : value;
    }

    this.setState({ participationValue: value });
  };

  cpfClassNameAndMessage = (isClassName) => {
    const { validFields } = this.state;
    if (
      validFields.isCPFValid === undefined &&
      validFields.isCPFUnique === undefined
    ) {
      return isClassName ? "" : "";
    } else {
      if (validFields.isCPFValid) {
        if (validFields.isCPFUnique) {
          return isClassName ? "is-valid" : "";
        } else {
          return isClassName
            ? "is-invalid"
            : "O CPF digitado já está cadastrado";
        }
      } else {
        return isClassName
          ? "is-invalid"
          : "O CPF digitado não está no formato válido";
      }
    }
  };

  cnpjClassNameAndMessage = (isClassName) => {
    const { validFields } = this.state;
    if (
      validFields.isCNPJValid === undefined &&
      validFields.isCNPJUnique === undefined
    ) {
      return isClassName ? "" : "";
    } else {
      if (validFields.isCNPJValid) {
        if (validFields.isCNPJUnique) {
          return isClassName ? "is-valid" : "";
        } else {
          return isClassName
            ? "is-invalid"
            : "O CNPJ digitado já está cadastrado";
        }
      } else {
        return isClassName
          ? "is-invalid"
          : "O CNPJ digitado não está no formato válido";
      }
    }
  };

  emailClassNameAndMessage = (isClassName) => {
    const { validFields } = this.state;
    if (
      validFields.isEmailValid === undefined &&
      validFields.isEmailUnique === undefined
    ) {
      return isClassName ? "" : "";
    } else {
      if (validFields.isEmailValid) {
        if (validFields.isEmailUnique) {
          return isClassName ? "is-valid" : "";
        } else {
          return isClassName
            ? "is-invalid"
            : "O E-mail digitado já está cadastrado";
        }
      } else {
        return isClassName
          ? "is-invalid"
          : "O E-mail digitado não está no formato válido";
      }
    }
  };

  cpfUserClassNameAndMessage = (isClassName) => {
    const { validFields } = this.state;
    if (
      validFields.isCPFUserValid === undefined &&
      validFields.isCPFUserUnique === undefined
    ) {
      return isClassName ? "" : "";
    } else {
      if (validFields.isCPFUserValid) {
        if (validFields.isCPFUserUnique) {
          return isClassName ? "is-valid" : "";
        } else {
          return isClassName
            ? "is-invalid"
            : "O CPF digitado já está cadastrado";
        }
      } else {
        return isClassName
          ? "is-invalid"
          : "O CPF digitado não está no formato válido";
      }
    }
  };

  emailUserClassNameAndMessage = (isClassName) => {
    const { validFields } = this.state;
    if (
      validFields.isEmailUserValid === undefined &&
      validFields.isEmailUserUnique === undefined
    ) {
      return isClassName ? "" : "";
    } else {
      if (validFields.isEmailUserValid) {
        if (validFields.isEmailUserUnique) {
          return isClassName ? "is-valid" : "";
        } else {
          return isClassName
            ? "is-invalid"
            : "O E-mail digitado já está cadastrado";
        }
      } else {
        return isClassName
          ? "is-invalid"
          : "O E-mail digitado não está no formato válido";
      }
    }
  };

  checkAllFieldsIsValid = () => {
    const {
      cellName,
      privilegesChoise,
      name,
      password,
      cellphone,
      cep,
      street,
      placeNumber,
      neighborhood,
      city,
      state,
      nameUser,
      validFields,

      isTicketParcel,
      isCreditCard,
    } = this.state;
    let isCellNameValid = cellName !== "";
    let isPrivilegesValid = Object.values(privilegesChoise).length > 0;

    let isCNPJValid =
      validFields.isCNPJValid !== undefined ? validFields.isCNPJValid : false;
    let isCNPJUnique =
      validFields.isCNPJUnique !== undefined ? validFields.isCNPJUnique : false;
    let isCPFValid =
      validFields.isCPFValid !== undefined ? validFields.isCPFValid : false;
    let isCPFUnique =
      validFields.isCPFUnique !== undefined ? validFields.isCPFUnique : false;
    let isEmailValid =
      validFields.isEmailValid !== undefined ? validFields.isEmailValid : false;
    let isEmailUnique =
      validFields.isEmailUnique !== undefined
        ? validFields.isEmailUnique
        : false;
    let isNameValid = name !== undefined && name !== "";
    let isCellphoneValid = cellphone !== undefined && cellphone !== "";
    let isCepValid = cep !== undefined && cep !== "";
    let isStreetValid = street !== undefined && street !== "";
    let isPlaceNumberValid = placeNumber !== undefined && placeNumber !== "";
    let isNeighborhoodValid = neighborhood !== undefined && neighborhood !== "";
    let isCityValid = city !== undefined && city !== "";
    let isStateValid = state !== undefined && state !== "";
    let isAgencyValid =
      validFields.isAgencyValid !== undefined
        ? validFields.isAgencyValid
        : false;
    let isOperationValid =
      validFields.isOperationValid !== undefined
        ? validFields.isOperationValid
        : false;
    let isAccountValid =
      validFields.isAccountValid !== undefined
        ? validFields.isAccountValid
        : false;
    let isBankValid =
      validFields.isBankValid !== undefined ? validFields.isBankValid : false;
    let isCPFUserValid =
      validFields.isCPFUserValid !== undefined
        ? validFields.isCPFUserValid
        : false;
    let isCPFUserUnique =
      validFields.isCPFUserUnique !== undefined
        ? validFields.isCPFUserUnique
        : false;
    let isEmailUserValid =
      validFields.isEmailUserValid !== undefined
        ? validFields.isEmailUserValid
        : false;
    let isEmailUserUnique =
      validFields.isEmailUserUnique !== undefined
        ? validFields.isEmailUserUnique
        : false;
    let isNameUserValid = nameUser !== undefined && nameUser !== "";
    let isPasswordValid = password !== undefined && password !== "";
    let isProspectNameValid =
      validFields.isProspectNameValid !== undefined
        ? validFields.isProspectNameValid
        : false;
    let isBeginDateValid =
      validFields.isBeginDateValid !== undefined
        ? validFields.isBeginDateValid
        : false;
    let isEndDateValid =
      validFields.isEndDateValid !== undefined
        ? validFields.isEndDateValid
        : false;
    let isParticipationValueValid =
      validFields.isParticipationValueValid !== undefined
        ? validFields.isParticipationValueValid
        : false;
    let isDiscountValid =
      validFields.isDiscountValid !== undefined
        ? validFields.isDiscountValid
        : false;
    let isTicketParcelValid = isTicketParcel
      ? validFields.isTicketParcelValid !== undefined
        ? validFields.isTicketParcelValid
        : false
      : undefined;
    let isCreditCardValid = isCreditCard
      ? validFields.isCreditCardValid !== undefined
        ? validFields.isCreditCardValid
        : false
      : undefined;
    this.setState((state) => ({
      ...state,
      validFields: {
        ...validFields,
        isCellNameValid,
        isPrivilegesValid,
        isCNPJValid,
        isCNPJUnique,
        isCPFValid,
        isCPFUnique,
        isEmailValid,
        isEmailUnique,
        isPasswordValid,
        isNameValid,
        isCepValid,
        isCellphoneValid,
        isStreetValid,
        isPlaceNumberValid,
        isNeighborhoodValid,
        isCityValid,
        isStateValid,
        isAgencyValid,
        isOperationValid,
        isAccountValid,
        isBankValid,

        isCPFUserValid,
        isCPFUserUnique,
        isEmailUserValid,
        isEmailUserUnique,
        isNameUserValid,

        isProspectNameValid,
        isBeginDateValid,
        isEndDateValid,
        isParticipationValueValid,
        isDiscountValid,
        isTicketParcelValid,
        isCreditCardValid,
      },
    }));
  };

  isPrivilegesValid = () => {
    const { privilegesChoise, validFields } = this.state;
    validFields.isPrivilegesValid = Object.values(privilegesChoise).length > 0;
    this.setState({ validFields });
  };

  isEmailValid = (e) => {
    e.persist();
    const { validFields } = this.state;
    let email =
      e.target.name === "email" ? this.state.email : this.state.emailUser;
    let isEmailValid =
      email !== ""
        ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
        : false;
    let isEmailUnique = undefined;
    if (isEmailValid) {
      if (this.props.from === "partner") {
        this.setState((state) => ({
          validFields: {
            ...state.validFields,
            isEmailUserValid: isEmailValid,
            isEmailUserUnique: true,
          },
        }));
      } else {
        hasEmailAvailable({ email: email }).then((res) => {
          if (res.ok) {
            if (res.data) {
              isEmailUnique = true;
              if (e.target.name === "email") {
                this.setState((state) => ({
                  ...state,
                  validFields: { ...validFields, isEmailValid, isEmailUnique },
                }));
              } else {
                this.setState((state) => ({
                  ...state,
                  validFields: {
                    ...validFields,
                    isEmailUserValid: isEmailValid,
                    isEmailUserUnique: isEmailUnique,
                  },
                }));
              }
            } else {
              isEmailUnique = false;
              if (e.target.name === "email") {
                this.setState((state) => ({
                  ...state,
                  validFields: { ...validFields, isEmailValid, isEmailUnique },
                }));
              } else {
                this.setState((state) => ({
                  ...state,
                  validFields: {
                    ...validFields,
                    isEmailUserValid: isEmailValid,
                    isEmailUserUnique: isEmailUnique,
                  },
                }));
              }
            }
          }
        });
      }
    } else {
      if (e.target.name === "email") {
        this.setState((state) => ({
          ...state,
          validFields: { ...validFields, isEmailValid },
        }));
      } else {
        this.setState((state) => ({
          ...state,
          validFields: {
            ...validFields,
            isEmailUserValid: isEmailValid,
          },
        }));
      }
    }
  };

  isPasswordValid = () => {
    const { password } = this.state;
    let isPasswordValid = password !== undefined && password !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isPasswordValid },
    }));
  };

  isNameValid = (e) => {
    e.persist();
    let name = e.target.name === "name" ? this.state.name : this.state.nameUser;
    let isNameValid = !validations.isValidFullname(name);
    if (e.target.name === "name") {
      this.setState((state) => ({
        ...state,
        validFields: { ...this.state.validFields, isNameValid },
      }));
    } else {
      this.setState((state) => ({
        ...state,
        validFields: {
          ...this.state.validFields,
          isNameUserValid: isNameValid,
        },
      }));
    }
  };

  isCellNameValid = () => {
    const { cellName } = this.state;
    let isCellNameValid = cellName !== undefined && cellName !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isCellNameValid },
    }));
  };

  isCNPJValid = () => {
    const { cnpj, validFields } = this.state;
    let isCNPJValid = cnpj !== undefined && this.validateCNPJ(cnpj);
    if (isCNPJValid) {
      getOutsourceCnpjCpf({
        cnpj_cpf: cnpj.replace(/\./g, "").replace(/\//g, "").replace(/-/g, ""),
      }).then((res) => {
        if (res.ok) {
          if (res.data) {
            this.setState((state) => ({
              ...state,
              disabledPartnerField:
                this.props.from === "partner" ? false : true,
              email: res.data.email,
              name: res.data.fullNameOrCompanyName,
              cellphone: res.data.phone,
              cep: res.data.addressZipCode,
              street: res.data.addressStreet,
              placeNumber: res.data.addressNumber,
              neighborhood: res.data.addressDistrict,
              city: res.data.addressCity,
              state: res.data.addressState,
              bankData: JSON.parse(res.data.bankData),
              additionalInformation: res.data.additionalInformation,
              validFields: {
                ...validFields,
                isCNPJValid,
                isCNPJUnique: true,
                isEmailValid: true,
                isEmailUnique: true,
                isNameValid: true,
                isCellphoneValid: true,
                isPlaceNumberValid: true,
                isCepValid: true,
                isStreetValid: true,
                isNeighborhoodValid: true,
                isCityValid: true,
                isStateValid: true,
                isAgencyValid: true,
                isOperationValid: true,
                isAccountValid: true,
                isBankValid: true,
              },
            }));
          } else {
            if (this.props.from === "partner") {
              this.setState((state) => ({
                validFields: {
                  ...validFields,
                  isCNPJValid,
                  isCNPJUnique: true,
                },
              }));
            } else {
              let bankData = {
                agency: "",
                operation: "",
                account: "",
                bank: "",
              };
              this.setState((state) => ({
                ...state,
                disabledPartnerField: false,
                email: "",
                name: "",
                cellphone: "",
                cep: "",
                street: "",
                placeNumber: "",
                neighborhood: "",
                city: "",
                state: "",
                bankData,
                additionalInformation: "",
                validFields: {
                  ...validFields,
                  isCNPJValid,
                  isCNPJUnique: true,
                  isEmailValid: undefined,
                  isEmailUnique: undefined,
                  isNameValid: undefined,
                  isCellphoneValid: undefined,
                  isPlaceNumberValid: undefined,
                  isCepValid: undefined,
                  isStreetValid: undefined,
                  isNeighborhoodValid: undefined,
                  isCityValid: undefined,
                  isStateValid: undefined,
                  isAgencyValid: undefined,
                  isOperationValid: undefined,
                  isAccountValid: undefined,
                  isBankValid: undefined,
                },
              }));
            }
          }
        }
      });
    } else {
      this.setState((state) => ({
        ...state,
        validFields: { ...validFields, isCNPJValid, isCNPJUnique: undefined },
      }));
    }
  };

  validateCNPJ = (cnpj) => {
    cnpj = cnpj
      .replace(/\./g, "")
      .replace(/\//g, "")
      .replace(/-/g, "")
      .replace(/_/g, "");
    if (cnpj.length !== 14) {
      return false;
    }

    let sum = 0;
    let rest;
    let count = 5;
    for (let i = 1; i <= 12; i++) {
      sum += parseInt(cnpj.substring(i - 1, i), 10) * count;
      if (count === 2) {
        count = 10;
      }
      count -= 1;
    }
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) {
      rest = 0;
    }
    if (rest !== parseInt(cnpj.substring(12, 13), 10)) {
      return false;
    }

    sum = 0;
    count = 6;
    for (let i = 1; i <= 13; i++) {
      sum += parseInt(cnpj.substring(i - 1, i), 10) * count;
      if (count === 2) {
        count = 10;
      }
      count -= 1;
    }
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) {
      rest = 0;
    }
    if (rest !== parseInt(cnpj.substring(13, 14), 10)) {
      return false;
    }
    return true;
  };

  isCPFValid = (e) => {
    e.persist();
    const { validFields } = this.state;
    let cpf = e.target.name === "cpf" ? this.state.cpf : this.state.cpfUser;
    let isCPFValid = cpf !== undefined && this.validateCPF(cpf);
    if (e.target.name === "cpf") {
      if (isCPFValid) {
        getOutsourceCnpjCpf({
          cnpj_cpf: cpf.replace(/\./g, "").replace(/-/g, ""),
        }).then((res) => {
          if (res.ok) {
            if (res.data) {
              this.setState((state) => ({
                ...state,
                disabledPartnerField:
                  this.props.from === "partner" ? false : true,
                email: res.data.email,
                name: res.data.fullNameOrCompanyName,
                cellphone: res.data.phone,
                cep: res.data.addressZipCode,
                street: res.data.addressStreet,
                placeNumber: res.data.addressNumber,
                neighborhood: res.data.addressDistrict,
                city: res.data.addressCity,
                state: res.data.addressState,
                bankData: JSON.parse(res.data.bankData),
                additionalInformation: res.data.additionalInformation,
                validFields: {
                  ...validFields,
                  isCPFValid,
                  isCPFUnique: true,
                  isEmailValid: true,
                  isEmailUnique: true,
                  isNameValid: true,
                  isCellphoneValid: true,
                  isPlaceNumberValid: true,
                  isCepValid: true,
                  isStreetValid: true,
                  isNeighborhoodValid: true,
                  isCityValid: true,
                  isStateValid: true,
                  isAgencyValid: true,
                  isOperationValid: true,
                  isAccountValid: true,
                  isBankValid: true,
                },
              }));
            } else {
              hasCpfAvailable({
                cpf: cpf.replace(/\./g, "").replace(/-/g, ""),
              }).then((res) => {
                if (res.ok) {
                  if (this.props.from === "partner") {
                    this.setState((state) => ({
                      ...state,
                      validFields: {
                        ...validFields,
                        isCPFValid: isCPFValid,
                        isCPFUnique: res.data,
                      },
                    }));
                  } else {
                    let bankData = {
                      agency: "",
                      operation: "",
                      account: "",
                      bank: "",
                    };
                    this.setState((state) => ({
                      ...state,
                      disabledPartnerField: false,
                      cnpj: "",
                      email: "",
                      name: "",
                      cellphone: "",
                      cep: "",
                      street: "",
                      placeNumber: "",
                      neighborhood: "",
                      city: "",
                      state: "",
                      bankData,
                      additionalInformation: "",
                      validFields: {
                        ...validFields,
                        isCPFValid: isCPFValid,
                        isCPFUnique: res.data,
                        isCNPJValid: undefined,
                        isCNPJUnique: undefined,
                        isEmailValid: undefined,
                        isEmailUnique: undefined,
                        isNameValid: undefined,
                        isCellphoneValid: undefined,
                        isPlaceNumberValid: undefined,
                        isCepValid: undefined,
                        isStreetValid: undefined,
                        isNeighborhoodValid: undefined,
                        isCityValid: undefined,
                        isStateValid: undefined,
                        isAgencyValid: undefined,
                        isOperationValid: undefined,
                        isAccountValid: undefined,
                        isBankValid: undefined,
                      },
                    }));
                  }
                }
              });
            }
          }
        });
      } else {
        this.setState((state) => ({
          ...state,
          validFields: {
            ...validFields,
            isCPFValid,
          },
        }));
      }
    } else if (e.target.name === "cpfUser") {
      if (isCPFValid) {
        hasCpfAvailable({ cpf: cpf.replace(/\./g, "").replace(/-/g, "") }).then(
          (res) => {
            if (res.ok) {
              this.setState((state) => ({
                ...state,
                validFields: {
                  ...validFields,
                  isCPFUserValid: isCPFValid,
                  isCPFUserUnique: res.data,
                },
              }));
            }
          }
        );
      } else {
        this.setState((state) => ({
          ...state,
          validFields: {
            ...validFields,
            isCPFUserValid: isCPFValid,
          },
        }));
      }
    }
  };

  validateCPF = (cpf) => {
    cpf = cpf.replace(/\./g, "");
    cpf = cpf.replace(/-/g, "");

    let ar = cpf.split("");
    let count = 0;
    let sum = 0;
    for (let i = 10; i >= 2; i--) {
      sum += parseInt(ar[count]) * i;
      count++;
    }
    let restoPrimeiro = sum % 11;
    restoPrimeiro < 2
      ? (restoPrimeiro = 0)
      : (restoPrimeiro = 11 - restoPrimeiro);
    let primeiroDigito = restoPrimeiro === parseInt(ar[9]);
    count = 0;
    sum = 0;
    for (let i = 11; i >= 2; i--) {
      sum += parseInt(ar[count]) * i;
      count++;
    }
    let restoSegundo = sum % 11;
    restoSegundo < 2 ? (restoSegundo = 0) : (restoSegundo = 11 - restoSegundo);
    let segundoDigito = restoSegundo === parseInt(ar[10]);
    return primeiroDigito && segundoDigito;
  };

  isCellphoneValid = () => {
    const { cellphone, validFields } = this.state;
    let cellphoneAux = cellphone.replace(/_/g, "");
    let isCellphoneValid =
      cellphoneAux !== undefined &&
      cellphoneAux !== "" &&
      cellphoneAux.length >= 14;
    // cellphoneAux.includes("_")
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isCellphoneValid },
    }));
  };

  isCepValid = () => {
    const { validFields, cep } = this.state;
    CEP(cep)
      .then((res) => {
        let { street, city, neighborhood, state, states } = this.state;
        let addressState = state;
        street = res.street;
        city = res.city;
        neighborhood = res.neighborhood;

        for (let i = 0; i < states.length; i++) {
          if (res.state === states[i].id) {
            addressState = states[i].name;
            break;
          }
        }
        let isCepValid = cep !== "";
        this.setState((state) => ({
          ...state,
          street,
          city,
          neighborhood,
          state: addressState,
          validFields: { ...validFields, isCepValid },
        }));
        this.isStreetValid();
        this.isCityValid();
        this.isNeighborhoodValid();
        this.isStateValid();
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          validFields: { ...validFields, isCepValid: false },
        }));
      });
  };

  isCityValid = () => {
    const { validFields, city } = this.state;
    let isCityValid = city !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isCityValid },
    }));
  };

  isStreetValid = () => {
    const { validFields, street } = this.state;
    let isStreetValid = street !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isStreetValid },
    }));
  };

  isNeighborhoodValid = () => {
    const { validFields, neighborhood } = this.state;
    let isNeighborhoodValid = neighborhood !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isNeighborhoodValid },
    }));
  };

  isStateValid = () => {
    const { validFields, state } = this.state;
    let isStateValid = state !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isStateValid },
    }));
  };

  isPlaceNumberValid = () => {
    const { validFields, placeNumber } = this.state;
    let isPlaceNumberValid = placeNumber !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isPlaceNumberValid },
    }));
  };

  isAgencyValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;

    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isAgencyValid: value !== "" },
    }));
  };

  isOperationValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;

    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isOperationValid: value !== "" },
    }));
  };

  isAccountValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;

    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isAccountValid: value !== "" },
    }));
  };

  isBankValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;

    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isBankValid: value !== "" },
    }));
  };

  isProspectNameValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;

    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isProspectNameValid: value !== "" },
    }));
  };

  isDateValid = (e) => {
    e.persist();
    const name =
      e.target.name === "beginDate" ? "isBeginDateValid" : "isEndDateValid";
    const value = e.target.value;
    const { validFields } = this.state;
    let date = parse(value, "dd/MM/yyyy", new Date());
    let isValid;
    if (date.getTime) {
      if (!isNaN(date.getTime())) {
        if (e.target.name === "endDate") {
          isValid =
            date > parse(this.state.beginDate, "dd/MM/yyyy", new Date());
        } else {
          isValid = date > new Date("01/01/1900");
        }
      } else {
        isValid = false;
      }
    } else {
      isValid = false;
    }
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, [name]: isValid },
    }));
  };

  isParticipationValueValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields, isOutsource } = this.state;
    let valid = undefined;
    if (isOutsource === "Indicador") {
      valid = value !== null && value !== "";
    } else {
      valid =
        value !== null && value !== "" ? value >= 0 && value <= 100 : false;
    }
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isParticipationValueValid: valid },
    }));
  };

  isParcelValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;
    let name =
      e.target.name === "ticketParcel"
        ? "isTicketParcelValid"
        : "isCreditCardValid";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, [name]: value !== "" },
    }));
  };

  submit = () => {
    const {
      isOutsource,
      physicalPerson,
      cellName,
      email,
      name,
      cpf,
      cnpj,
      cellphone,
      cep,
      street,
      placeNumber,
      neighborhood,
      city,
      state,
      bankData,
      additionalInformation,
      cpfUser,
      emailUser,
      nameUser,
      password,
      isTicketParcel,
      ticketParcel,
      isCreditCard,
      creditCardParcel,
      prospectName,
      beginDate,
      endDate,
      participationValue,
      discount,
      validFields,
    } = this.state;
    const { cellId, data, isIntern } = this.props;
    this.checkAllFieldsIsValid();
    let cnpjOrCpfValid =
      physicalPerson === "Pessoa Jurídica"
        ? validFields.isCNPJValid && validFields.isCNPJUnique
        : validFields.isCPFValid && validFields.isCPFUnique;
    this.setState(
      (state) => ({ ...state, disabledSubmitBtn: true }),
      () => {
        if (data === undefined) {
          let validOutsource =
            validFields.isNameValid &&
            validFields.isEmailUnique &&
            validFields.isEmailValid &&
            validFields.isCellphoneValid &&
            validFields.isCepValid &&
            validFields.isStreetValid &&
            validFields.isPlaceNumberValid &&
            validFields.isNeighborhoodValid &&
            validFields.isCityValid &&
            validFields.isStateValid &&
            validFields.isAgencyValid &&
            validFields.isOperationValid &&
            validFields.isAccountValid &&
            validFields.isBankValid;
          let valid =
            cnpjOrCpfValid && validFields.isCellNameValid && isIntern
              ? validOutsource
              : validFields.isPrivilegesValid &&
                validFields.isCPFUserValid &&
                validFields.isCPFUserUnique &&
                validFields.isEmailUserValid &&
                validFields.isEmailUserUnique &&
                validFields.isNameUserValid &&
                validFields.isPasswordValid &&
                (isIntern
                  ? validFields.isProspectNameValid &&
                    validFields.isBeginDateValid &&
                    validFields.isEndDateValid &&
                    validFields.isParticipationValueValid &&
                    isOutsource !== "Indicador"
                    ? validFields.isDiscountValid &&
                      validFields.isCreditCardValid &&
                      validFields.isTicketParcelValid
                    : true
                  : true);
          if (valid) {
            let privilegesChoise = [];
            this.state.privilegesChoise.forEach((element) => {
              privilegesChoise.push({
                cellId: null,
                privilegeId: element.privilege.id,
                delete: element.delete !== null ? element.delete : false,
                insert: element.insert !== null ? element.insert : false,
                read: element.read !== null ? element.read : false,
                update: element.update !== null ? element.update : false,
              });
            });

            let prospectorId = "";
            this.state.allUsers.forEach((element) => {
              if (element.name === prospectName) {
                prospectorId = element.id;
              }
            });
            let beginDateSplit = beginDate.split("/");
            let endDateSplit = endDate.split("/");

            let v = {
              name: cellName,
              masterCell: isIntern ? 1 : cellId,
              outsourceDTOInsertRequest: isIntern
                ? {
                    email: email,
                    fullNameOrCompanyName: name.trim(),
                    cpfOrCnpj:
                      physicalPerson === "Pessoa Física"
                        ? masks.removeNumberMask(cpf)
                        : masks.removeNumberMask(cnpj),
                    phone: cellphone,
                    addressStreet: street,
                    addressNumber: placeNumber,
                    addressDistrict: neighborhood,
                    addressCity: city,
                    addressState: state,
                    addressZipCode: cep,
                    bankData: JSON.stringify(bankData),
                    additionalInformation: additionalInformation,
                    physicalPerson: physicalPerson,
                    isOutsource: isOutsource === "Terceirizado",
                    isFinder: isOutsource === "Indicador",
                    cellId: cellId,
                    useTicket: isTicketParcel,
                    ticketParcel:
                      ticketParcel !== "" ? parseInt(ticketParcel) : null,
                    useCreditCard: isCreditCard,
                    creditCardParcel:
                      creditCardParcel !== ""
                        ? parseInt(creditCardParcel)
                        : null,
                    prospectorId,
                    beginDate: new Date(
                      beginDateSplit[2] +
                        "/" +
                        beginDateSplit[1] +
                        "/" +
                        beginDateSplit[0]
                    ),
                    endDate: new Date(
                      endDateSplit[2] +
                        "/" +
                        endDateSplit[1] +
                        "/" +
                        endDateSplit[0]
                    ),
                    participationValue,
                    discount,
                  }
                : null,
              user: {
                cpf: masks.removeNumberMask(cpfUser),
                password: password,
                name: nameUser.trim(),
                email: emailUser,
                isOutsource: isOutsource === "Terceirizado",
                isAccredited: isOutsource === "Accredited",
                isFinder: isOutsource === "Indicador",
              },
              privileges: privilegesChoise,
            };
            putCellInsertWorkGroup(JSON.stringify(v))
              .then((res) => {
                if (res.ok) {
                  this.props.onHide(true);
                  this.props.onAddAlert("Grupo cadastrado.", "success", false);
                } else {
                  this.props.onAddAlert(
                    "Houve um problema ao tentar cadastrar a Célula e o Parceiro, por favor tente mais tarde.",
                    "danger",
                    false
                  );
                  this.setState((state) => ({
                    ...state,
                    disabledSubmitBtn: false,
                  }));
                }
              })
              .catch(() => {
                this.props.onAddAlert(
                  "Houve um problema ao tentar cadastrar a Célula e o Parceiro, por favor tente mais tarde.",
                  "danger",
                  false
                );
                this.setState((state) => ({
                  ...state,
                  disabledSubmitBtn: false,
                }));
              });
          } else {
            this.props.onAddAlert(
              "Todos os campos devem ser preenchidos corretamente.",
              "danger",
              false
            );
            this.setState((state) => ({ ...state, disabledSubmitBtn: false }));
          }
        } else {
          let valid =
            cnpjOrCpfValid &&
            validFields.isEmailUnique &&
            validFields.isEmailValid &&
            validFields.isNameValid &&
            validFields.isCellphoneValid &&
            validFields.isCepValid &&
            validFields.isStreetValid &&
            validFields.isPlaceNumberValid &&
            validFields.isNeighborhoodValid &&
            validFields.isCityValid &&
            validFields.isStateValid &&
            validFields.isAgencyValid &&
            validFields.isOperationValid &&
            validFields.isAccountValid &&
            validFields.isBankValid &&
            validFields.isProspectNameValid &&
            validFields.isBeginDateValid &&
            validFields.isEndDateValid &&
            validFields.isParticipationValueValid;
          if (valid) {
            let prospectorId = "";
            this.state.allUsers.forEach((element) => {
              if (element.name === prospectName) {
                prospectorId = element.id;
              }
            });
            let beginDateSplit = beginDate.split("/");
            let endDateSplit = endDate.split("/");
            let v = {
              id: data.id,
              email: email,
              fullNameOrCompanyName: name,
              cpfOrCnpj:
                physicalPerson === "Pessoa Jurídica"
                  ? masks.removeNumberMask(cnpj)
                  : masks.removeNumberMask(cpf),
              phone: cellphone,
              addressStreet: street,
              addressNumber: placeNumber,
              addressDistrict: neighborhood,
              addressCity: city,
              addressState: state,
              addressZipCode: cep,
              bankData: JSON.stringify(bankData),
              additionalInformation: additionalInformation,
              physicalPerson: physicalPerson,
              isOutsource: isOutsource === "Terceirizado",
              isFinder: isOutsource === "Indicador",
              useCreditCard: isCreditCard,
              creditCardParcel:
                creditCardParcel !== "" ? parseInt(creditCardParcel) : null,
              useTicket: isTicketParcel,
              ticketParcel: ticketParcel !== "" ? parseInt(ticketParcel) : null,
              prospectorId,
              beginDate: new Date(
                beginDateSplit[2] +
                  "/" +
                  beginDateSplit[1] +
                  "/" +
                  beginDateSplit[0]
              ),
              endDate: new Date(
                endDateSplit[2] + "/" + endDateSplit[1] + "/" + endDateSplit[0]
              ),
              participationValue,
              discount,
            };
            updateOutsource(v).then((res) => {
              if (res.ok) {
                this.props.onAddAlert(
                  "Parceiro atualizado com sucesso.",
                  "success",
                  false
                );
                this.props.onHide(true);
              } else {
                this.props.onAddAlert(
                  "Houve um erro ao tentar atualizar o parceiro, tente mais tarde.",
                  "danger",
                  false
                );
              }
            });
          } else {
            this.props.onAddAlert(
              "Todos os campos devem ser preenchidos corretamente para atualizar.",
              "danger",
              false
            );
            this.setState((state) => ({ ...state, disabledSubmitBtn: false }));
          }
        }
      }
    );
  };

  render() {
    const {
      allUsers,
      isOutsource,
      physicalPerson,
      cellName,
      email,
      name,
      cellphone,
      cpf,
      validFields,
      disabledSubmitBtn,
      type,
      cnpj,
      cep,
      street,
      neighborhood,
      city,
      state,
      placeNumber,
      bankData,
      cpfUser,
      nameUser,
      emailUser,
      password,
      additionalInformation,
      privileges,
      privilegesChoise,
      prospectName,
      beginDate,
      endDate,
      participationValue,
      discount,
      isTicketParcel,
      ticketParcels,
      ticketParcel,
      isCreditCard,
      creditCardParcels,
      creditCardParcel,
      disabledField,
      disabledPartnerField,
    } = this.state;
    const { show, onHide, data, isIntern, from } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="lg"
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {data === undefined
              ? "Adicionar Célula e Parceiro"
              : "Editar Parceiro"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data === undefined && (
            <div>
              <Form.Label>
                <h5>Dados da Célula </h5>
              </Form.Label>
              <div className={"partner-border"} />
              <div className={"m-2"}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Nome da célula{" "}
                        <Form.Label className="is-required">*</Form.Label>
                      </Form.Label>
                      <InputMask
                        name={"cellName"}
                        onChange={this.handleInputChange}
                        onBlur={() => this.isCellNameValid()}
                        value={cellName}
                      >
                        <Form.Control
                          type="text"
                          name="cellName"
                          className={
                            validFields.isCellNameValid === undefined
                              ? ""
                              : validFields.isCellNameValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        />
                      </InputMask>
                      <Form.Text className="is-required">
                        {validFields.isCellNameValid === undefined
                          ? ""
                          : validFields.isCellNameValid
                          ? ""
                          : "O campo nome da célula não deve ser vazio"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>
                        Privilégios{" "}
                        <Form.Label className="is-required">*</Form.Label>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={state}
                        onChange={this.handlePrivileges}
                        onBlur={() => this.isPrivilegesValid()}
                      >
                        <option />
                        {privileges &&
                          Object.values(privileges).map((value, i) => {
                            return <option key={i}> {value.name}</option>;
                          })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Privilégios escolhidos para célula
                      </Form.Label>
                      <Table
                        striped
                        bordered
                        hover
                        className="table table-bordered cell-table-responsible bms-color-text "
                        responsive={true}
                      >
                        <thead className={"text-center"}>
                          <tr>
                            <th>Nome</th>
                            <th>Criar</th>
                            <th>Ver</th>
                            <th>Editar</th>
                            <th>Excluir</th>
                            <th>Remover da lista</th>
                          </tr>
                        </thead>
                        <tbody className={"text-center"}>
                          {privilegesChoise !== null &&
                            Object.values(privilegesChoise).map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td>
                                    <Form.Check
                                      readOnly={true}
                                      checked={item.insert}
                                      value={item.insert}
                                      onClick={() =>
                                        this.changePrivileges(item, "insert")
                                      }
                                      disabled={
                                        item?.insert === null ||
                                        item.name ===
                                          "configuracoes/parceiros" ||
                                        item.name === "configuracoes/usuarios"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Form.Check
                                      readOnly={true}
                                      checked={item.read}
                                      value={item.read}
                                      onClick={() =>
                                        this.changePrivileges(item, "read")
                                      }
                                      disabled={
                                        item?.read === null ||
                                        item.name ===
                                          "configuracoes/parceiros" ||
                                        item.name === "configuracoes/usuarios"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Form.Check
                                      readOnly={true}
                                      checked={item.update}
                                      value={item.update}
                                      onClick={() =>
                                        this.changePrivileges(item, "update")
                                      }
                                      disabled={
                                        item?.update === null ||
                                        item.name ===
                                          "configuracoes/parceiros" ||
                                        item.name === "configuracoes/usuarios"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Form.Check
                                      readOnly={true}
                                      checked={item.delete}
                                      value={item.delete}
                                      onClick={() =>
                                        this.changePrivileges(item, "delete")
                                      }
                                      disabled={
                                        item?.delete === null ||
                                        item.name ===
                                          "configuracoes/parceiros" ||
                                        item.name === "configuracoes/usuarios"
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      onClick={() =>
                                        this.removePrivilegeChoise(item)
                                      }
                                      className="fas fa-trash-alt is-required clickable"
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      <Form.Text className="is-required">
                        {validFields.isPrivilegesValid === undefined
                          ? ""
                          : validFields.isPrivilegesValid
                          ? ""
                          : "A lista de privilégios não deve ser vazia"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          )}

          {/* Dados do Parceiro */}
          {data === undefined && isIntern && (
            <div>
              <Form.Label className={"mt-2"}>
                <h5>Dados do Parceiro</h5>
              </Form.Label>
              <div className={"partner-border"} />{" "}
            </div>
          )}
          {(from === "partner" || isIntern) && (
            <div className={"m-2"}>
              <Row className={"text-center"}>
                <Col>
                  <Form.Check
                    id={"outsourced"}
                    type={"radio"}
                    label={"Terceirizado"}
                    value={"Terceirizado"}
                    name={"isOutsource"}
                    onChange={(e) => this.handleIsOutsourceChange(e)}
                    checked={isOutsource === "Terceirizado"}
                  />
                </Col>
                <Col>
                  <Form.Check
                    id={"accredited"}
                    type={"radio"}
                    label={"Credenciado"}
                    value={"Credenciado"}
                    name={"isOutsource"}
                    onChange={(e) => this.handleIsOutsourceChange(e)}
                    checked={isOutsource === "Credenciado"}
                  />
                </Col>
                <Col>
                  <Form.Check
                    id={"finder"}
                    type={"radio"}
                    label={"Indicador"}
                    value={"Indicador"}
                    name={"isOutsource"}
                    onChange={(e) => this.handleIsOutsourceChange(e)}
                    checked={isOutsource === "Indicador"}
                  />
                </Col>
              </Row>
              <Row className={"text-center mt-2"}>
                <Col>
                  <Form.Check
                    id={"legalPerson"}
                    type={"radio"}
                    label={"Pessoa Jurídica"}
                    value={"Pessoa Jurídica"}
                    name={"physicalPerson"}
                    onChange={(e) => this.handleOutsourceChoise(e)}
                    checked={physicalPerson === "Pessoa Jurídica"}
                    disabled={this.props.from === "partner"}
                  />
                </Col>
                <Col>
                  <Form.Check
                    id={"physicalPerson"}
                    type={"radio"}
                    label={"Pessoa Física"}
                    value={"Pessoa Física"}
                    name={"physicalPerson"}
                    onChange={(e) => this.handleOutsourceChoise(e)}
                    checked={physicalPerson === "Pessoa Física"}
                    disabled={this.props.from === "partner"}
                  />
                </Col>
              </Row>
              <Row>
                {physicalPerson === "Pessoa Jurídica" ? (
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        CNPJ <Form.Label className="is-required">*</Form.Label>
                      </Form.Label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        value={cnpj}
                        onChange={this.handleInputChange}
                        onBlur={() => this.isCNPJValid()}
                        placeholder="12.123.123/1234-12"
                        disabled={this.props.from === "partner"}
                      >
                        <Form.Control
                          type="text"
                          name="cnpj"
                          className={this.cnpjClassNameAndMessage(true)}
                        />
                      </InputMask>
                      <Form.Text className="is-required">
                        {this.cnpjClassNameAndMessage(false)}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        CPF <Form.Label className="is-required">*</Form.Label>
                      </Form.Label>
                      <InputMask
                        mask="999.999.999-99"
                        value={cpf}
                        onChange={this.handleInputChange}
                        onBlur={(e) => this.isCPFValid(e)}
                        placeholder="123.123.123-12"
                        disabled={this.props.from === "partner"}
                      >
                        <Form.Control
                          type="text"
                          name="cpf"
                          className={this.cpfClassNameAndMessage(true)}
                        />
                      </InputMask>
                      <Form.Text className="is-required">
                        {this.cpfClassNameAndMessage(false)}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row className="mt-1">
                <Col>
                  <Form.Group>
                    <Form.Label>
                      E-mail <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      placeholder="teste@gmail.com"
                      onChange={this.handleInputChange}
                      onBlur={(e) => this.isEmailValid(e)}
                      className={this.emailClassNameAndMessage(true)}
                      disabled={disabledPartnerField}
                    />
                    <Form.Text className="is-required">
                      {this.emailClassNameAndMessage(false)}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Nome completo parceiro
                      <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={name}
                      onChange={this.handleInputChange}
                      onBlur={(e) => this.isNameValid(e)}
                      className={
                        validFields.isNameValid === undefined
                          ? ""
                          : validFields.isNameValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        disabledField ||
                        disabledPartnerField
                      }
                    />
                    <Form.Text className="is-required">
                      {validFields.isNameValid === undefined
                        ? ""
                        : validFields.isNameValid
                        ? ""
                        : "O campo deve conter um nome completo valido. Com nome, sobrenome e não pode conter números."}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Número para contato{" "}
                      <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={cellphone}
                      placeholder="(48) 99123-4789"
                      onChange={this.handleInputChange}
                      onBlur={() => this.isCellphoneValid()}
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        disabledField ||
                        disabledPartnerField
                      }
                    >
                      <Form.Control
                        type="text"
                        name="cellphone"
                        className={
                          validFields.isCellphoneValid === undefined
                            ? ""
                            : validFields.isCellphoneValid
                            ? "is-valid"
                            : "is-invalid"
                        }
                      />
                    </InputMask>
                    <Form.Text className="is-required">
                      {validFields.isCellphoneValid === undefined
                        ? ""
                        : validFields.isCellphoneValid
                        ? ""
                        : "O campo telefone deve ser preenchido no formato indicado: (dd) 91234-1234."}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      CEP <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <InputMask
                      placeholder="12312-312"
                      mask="99999-999"
                      value={cep}
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        disabledField ||
                        disabledPartnerField
                      }
                      onChange={this.handleInputChange}
                      onBlur={() => this.isCepValid()}
                    >
                      <Form.Control
                        name="cep"
                        className={
                          validFields.isCepValid === undefined
                            ? ""
                            : validFields.isCepValid
                            ? "is-valid"
                            : "is-invalid"
                        }
                      />
                    </InputMask>
                    <Form.Text className="is-required">
                      {validFields.isCepValid === undefined
                        ? ""
                        : validFields.isCepValid
                        ? ""
                        : cep.length !== 0
                        ? "O CEP informado não é valido"
                        : "O campo endereço não pode ser vazio."}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Rua <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="street"
                      placeholder="Rua"
                      value={street}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isStreetValid()}
                      className={
                        validFields.isStreetValid === undefined
                          ? ""
                          : validFields.isStreetValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        cep === "" ||
                        disabledPartnerField
                      }
                    />
                    <Form.Text className="is-required">
                      {validFields.isStreetValid === undefined
                        ? ""
                        : validFields.isStreetValid
                        ? ""
                        : "O campo endereço não pode ser vazio."}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Número <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="placeNumber"
                      placeholder="Número"
                      value={placeNumber}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isPlaceNumberValid()}
                      className={
                        validFields.isPlaceNumberValid === undefined
                          ? ""
                          : validFields.isPlaceNumberValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        cep === "" ||
                        disabledPartnerField
                      }
                    />
                    <Form.Text className="is-required">
                      {validFields.isPlaceNumberValid === undefined
                        ? ""
                        : validFields.isPlaceNumberValid
                        ? ""
                        : "O campo número não pode ser vazio."}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Bairro <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="neighborhood"
                      placeholder="Bairro"
                      value={neighborhood}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isNeighborhoodValid()}
                      className={
                        validFields.isNeighborhoodValid === undefined
                          ? ""
                          : validFields.isNeighborhoodValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        cep === "" ||
                        disabledPartnerField
                      }
                    />
                    <Form.Text className="is-required">
                      {validFields.isNeighborhoodValid === undefined
                        ? ""
                        : validFields.isNeighborhoodValid
                        ? ""
                        : "O campo endereço não pode ser vazio."}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Município{" "}
                      <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="Cidade"
                      value={city}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isCityValid()}
                      className={
                        validFields.isCityValid === undefined
                          ? ""
                          : validFields.isCityValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        cep === "" ||
                        disabledPartnerField
                      }
                    />
                    <Form.Text className="is-required">
                      {validFields.isCityValid === undefined
                        ? ""
                        : validFields.isCityValid
                        ? ""
                        : "O campo endereço não pode ser vazio."}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>
                      Estado <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="state"
                      value={state}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isStateValid()}
                      className={
                        validFields.isStateValid === undefined
                          ? ""
                          : validFields.isStateValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        cep === "" ||
                        disabledPartnerField
                      }
                    >
                      <option />
                      <option>Acre</option>
                      <option>Alagoas</option>
                      <option>Amapá</option>
                      <option>Amazonas</option>
                      <option>Bahia</option>
                      <option>Ceará</option>
                      <option>Espirito Santo</option>
                      <option>Goiás</option>
                      <option>Maranhão</option>
                      <option>Mato Grosso</option>
                      <option>Mato Grosso do Sul</option>
                      <option>Minas Gerais</option>
                      <option>Pará</option>
                      <option>Paraíba</option>
                      <option>Paraná</option>
                      <option>Pernambuco</option>
                      <option>Piauí</option>
                      <option>Rio de Janeiro</option>
                      <option>Rio Grande do Norte</option>
                      <option>Rio Grande do Sul</option>
                      <option>Rondônia</option>
                      <option>Roraima</option>
                      <option>Santa Catarina</option>
                      <option>São Paulo</option>
                      <option>Sergipe</option>
                      <option>Tocantins</option>
                    </Form.Control>
                    <Form.Text className="is-required">
                      {validFields.isStateValid === undefined
                        ? ""
                        : validFields.isStateValid
                        ? ""
                        : "O campo endereço não pode ser vazio."}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              {/* Informações Bancarias */}
              <Row className="customer-row-margin">
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Agência <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <InputMask
                      placeholder="123123123"
                      value={bankData["agency"]}
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        disabledField ||
                        disabledPartnerField
                      }
                      onChange={this.handleBankData}
                      onBlur={this.isAgencyValid}
                    >
                      <Form.Control
                        name="agency"
                        className={
                          validFields.isAgencyValid === undefined
                            ? ""
                            : validFields.isAgencyValid
                            ? "is-valid"
                            : "is-invalid"
                        }
                      />
                    </InputMask>
                    <Form.Text className="is-required">
                      {validFields.isAgencyValid === undefined
                        ? ""
                        : validFields.isAgencyValid
                        ? ""
                        : "O campo agência não deve ser vazio"}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Operação{" "}
                      <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <InputMask
                      placeholder="123123123"
                      value={bankData["operation"]}
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        disabledField ||
                        disabledPartnerField
                      }
                      onChange={this.handleBankData}
                      onBlur={this.isOperationValid}
                    >
                      <Form.Control
                        name="operation"
                        className={
                          validFields.isOperationValid === undefined
                            ? ""
                            : validFields.isOperationValid
                            ? "is-valid"
                            : "is-invalid"
                        }
                      />
                    </InputMask>
                    <Form.Text className="is-required">
                      {validFields.isOperationValid === undefined
                        ? ""
                        : validFields.isOperationValid
                        ? ""
                        : "O campo operação não deve ser vazio"}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Conta <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <InputMask
                      placeholder="123123123"
                      value={bankData["account"]}
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        disabledField ||
                        disabledPartnerField
                      }
                      onChange={this.handleBankData}
                      onBlur={this.isAccountValid}
                    >
                      <Form.Control
                        name="account"
                        className={
                          validFields.isAccountValid === undefined
                            ? ""
                            : validFields.isAccountValid
                            ? "is-valid"
                            : "is-invalid"
                        }
                      />
                    </InputMask>
                    <Form.Text className="is-required">
                      {validFields.isAccountValid === undefined
                        ? ""
                        : validFields.isAccountValid
                        ? ""
                        : "O campo conta não deve ser vazio"}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Banco <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <InputMask
                      placeholder="123123123"
                      value={bankData["bank"]}
                      onChange={this.handleBankData}
                      onBlur={this.isBankValid}
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        disabledField ||
                        disabledPartnerField
                      }
                    >
                      <Form.Control
                        name="bank"
                        className={
                          validFields.isBankValid === undefined
                            ? ""
                            : validFields.isBankValid
                            ? "is-valid"
                            : "is-invalid"
                        }
                      />
                    </InputMask>
                    <Form.Text className="is-required">
                      {validFields.isBankValid === undefined
                        ? ""
                        : validFields.isBankValid
                        ? ""
                        : "O campo banco não deve ser vazio"}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              {/* Informações adicionais: */}
              <Row className="customer-row-margin">
                <Col>
                  <Form.Group>
                    <Form.Label>Informações adicionais:</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="additionalInformation"
                      value={additionalInformation}
                      onChange={this.handleInputChange}
                      disabled={
                        (physicalPerson === "Pessoa Jurídica"
                          ? !(
                              validFields.isCNPJValid &&
                              validFields.isCNPJUnique
                            )
                          : !(
                              validFields.isCPFValid && validFields.isCPFUnique
                            )) ||
                        !(
                          validFields.isEmailValid && validFields.isEmailUnique
                        ) ||
                        disabledField ||
                        disabledPartnerField
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/*Dados Contrato */}
              <>
                <Row>
                  <Col>
                    <Form.Label>
                      <h5>{"Dados do Contrato"}</h5>
                    </Form.Label>
                    <div className={"partner-border"} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Nome do prospector
                        <Form.Label className="is-required">*</Form.Label>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="prospectName"
                        value={prospectName}
                        className={
                          validFields.isProspectNameValid === undefined
                            ? ""
                            : validFields.isProspectNameValid
                            ? "is-valid"
                            : "is-invalid"
                        }
                        onChange={(e) => this.handleInputChange(e)}
                        onBlur={(e) => this.isProspectNameValid(e)}
                        disabled={
                          (physicalPerson === "Pessoa Jurídica"
                            ? !(
                                validFields.isCNPJValid &&
                                validFields.isCNPJUnique
                              )
                            : !(
                                validFields.isCPFValid &&
                                validFields.isCPFUnique
                              )) ||
                          !(
                            validFields.isEmailValid &&
                            validFields.isEmailUnique
                          ) ||
                          disabledField ||
                          disabledPartnerField
                        }
                      >
                        <option></option>
                        {allUsers?.map((item, i) => {
                          return <option key={i}>{item.name}</option>;
                        })}
                      </Form.Control>
                      <Form.Text className="is-required">
                        {validFields.isProspectNameValid === undefined
                          ? ""
                          : validFields.isProspectNameValid
                          ? ""
                          : "O campo nome do prospector não deve ser vazio"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Data de inicio
                        <Form.Label className="is-required">*</Form.Label>
                      </Form.Label>
                      <InputMask
                        mask="99/99/9999"
                        name={"beginDate"}
                        onChange={this.handleInputChange}
                        onBlur={(e) => this.isDateValid(e)}
                        value={beginDate}
                        disabled={
                          (physicalPerson === "Pessoa Jurídica"
                            ? !(
                                validFields.isCNPJValid &&
                                validFields.isCNPJUnique
                              )
                            : !(
                                validFields.isCPFValid &&
                                validFields.isCPFUnique
                              )) ||
                          !(
                            validFields.isEmailValid &&
                            validFields.isEmailUnique
                          ) ||
                          disabledField ||
                          disabledPartnerField
                        }
                      >
                        <Form.Control
                          type="text"
                          name="beginDate"
                          className={
                            validFields.isBeginDateValid === undefined
                              ? ""
                              : validFields.isBeginDateValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        />
                      </InputMask>
                      <Form.Text className="is-required">
                        {validFields.isBeginDateValid === undefined
                          ? ""
                          : validFields.isBeginDateValid
                          ? ""
                          : "O campo data de inicio não deve ser vazio e deve conter uma data valida"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        Data de fim
                        <Form.Label className="is-required">*</Form.Label>
                      </Form.Label>
                      <InputMask
                        mask="99/99/9999"
                        name={"endDate"}
                        onChange={this.handleInputChange}
                        onBlur={(e) => this.isDateValid(e)}
                        value={endDate}
                        disabled={
                          (physicalPerson === "Pessoa Jurídica"
                            ? !(
                                validFields.isCNPJValid &&
                                validFields.isCNPJUnique
                              )
                            : !(
                                validFields.isCPFValid &&
                                validFields.isCPFUnique
                              )) ||
                          !(
                            validFields.isEmailValid &&
                            validFields.isEmailUnique
                          ) ||
                          disabledField ||
                          disabledPartnerField
                        }
                      >
                        <Form.Control
                          type="text"
                          name="endDate"
                          className={
                            validFields.isEndDateValid === undefined
                              ? ""
                              : validFields.isEndDateValid
                              ? "is-valid"
                              : "is-invalid"
                          }
                        />
                      </InputMask>
                      <Form.Text className="is-required">
                        {validFields.isEndDateValid === undefined
                          ? ""
                          : validFields.isEndDateValid
                          ? ""
                          : "O campo data de fim não deve ser vazio, deve conter uma data valida e uma data superio a data de inicio"}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group>
                  <Form.Label>
                    Valor de participação(
                    {isOutsource === "Indicador" ? "R$" : "%"})
                    <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <InputMask
                    name={"participationValue"}
                    value={participationValue}
                    onChange={(e) => this.handleParticipationValue(e)}
                    onBlur={(e) => this.isParticipationValueValid(e)}
                    disabled={
                      (physicalPerson === "Pessoa Jurídica"
                        ? !(validFields.isCNPJValid && validFields.isCNPJUnique)
                        : !(
                            validFields.isCPFValid && validFields.isCPFUnique
                          )) ||
                      !(
                        validFields.isEmailValid && validFields.isEmailUnique
                      ) ||
                      disabledField ||
                      disabledPartnerField
                    }
                  >
                    <Form.Control
                      type="number"
                      name="participationValue"
                      className={
                        validFields.isParticipationValueValid === undefined
                          ? ""
                          : validFields.isParticipationValueValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                    />
                  </InputMask>
                  <Form.Text className="is-required">
                    {validFields.isParticipationValueValid === undefined
                      ? ""
                      : validFields.isParticipationValueValid
                      ? ""
                      : "O campo valor de participação não deve ser vazio"}
                  </Form.Text>
                </Form.Group>
              </>
              {/*Valor do Serviço */}
              {isOutsource !== "Indicador" && (
                <>
                  <Row>
                    <Col>
                      <Form.Label>
                        <h5>{"Valor do Serviço"}</h5>
                      </Form.Label>
                      <div className={"partner-border"} />
                    </Col>
                  </Row>

                  <Form.Group>
                    <Form.Label>{"Desconto: " + discount + "%"}</Form.Label>
                    <Row>
                      <Col sm={1} md={1} lg={1} xm={1}>
                        <div>0%</div>
                      </Col>
                      <Col sm={10} md={10} lg={10} xm={10}>
                        <div className="slidecontainer">
                          <input
                            type="range"
                            min="0"
                            max="100"
                            value={discount}
                            name={"discount"}
                            className="slider"
                            id="discount"
                            onChange={(e) => this.handleInputChange(e)}
                            disabled={
                              (physicalPerson === "Pessoa Jurídica"
                                ? !(
                                    validFields.isCNPJValid &&
                                    validFields.isCNPJUnique
                                  )
                                : !(
                                    validFields.isCPFValid &&
                                    validFields.isCPFUnique
                                  )) ||
                              !(
                                validFields.isEmailValid &&
                                validFields.isEmailUnique
                              ) ||
                              disabledField ||
                              disabledPartnerField
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={1} md={1} lg={1} xm={1}>
                        100%
                      </Col>
                    </Row>
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Group controlId="exampleForm.ControlSelect2">
                        <Form.Label>
                          {"Parcela do boleto"}
                          {isTicketParcel ? (
                            <Form.Label className="is-required">*</Form.Label>
                          ) : null}
                        </Form.Label>
                        <Row>
                          <Col sm={1} md={1} lg={1} xm={1}>
                            <Form.Check
                              type="checkbox"
                              className={"pt-2"}
                              name={"isTicketParcel"}
                              value={isTicketParcel}
                              checked={isTicketParcel}
                              onChange={() =>
                                this.setState({
                                  isTicketParcel: !isTicketParcel,
                                  ticketParcel: "",
                                })
                              }
                              disabled={
                                (physicalPerson === "Pessoa Jurídica"
                                  ? !(
                                      validFields.isCNPJValid &&
                                      validFields.isCNPJUnique
                                    )
                                  : !(
                                      validFields.isCPFValid &&
                                      validFields.isCPFUnique
                                    )) ||
                                !(
                                  validFields.isEmailValid &&
                                  validFields.isEmailUnique
                                ) ||
                                disabledField ||
                                disabledPartnerField
                              }
                            />
                          </Col>
                          <Col sm={11} md={11} lg={11} xm={11}>
                            <Form.Control
                              as="select"
                              name={"ticketParcel"}
                              value={ticketParcel}
                              onChange={this.handleInputChange}
                              disabled={!isTicketParcel}
                              onBlur={
                                isTicketParcel
                                  ? (e) => this.isParcelValid(e)
                                  : null
                              }
                              className={
                                validFields.isTicketParcelValid === undefined
                                  ? ""
                                  : validFields.isTicketParcelValid
                                  ? "is-valid"
                                  : "is-invalid"
                              }
                            >
                              <option />
                              {ticketParcels &&
                                ticketParcels.map((value, key) => {
                                  return <option key={key}>{value}</option>;
                                })}
                            </Form.Control>
                            <Form.Text className="is-required">
                              {validFields.isTicketParcelValid === undefined
                                ? ""
                                : validFields.isTicketParcelValid
                                ? ""
                                : "O campo parcela do boleto não deve ser vazio"}
                            </Form.Text>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="exampleForm.ControlSelect3">
                        <Form.Label>
                          Parcela do cartão de crédito
                          {isCreditCard ? (
                            <Form.Label className="is-required ">*</Form.Label>
                          ) : null}
                        </Form.Label>
                        <Row>
                          <Col sm={1} md={1} lg={1} xm={1}>
                            <Form.Check
                              type="checkbox"
                              className={"pt-2"}
                              name={"isCreditCard"}
                              value={isCreditCard}
                              checked={isCreditCard}
                              onChange={() =>
                                this.setState({
                                  isCreditCard: !isCreditCard,
                                  creditCardParcel: "",
                                })
                              }
                              disabled={
                                (physicalPerson === "Pessoa Jurídica"
                                  ? !(
                                      validFields.isCNPJValid &&
                                      validFields.isCNPJUnique
                                    )
                                  : !(
                                      validFields.isCPFValid &&
                                      validFields.isCPFUnique
                                    )) ||
                                !(
                                  validFields.isEmailValid &&
                                  validFields.isEmailUnique
                                ) ||
                                disabledField ||
                                disabledPartnerField
                              }
                            />
                          </Col>
                          <Col sm={11} md={11} lg={11} xm={11}>
                            <Form.Control
                              as="select"
                              name={"creditCardParcel"}
                              value={creditCardParcel}
                              onChange={this.handleInputChange}
                              disabled={!isCreditCard}
                              onBlur={
                                isCreditCard
                                  ? (e) => this.isParcelValid(e)
                                  : null
                              }
                              className={
                                validFields.isCreditCardValid === undefined
                                  ? ""
                                  : validFields.isCreditCardValid
                                  ? "is-valid"
                                  : "is-invalid"
                              }
                            >
                              <option />
                              {creditCardParcels &&
                                creditCardParcels.map((value, key) => {
                                  return <option key={key}>{value}</option>;
                                })}
                            </Form.Control>
                            <Form.Text className="is-required">
                              {validFields.isCreditCardValid === undefined
                                ? ""
                                : validFields.isCreditCardValid
                                ? ""
                                : "O campo parcela cartão de credito não deve ser vazio"}
                            </Form.Text>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}

          {/* Dados do Usuário */}
          {data === undefined && (
            <div className={"m-2"}>
              <Form.Label>
                <h5>Dados do Usuário </h5>
              </Form.Label>
              <div className={"partner-border"} />
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      CPF <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <InputMask
                      mask="999.999.999-99"
                      value={cpfUser}
                      onChange={this.handleInputChange}
                      onBlur={(e) => this.isCPFValid(e)}
                      placeholder="123.123.123-12"
                      disabled={this.props.data !== undefined}
                    >
                      <Form.Control
                        type="text"
                        name="cpfUser"
                        className={this.cpfUserClassNameAndMessage(true)}
                      />
                    </InputMask>
                    <Form.Text className="is-required">
                      {this.cpfUserClassNameAndMessage(false)}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <Form.Group>
                    <Form.Label>
                      E-mail <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="emailUser"
                      value={emailUser}
                      placeholder="teste@gmail.com"
                      onChange={this.handleInputChange}
                      onBlur={(e) => this.isEmailValid(e)}
                      className={this.emailUserClassNameAndMessage(true)}
                      disabled={data !== undefined}
                    />
                    <Form.Text className="is-required">
                      {this.emailUserClassNameAndMessage(false)}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Nome completo usuário
                      <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="nameUser"
                      value={nameUser}
                      onChange={this.handleInputChange}
                      onBlur={(e) => this.isNameValid(e)}
                      className={
                        validFields.isNameUserValid === undefined
                          ? ""
                          : validFields.isNameUserValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                      disabled={
                        !(
                          validFields.isEmailUserValid &&
                          validFields.isEmailUserUnique
                        ) || !validFields.isCPFUserValid
                      }
                    />
                    <Form.Text className="is-required">
                      {validFields.isNameUserValid === undefined
                        ? ""
                        : validFields.isNameUserValid
                        ? ""
                        : "O campo deve conter um nome completo valido. Com nome, sobrenome e não pode conter números."}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Senha <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={type}
                        name="password"
                        value={password}
                        placeholder="senha"
                        onChange={this.handleInputChange}
                        onBlur={() => {
                          this.isPasswordValid();
                        }}
                        className={
                          validFields.isPasswordValid !== undefined
                            ? validFields.isPasswordValid
                              ? "is-valid"
                              : "is-invalid"
                            : ""
                        }
                        disabled={
                          !(
                            validFields.isEmailUserValid &&
                            validFields.isEmailUserUnique
                          ) ||
                          cpfUser === "" ||
                          !validFields.isCPFUserValid
                        }
                      />
                      <InputGroup.Append>
                        <Button
                          variant="outline-secondary"
                          onClick={this.passwordHandleClick}
                        >
                          {type === "password" ? (
                            <i className="fas fa-eye" />
                          ) : (
                            <i className="fas fa-eye-slash" />
                          )}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className="is-required">
                      {validFields.isPasswordValid !== undefined
                        ? validFields.isPasswordValid
                          ? ""
                          : "O campo senha não pode ser vazio"
                        : ""}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Col className=" p-0">
              <Button
                variant="primary"
                className="w-100 modal-user-button-without-rounded"
                onClick={() => {
                  this.submit();
                }}
                disabled={disabledSubmitBtn || disabledField}
              >
                {this.props.data !== undefined ? "Atualizar" : "Adicionar"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalUser);
