export const pensionFormNames = {
  degreeOfKinship: "degreeOfKinship",
  kinshipDocuments: "kinshipDocuments",
  kinshipDocumentsDescription: "kinshipDocumentsDescription",
  economicDependencyDocuments: "economicDependencyDocuments",
  economicDependencyDocumentsDescription:
    "economicDependencyDocumentsDescription",
  dateOfDeath: "dateOfDeath",
  deathCertificate: "deathCertificate",
  causeOfDeath: "causeOfDeath",
  occurInTheExerciseOfFunction: "occurInTheExerciseOfFunction",
  workingInDateOfDeath: "workingInDateOfDeath",
  deceasedActivity: "deceasedActivity",
  regimeOnDateOfDeath: "regimeOnDateOfDeath",
  regimeOnDateOfDeathDescription: "regimeOnDateOfDeathDescription",
  wasRetired: "wasRetired",
  wasRetiredBenefitNumber: "wasRetiredBenefitNumber",
  receivedBenefit: "receivedBenefit",
  youGetSomeBenefit: "youGetSomeBenefit",
  youGetSomeBenefitDescription: "youGetSomeBenefitDescription",
  contactWay: "contactWay",
  additionalInformation: "additionalInformation",
  commitmentTerm: "commitmentTerm",
  requestReason: "requestReason",
  hasDisability: "hasDisability",
  hasDependentsUnder18: "hasDependentsUnder18",
  hasDependentsUnder18CPF: "hasDependentsUnder18CPF",
  deceasedINSSPassword: "deceasedINSSPassword",
  applicantINSSPassword: "applicantINSSPassword",
};
