import React, { useRef, useEffect, useState } from "react";
import { Form, Loading, Select, Button, Layout } from 'element-react'
import DataTableDinamicFilter from "../../../genericComponents/datatable/DataTableDinamicFilter";
import { saveUserComissionConfig, getDataTableUserComissionConfig, getDeleteUserComissionConfig, getAllUsersNotCustomer, getAllProcessType } from "../../../services/RequestService";
import CurrencyInput from "../../../components/fields/MoneyField";
import { fn } from "../../../utils";
import { useAlert } from "react-alert";

export default function ComissionPage() {
  const alert = useAlert();
  const formRef = useRef();
  const dataTable = useRef();
  const [loading, setLoading] = useState(false);
  const [listsOfSelect, setListsOfSelect] = useState({
    users: [],
    processType: [],
  });

  const [form, setForm] = useState({
    userId: null,
    processTypeId: null,
    comissionValue: null,
  });

  const filter = [
    { label: "Usuário", key: "user.name" },
    { label: "Tese", key: "processType.name" },
    { label: "Valor", key: "comissionValue" }
  ];

  const columns = [
    { label: "Usuário", prop: "userName" },
    { label: "Tese", prop: "processTypeDescription" },
    { label: "Valor", prop: "comissionValue" },
    {
      label: "Ações",
      type: "action",
      render: (r) => (<Button icon='delete' type="danger" onClick={remove.bind(this, r)} />)
    },
  ];

  useEffect(() => {
    loadLists();
  }, []);

  async function loadLists() {
    const list = { users: [], processType: [] };
    let result = await getAllUsersNotCustomer();
    if (result.ok) {
      list.users = (result.data || []).map((v) => {
        return { label: v.name, value: v.id };
      });
    }
    result = await getAllProcessType();
    if (result.ok) {
      list.processType = (result.data || []).map((v) => {
        return { label: v.name, value: v.id };
      });
    }
    setListsOfSelect(list);
  }

  function save(event) {
    event.preventDefault();
    const params = {
      ...form,
      comissionValue: fn.float(form.comissionValue),
    };
    if (params.userId <= 0 || params.processTypeId <= 0 || params.comissionValue <= 0) {
      alert.show("Todos os campos são obrigatórios!", { type: "error" });
      return;
    }
    setLoading(true);
    saveUserComissionConfig(params).then((resp) => {
      if (resp.status === 200) {
        dataTable.current.refreshDataTable();
        alert.show("Registro foi salvo com sucesso!", { type: "success" });
      } else {
        alert.show(resp.data?.log || "Ocorreu um erro ao salvar os dados, tente novamente", { type: "error" });
        console.error("save-user-config", resp.data?.detail);
      }
      setLoading(false);
    });
  }

  function remove({ id }) {
    setLoading(true);
    getDeleteUserComissionConfig(id).then((resp) => {
      if (resp.ok) {
        dataTable.current.refreshDataTable();
        alert.show("Registro removido com sucesso!", { type: "success" });
      }
      setLoading(false);
    });
  }

  function onChange(key, value) {
    setForm(Object.assign({}, form, { [key]: value }));
  }

  return (
    <Loading loading={loading}>
      <DataTableDinamicFilter
        ref={dataTable}
        fields={filter}
        ajaxData={getDataTableUserComissionConfig}
        columns={columns}
        pageTile={"Configuração de Comissão de Usuários"}
        pageForm={
          <Form className='dinamic-filter-form' ref={formRef} model={form} labelPosition="top">
            <Layout.Row gutter="20">
              <Layout.Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item label="Usuário" prop="userId">
                  <Select value={form.userId} clearable={true} filterable={true} onChange={onChange.bind(this, 'userId')}>
                    {(listsOfSelect.users || []).map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item label="Tese" prop="processTypeId">
                  <Select value={form.processTypeId} clearable={true} filterable={true} onChange={onChange.bind(this, 'processTypeId')}>
                    {(listsOfSelect.processType || []).map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item label="Valor" prop="comissionValue">
                  <CurrencyInput className={"form-control"} placeholder={"R$ 0,00"} type="text" value={form.comissionValue} onChange={(e) => onChange('comissionValue', e.target.value)} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item label="Ações">
                  <Button type="primary" onClick={save.bind(this)}>Salvar</Button>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        }
      />
    </Loading>
  );
}
