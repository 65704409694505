import React from "react";
import { connect } from "react-redux";
import { Modal, Form, Row } from "react-bootstrap";
import { alertActions } from "../../../store/actions/AlertActions";
import {
  postUpdateDocumentSubType,
  putInsertDocumentSubType,
} from "../../../services/RequestService";
import "../../../pages/settings/process/SettingsProcessPage.css";
import { FormButton, Input } from "../../../genericComponents";
import { FormFull } from "form-full";

function ModalDocumentSubType({
  show,
  onHide,
  subType,
  allSubtypes,
  onAddAlert,
}) {
  const [loading, setLoading] = React.useState(false);

  function validateNameField(name) {
    for (let i = 0; i < allSubtypes.length; i++) {
      let b = allSubtypes[i];
      if (b.name === name) {
        return subType?.id === b.id
          ? null
          : "O valor digitado já é um tipo de documento";
      }
    }
    return null;
  }

  function submit({ name }) {
    setLoading(true);
    const requestCallback = subType
      ? postUpdateDocumentSubType
      : putInsertDocumentSubType;

    requestCallback({
      id: subType?.id ?? undefined,
      name,
    })
      .then((res) => {
        onAddAlert(
          `Subtipo ${subType ? "atualizado" : "adicionado"} com sucesso.`,
          "success"
        );
        onHide(true);
      })
      .catch((e) => {
        onAddAlert(
          `Ocorreu um erro ao tentar ${
            subType ? "atualizar" : "adicionar"
          } o subtipo.`,
          "danger"
        );
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <FormFull
        onSubmit={submit}
        actualValues={{ name: subType?.name }}
        disabled={loading}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {subType ? "Atualização" : "Criação"} de Subtipo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Input
              label="Nome"
              name="name"
              required="Campo obrigatório"
              validation={(name) => validateNameField(name)}
              maskToSubmit={(name) => name.trim()}
            />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <FormButton
              name="submit"
              actionType="submit"
              disabled={loading}
              className="w-100 button-without-rounded"
            >
              {subType ? "Atualizar" : "Cadastrar"}
            </FormButton>
          </Row>
        </Modal.Footer>
      </FormFull>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalDocumentSubType);
