import React, { Component } from "react";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import ModalAlertComp from "../modal/ModalAlertComp";
import { getAllAlertsByDone } from "../../../services/RequestService";
import "../../../pages/calendar/CalendarPage.css";
import ModalReadAlertMessage from "../modal/ModalReadAlertMessage";
import ModalReadAlertDelayDelivery from "../modal/ModalReadAlertDelayDelivery";
import { FullHeightCard } from "../../../genericComponents";
import DOMPurify from "dompurify";

class CardAlertsComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      showModal: false,
      showModalReadAlertMessage: false,
      showModalReadAlertDelayDelivery: false,
      alertId: null,
      description: null,
      taskDate: null,
      processDate: null,
      page: 0,
      hasMore: true,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.loadActions();
  };

  loadActions = () => {
    const { page, alerts, hasMore, loading } = this.state;
    if (hasMore && !loading) {
      this.setState({ loading: true });
      getAllAlertsByDone(page)
        .then((res) => {
          const d = Array.isArray(res.data) ? res.data : [];
          this.setState({
            alerts: alerts.concat(d),
            page: page + 1,
            hasMore: d.length > 0,
            loading: false,
          });
        })
        .catch((error) => console.log("Erro action", error))
        .finally(() => {
          this.props.subtractLoading();
        });
    }
  };

  getProcessCreateDateDif = (createDate) => {
    let cdDate = new Date(createDate);
    return cdDate.toLocaleDateString("pt-BR");
  };

  setShowModal = (showModal) => {
    this.setState((state) => ({ ...state, showModal }));
    if (!showModal) {
      this.componentDidMount();
    }
  };

  setShowModalReadAlertMessage = (
    showModalReadAlertMessage,
    alertId,
    description
  ) => {
    this.setState((state) => ({
      ...state,
      showModalReadAlertMessage,
      alertId,
      description,
    }));
    if (!showModalReadAlertMessage) {
      this.componentDidMount();
    }
  };

  setShowModalReadAlertDelayDelivery = (
    showModalReadAlertDelayDelivery,
    alertId,
    description,
    taskDate,
    processDate
  ) => {
    this.setState((state) => ({
      ...state,
      showModalReadAlertDelayDelivery,
      alertId,
      description,
      taskDate,
      processDate,
    }));
  };

  getActionTypeName = (actionType) => {
    if (actionType.name === "message") {
      return "Mensagem";
    }

    if (actionType.name === "process_delay_delivery") {
      return "Prorrogar prazo de processo";
    }

    if (actionType.name === "task_delay_delivery") {
      return "Prorrogar prazo de tarefa";
    }
  };

  render() {
    const {
      alerts,
      showModal,
      loading,
      showModalReadAlertMessage,
      showModalReadAlertDelayDelivery,
      alertId,
      description,
      taskDate,
      processDate,
    } = this.state;
    return (
      <FullHeightCard
        handleScroll={this.loadActions}
        pageContainerChild
        xs={12}
        xl={4}
        header={
          <Row className="mx-0 mt-2 px-3 pt-3">
            <Col className="p-0 text-left">
              <span>Lembretes</span>
            </Col>
            <Col className="p-0 text-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{alerts.length} lembretes</Tooltip>}
              >
                <div className="d-inline mr-2">
                  <i className="far fa-clone mr-1" />
                  <span>{alerts.length}</span>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Adicionar lembrete</Tooltip>}
              >
                <i
                  className="fas fa-plus-circle clickable mr-2"
                  onClick={() => this.setShowModal(true)}
                />
              </OverlayTrigger>
            </Col>
          </Row>
        }
      >
        {alerts.length > 0 &&
          alerts.map((alert) => {
            return (
              <div
                className={
                  alert.done
                    ? "alert-history-backgroud secondary-color-border p-2 m-2 rounded"
                    : "alert-backgroud warning-color-border p-2 m-2 rounded"
                }
                key={"alert" + alert.id}
                onClick={() => {
                  let show = true;
                  let nullValue = null;
                  if (alert["alertType"].name === "message") {
                    this.setShowModalReadAlertMessage(
                      show,
                      alert.id,
                      alert.description
                    );
                  } else {
                    if (alert["alertType"].name === "task_delay_delivery") {
                      this.setShowModalReadAlertDelayDelivery(
                        show,
                        alert.id,
                        alert.description,
                        alert["taskDate"]["deliveryDate"],
                        nullValue
                      );
                    } else {
                      if (
                        alert["alertType"].name === "process_delay_delivery"
                      ) {
                        this.setShowModalReadAlertDelayDelivery(
                          show,
                          alert.id,
                          alert.description,
                          nullValue,
                          alert["processDate"]["deliveryDate"]
                        );
                      }
                    }
                  }
                }}
              >
                <Row className="m-0">
                  <Col
                    sm={9}
                    md={9}
                    lg={9}
                    xs={9}
                    className="p-0 text-left alert-color-text"
                  >
                    <small>Tipo</small>
                    <h6>{this.getActionTypeName(alert.alertType)}</h6>
                    <small>Descrição</small>
                    <h6
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(alert.description),
                      }}
                    ></h6>
                  </Col>
                </Row>
              </div>
            );
          })}
        <div className={"p-3 text-center"} style={{ minHeight: "50px" }}>
          {alerts.length <= 0 && <span>Você não possui nenhum lembrete</span>}
          {loading && <span>Carregando...</span>}
        </div>
        {showModal && (
          <ModalAlertComp
            show={showModal}
            onHide={() => this.setShowModal(false)}
          />
        )}
        {showModalReadAlertMessage && (
          <ModalReadAlertMessage
            show={showModalReadAlertMessage}
            onHide={() => this.setShowModalReadAlertMessage(false, null, null)}
            alertId={alertId}
            description={description}
          />
        )}
        {showModalReadAlertDelayDelivery && (
          <ModalReadAlertDelayDelivery
            show={showModalReadAlertDelayDelivery}
            onHide={() =>
              this.setShowModalReadAlertDelayDelivery(false, null, null, null)
            }
            description={description}
            alertId={alertId}
            taskDate={taskDate}
            processDate={processDate}
          />
        )}
      </FullHeightCard>
    );
  }
}

export default CardAlertsComp;
