import getDashboardStatus from "./dashboard/getDashboardStatus";
import getAllDocumentSubTypes from "./documentSubType/getAllDocumentSubTypes";
import getCheckAvailableDocumentSubType from "./documentSubType/getCheckAvailableDocumentSubType";
import deleteDocumentSubType from "./documentSubType/deleteDocumentSubType";
import postUpdateDocumentSubType from "./documentSubType/postUpdateDocumentSubType";
import putInsertDocumentSubType from "./documentSubType/putInsertDocumentSubType";

import getAllPaymentMethods from "./paymentMethod/getAllPaymentMethods.js.js";
import postUpdatePaymentMethod from "./paymentMethod/postUpdatePaymentMethod";

export const Models = {
  getDashboardStatus,
  getAllDocumentSubTypes,
  getCheckAvailableDocumentSubType,
  deleteDocumentSubType,
  postUpdateDocumentSubType,
  putInsertDocumentSubType,
  getAllPaymentMethods,
  postUpdatePaymentMethod,
};
