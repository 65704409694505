import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

function ModalFinishProcess({
  doneProcessModal,
  postProcessDone,
  setDoneProcessModal,
}) {
  return (
    <Modal show={doneProcessModal}>
      <Modal.Body className="align-self-center text-center">
        <Row>Deseja finalizar o processo?</Row>
        <Row>
          <Col>
            <Button onClick={postProcessDone}>Sim</Button>
          </Col>
          <Col>
            <Button onClick={() => setDoneProcessModal(!doneProcessModal)}>
              Não
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ModalFinishProcess;
