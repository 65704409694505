import { parse } from "date-fns";
import React from "react";
import { Row } from "react-bootstrap";
import {
  CheckInputs,
  InputINSS,
  InputYear,
  RadioGroup,
} from "../../../genericComponents";

/* Número do beneficio, Ano da concessão */
function RenderIsRetired() {
  return (
    <Row>
      <InputINSS
        colProps={{ xs: 12, md: 6 }}
        label="Número do benefício"
        inputMaskProps={{
          placeholder: "Número do beneficio da aposentadoria",
        }}
        name="benefitNumber"
        required='O campo "Número do beneficio" deve ser preenchido.'
      />
      <InputYear
        colProps={{ xs: 12, md: 6 }}
        label="Ano da concessão"
        inputMaskProps={{ placeholder: "Ano de concessão do benefício" }}
        name="benefitYear"
        required='O campo "Ano da concessão" deve ser preenchido.'
        minDate={parse("1900", "yyyy", new Date())}
        maxDate={parse(new Date().getFullYear(), "yyyy", new Date())}
      />
    </Row>
  );
}

function RenderIsNotRetired({ serverData }) {
  const [isRegime, setIsRegime] = React.useState(false);

  const publicServer = serverData?.publicServer;
  React.useEffect(() => {
    if (publicServer === "Sim, servidor com regime próprio") {
      setIsRegime(true);
    }
  }, [publicServer, setIsRegime]);
  return (
    <>
      {/* Você é ou já foi servidor público? */}
      <div>
        <Row>
          <RadioGroup
            label="Você é ou já foi servidor público?"
            name="publicServer"
            options={[
              {
                value: "Sim, servidor CLT",
                label: "Sim, servidor CLT",
              },
              {
                value: "Não sei responder",
                label: "Não sei responder",
              },
              {
                value: "Sim, servidor com regime próprio",
                label: "Sim, servidor com regime próprio",
              },
              {
                value: "Não",
                label: "Não",
              },
            ]}
            required='O campo "Você é ou já foi servidor público?" é obrigatório.'
            inline
            onChange={(event, value) => {
              setIsRegime(value === "Sim, servidor com regime próprio");
            }}
          />
        </Row>
        {isRegime ? (
          <Row>
            <CheckInputs
              label="Já é aposentado em algum regime?"
              checked={Boolean(serverData?.regime)}
              inputs={[
                {
                  name: "regime",
                  inputMaskProps: { placeholder: "Descrição" },
                  required:
                    'O campo de "Descrição" é obrigatório pois "Já é aposentado em algum regime?" está selecionado.',
                  maxLength: 200,
                },
              ]}
            />
          </Row>
        ) : null}
      </div>
      {/* Já fez alguma tentativa de encaminhamento de aposentadoria? */}
      <Row>
        <RadioGroup
          label="Já fez alguma tentativa de encaminhamento de aposentadoria?"
          name="tryRetirement"
          options={[
            {
              value: "Sim",
              label: "Sim",
            },
            {
              value: "Não",
              label: "Não",
            },
            {
              value: "Não sei responder",
              label: "Não sei responder",
            },
          ]}
          required='O campo "Já fez alguma tentativa de encaminhamento de aposentadoria?" é obrigatório.'
          inline
        />
      </Row>
      {/* Já fez algum estudo sobre sua aposentadoria? */}
      <Row>
        <RadioGroup
          label="Já fez algum estudo sobre sua aposentadoria?"
          name="retirementStudy"
          options={[
            {
              value: "Sim",
              label: "Sim",
            },
            {
              value: "Não",
              label: "Não",
            },
            {
              value: "Não sei responder",
              label: "Não sei responder",
            },
          ]}
          required='O campo "Já fez algum estudo sobre sua aposentadoria?" é obrigatório.'
          inline
        />
      </Row>
    </>
  );
}

function CustomerSurvey1({ serverData }) {
  const [isRetired, setIsRetired] = React.useState(null);

  const serverIsRetired = serverData?.isRetired;
  React.useEffect(() => {
    if (serverIsRetired === "Já sou aposentado") {
      setIsRetired(true);
    }
  }, [serverIsRetired, setIsRetired]);

  return (
    <>
      <Row>
        <RadioGroup
          label="O senhor(a) já é aposentado ou gostaria de se aposentar?"
          name="isRetired"
          options={[
            {
              value: "Já sou aposentado",
              label: "Já sou aposentado",
            },
            {
              value: "Gostaria de me aposentar",
              label: "Gostaria de me aposentar",
            },
          ]}
          required='O campo "O senhor(a) já é aposentado ou gostaria de se aposentar?" é obrigatório.'
          inline
          onChange={(event, value) => {
            setIsRetired(value === "Já sou aposentado");
          }}
        />
      </Row>
      {isRetired === true ? <RenderIsRetired /> : null}
      {isRetired === false ? (
        <RenderIsNotRetired serverData={serverData} />
      ) : null}
    </>
  );
}

export default React.memo(CustomerSurvey1);
