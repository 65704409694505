import { MessageBox } from "element-react";
import { postRemoveSeemScenario } from "../../../../services/RequestService";

export function getMenu(props) {
  const {
    decodedToken,
    oneProcess,
    responsibleProcessDisabled,
    processTypeDisabled,
    setShowModalDocument,
    setShowModalInsertSeem,
    setShowModalPayment,
    done,
    doneProcessModal,
    setShowModalAttachmentDocument,
    setShowModalContractSing,
    isPaymentOfProcessValid,
    updateProcessButton,
    setDoneProcessModal,
    setShowModalPauseOrResumeProcess,
    setShowModalPaymentTypeOffer,
    setShowModalGeradorDocumento,
    setShowModalSelectScenario,
    setShowModalJustify,
    setShowModalPaymentExtra,
    menuButtonsConditions,
    setShowModalSelectPayment,
    isUserView,
    changeNegotiation,
    alert,
    setShowModalAcceptProposal,
    origin,
    selectTypeSignContract,
    postSendContractAgain
  } = props;

  const {
    processContractListLength,
    payAtTheBeginning,
    processName,
    documentsTypeLength,
    processStatusName,
    hasTransactions,
    canPauseOrResume,
    isPaused,
    hasPayment,
    isProcessWhoIsLoggedIn,
    hasSeem,
    isScenarioSelected,
    canChangeNegotiation,
  } = menuButtonsConditions;

  let finishButton = false;
  try {
    const {read, create, update } = JSON.parse(decodedToken.privileges)?.finalizar_processo
    const { isAdmin } = JSON.parse(localStorage.user)
    finishButton = isAdmin || read || create || update
  } catch (e) {
    console.error('[MENU] get localStorage user error')
  }

  const isPreAnalise = ["Pré Análise Aposentadoria", "Pré Análise Pensão por Morte"].includes(processName);

  if (decodedToken.is_user_and_customer && isUserView) return [];

  const menu = [{ title: "Painel de Ação" }];
  if (!decodedToken.is_customer || origin === 'side') {
    menu.push({
      variant: responsibleProcessDisabled && processTypeDisabled ? "primary" : "success",
      onClick: updateProcessButton,
      label: "Atualizar",
    });

    if (processContractListLength >= 2) {
      menu.push({
        label: "Adicionar Contrato",
        onClick: () => setShowModalDocument(true)
      });
    }

    if ((!isProcessWhoIsLoggedIn && processContractListLength >= 2 && payAtTheBeginning
      && processName.includes("Planejamento Previdenciário", "Pré Análise Aposentadoria")) || processName.includes("Pré Análise Aposentadoria")) {
      menu.push({
        label: isPreAnalise ? "Cenário" : "Planejamento",
        onClick: () => setShowModalInsertSeem(true),
      });
    }

    if ((payAtTheBeginning && processStatusName === "AGUARDANDO ESCOLHA DO MÉTODO DE PAGAMENTO E/OU DESCONTO") || oneProcess.allowsNewPayments) {
      menu.push({
        label: "Adicionar opção de pagamento",
        onClick: () => setShowModalPaymentTypeOffer(true),
      });
    }

    menu.push({
      label: "Assinatura de Contrato",
      onClick: () => MessageBox.confirm('De qual forma será a assinatura do contrato?', 'Pergunta', {
        confirmButtonText: 'Manual',
        cancelButtonText: 'Eletrônica',
        showClose: false,
        type: 'warning'
      }).then(() => selectTypeSignContract('MANUAL'))
        .catch(() => selectTypeSignContract('ELETRONIC'))
    });

    menu.push({
      label: "Gerador de Documentos",
      onClick: () => setShowModalGeradorDocumento(true),
    });

    menu.push({ title: "Principal" });

    if (documentsTypeLength || isPreAnalise) {
      menu.push({
        label: "Adicionar Documentos do Anexo",
        variant: "warning",
        onClick: () => setShowModalAttachmentDocument(true),
      });
    }

    if (processContractListLength) {
      menu.push({
        label: "Visualizar Documentos",
        variant: "warning",
        onClick: () => setShowModalContractSing(true),
      });
    }

    if (processContractListLength >= 2 && !payAtTheBeginning && isPaymentOfProcessValid()) {
      menu.push({
        label: hasPayment ? "Liberar pagamento" : "Atribuir RMI Simulada",
        onClick: () => setShowModalPaymentExtra(true),
      });
    }

    if (processStatusName === "AGUARDANDO PAGAMENTO" || hasTransactions || oneProcess.allowsNewPayments) {
      menu.push({
        label: "Pagamento",
        variant: "warning",
        onClick: () => setShowModalPayment(true),
      });
    }

    menu.push({
      label: "Adiar",
      variant: "warning",
      onClick: () => setShowModalJustify(true),
    });

    if (canPauseOrResume) {
      menu.push({
        label: isPaused ? "Retomar processo" : "Pausar processo",
        variant: "warning",
        onClick: () => setShowModalPauseOrResumeProcess(true),
      });
    }

    menu.push({
      label: "Reenviar Contrato",
      variant: "warning",
      onClick: postSendContractAgain,
    });


    if (finishButton) {
      menu.push({
        label: done ? "Reabrir" : "Finalizar",
        variant: done ? "success" : "danger",
        onClick: () => setDoneProcessModal(!doneProcessModal),
      });
    }

    if (isProcessWhoIsLoggedIn && decodedToken.is_user_and_customer && decodedToken.is_customer) {
      menu.push({ title: "Ações de cliente" });
    }

    if (isProcessWhoIsLoggedIn && decodedToken.is_user_and_customer && decodedToken.is_customer &&
      decodedToken.customer_id === oneProcess.customer.id && processStatusName === "AGUARDANDO ESCOLHA DO PAGAMENTO") {
      menu.push({
        label: "Escolher pagamento",
        variant: "primary",
        onClick: () => setShowModalSelectPayment(true),
      });
    }

    if (isProcessWhoIsLoggedIn && decodedToken.is_user_and_customer && decodedToken.is_customer && payAtTheBeginning
      && processName.includes("Planejamento Previdenciário") && hasSeem) {
      menu.push({
        label: isScenarioSelected ? "Alterar Cenário" : "Selecionar Cenário",
        variant: "primary",
        onClick: () => setShowModalSelectScenario(true),
      });
    }

    if (canChangeNegotiation) {
      menu.push({
        label: "Revisar proposta",
        variant: "primary",
        onClick: () => changeNegotiation(),
      });
    }

    if (decodedToken.is_admin && oneProcess?.seem?.id) {
      menu.push({
        label: "Remover Cenário",
        variant: "danger",
        onClick: () => postRemoveSeemScenario(oneProcess.id).then((r) => {
          alert.show(r.ok ? "Cenário removido com sucesso!" : r.data.log, { type: r.ok ? "success" : "error" });
        }),
      });
    }
    return menu;
  }

  if (oneProcess.processStatus.name === "AGUARDANDO ACEITE DA PROPOSTA") {
    menu.push({
      onClick: () => setShowModalAcceptProposal(true),
      label: "Visualizar Proposta",
    })
  }

  if ((oneProcess.processStatus.name === "AGUARDANDO ESCOLHA DO PAGAMENTO" || oneProcess.allowsNewPayments)
    && decodedToken.customer_id === oneProcess.customer.id) {
    menu.push({
      onClick: () => setShowModalSelectPayment(true),
      label: "Escolher pagamento",
    })
  }

  const { transactions, payment} = oneProcess
  const paymentCustomParcels = JSON.parse(payment?.jsonParcels || '[]')
  const typeDescription = [...new Set(paymentCustomParcels.map((cp) => cp.paymentType))]
  const trans = [...new Set(transactions.map((cp) => cp.paymentType))]

  const showPayBtn = (typeDescription.length > 1 && trans.length < typeDescription.length)
      || ((paymentCustomParcels.length > 0 && (paymentCustomParcels.filter(t => t.paymentType.toUpperCase() === 'PIX').length > 0 || oneProcess.payment?.paymentType.toUpperCase() === "PIX"))
      && ((transactions || []).filter(t => t.typePayment.toUpperCase() === "PIX" && t.status === "RECEIVED").length === 0))

  if (oneProcess.processStatus.name === "AGUARDANDO PAGAMENTO" && oneProcess.processType !== null
    && (oneProcess.transactions.length === 0 || showPayBtn)) {
    menu.push({
      onClick: () => setShowModalPayment(true),
      label: "Pagamento",
    })
  }

  if (oneProcess.processType && oneProcess.processType.payAtTheBeginning && oneProcess["processType"]["name"].includes("Planejamento Previdenciário")
    && oneProcess.seem !== null) {
    menu.push({
      onClick: () => setShowModalSelectScenario(true),
      label: isScenarioSelected ? "Alterar Cenário" : "Selecionar Cenário",
    })
  }

  if (oneProcess.processContractList.length > 0) {
    menu.push({
      label: "Visualizar Documentos",
      variant: "warning",
      onClick: () => setShowModalContractSing(true),
    })
  }

  return menu
}
