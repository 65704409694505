const pbx_cep = require("pbx_busca_endereco");

const allCitiesFromStates = {};
const states = [];

pbx_cep.GetAllBrazilStateAndCities().map((item) => {
  allCitiesFromStates[item.initials] = item.cities;
  states.push({ label: item.name, value: item.initials });
  return null;
});
const location = { allCitiesFromStates, states };
export default location;
