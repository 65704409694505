import { parse } from "date-fns";
import React from "react";
import { Row } from "react-bootstrap";
import { InputYear, RadioGroup } from "../../../genericComponents";
import { validations } from "../../../utils";

function CustomerSurvey12({ serverData }) {
  const [accidentHelp, setAccidentHelp] = React.useState(false);

  const serverAccidentHelp = serverData?.accidentHelp;
  React.useEffect(() => {
    if (serverAccidentHelp === "Sim") {
      setAccidentHelp(true);
    }
  }, [serverAccidentHelp, setAccidentHelp]);

  return (
    <Row>
      <RadioGroup
        colProps={{ xs: 12 }}
        label="Você já recebeu algum auxílio acidente?"
        name="accidentHelp"
        options={["Sim", "Não", "Não sei responder"].map((item) => ({
          label: item,
          value: item,
        }))}
        required='O campo "Você já recebeu algum auxílio acidente?" é obrigatório.'
        inline
        onChange={(event, value) => {
          setAccidentHelp(value === "Sim");
        }}
      />
      {accidentHelp ? (
        <>
          <InputYear
            colProps={{ md: 6 }}
            label="Ano de início"
            name="accidentHelpYearStart"
            required='O campo "Ano de início" é obrigatório.'
            inputMaskProps={{ placeholder: "Informe o ano de início" }}
            minDate={parse("1900", "yyyy", new Date())}
            maxDate={parse(new Date().getFullYear(), "yyyy", new Date())}
            onBlur={(event, value, ffHandler) => {
              const dateToCompare = ffHandler.getValue("accidentHelpYearEnd");
              if (dateToCompare !== "____") {
                ffHandler.testFieldError("accidentHelpYearEnd");
              }
            }}
            validation={(value, ffHandler) => {
              const result = validations.isDATE(
                parse(value, "yyyy", new Date()),
                null,
                parse(
                  ffHandler.getValue("accidentHelpYearEnd"),
                  "yyyy",
                  new Date()
                ),
                "yyyy"
              );
              return result;
            }}
          />
          <InputYear
            colProps={{ md: 6 }}
            label="Ano de término"
            name="accidentHelpYearEnd"
            required='O campo "Ano de término" é obrigatório.'
            inputMaskProps={{ placeholder: "Informe o ano de término" }}
            minDate={parse("1900", "yyyy", new Date())}
            maxDate={parse(new Date().getFullYear(), "yyyy", new Date())}
            onBlur={(event, value, ffHandler) => {
              const dateToCompare = ffHandler.getValue("accidentHelpYearStart");
              if (dateToCompare !== "____") {
                ffHandler.testFieldError("accidentHelpYearStart");
              }
            }}
            validation={(value, ffHandler) => {
              const result = validations.isDATE(
                parse(value, "yyyy", new Date()),
                parse(
                  ffHandler.getValue("accidentHelpYearStart"),
                  "yyyy",
                  new Date()
                ),
                null,
                "yyyy"
              );
              return result;
            }}
          />
        </>
      ) : null}
    </Row>
  );
}

export default React.memo(CustomerSurvey12);
