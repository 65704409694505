import React, { Component } from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Form,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import { alertActions } from "../../../store/actions/AlertActions";
import { getAllUser, putInsertAlert } from "../../../services/RequestService";
import InputAutoCompleteAlert from "../../InputAutoComplete/InputAutoCompleteAlert";
import "../../../pages/calendar/CalendarPage.css";

class ModalAlertComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allUsers: [],
      users: [],
      showList: false,
      description: "",
    };
  }

  componentDidMount = () => {
    getAllUser().then((res) => {
      this.setState((state) => ({ ...state, allUsers: res.data }));
    });
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
      insertAction: {
        ...this.state.insertAction,
        [e.target.name]: e.target.value,
      },
    }));
  };

  submitInsertAction = () => {
    const { description, users } = this.state;

    if (
      description === "" ||
      description === undefined ||
      users === undefined ||
      users.length === 0
    ) {
      this.props.onAddAlert(
        "Os campos marcados com * devem ser preenchidos",
        "danger"
      );
    } else {
      let usersId = [];

      for (let i = 0; i < users.length; i++) {
        usersId.push(users[i].id);
      }

      let insertAlert = { description: description, listUserId: usersId };
      putInsertAlert(insertAlert).then((res) => {
        if (res.ok) {
          this.props.onAddAlert("Lembrete adicionado.", "success");
          this.props.onHide();
        }
      });
    }
  };

  addUserToList = (user) => {
    const { allUsers, users } = this.state;
    if (!this.userHasAlreadyAdded(user)) {
      for (let i = 0; i < allUsers.length; i++) {
        if (user.id === allUsers[i].id) {
          users.push(user);
          this.setState((state) => ({ ...state, users }));
          break;
        }
      }
    }
  };

  delUserFromList = (user) => {
    const { allUsers, users } = this.state;

    for (let i = 0; i < allUsers.length; i++) {
      if (user.id === allUsers[i].id) {
        users.splice(users.indexOf(user), 1);
        this.setState((state) => ({ ...state, users }));
        break;
      }
    }
  };

  userHasAlreadyAdded = (user) => {
    const users = this.state.users;
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === user.id) {
        this.props.onAddAlert(
          "O usuário escolhido já foi adicionado à lista",
          "danger"
        );
        return true;
      }
    }
    return false;
  };

  render() {
    const { description, users } = this.state;
    const { show, onHide } = this.props;

    return (
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="md"
          scrollable={true}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Lembrete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mt-3">
              <Col>
                <Form.Group>
                  <Form.Label>
                    Descrição{" "}
                    <Form.Label className="danger-color">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    name="description"
                    value={description}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="m-1 d-block">
              <div className="mb-2">
                <span>Enviar para</span> <span className="danger-color">*</span>
              </div>
              <InputAutoCompleteAlert
                addUserToList={(user) => this.addUserToList(user)}
              />
            </Row>

            {users !== undefined && users !== null && users.length > 0 ? (
              <Row className="m-2">
                <Table striped bordered className="mt-3">
                  <tbody>
                    {users.map((user, key) => {
                      return (
                        <tr key={key}>
                          <td>{user.email}</td>
                          <td
                            className="text-center"
                            onClick={() => this.delUserFromList(user)}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip> remover usuário</Tooltip>}
                            >
                              <i className="fas fa-trash clickable"></i>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            ) : null}
            <Row className="my-3">
              <Col></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="p-0">
            <Row className="w-100 m-0">
              <Col className="p-0">
                <Button
                  variant="primary"
                  className="w-100 button-without-rounded"
                  onClick={this.submitInsertAction}
                >
                  Inserir
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalAlertComp);
