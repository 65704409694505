import {
  getCellByUserId,
  getEmailByCPF,
  insertCustomer,
  transformUserToCustomer,
  updateCustomer,
} from "../../../services/RequestService";
import { contactNames, epiNames } from "../models/formArrayNames";
import { pensionFormNames } from "../models/pensionFormNames";

function formatPensionData(pensionData, formatValue) {
  const degreeOfKinship = formatValue(pensionFormNames.degreeOfKinship);
  const kinshipDocuments = JSON.stringify({
    option: formatValue(pensionFormNames.kinshipDocuments),
    description: formatValue(pensionFormNames.kinshipDocumentsDescription),
  });
  const economicDependencyDocuments = JSON.stringify({
    option: formatValue(pensionFormNames.economicDependencyDocuments),
    description: formatValue(
      pensionFormNames.economicDependencyDocumentsDescription
    ),
  });
  const dateOfDeath = formatValue(pensionFormNames.dateOfDeath);
  const deathCertificate = formatValue(pensionFormNames.deathCertificate);
  const causeOfDeath = formatValue(pensionFormNames.causeOfDeath);
  const occurInTheExerciseOfFunction = formatValue(
    pensionFormNames.occurInTheExerciseOfFunction
  );
  const workingInDateOfDeath = formatValue(
    pensionFormNames.workingInDateOfDeath
  );
  const deceasedActivity = formatValue(pensionFormNames.deceasedActivity);
  const regimeOnDateOfDeath = JSON.stringify({
    option: formatValue(pensionFormNames.regimeOnDateOfDeath),
    description: formatValue(pensionFormNames.regimeOnDateOfDeathDescription),
  });
  const wasRetired = JSON.stringify({
    option: formatValue(pensionFormNames.wasRetired),
    benefitNumber: formatValue(pensionFormNames.wasRetiredBenefitNumber),
  });
  const receivedBenefit = formatValue(pensionFormNames.receivedBenefit);
  const youGetSomeBenefit = JSON.stringify({
    option: pensionData[pensionFormNames.youGetSomeBenefit] ? "Sim" : "Não",
    which: formatValue(pensionFormNames.youGetSomeBenefitDescription),
  });
  const requestReason = formatValue(pensionFormNames.requestReason);
  const hasDisability = formatValue(pensionFormNames.hasDisability);
  const hasDependentsUnder18 = JSON.stringify({
    option: formatValue(pensionFormNames.hasDependentsUnder18),
    cpf: formatValue(pensionFormNames.hasDependentsUnder18CPF),
  });
  const deceasedINSSPassword = formatValue(
    pensionFormNames.deceasedINSSPassword
  );
  const applicantINSSPassword = formatValue(
    pensionFormNames.applicantINSSPassword
  );

  return {
    degreeOfKinship,
    kinshipDocuments,
    economicDependencyDocuments,
    dateOfDeath,
    deathCertificate,
    causeOfDeath,
    occurInTheExerciseOfFunction,
    workingInDateOfDeath,
    deceasedActivity,
    regimeOnDateOfDeath,
    wasRetired,
    receivedBenefit,
    youGetSomeBenefit,
    requestReason,
    hasDisability,
    hasDependentsUnder18,
    deceasedINSSPassword,
    applicantINSSPassword,
  };
}
//
function formatRetirementData(retirementData, formatValue) {
  const benefitNumber = JSON.stringify({
    number: formatValue("benefitNumber"),
    year: formatValue("benefitYear"),
  });
  const regime = JSON.stringify({
    option: formatValue("regime") ? "Sim" : "Não",
    value: formatValue("regime"),
  });

  const militaryService = JSON.stringify({
    option: formatValue("militaryService"),
    date: {
      from: formatValue("militaryYearStart"),
      to: formatValue("militaryYearEnd"),
    },
  });

  const technicalAgricultureSchool = JSON.stringify({
    option: formatValue("technicalSchool"),
    date: {
      from: formatValue("technicalSchoolYearStart"),
      to: formatValue("technicalSchoolYearEnd"),
    },
  });
  const unhealthyDangerousness = JSON.stringify({
    option: formatValue("optionDangerous"),
    agents: {
      ruido:
        formatValue("dangerousNoiseCompany") +
        "'/'" +
        formatValue("dangerousNoiseMachine"),
      calor: formatValue("dangerousHeat"),
      frio: formatValue("dangerousCold"),
      eletricidade: formatValue("dangerousElectricity"),
      agentesQuimicos: formatValue("dangerousChemicalAgents"),
      trabalhoEmAltura: formatValue("dangerousHeight"),
      porteDeArma: formatValue("dangerousGunBearing"),
      agentesBiologicos: formatValue("dangerousBiologicalAgents"),
    },
  });

  const whichAre = [];
  epiNames.map((name) => {
    const value = retirementData[name];
    if (value) {
      whichAre.push(value);
    }
    return null;
  });

  const epi = JSON.stringify({
    option: formatValue("optionEPI"),
    whichAre,
  });
  const agriculture = JSON.stringify({
    weddingDate: formatValue("weddingDate"),
    periodWorked: formatValue("periodWorked"),
    haveDocumentsForProof: formatValue("haveDocumentsForProof"),
    option: formatValue("optionAgriculture"),
    condition: formatValue("condition"),
    uf: formatValue("terrainState"),
    city: formatValue("terrainCity"),
    propertyArea: formatValue("propertyArea"),
    exploredArea: formatValue("exploredArea"),
    resources: formatValue("resources"),
  });

  let weddingSplit = retirementData?.weddingDateFishing?.split("/");

  const fishing =
    retirementData.familyEconomyRegime === "Sim"
      ? {
        familyEconomyRegime: formatValue("familyEconomyRegime"),
        weddingDate: retirementData.weddingDateFishing
          ? new Date(
            weddingSplit[1] + "/" + weddingSplit[0] + "/" + weddingSplit[2]
          )
          : "",
        artisanalFishing: formatValue("artisanalFishing"),
        hasDocumentoForProof: formatValue("haveDocumentsForProofFishing"),
        vesselType: formatValue("vesselType"),
        vesselMeasure: formatValue("vesselMeasure"),
        ownVessel: formatValue("ownVessel"),
        fishSpecies: formatValue("fishSpecies"),
        howFishWasDone: formatValue("howFishWasDone"),
        whoWorksWithYou: formatValue("whoWorksWithYou"),
        otherIncome: formatValue("otherIncome"),
        cooperative: formatValue("cooperative"),
        haveAnEmployeeOrServiceProvider: formatValue(
          "haveAnEmployeeOrServiceProvider"
        ),
        fishingParents: formatValue("fishingParents"),
      }
      : null;
  const doubleBurden = JSON.stringify({
    option: formatValue("optionTwoPlaces"),
    name: formatValue("twoPlaceCompanies"),
  });

  const disabled = JSON.stringify({
    option: formatValue("deficiency"),
    deficiency: {
      whichOne: formatValue("deficiencyName"),
      from: formatValue("deficiencyYearStart"),
    },
  });

  const disasterAssistance = JSON.stringify({
    option: formatValue("accidentHelp"),
    date: {
      from: formatValue("accidentHelpYearStart"),
      to: formatValue("accidentHelpYearEnd"),
    },
  });

  const myInssPassword = JSON.stringify({
    option: formatValue("inss"),
    password: formatValue("inssPassword"),
  });

  const activityIncome = JSON.stringify({
    option: retirementData.optionActivityIncome ? "Sim" : "Não",
    value: formatValue("optionActivityIncome"),
  });
  const cooperative = JSON.stringify({
    option: retirementData.optionCooperative ? "Sim" : "Não",
    value: formatValue("optionCooperative"),
  });
  const activityOrExtraIncome = JSON.stringify({
    option: retirementData.optionActivityOrExtraIncome ? "Sim" : "Não",
    value: formatValue("optionActivityOrExtraIncome"),
  });

  const isRetired = formatValue("isRetired");
  const publicServer = formatValue("publicServer");
  const lawsuit = formatValue("workProcess");
  const parentsFarmer = formatValue("parentsFarmer", "Não");
  const employeeOrServiceProvider = formatValue(
    "employeeOrServiceProvider",
    "Não"
  );
  const retirementProcess = formatValue("tryRetirement");
  const retirementStudy = formatValue("retirementStudy");
  const beforeAprilNineFive = formatValue("beforeAprilNineFive");
  const disabledWorker = formatValue("disabledWorker");

  return {
    benefitNumber,
    regime,
    militaryService,
    technicalAgricultureSchool,
    unhealthyDangerousness,
    epi,
    agriculture,
    fishing,
    doubleBurden,
    disabled,
    disasterAssistance,
    myInssPassword,
    activityIncome,
    cooperative,
    activityOrExtraIncome,
    isRetired,
    publicServer,
    lawsuit,
    parentsFarmer,
    employeeOrServiceProvider,
    retirementProcess,
    retirementStudy,
    beforeAprilNineFive,
    disabledWorker,
  };
}

export default (props, state, finishCallback) => {
  const { id, from } = props;
  const { basicData, surveyData, customerId } = state;
  function formatValue(propertyName, placeholder = "") {
    return surveyData[propertyName] === undefined ? placeholder : surveyData[propertyName];
  }

  let contactWay = "";
  if (surveyData.contactWay) {
    contactNames.map((name) => {
      if (surveyData.contactWay[name]) {
        contactWay += `${contactWay ? "," : ""}${name}`;
      }
      return null;
    });
  } else {
    contactWay = "Whatsapp,SMS,E-mail,Telefone"
  }

  const additionalInformation = formatValue("additionalInformation");
  const termOfAgreement = true;

  const register = {
    // basic data
    fullname: basicData.fullname,
    cpf: basicData.cpf,
    rg: basicData.rg ?? "",
    birthday: basicData.birthday,
    gender: basicData.gender,
    phone: basicData.cellphone,
    alternativePhone: basicData.alternativePhone,
    email: basicData.email,
    nationality: basicData.nationality,
    occupation: basicData.occupation,
    maritualStatus: basicData.maritalStatus,
    addressStreet: basicData.street,
    addressNumber: basicData.placeNumber,
    addressDistrict: basicData.neighborhood,
    addressCity: basicData.city,
    addressState: basicData.state,
    addressZipCode: basicData.cep,
    addressComplement: basicData?.complement ?? "",

    indication: basicData?.indication ?? "",
    indicatorName: basicData?.indicatorName ?? "",
    indicatorDoc: basicData?.indicatorDoc ?? "",
    contactWay,
    additionalInformation,
    termOfAgreement,
  };

  if (surveyData[pensionFormNames.degreeOfKinship]) {
    // adiciona o formulário de "pensão por morte"
    register.pension = formatPensionData(surveyData, formatValue);
  } else {
    // adiciona o formulário de "aposentadoria"
    register.retirement = formatRetirementData(surveyData, formatValue);
  }

  if (id === undefined) {
    register.password = basicData.password;

    let decodedToken = undefined;
    if (from !== "login") {
      let jwt = require("jwt-decode");
      decodedToken = jwt(localStorage.getItem("token"));
    }

    register.outsourceAccredittedUser =
      decodedToken?.is_outsourced || decodedToken?.is_accredited
        ? decodedToken.user_id
        : null;

    register.userId = props.from === "login" ? 1 : decodedToken.user_id;
    register.isRetirementWithThreePayment =
      surveyData?.retirementType === "isRetirementWithThreePayment";
    register.isRetirementWithFiveHundredPayment =
      surveyData?.retirementType === "isRetirementWithFiveHundredPayment";
    register.isEncaminhamento = props?.category === "encaminhamento";
    register.isEncaminhamentoDireto =
      props?.category === "encaminhamento_direto";
    register.isPreAnaliseAposentadoria =
      props?.category === "pre_analise_aposentadoria";
    register.isPreAnalisePensaoPorMorte =
      props?.category === "pre_analise_pensao_morte";

    function registerCall(registerObj) {
      getEmailByCPF({ cpf: register.cpf }).then((res) => {
        if (res.data !== null) {
          delete registerObj["email"];
          delete registerObj["fullname"];
          delete registerObj["password"];
          transformUserToCustomer(JSON.stringify(registerObj)).then((res) => {
            if (!res.ok) {
              props.onAddAlert(
                "Houve um erro ao tentar efetuar o cadastro.",
                "danger",
                true
              );
              return finishCallback(null);
            }
            finishCallback("Dados do usuário cadastrados.");
          });
        } else {
          insertCustomer(JSON.stringify(registerObj)).then((res) => {
            if (!res.ok) {
              props.onAddAlert(
                "Houve um erro ao tentar efetuar o cadastro.",
                "danger",
                true
              );
              return finishCallback(null);
            }
            finishCallback("Dados do usuário cadastrados.");
          });
        }
      });
    }

    if (from === "login") {
      register.entryFrom = 1;
      registerCall(register);
    } else {
      getCellByUserId().then((res) => {
        if (res.ok) {
          register.entryFrom = res.data.isIntern ? 2 : 4;
          registerCall(register);
        }
      });
    }
  } else {
    register.id = customerId;
    register.user_id = id;
    updateCustomer(JSON.stringify(register)).then((res) => {
      if (!res.ok) {
        props.onAddAlert(
          "Houve um erro ao tentar atualizar o cadastro.",
          "danger",
          true
        );
        return finishCallback(null);
      }
      finishCallback("Dados do usuário atualizados.");
    });
  }
};
