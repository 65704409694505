import caller from "../../helpers/caller";
import Parse from "../../helpers/Parse";

function parseFunction(item) {
  return {
    id: item.id,
    name: item.name,
  };
}
export default async function getAllDocumentSubTypes(api) {
  const response = await caller(api.get, "/document_sub_type/get_all");
  const parse = new Parse(response.data, response.endpoint, parseFunction);
  return { ...response, data: parse.parseArray() };
}
