import React, {useEffect, useState} from "react";
import {Select, Loading, Button, Dialog} from "element-react";
import {attachDocument, genDocument, getDocumentTemplates} from "../../../../services/RequestService";
import {useAlert} from "react-alert";

export default function ModalGeradorDocumento({onHide, show, process}) {
  const processId = process.id
  const alert = useAlert();
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateId, setTemplateId] = useState(null);

  useEffect(() => {
    getDocumentTemplates().then(({ok, data}) => {
      if (ok) setTemplates(data);
    });
  }, []);

  async function generateDoc() {
    setLoading(true)
    setPdfUrl(null)
    const {ok, data} = await genDocument({processId, templateId})
    if (ok) {
      setPdfUrl(data)
    } else {
      alert.show("Erro ao gerar documento: " + data?.log, {type: "error"})
    }
    setLoading(false)
  }

  async function attachOnProcess(sendSign = false) {
    setLoading(true)
    const {ok, data} = await attachDocument({processId, templateId, sendSign, pdfUrl})
    if (ok) {
      alert.show("Documento anexado com sucesso", {type: "success"})
    } else {
      alert.show("Erro ao gerar documento: " + data?.log, {type: "error"})
    }
    setLoading(false)
  }

  return (
    <Dialog title="Gerador de Documentos" size="large" visible={show} onCancel={onHide} lockScroll={true}>
      <Dialog.Body style={{minHeight: '700px'}}>
        <Loading loading={loading}>
          <Select value={templateId} onChange={(v) => setTemplateId(v)} style={{width: '50%'}}>
            {(templates || []).map(el => (<Select.Option key={el.value} label={el.label} value={el.value}/>))}
          </Select>
          <Button type="success" onClick={generateDoc.bind(this)}>Gerar Documento</Button>
          <br/>
          <br/>
          <div style={{textAlign: 'center', border: '1px solid #ddd', minHeight: 400, marginBottom: 10}}>
            {pdfUrl ? (<iframe src={'data:application/pdf;base64,' + pdfUrl} title="Visualizar" width="100%" height="600px"/>)
              : (<label style={{padding: 10}}>Sem docmento para exibir</label>)}
          </div>
          <Button disabled={!pdfUrl} type="success" onClick={() => attachOnProcess(false)}>
            Anexar ao Processo
          </Button>
          <Button disabled={!pdfUrl} type="info" onClick={() => attachOnProcess(true)}>
            Anexar ao Processo e Enviar para Assinatura
          </Button>
        </Loading>
      </Dialog.Body>
    </Dialog>
  )
}

