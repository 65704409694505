import React from "react";
import { Row } from "react-bootstrap";
import { Input, InputCEP } from "../../../genericComponents";
import { getAddressItems } from "../validations";

function BasicData4({ disabled, changeState, isValidCEP, states }) {
  const [cepLoading, setCepLoading] = React.useState(false);

  return (
    <Row>
      <InputCEP
        colProps={{ sm: 4, xl: 2 }}
        label="CEP"
        name="cep"
        disabled={disabled}
        required="Insira um número de CEP"
        loading={cepLoading}
        onChange={(event, value, ffHandler) => {
          if (isValidCEP) {
            changeState({ isValidCEP: false });
            ffHandler.setValue("street", "");
          }
        }}
        onBlur={async (event, cepValue, ffHandler) => {
          changeState({ isValidCEP: false });
          const result = await ffHandler.testFieldError("cep", false);
          if (!result) {
            setCepLoading(true);

            getAddressItems(cepValue, ffHandler, "cep", states, (isValid) => {
              setCepLoading(false);
              changeState({ isValidCEP: isValid });
            });
          }
        }}
      />

      <Input
        colProps={{ sm: 8, xl: 4 }}
        label="Rua"
        name="street"
        maxLength={100}
        inputMaskProps={{ placeholder: "Rua" }}
        disabled={disabled || !isValidCEP}
        required="O campo deve ser preenchido."
      />

      <Input
        colProps={{ sm: 4, xl: 2 }}
        label="Número"
        name="placeNumber"
        maxLength={20}
        inputMaskProps={{
          placeholder: "Número",
        }}
        mask={(value) => value.replace(/[^\d]/g, "")}
        disabled={disabled}
        required="O campo deve ser preenchido."
      />

      <Input
        colProps={{ sm: 8, xl: 4 }}
        label="Complemento"
        maxLength={100}
        name="complement"
        inputMaskProps={{
          placeholder: "Complemento",
        }}
        disabled={disabled}
        required="O campo deve ser preenchido."
      />
    </Row>
  );
}

export default React.memo(BasicData4);
