import React, { Component } from "react";
import SingleCustomerComp from "../../components/customer/SingleCustomerComp";

class SingleCustomerPage extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      id: params?.id,
      from: params?.from,
      category: params?.category,
    };
  }

  render() {
    const { id, from, category } = this.state;
    return (
      <SingleCustomerComp
        privileges={this.props.privileges}
        isAdmin={this.props.decodedToken?.is_admin}
        from={from}
        id={id}
        category={category}
      />
    );
  }
}

export default SingleCustomerPage;
