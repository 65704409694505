import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { acceptProposal } from "../../../../services/RequestService";
import { alertActions } from "../../../../store/actions/AlertActions";
import { connect } from "react-redux";
import styles from "../styles/Modal.module.css";

function getLocation(props) {
  navigator.geolocation.getCurrentPosition(
    function (position) {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      accept(props, lat, long);
    },
    function (error) {
      accept(props, null, null);
    }
  );
}

function accept(props, lat, long) {
  const { onHide, onAddAlert, proposal } = props;
  acceptProposal({
    processContractId: proposal?.id,
    latitude: lat,
    longitude: long,
  }).then((res) => {
    if (res.ok) {
      onAddAlert("Proposta aceita com sucesso.", "success", false);
      onHide(true);
    } else {
      onAddAlert(
        "Houve um erro ao tentar finalizar o processo. Por favor novamente tentar mais tarde!",
        "danger",
        true
      );
      onHide(false);
    }
  });
}

function getText(proposal) {
  const isForwarding = proposal?.name.includes("encaminhamento");
  const isRegistration = proposal?.name.includes("averbacao");
  const isPension = proposal?.name.includes("pensao");

  if (isForwarding) {
    return "O serviço consiste no Pedido de Concessão de Benefício Previdenciário junto ao INSS, que compreende o encaminhamento do Requerimento Administrativo fundamentado e instruído com os documento necessários para obtermos o resultado identificado pela opção feita no Planejamento Previdenciário.";
  }
  if (isRegistration) {
    return "";
  }
  if (isPension) {
    return "O serviço de Concessão de Pensão por Morte oferecido pela Qualitte Aposentadorias consiste na orientação do cliente quanto aos prazos para realizar o pedido junto ao INSS, quanto aos documentos necessários para comprovação da dependência econômica, do vínculo conjugal e da qualidade de segurado; e ainda todo uma análise da situação fática, para possibilitar um pedido correto, instruído com todos os documentos necessários, visando a concessão rápida e segura do benefício.";
  }

  return "A proposta de serviço para o Planejamento Previdenciário tem o objetivo de diagnosticar o momento ideal e as estratégias para você obter o maior valor de benefício";
}

function getTitle(proposal) {
  const isForwarding = proposal?.name.includes("encaminhamento");
  const isRegistration = proposal?.name.includes("averbacao");
  const isPension = proposal?.name.includes("pensao");

  if (isForwarding) {
    return "Proposta de Encaminhamento de Benefício.pdf";
  }
  if (isRegistration) {
    const isFishing = proposal?.name.includes("pesca");
    const isSpecial = proposal?.name.includes("especial");
    const isRural = proposal?.name.includes("rural");
    if (isFishing) {
      return "Proposta de Pesca Artesanal.pdf";
    } else if (isSpecial) {
      return "Proposta de Averbação Especial.pdf";
    } else if (isRural) {
      return "Proposta de Averbação Rural.pdf";
    }
  }
  if (isPension) {
    return "Proposta de Pensão por Morte";
  }

  return "Proposta de Planejamento Previdenciario.pdf";
}

class ModalAcceptProposal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    const { show, onHide, proposal } = this.props;
    const { loading } = this.state;
    return (
      <Modal show={show} size="lg" onHide={onHide}>
        <Modal.Header closeButton>
          <div className={styles.title}>PROPOSTA DE PRESTAÇÃO DE SERVIÇO</div>
        </Modal.Header>
        <Modal.Body className=" text-center">
          <div className={styles.text}>
            {
              "Seja bem-vindo a Qualitte Aposentadorias, obrigado por sua confiança."
            }
          </div>
          <div className={styles.text}>{getText(proposal)}</div>
          <Row className={styles.view_document}>
            <Col className={styles.text}>{getTitle(proposal)}</Col>
            <Col className={styles.download_button}>
              <a
                href={
                  "data:application/pdf;base64," + proposal?.generatedContract
                }
                download
                className={styles.text}
              >
                Baixar
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center text-center">
              <div className={styles.info_text}>Dúvidas e informações</div>
              <div className={styles.info_text}>
                {"SAC 4020-25-80 WhatApp (47) 3310-3900"}
              </div>
              <div className={styles.info_text}>
                Email: aposentadoria.falecom@qualitte.com.br
              </div>
            </Col>
            <Col className={styles.button_col}>
              <Button
                disabled={loading}
                className={styles.button}
                onClick={() =>
                  this.setState(
                    (state) => ({ ...state, loading: true }),
                    () => getLocation(this.props)
                  )
                }
              >
                {loading ? "Aceitando Proposta..." : "Aceitar Proposta"}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalAcceptProposal);
