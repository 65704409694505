import React from "react";
import {Card, Col, Form, Tooltip, OverlayTrigger} from "react-bootstrap";
import genericStyles from "../styles/SingleProcess.module.css";
import InputMask from "react-input-mask";

function getFormattedDate(date) {
  if (date === null || date === undefined) {
    return "Não possui";
  } else {
    let cdDate = new Date(date);
    return cdDate.toLocaleDateString("pt-BR");
  }
}

function RenderInput({name, label, value, disabled, colProps, options, type, ...props}) {
  if (value || value === 0) {
    return (
      <OverlayTrigger
        placement={"bottom"}
        overlay={<Tooltip id={name}>{value}</Tooltip>}
      >
        <Col {...colProps}>
          <div className={`div-form-group ${disabled ? "" : "enabled"}`}>
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              {type === "date" ? (
                <InputMask
                  {...props}
                  size="sm"
                  className={"form-control form-control-sm"}
                  onFocus={(e) => e.target.select()}
                  mask={"99/99/9999"}
                  name={name}
                  placeholder={value}
                  disabled={disabled}
                />
              ) : (
                <Form.Control
                  {...props}
                  disabled={disabled}
                  name={name}
                  value={value}
                  size="sm"
                  as={options ? "select" : "input"}
                  className="text-truncate"
                >
                  {options ? (
                    <>
                      <option/>
                      {options.map(({value, label}, key) => (
                        <option key={key} value={value}>
                          {label}
                        </option>
                      ))}
                    </>
                  ) : null}
                </Form.Control>
              )}
            </Form.Group>
          </div>
        </Col>
      </OverlayTrigger>
    );
  }

  return (
    <Col {...colProps}>
      <div className={`div-form-group ${disabled ? "" : "enabled"}`}>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            {...props}
            disabled={disabled}
            name={name}
            value={value || ''}
            size="sm"
            className="text-truncate"
            as={options ? "select" : "input"}
          >
            {options ? (
              <>
                <option/>
                {options.map(({value, label}, key) => (
                  <option key={key} value={value}>
                    {label}
                  </option>
                ))}
              </>
            ) : null}
          </Form.Control>
        </Form.Group>
      </div>
    </Col>
  );
}

function ProcessInfos({
                        allCells,
                        allProcessType,
                        processType,
                        processTypeDisabled,
                        deadlineDisabled,
                        responsibleProcessDisabled,
                        allProcessStatus,
                        processStatus,
                        processStatusDisabled,
                        oneProcess,
                        responsibleProcess,
                        creatorCell,
                        handleChangeProcessStatus,
                        handleChangeDeadline,
                        handleChangeProcessType,
                        handleChangeResponsibleProcess,
                        handleCustomerProtocol
                      }) {
  const [contentVisible, setContentVisible] = React.useState(true);
  return (
    <Card className={genericStyles.cards}>
      <div>
        <div
          className={`clickable pt-3 mt-n3 px-3 mx-n3 d-flex justify-content-between header-card-hover ${contentVisible ? "pb-2" : "mb-n3 pb-3"}`}
          onClick={() => setContentVisible(!contentVisible)}
        >
          <span><strong>Dados do Andamento</strong></span>
          <i className={`fas fa-angle-down pl-2 navi-rotate-icon ${contentVisible ? "" : "navi-icon-right"}`}/>
        </div>
        {contentVisible ? (
          <div>
            <div className={"mb-2 border-top"}/>
            <Form.Row>
              <RenderInput
                colProps={{xs: "12", sm: "2"}}
                name="processId"
                label="ID"
                value={oneProcess.id}
                type="text"
                disabled
              />

              <RenderInput
                colProps={{xs: "12", sm: "4", md: "3"}}
                name="customerId"
                label="ID DO CLIENTE"
                value={oneProcess.customer.id}
                type="text"
                disabled
              />

              <RenderInput
                colProps={{xs: "12", sm: "6", md: "5"}}
                name="customerName"
                label="NOME DO CLIENTE"
                value={oneProcess.customer.name}
                type="text"
                disabled
              />

              <RenderInput
                colProps={{xs: "12", sm: "3", md: "2"}}
                name="applicationProtocol"
                label="PROTOCOLO"
                value={oneProcess.applicationProtocol}
                type="text"
                onChange={handleCustomerProtocol}
                disabled={processStatusDisabled}
              />
              <RenderInput
                colProps={{xs: "12", sm: "4", md: "4"}}
                name="createDate"
                label="DATA DE CRIAÇÃO"
                value={getFormattedDate(oneProcess.createDate)}
                type="text"
                disabled
              />
              <RenderInput
                colProps={{xs: "12", sm: "5", md: "4"}}
                name="updateDate"
                label="PRAZO DE ENTREGA"
                value={
                  oneProcess.paused
                    ? "Processo pausado"
                    : getFormattedDate(oneProcess.deliveryDate)
                }
                type={oneProcess.paused ? "text" : "date"}
                disabled={!oneProcess.paused && deadlineDisabled}
                onChange={handleChangeDeadline}
              />
              <RenderInput
                colProps={{xs: "12", sm: "4", md: "4"}}
                name="applicationProtocol"
                label="DATA PROTOCOLO"
                value={oneProcess.protocolDate ? oneProcess.protocolDate.split("-").reverse().join("/") : 'Não Informado'}
                type="text"
                onChange={handleCustomerProtocol}
                disabled={processStatusDisabled}
              />
              <RenderInput
                colProps={{xs: "12", md: "6"}}
                name="processType"
                label="TESE"
                value={processType}
                disabled={processTypeDisabled}
                onChange={handleChangeProcessType}
                options={allProcessType ? Object.keys(allProcessType).map((item) => ({
                  value: item,
                  label: item
                })) : null}
              />
              <RenderInput
                colProps={{xs: "12", md: "6"}}
                name="processStatus"
                label="STATUS"
                value={processStatus}
                disabled={processStatusDisabled}
                onChange={handleChangeProcessStatus}
                options={allProcessStatus ? Object.keys(allProcessStatus).map((item) => ({
                  value: item,
                  label: item
                })) : null}
              />

              <RenderInput
                colProps={{xs: "12", sm: "6", md: "6"}}
                name="owner"
                label="CÉLULA RESPONSÁVEL"
                value={responsibleProcess}
                disabled={responsibleProcessDisabled}
                onChange={handleChangeResponsibleProcess}
                options={allCells ? Object.keys(allCells).map((item) => ({value: item, label: item})) : null}
              />

              <RenderInput
                colProps={{xs: "12", sm: "6", md: "6"}}
                name="creator"
                label="CÉLULA CRIADORA"
                value={creatorCell}
                disabled
              />
            </Form.Row>
          </div>
        ) : null}
      </div>
    </Card>
  );
}

export default ProcessInfos;
