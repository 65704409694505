import React from "react";
import { PageContainer } from "../../genericComponents";
import logo from "../../res/LogoQualitte.png";
import { Image } from "react-bootstrap";

function HomePage() {
  return (
    <PageContainer withoutCard>
      <div className="h-100 d-flex align-items-center justify-content-center">
        <Image
          src={logo}
          className={`w-75 on-load-image`}
          onLoad={(event) => {
            event.target.style.opacity = 0.15;
          }}
        />
      </div>
    </PageContainer>
  );
}

export default HomePage;
