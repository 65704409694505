import React from "react";
import { Row, Col } from "react-bootstrap";
import { CheckInputs, RadioGroup } from "../../../genericComponents";
import { dangerousNames } from "../models/formArrayNames";

function CustomerSurvey5({ serverData }) {
  const [dangerousWork, setDangerousWork] = React.useState(false);

  function onCheckInputChange(event, value, ffHandler) {
    ffHandler.testFieldError("optionDangerous");
  }
  const optionDangerous = serverData?.optionDangerous;
  React.useEffect(() => {
    if (optionDangerous === "Sim") {
      setDangerousWork(true);
    }
  }, [optionDangerous, setDangerousWork]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Trabalhou com insalubridade e periculosidade?"
          name="optionDangerous"
          options={[
            {
              value: "Sim",
              label: "Sim",
            },
            {
              value: "Não",
              label: "Não",
            },
            {
              value: "Não sei responder",
              label: "Não sei responder",
            },
          ]}
          required='O campo "Trabalhou com insalubridade e periculosidade?" é obrigatório.'
          inline
          onChange={(event, value) => {
            setDangerousWork(value === "Sim");
          }}
          validation={(value, ffHandler) => {
            const isValid =
              value !== "Sim" ||
              Boolean(dangerousNames.find((name) => ffHandler.getValue(name)));
            return isValid ? null : "Um ou mais agentes devem ser selecionados";
          }}
        />
      </Row>
      {dangerousWork ? (
        <div>
          <Row>
            <Col className="p-0" xs={12} xl={6}>
              <CheckInputs
                label="Ruído"
                checked={serverData?.dangerousNoiseCompany}
                inputs={[
                  {
                    name: "dangerousNoiseCompany",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Empresa" },
                    required:
                      'O campo de "Empresa" é obrigatório pois "Ruído" está selecionado.',
                    maxLength: 100,
                  },
                  {
                    name: "dangerousNoiseMachine",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Máquina" },
                    required:
                      'O campo de "Máquina" é obrigatório pois "Ruído" está selecionado.',
                    maxLength: 100,
                  },
                ]}
              />
            </Col>

            {/*CALOR*/}
            <Col className="p-0" xs={12} xl={6}>
              <CheckInputs
                label="Calor"
                checked={serverData?.dangerousHeat}
                inputs={[
                  {
                    name: "dangerousHeat",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Empresa" },
                    required:
                      'O campo de "Empresa" é obrigatório pois "Calor" está selecionado.',
                    maxLength: 100,
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            {/*FRIO*/}
            <Col className="p-0" xs={12} xl={6}>
              <CheckInputs
                label="Frio"
                checked={serverData?.dangerousCold}
                inputs={[
                  {
                    name: "dangerousCold",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Empresa" },
                    required:
                      'O campo de "Empresa" é obrigatório pois "Frio" está selecionado.',
                    maxLength: 100,
                  },
                ]}
              />
            </Col>

            {/*Eletricidade*/}
            <Col className="p-0" xs={12} xl={6}>
              <CheckInputs
                label="Eletricidade"
                checked={serverData?.dangerousElectricity}
                inputs={[
                  {
                    name: "dangerousElectricity",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Empresa" },
                    required:
                      'O campo de "Empresa" é obrigatório pois "Eletricidade" está selecionado.',
                    maxLength: 100,
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            {/*Agentes químicos */}
            <Col className="p-0" xs={12} xl={6}>
              <CheckInputs
                label="Agentes químicos"
                checked={serverData?.dangerousChemicalAgents}
                inputs={[
                  {
                    name: "dangerousChemicalAgents",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Empresa" },
                    required:
                      'O campo de "Empresa" é obrigatório pois "Agentes químicos" está selecionado.',
                    maxLength: 100,
                  },
                ]}
              />
            </Col>
            {/*Trabalho em altura*/}

            <Col className="p-0" xs={12} xl={6}>
              <CheckInputs
                label="Trabalho em altura"
                checked={serverData?.dangerousHeight}
                inputs={[
                  {
                    name: "dangerousHeight",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Empresa" },
                    required:
                      'O campo de "Empresa" é obrigatório pois "Trabalho em altura" está selecionado.',
                    maxLength: 100,
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            {/*Com porte de arma de fogo */}

            <Col className="p-0" xs={12} xl={6}>
              <CheckInputs
                label="Com porte de arma de fogo"
                checked={serverData?.dangerousGunBearing}
                inputs={[
                  {
                    name: "dangerousGunBearing",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Empresa" },
                    required:
                      'O campo de "Empresa" é obrigatório pois "Com porte de arma de fogo" está selecionado.',
                    maxLength: 100,
                  },
                ]}
              />
            </Col>

            {/*Agentes biológicos*/}

            <Col className="p-0" xs={12} xl={6}>
              <CheckInputs
                label="Agentes biológicos"
                checked={serverData?.dangerousBiologicalAgents}
                inputs={[
                  {
                    name: "dangerousBiologicalAgents",
                    onChange: onCheckInputChange,
                    inputMaskProps: { placeholder: "Empresa" },
                    required:
                      'O campo de "Empresa" é obrigatório pois "Agentes biológicos" está selecionado.',
                    maxLength: 100,
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      ) : null}
    </>
  );
}

export default React.memo(CustomerSurvey5);
