import React, { Component } from "react";
import { Modal, Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import {
  getAllWithDiscount,
  postChooseDiscountParcels,
} from "../../../../services/RequestService";
import { connect } from "react-redux";
import { alertActions } from "../../../../store/actions/AlertActions";
import { masks } from "../../../../utils";

// function floorFigure(figure, decimals) {
//   if (!decimals) decimals = 2;
//   let d = Math.pow(10, decimals);
//   return (parseInt(figure * d) / d).toFixed(decimals);
// }
class ModalPaymentTypeOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      value: props?.value ?? 0,
      discount: 0,
      valueWithDiscount: props?.value
        ? masks.inputMoney(props.value + ",00")
        : 0,
      maxDiscountInReais: 0,
      maxDiscount: 0,
      creditCardParcels: [],
      useCreditCard: false,
      maxParcelCreditCard: 0,
      isCreditCard: false,
      creditCardParcel: 0,
      useTicket: false,
      maxParcelTicket: 0,
      ticketParcels: [],
      isTicketParcel: false,
      ticketParcel: 0,
      pix: 0,
      isPix: false,
      validFields: {
        isTicketParcelValid: undefined,
        isCreditCardValid: undefined,
        isDiscountInReaisValid: props?.value ? true : undefined,
      },
    };
  }

  componentDidMount = () => {
    const { processId, value } = this.props;
    this.setState({ loading: true });
    getAllWithDiscount({ processId }).then((res) => {
      if (res.ok) {
        res.data.paymentMethods.forEach((element) => {
          if (element.name === "Boleto") {
            let parcels = [];
            parcels = this.parcels(element.parcels, value);
            this.setState((state) => ({
              ...state,
              useTicket: true,
              ticketParcels: parcels,
              maxParcelTicket: element.parcels,
            }));
          } else if (element.name === "Cartão de Crédito") {
            let parcels = [];
            parcels = this.parcels(element.parcels, value);
            this.setState((state) => ({
              ...state,
              useCreditCard: true,
              creditCardParcels: parcels,
              maxParcelCreditCard: element.parcels,
            }));
          }
        });
        const maxDiscount = res.data.discount;
        let maxDiscountInReais = value - (value * res.data.discount) / 100;
        this.setState({ maxDiscount, maxDiscountInReais, loading: false });
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDiscountChange = (e) => {
    e.persist();
    const { value } = e.target;
    const processValue = this.state.value;
    const { validFields } = this.state;
    let valueWithDiscount = masks.inputMoney(
      (processValue - (processValue * value) / 100).toFixed(2)
    );
    if (valueWithDiscount < 3.0) {
      valueWithDiscount = 0;
    }
    this.setState(
      (state) => ({
        ...state,
        discount: value,
        valueWithDiscount,
        creditCardParcel: 0,

        ticketParcel: 0,
        validFields: {
          ...validFields,
          isCreditCardValid: undefined,
          isTicketParcelValid: undefined,
          isDiscountInReaisValid: true,
        },
      }),
      () => this.calcParcels()
    );
  };

  handleDiscountChangeInReais = (e) => {
    e.persist();
    const { value } = e.target;
    let v = value <= 0 ? "0,00" : masks.inputMoney(value);
    let discount = (
      100 -
      (v.replace(".", "").replace(",", ".") * 100) / this.state.value
    ).toFixed(4);

    this.setState({
      valueWithDiscount: v,
      discount,
    });
  };

  calcParcels = () => {
    const {
      valueWithDiscount,
      useCreditCard,
      maxParcelCreditCard,
      useTicket,
      maxParcelTicket,
    } = this.state;

    let ticketParcels = [];
    let creditCardParcels = [];
    if (useCreditCard) {
      creditCardParcels = this.parcels(
        maxParcelCreditCard,
        parseFloat(valueWithDiscount.replace(".", "").replace(",", "."))
      );
    }
    if (useTicket) {
      ticketParcels = this.parcels(
        maxParcelTicket,
        parseFloat(valueWithDiscount.replace(".", "").replace(",", "."))
      );
    }
    this.setState({
      creditCardParcels,
      ticketParcels,
    });
  };

  parcels = (max, valueWithDiscount) => {
    let element = [];
    console.log(max, valueWithDiscount);
    for (let i = 1; i < max + 1; i++) {
      let v = (valueWithDiscount / i).toFixed(2);
      if (v >= 3.0) {
        element.push({
          label: `Em ${i}x de R$ ${v}`,
          value: i,
        });
      }
    }
    return element;
  };

  isParcelValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;
    let name =
      e.target.name === "ticketParcel"
        ? "isTicketParcelValid"
        : "isCreditCardValid";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, [name]: value !== "" },
    }));
  };

  isDiscountInReaisValid = () => {
    const { /* maxDiscountInReais, value,  */ validFields } = this.state;
    // let valueWithDiscount = parseFloat(
    //   this.state.valueWithDiscount.replace(".", "").replace(",", ".")
    // );
    // let isDiscountInReaisValid = true;
    // if (valueWithDiscount > value || maxDiscountInReais > valueWithDiscount) {
    //   isDiscountInReaisValid = false;
    // }

    this.calcParcels();
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isDiscountInReaisValid: true },
    }));
  };

  disabledField = () => {
    const {
      valueWithDiscount,
      isTicketParcel,
      useTicket,
      isCreditCard,
      useCreditCard,
      validFields,
    } = this.state;
    if (!validFields.isDiscountInReaisValid) {
      return true;
    } else if (valueWithDiscount >= 3.0) {
      if (useCreditCard && useTicket) {
        if (isTicketParcel && isCreditCard) {
          return (
            !validFields.isTicketParcelValid && !validFields.isCreditCardValid
          );
        } else if (isTicketParcel) {
          return !validFields.isTicketParcelValid;
        } else if (isCreditCard) {
          return !validFields.isCreditCardValid;
        }
        return true;
      } else if (useTicket) {
        return isTicketParcel ? !validFields.isTicketParcelValid : true;
      } else if (useCreditCard) {
        return isCreditCard ? !validFields.isCreditCardValid : true;
      }
    } else {
      return false;
    }
  };

  submit = () => {
    const {
      discount,
      isTicketParcel,
      ticketParcel,
      isCreditCard,
      creditCardParcel,
      isPix,
    } = this.state;
    const { processId } = this.props;
    this.setState({ loading: true });
    postChooseDiscountParcels({
      processId,
      discount,
      useCreditCard: isCreditCard,
      creditCardParcels: creditCardParcel,
      useTicket: isTicketParcel,
      ticketParcels: ticketParcel,
      usePix: isPix,
    }).then((res) => {
      if (res.ok) {
        this.setState({ loading: false });
        this.props.onHide();
        this.props.onAddAlert(
          "Opção de pagamento adicionado com sucesso!",
          "success",
          false
        );
      } else {
        this.setState({ loading: false });
        this.props.onHide();
        this.props.onAddAlert(
          "Houve um problema ao tentar adicionar a opção de pagamento, por favor tente mais tarde!",
          "danger",
          false
        );
      }
    });
  };

  render() {
    const { show, onHide } = this.props;
    const {
      loading,
      value,
      discount,
      valueWithDiscount,
      maxDiscount,
      creditCardParcels,
      useCreditCard,
      isCreditCard,
      creditCardParcel,
      ticketParcels,
      useTicket,
      isTicketParcel,
      ticketParcel,
      isPix,
      validFields,
    } = this.state;
    return (
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Escolha de desconto/método de pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Valor do serviço</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <div>R$</div>
              </InputGroup.Text>
              <Form.Control disabled={true} value={value} />
            </InputGroup>
          </Form.Group>
          {maxDiscount > 0 && (
            <Form.Group>
              <Form.Label>{"Desconto: " + discount + "%"}</Form.Label>
              <Row>
                <Col sm={1} md={1} lg={1} xm={1}>
                  <div>0%</div>
                </Col>
                <Col sm={10} md={10} lg={10} xm={10}>
                  <div className="slidecontainer">
                    <input
                      type="range"
                      min={"0"}
                      max={maxDiscount}
                      step={0.1}
                      value={discount}
                      name={"discount"}
                      className="slider"
                      id="discount"
                      onChange={(e) => this.handleDiscountChange(e)}
                    />
                  </div>
                </Col>
                <Col sm={1} md={1} lg={1} xm={1}>
                  {maxDiscount + "%"}
                </Col>
              </Row>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Valor com desconto</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <div>R$</div>
              </InputGroup.Text>
              <Form.Control
                value={valueWithDiscount}
                onChange={(e) => this.handleDiscountChangeInReais(e)}
                onBlur={() => this.isDiscountInReaisValid()}
                className={
                  validFields.isDiscountInReaisValid === undefined
                    ? ""
                    : validFields.isDiscountInReaisValid
                    ? "is-valid"
                    : "is-invalid"
                }
              />
            </InputGroup>
            <Form.Text className="is-required">
              {validFields.isDiscountInReaisValid === undefined
                ? ""
                : validFields.isDiscountInReaisValid
                ? ""
                : "O Valor com desconto está acima do valor do serviço, ou está abaixo do valor de desconto máximo"}
            </Form.Text>
          </Form.Group>
          {valueWithDiscount.replace(".", "").replace(",", ".") > 3.0 && (
            <Form.Group>
              <Row>
                {useTicket && (
                  <Col>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                      <Form.Label>
                        {"Pix"}
                        {isPix ? (
                          <Form.Label className="is-required">*</Form.Label>
                        ) : null}
                      </Form.Label>
                      <Row>
                        <Col sm={1} md={1} lg={1} xm={1}>
                          <Form.Check
                            type="checkbox"
                            className={"pt-2"}
                            name={"isPix"}
                            value={isPix}
                            checked={isPix}
                            onChange={() =>
                              this.setState((state) => ({
                                ...state,
                                isPix: !isPix,
                                pix: "",
                              }))
                            }
                          />
                        </Col>
                        <Col sm={11} md={11} lg={11} xm={11}>
                          <Form.Control
                            name={"pix"}
                            value={
                              isPix ? "Em 1x de R$ " + valueWithDiscount : ""
                            }
                            onChange={this.handleInputChange}
                            onBlur={
                              isTicketParcel
                                ? (e) => this.isParcelValid(e)
                                : null
                            }
                            disabled={true}
                            className={isPix ? "is-valid" : ""}
                          ></Form.Control>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}
                {useTicket && (
                  <Col>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                      <Form.Label>
                        {"Parcela do boleto"}
                        {isTicketParcel ? (
                          <Form.Label className="is-required">*</Form.Label>
                        ) : null}
                      </Form.Label>
                      <Row>
                        <Col sm={1} md={1} lg={1} xm={1}>
                          <Form.Check
                            type="checkbox"
                            className={"pt-2"}
                            name={"isTicketParcel"}
                            value={isTicketParcel}
                            checked={isTicketParcel}
                            onChange={() =>
                              this.setState((state) => ({
                                ...state,
                                isTicketParcel: !isTicketParcel,
                                ticketParcel: "",
                                validFields: {
                                  ...validFields,
                                  isTicketParcelValid: undefined,
                                },
                              }))
                            }
                          />
                        </Col>
                        <Col sm={11} md={11} lg={11} xm={11}>
                          <Form.Control
                            as="select"
                            name={"ticketParcel"}
                            value={ticketParcel}
                            onChange={this.handleInputChange}
                            onBlur={
                              isTicketParcel
                                ? (e) => this.isParcelValid(e)
                                : null
                            }
                            disabled={!isTicketParcel}
                            className={
                              validFields.isTicketParcelValid === undefined
                                ? ""
                                : validFields.isTicketParcelValid
                                ? "is-valid"
                                : "is-invalid"
                            }
                          >
                            <option />
                            {ticketParcels &&
                              ticketParcels.map((item, key) => {
                                return (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </Form.Control>
                          <Form.Text className="is-required">
                            {validFields.isTicketParcelValid === undefined
                              ? ""
                              : validFields.isTicketParcelValid
                              ? ""
                              : "O campo parcela do boleto não deve ser vazio"}
                          </Form.Text>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}
                {useCreditCard && (
                  <Col>
                    <Form.Group controlId="exampleForm.ControlSelect3">
                      <Form.Label>
                        Parcela do cartão de crédito
                        {isCreditCard ? (
                          <Form.Label className="is-required ">*</Form.Label>
                        ) : null}
                      </Form.Label>
                      <Row>
                        <Col sm={1} md={1} lg={1} xm={1}>
                          <Form.Check
                            type="checkbox"
                            className={"pt-2"}
                            name={"isCreditCard"}
                            value={isCreditCard}
                            checked={isCreditCard}
                            onChange={() =>
                              this.setState((state) => ({
                                ...state,
                                isCreditCard: !isCreditCard,
                                creditCardParcel: "",
                                validFields: {
                                  ...validFields,
                                  isCreditCardValid: undefined,
                                },
                              }))
                            }
                          />
                        </Col>
                        <Col sm={11} md={11} lg={11} xm={11}>
                          <Form.Control
                            as="select"
                            name={"creditCardParcel"}
                            value={creditCardParcel}
                            onChange={this.handleInputChange}
                            onBlur={
                              isCreditCard ? (e) => this.isParcelValid(e) : null
                            }
                            disabled={!isCreditCard}
                            className={
                              validFields.isCreditCardValid === undefined
                                ? ""
                                : validFields.isCreditCardValid
                                ? "is-valid"
                                : "is-invalid"
                            }
                          >
                            <option />
                            {creditCardParcels &&
                              creditCardParcels.map((item, key) => {
                                return (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </Form.Control>
                          <Form.Text className="is-required">
                            {validFields.isCreditCardValid === undefined
                              ? ""
                              : validFields.isCreditCardValid
                              ? ""
                              : "O campo parcela cartão de credito não deve ser vazio"}
                          </Form.Text>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Button
              variant="primary"
              disabled={this.disabledField() || loading}
              className="w-100 button-without-rounded"
              onClick={this.submit}
            >
              {loading ? "Cadastrando..." : "Cadastrar"}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalPaymentTypeOffer);
