import React from "react";
import { Button, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { reducerKeys } from "../../../../store/reducers/reducer";
import { masks } from "../../../../utils";
import ReceivablesChart from "../charts/ReceivablesChart";
import {
  Card,
  CardTitle,
  RenderItemContainer,
  RenderValue,
} from "../components";
import FifthGroupDetails from "../components/FifthGroupDetails";

function FifthGroup({ colProps, infos, loading, history }) {
  const [showFifthGroupDetails, setShowFifthGroupDetails] =
    React.useState(false);

  return (
    <>
      <Card colProps={colProps}>
        <CardTitle title="RECEBÍVEIS DE DUPLICATAS"></CardTitle>
        <Row className="pr-3 pl-1">
          <ReceivablesChart infos={loading ? null : infos} />
        </Row>
        <RenderItemContainer className="mx-3 mt-1 p-3">
          <RenderValue
            subtitle="Valor Atrasado"
            title={loading ? null : masks.money(infos.lateAmount)}
            large
          />
        </RenderItemContainer>
        <Button
          disabled={infos.lateProcesses === undefined}
          variant="primary"
          className="button-without-rounded mx-3 mt-1 p-3"
          onClick={() => setShowFifthGroupDetails(true)}
        >
          Ver Detalhes
        </Button>
      </Card>
      {infos.lateProcesses !== undefined && (
        <FifthGroupDetails
          show={showFifthGroupDetails}
          onHide={() => setShowFifthGroupDetails(false)}
          lateProcesses={infos.lateProcesses}
          history={history}
        />
      )}
    </>
  );
}

function stateToProps(state) {
  const { fifthGroupInfos, fifthGroupLoading } =
    state[reducerKeys.generalPanel];
  return { infos: fifthGroupInfos, loading: fifthGroupLoading };
}

export default connect(stateToProps)(FifthGroup);
