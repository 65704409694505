import React, { useState, useEffect } from 'react'
import { useAlert } from "react-alert";
import { Loading, Dropdown, Button } from 'element-react'
import { getMessageConfigListComboBox, searchProcessByDinamicFilter, sendMessage } from '../../services/RequestService';
import DataTableDinamicFilter from '../../genericComponents/datatable/DataTableDinamicFilter';
import { masks } from "../../utils/index";

export default function MessageSender() {
  const alert = useAlert();
  const [loading, setLoading] = useState(false)
  const [templates, setTemplates] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState([]);

  const filter = [
    { key: 'id', label: 'ID Processo', type: 'number' },
    { key: 'processType.name', label: 'Tipo do Processo', type: 'text' },
    { key: 'processStatus.name', label: 'Situação do Processo', type: 'text' },
    { key: 'customer.id', label: 'ID Cliente', type: 'number' },
    { key: 'customer.fullname', label: 'Nome', type: 'text' },
    { key: 'customer.cpf', label: 'CPF', type: 'text' },
    { key: 'customer.birthday', label: 'Data Nascimento', type: 'text' },
    { key: 'customer.email', label: 'E-Mail', type: 'text' },
    { key: 'customer.phone', label: 'Telefone', type: 'text' },
    { key: 'customer.contactWay', label: 'Tipo de Contato', type: 'text' },
    { key: 'deliveryDate', label: 'Prazo de Entrega', type: 'date' },
    { key: 'doneDate', label: 'Entrega do Processo', type: 'date' },
    { key: 'createDate', label: 'Criação do Processo', type: 'date' },
    { key: 'pausedDate', label: 'Processo Pausado em', type: 'date' },
    { key: 'done', label: 'Processo Finalizado', type: 'boolean' },
    { key: 'paused', label: 'Processo Pausado', type: 'boolean' },
    { key: 'payment.amount', label: 'Valor', type: 'number' },
    { key: 'payment.rmiSimulada', label: 'RMI Simulada', type: 'number' },
    { key: 'payment.rmiConcedida', label: 'RMI Concedida', type: 'number' },
    { key: 'payment.complementoPositivo', label: 'Complemento Positivo', type: 'number' },
    { key: 'selectedPaymentType', label: 'Tipo de Pagamento', type: 'text' }
  ]

  const columns = [
    { type: 'selection' },
    { label: "ID Cliente", prop: "idCliente", width: 150 },
    { label: "Nome", prop: "nome", width: 250 },
    { label: "ID Processo", prop: "idProcesso", width: 150 },
    { label: "Tese", prop: "tipoProcesso", width: 180 },
    { label: "Status Processo", prop: "statusProcesso", width: 170 },
    { label: "Nascimento", prop: "aniversario", width: 150 },
    { label: "Prazo Entrega", prop: "dataEntrega", width: 150, render: (r) => masks.convertDateToBr(r.dataEntrega) },
    { label: "Paudado?", prop: "processoPausado", width: 150 },
    { label: "Data Pausa", prop: "dataPausaProcesso", width: 150, render: (r) => masks.convertDateToBr(r.dataPausaProcesso) },
    { label: "Aberto?", prop: "processoFinalizado", width: 150 },
    { label: "Honorários", prop: "valorPagamento", width: 150, render: (r) => masks.money(r.valorPagamento) },
    { label: "Tipo Pagamento", prop: "tipoPagamento", width: 150 },
    { label: "Criado em", prop: "dataCriacaoProcesso", width: 150, render: (r) => masks.convertDateToBr(r.dataCriacaoProcesso) },
    { label: "Telefone", prop: "telefone", width: 200 },
    { label: "E-mail", prop: "email", width: 300 },
    { label: "Tipo de Contato", prop: "tipoContato", width: 300 }
  ];

  useEffect(() => {
    if (templates === null) {
      getMessageConfigListComboBox().then(({ data, ok }) => {
        if (ok) setTemplates(data)
      })
    }
  }, [templates, setTemplates]);

  function send(templateId) {
    setLoading(true)
    sendMessage(selectedProcess, templateId).then(({ ok, data }) => {
      if (ok) alert.success('Mensagens enviadas com sucesso!');
      else alert.error('Erro ao enviar mensagens, ' + data?.log);
      setLoading(false)
    })
  }

  return (
    <Loading loading={loading}>
      <DataTableDinamicFilter
        pageTile={"Enviar Mensagens"}
        fields={filter}
        ajaxData={searchProcessByDinamicFilter}
        columns={columns}
        onSelect={setSelectedProcess}
        actions={
          (window.innerWidth > 768) &&
          <Dropdown onCommand={send.bind(this)} menu={(
            <Dropdown.Menu>
              {(templates || []).map((temp, idx) => (<Dropdown.Item disabled={selectedProcess.length === 0} key={idx} command={temp.value.toString()} >{temp.label}</Dropdown.Item>))}
            </Dropdown.Menu>
          )}>
            <Button type="primary" style={{ marginLeft: '10px' }}>
              Enviar Mensagem<i className="el-icon-caret-bottom el-icon--right"></i>
            </Button>
          </Dropdown>
        }
      />
    </Loading>
  );
};