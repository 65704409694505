import {
  getEmailByCPF,
  hasCpfAvailable,
  hasEmailAvailable,
} from "../../../services/RequestService";
import CEP from "cep-promise/dist/cep-promise-browser.min";

function errorTreatment(fieldName) {
  return `Não foi possível validar o ${fieldName} informado. Verifique sua conexão com a internet`;
}

async function validationCall(data, apiFunction, fieldName) {
  try {
    const res = await apiFunction(data);
    if (res.ok) {
      if (res.data) {
        return null;
      } else {
        return `O ${fieldName} digitado já está cadastrado.`;
      }
    } else {
      return errorTreatment(fieldName);
    }
  } catch (e) {
    return errorTreatment(fieldName);
  }
}

export async function isCPFValid(cpf) {
  let c = cpf?.replace(/\./g, "").replace(/-/g, "");
  return await validationCall({ cpf: c }, hasCpfAvailable, "CPF");
}

export async function isEmailValid(email, ffHandler) {
  const cpf = ffHandler.getValues().cpf;
  if (!(await isCPFValid(cpf))) {
    const res = await getEmailByCPF({ cpf });
    if (res.ok) {
      return null;
    }
  }
  return await validationCall({ email }, hasEmailAvailable, "e-mail");
}

export async function getEmail(cpf, ffHandler) {
  cpf = ffHandler.getValues().cpf;
  if (!(await isCPFValid(cpf))) {
    const res = await getEmailByCPF({ cpf });
    if (res.ok && res.data !== null) {
      ffHandler.setValue("email", res.data.email);
      ffHandler.testFieldError("email");
      ffHandler.setValue("fullname", res.data.name);
      ffHandler.testFieldError("fullname");
      return true;
    }
    return false;
  }
}

export async function getAddressItems(
  cep,
  ffHandler,
  name,
  states,
  onFinishCallback
) {
  CEP(cep)
    .then((res) => {
      ffHandler.setValue("city", res.city);
      ffHandler.testFieldError("city");
      ffHandler.setValue("neighborhood", res.neighborhood);
      ffHandler.testFieldError("neighborhood");
      const selectedState = states.find((item) => item.value === res.state);
      ffHandler.setValue("state", selectedState.label);
      ffHandler.testFieldError("state");
      ffHandler.setValue("street", res.street);
      ffHandler.testFieldError("street");
      onFinishCallback(true);
    })
    .catch((error) => {
      ffHandler.setCustomError(
        name,
        "O CEP informado não existe ou não foi possível validar. Verifique sua conexão com a internet"
      );
      onFinishCallback(false);
    });
}
