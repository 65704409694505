import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { deleteUser, getAllUser } from "../../../services/RequestService";
import { connect } from "react-redux";
import { alertActions } from "../../../store/actions/AlertActions";

class ModalDeleteUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: props.user,
      users: {},
      moveUserAction: "",
      moveUserActionType: "",
      moveUserTaskType: "",
      moveUserCustomerOutsourceId: "",
      moveUserOutsourceProspector: "",
      isActionValid: undefined,
      isActionTypeValid: undefined,
      isTaskTypeValid: undefined,
      isMoveUserCustomerOutsourceIdValid: undefined,
      isMoveUserOutsourceProspectorValid: undefined,
    };
  }

  componentDidMount = () => {
    const { user } = this.props;
    getAllUser().then((res) => {
      if (res.ok) {
        let users = {};
        res.data.forEach((item) => {
          if (user.id !== item.id) {
            users[item.name + " " + item.cpf] = item.id;
          }
        });
        this.setState((state) => ({ ...state, users }));
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  isActionValid = () => {
    const { moveUserAction } = this.state;
    let isActionValid = moveUserAction !== "";
    this.setState((state) => ({ ...state, isActionValid }));
  };

  isActionTypeValid = () => {
    const { moveUserActionType } = this.state;
    let isActionTypeValid = moveUserActionType !== "";
    this.setState((state) => ({ ...state, isActionTypeValid }));
  };

  isTaskTypeValid = () => {
    const { moveUserTaskType } = this.state;
    let isTaskTypeValid = moveUserTaskType !== "";
    this.setState((state) => ({ ...state, isTaskTypeValid }));
  };

  isMoveUserCustomerOutsourceIdValid = () => {
    const { moveUserCustomerOutsourceId } = this.state;
    let isMoveUserCustomerOutsourceIdValid = moveUserCustomerOutsourceId !== "";
    this.setState((state) => ({
      ...state,
      isMoveUserCustomerOutsourceIdValid,
    }));
  };

  isMoveUserOutsourceProspectorValid = () => {
    const { moveUserOutsourceProspector } = this.state;
    let isMoveUserOutsourceProspectorValid = moveUserOutsourceProspector !== "";
    this.setState((state) => ({
      ...state,
      isMoveUserOutsourceProspectorValid,
    }));
  };

  submit = () => {
    const {
      user,
      users,
      moveUserAction,
      moveUserActionType,
      moveUserTaskType,
      moveUserCustomerOutsourceId,
      moveUserOutsourceProspector,
    } = this.state;
    this.setState({ loading: true });
    deleteUser({
      userId: user.id,
      moveUserActionId: users[moveUserAction],
      moveUserTaskTypeId: users[moveUserTaskType],
      moveUserActionTypeId: users[moveUserActionType],
      moveUserCustomerOutsourceId: users[moveUserCustomerOutsourceId],
      moveUserOutsourceProspector: users[moveUserOutsourceProspector],
    }).then((res) => {
      if (res.ok) {
        this.setState({ loading: false });
        this.props.onAddAlert(
          "Usuário excluido com sucesso!",
          "success",
          false
        );
        this.props.onHide(true);
      } else {
        this.setState({ loading: false });
        this.props.onAddAlert(
          "Houve um problema ao tentar excluir o usuário de id " +
            user.id +
            ", por favor tente mais tarde!",
          "danger",
          false
        );
        this.props.onHide(false);
      }
    });
  };

  render() {
    const {
      loading,
      users,
      moveUserAction,
      moveUserActionType,
      moveUserTaskType,
      moveUserCustomerOutsourceId,
      moveUserOutsourceProspector,
      isActionValid,
      isActionTypeValid,
      isTaskTypeValid,
      isMoveUserCustomerOutsourceIdValid,
      isMoveUserOutsourceProspectorValid,
    } = this.state;
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} centered backdrop="static" size="md">
        <Modal.Header closeButton>Excluir usuário</Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p className="m-1">
                Para excluir um usuário, é necessário redistribuir as partes do
                sistema ao qual ele faz parte para outros usuários
              </p>
            </Col>
          </Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Trocar usuário dos andamentos
                <Form.Label className="is-required">*</Form.Label>
              </Form.Label>
              <Form.Control
                as="select"
                name="moveUserAction"
                value={moveUserAction}
                onChange={this.handleInputChange}
                onBlur={this.isActionValid}
                className={
                  isActionValid === undefined
                    ? ""
                    : isActionValid
                    ? "is-valid"
                    : "is-invalid"
                }
              >
                <option />
                {Object.keys(users).map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </Form.Control>
              <Form.Text className="is-required">
                {isActionValid === undefined
                  ? ""
                  : isActionValid
                  ? ""
                  : "Um valor deve ser selecionado"}
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Trocar usuário dos tipos de andamento
                <Form.Label className="is-required">*</Form.Label>
              </Form.Label>
              <Form.Control
                as="select"
                name="moveUserActionType"
                value={moveUserActionType}
                onChange={this.handleInputChange}
                onBlur={this.isActionTypeValid}
                className={
                  isActionTypeValid === undefined
                    ? ""
                    : isActionTypeValid
                    ? "is-valid"
                    : "is-invalid"
                }
              >
                <option />
                {Object.keys(users).map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </Form.Control>
              <Form.Text className="is-required">
                {isActionTypeValid === undefined
                  ? ""
                  : isActionTypeValid
                  ? ""
                  : "Um valor deve ser selecionado"}
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Trocar usuário dos tipos de tarefa
                <Form.Label className="is-required">*</Form.Label>
              </Form.Label>
              <Form.Control
                as="select"
                name="moveUserTaskType"
                value={moveUserTaskType}
                onChange={this.handleInputChange}
                onBlur={this.isTaskTypeValid}
                className={
                  isTaskTypeValid === undefined
                    ? ""
                    : isTaskTypeValid
                    ? "is-valid"
                    : "is-invalid"
                }
              >
                <option />
                {Object.keys(users).map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </Form.Control>
              <Form.Text className="is-required">
                {isTaskTypeValid === undefined
                  ? ""
                  : isTaskTypeValid
                  ? ""
                  : "Um valor deve ser selecionado"}
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Trocar usuário responsável pelos clientes
                <Form.Label className="is-required">*</Form.Label>
              </Form.Label>
              <Form.Control
                as="select"
                name="moveUserCustomerOutsourceId"
                value={moveUserCustomerOutsourceId}
                onChange={this.handleInputChange}
                onBlur={this.isMoveUserCustomerOutsourceIdValid}
                className={
                  isMoveUserCustomerOutsourceIdValid === undefined
                    ? ""
                    : isMoveUserCustomerOutsourceIdValid
                    ? "is-valid"
                    : "is-invalid"
                }
              >
                <option />
                {Object.keys(users).map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </Form.Control>
              <Form.Text className="is-required">
                {isMoveUserCustomerOutsourceIdValid === undefined
                  ? ""
                  : isMoveUserCustomerOutsourceIdValid
                  ? ""
                  : "Um valor deve ser selecionado"}
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Trocar usuário responsável pelos usuários que o tem como
                prospector
                <Form.Label className="is-required">*</Form.Label>
              </Form.Label>
              <Form.Control
                as="select"
                name="moveUserOutsourceProspector"
                value={moveUserOutsourceProspector}
                onChange={this.handleInputChange}
                onBlur={this.isMoveUserOutsourceProspectorValid}
                className={
                  isMoveUserOutsourceProspectorValid === undefined
                    ? ""
                    : isMoveUserOutsourceProspectorValid
                    ? "is-valid"
                    : "is-invalid"
                }
              >
                <option />
                {Object.keys(users).map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </Form.Control>
              <Form.Text className="is-required">
                {isMoveUserOutsourceProspectorValid === undefined
                  ? ""
                  : isMoveUserOutsourceProspectorValid
                  ? ""
                  : "Um valor deve ser selecionado"}
              </Form.Text>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Button
              variant="danger"
              className="w-100 button-without-rounded"
              onClick={this.submit}
              disabled={
                !(
                  isActionValid &&
                  isActionTypeValid &&
                  isTaskTypeValid &&
                  isMoveUserCustomerOutsourceIdValid &&
                  isMoveUserOutsourceProspectorValid
                ) || loading
              }
            >
              {loading ? "Excluindo..." : "Excluir"}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalDeleteUser);
