import React from "react";
import { Row } from "react-bootstrap";
import { Input } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";

function PensionRegister10() {
  return (
    <Row>
      <Input
        label="Você é uma pessoa com deficiência intelectual ou mental ou deficiência grave?"
        name={pensionFormNames.hasDisability}
        required={required.default}
        maxLength={255}
      />
    </Row>
  );
}

export default React.memo(PensionRegister10);
