import React, { Component } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import "../../../pages/calendar/CalendarPage.css";
import {
  // postAlertDoneActionDelayDelivery,
  postAlertDoneProcessDelayDelivery,
  postDoneAlertTaskDelayDelivery,
} from "../../../services/RequestService";

class ModalReadAlertDelayDelivery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deliveryDate: "",
      currentDeliveryDate: "",
    };
  }

  componentDidMount = () => {};

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  submit = () => {
    const { alertId, taskDate, processDate } = this.props;

    let deliveryDate = new Date(this.state.deliveryDate).getTime();
    if (taskDate !== null && taskDate !== undefined) {
      postDoneAlertTaskDelayDelivery({ alertId, deliveryDate }).then((res) => {
        if (res.ok) {
          this.props.onHide();
        }
      });
    } else {
      if (processDate !== null && processDate !== undefined) {
        postAlertDoneProcessDelayDelivery({ alertId, deliveryDate }).then(
          (res) => {
            if (res.ok) {
              this.props.onHide();
            }
          }
        );
      }
    }
  };

  messageOrClassnameDeliveryDate = (type) => {
    const { taskDate, processDate } = this.props;
    const { deliveryDate } = this.state;
    if (taskDate !== null) {
      if (deliveryDate !== "") {
        if (deliveryDate.split("T")[0] <= taskDate) {
          switch (type) {
            case "classname":
              return "is-invalid";
            case "message":
              return "A data de adiamento não pode ser menor ou igual a data atual de entrega";
            case "disabled":
              return true;
            default:
              return undefined;
          }
        } else {
          switch (type) {
            case "classname":
              return "is-valid";
            case "disabled":
              return false;
            default:
              return undefined;
          }
        }
      }
    } else {
      if (processDate !== null) {
        if (deliveryDate !== "") {
          if (deliveryDate.split("T")[0] <= processDate) {
            switch (type) {
              case "classname":
                return "is-invalid";
              case "message":
                return "A data de adiamento não pode ser menor ou igual a data atual de entrega";
              case "disabled":
                return true;
              default:
                return undefined;
            }
          } else {
            switch (type) {
              case "classname":
                return "is-valid";
              case "disabled":
                return false;
              default:
                return undefined;
            }
          }
        }
      }
    }
    return "";
  };

  formatDate = (date) => {
    date = date.split("T")[0].split("-");
    return date[2] + "/" + date[1] + "/" + date[0];
  };

  render() {
    const { deliveryDate } = this.state;
    const { show, onHide, description, taskDate, processDate } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            Confirmar adiamento de
            {taskDate !== null && taskDate !== undefined
              ? " andamento"
              : processDate !== null && processDate !== undefined
              ? " processo"
              : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  as="textarea"
                  value={description}
                  readOnly={true}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Data atual de entrega</Form.Label>
                <Form.Control
                  value={
                    taskDate !== null
                      ? this.formatDate(taskDate)
                      : processDate !== null
                      ? this.formatDate(processDate)
                      : ""
                  }
                  readOnly={true}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Adiar para</Form.Label>
                <Form.Control
                  type="date"
                  name="deliveryDate"
                  value={deliveryDate}
                  onChange={this.handleInputChange}
                  className={this.messageOrClassnameDeliveryDate("classname")}
                  pattern="[0-9]{2}/[0-3]"
                />
                <Form.Text className="calendar-is-required">
                  {this.messageOrClassnameDeliveryDate("message")}
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={this.submit}
            disabled={
              this.messageOrClassnameDeliveryDate("disabled") ||
              deliveryDate === ""
            }
          >
            Confirma adiamento
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalReadAlertDelayDelivery;
