import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { alertActions } from "../../store/actions/AlertActions";
import { deleteOutsource } from "../../services/RequestService";

class ModalDeleteCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  submit = () => {
    const { customer } = this.props;
    deleteOutsource({ customerId: customer.id }).then((res) => {
      if (res.ok) {
        this.props.onAddAlert(
          "O cliente " + customer.name + " removido",
          "success",
          false
        );
        this.props.onHide(true);
      } else {
        this.props.onAddAlert(
          "Houve um erro ao tentar remover o cliente. Por favor tente mais tarde!",
          "danger",
          false
        );
      }
    });
  };

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} centered backdrop="static" size="md">
        <Modal.Header closeButton>Excluir cliente</Modal.Header>
        <Modal.Body>
          Você tem certeza que deseja excluir esse cliente ?
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Col className=" p-0">
              <Button
                variant="secondary"
                className="w-100 button-without-rounded"
                onClick={onHide}
              >
                Voltar
              </Button>
            </Col>
            <Col className="p-0">
              <Button
                variant="danger"
                className="w-100 button-without-rounded"
                onClick={() => this.submit()}
              >
                Remover
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};
export default connect(null, mapDispatchToProps)(ModalDeleteCustomer);
