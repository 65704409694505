import React, { useState } from "react";
import { Upload, Button } from "element-react";
import { Modal, Table } from "react-bootstrap";
import { uploadSignedContract } from "../../services/RequestService";
import fn from "../../utils/functions";
import { useAlert } from "react-alert";

export default function ModalContractSing({ show, onHide, processContractList }) {
  const alert = useAlert();
  const [contracts, setContracts] = useState([]);

  if (contracts.length === 0 && processContractList.length > 0) {
    setContracts(processContractList)
  }

  async function upload(id, file) {
    const contractB64 = await fn.asyncPdfFileReader(file, true)
    const { ok, data } = await uploadSignedContract({ id, contractB64 })
    if (ok) {
      setContracts(contracts.map(c => {
        if (c.id === id) c.signedContract = contractB64
        return c
      }))
      alert.success('Contrato enviado com sucesso!')
    } else {
      alert.error('Não foi possivel enviar o contrato: ' + data?.log)
    }
  }

  return (
    <div>
      <Modal show={show} onHide={onHide} centered size="xl" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Assinatura de documento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"table-boleto-size"}>
            <Table className={"table-responsible"}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Precisa de assinatura?</th>
                  <th>Contratos gerado</th>
                  <th>Contratos assinados</th>
                </tr>
              </thead>
              <tbody>
                {processContractList && processContractList.map((t, key) => (
                  <tr key={key}>
                    <td>{t.id}</td>
                    <td>{t.needSignature ? "Sim" : "Não"}</td>
                    <td>{t.generatedContract && (<a href={"data:application/pdf;base64," + t.generatedContract} download> <i className={"fas fa-download clickable "} /> </a>)}</td>
                    <td>
                      {t.signedContract && (<a href={"data:application/pdf;base64," + t.signedContract} download> <i className={"fas fa-download clickable "} /> </a>)}
                      {!t.signedContract && t.name.includes('contrato') && (
                        <Upload action="''" accept=".pdf" beforeUpload={upload.bind(this, t.id)}>
                          <Button size="small" type="text"><i style={{ fontSize: '15px' }} className={"fas fa-upload clickable "} /></Button>
                        </Upload>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
