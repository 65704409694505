import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";

import Chart from "chart.js";
const fontFamily =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"';

const colors = [
  "#2ECC71",
  "#32322C",
  "#A3EBC1",
  "#449767",
  "#B5B8A3",
  "#395D48",
  "#AABA9E",
  "#7A8B99",
  "#214E34",
  "#DFF8EB",
];

function CalendarChart({ infos }) {
  const chartRef = React.useRef(null);

  const data = React.useMemo(() => {
    return {
      type: "bar",
      data: {
        datasets: infos?.map((item, index) => ({
          backgroundColor: colors[index],
          data: [item.value],
          label: item.label,
          barPercentage: 0.6,
          borderWidth: 0,
        })),
      },

      plugins: [ChartDataLabels],
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            align: "end",
            anchor: "end",
            backgroundColor: function (context) {
              return "white";
            },
            borderColor: function (context) {
              return "#000";
            },
            borderWidth: 1,
            borderRadius: 4,
            color: "black",
            font: {
              family: fontFamily,
              size: 12,
            },
            padding: {
              top: 4,
              bottom: 2,
              left: 6,
              right: 6,
            },
          },
        },
        layout: {
          padding: {
            top: 40,
            right: 20,
            bottom: 0,
            left: 16,
          },
        },

        legend: {
          align: "start",
          position: "bottom",
        },
        tooltips: {
          enabled: false,
        },
        scales: {
          xAxes: [
            {
              fontFamily: fontFamily,
              scaleLabel: {
                fontFamily: fontFamily,
              },

              ticks: {
                fontFamily: fontFamily,
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              fontFamily: fontFamily,
              scaleLabel: {
                fontFamily: fontFamily,
              },

              ticks: {
                fontFamily: fontFamily,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  }, [infos]);

  React.useEffect(() => {
    new Chart(chartRef.current, data);
  }, [data]);

  return <canvas style={{ minHeight: 330, maxHeight: 330 }} ref={chartRef} />;
}

export default CalendarChart;
