import { useDispatch } from "react-redux";
import { getDashboardStatus } from "../../../services/RequestService";
import { generalPanelActions } from "../../../store/actions/GeneralPanelActions";
import { hooks } from "../../../utils";

export default function useMount() {
  const dispatch = useDispatch();
  function setStorageValues() {
    getDashboardStatus()
      .then((res) => {
        const d = res.data;
        dispatch(generalPanelActions.setFirst(d.first));
        dispatch(generalPanelActions.setSecond(d.second));
        dispatch(generalPanelActions.setThird(d.third));
        dispatch(generalPanelActions.setFourth(d.fourth));
        dispatch(generalPanelActions.setFifth(d.fifth));
        dispatch(generalPanelActions.setSixth(d.sixth));
        dispatch(generalPanelActions.setSeventh(d.seventh));
      })
      .catch((e) => {
        // TODO tratar erro
        console.log(e);
      });
  }

  hooks.useMount(setStorageValues, () =>
    dispatch(generalPanelActions.requestData())
  );
}
