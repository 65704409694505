import React from "react";
import "./../SingleCustomerComp.css";
import BasicData1 from "./BasicData1";
import BasicData2 from "./BasicData2";
import BasicData3 from "./BasicData3";
import BasicData4 from "./BasicData4";
import BasicData5 from "./BasicData5";
import BasicData7 from "./BasicData7";
import BasicData6 from "./BasicData6";
import { FormButton, InputName } from "../../../genericComponents";
import { FormFull } from "form-full";
import { Row } from "react-bootstrap";

function setActualValues(data) {
  if (data) {
    return {
      cpf: data.cpf,
      email: data.email,
      rg: data.rg,
      birthday: data.birthday,
      gender: data.gender,
      cellphone: data.cellphone,
      alternativePhone: data.alternativePhone,
      fullname: data.fullname,
      nationality: data.nationality,
      cep: data.cep,
      street: data.street,
      placeNumber: data.placeNumber,
      complement: data.complement,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state,
      occupation: data.occupation,
      maritalStatus: data.maritalStatus,
      registeredBy: data.registeredBy?.name,
      indication: data.indication,
      indicatorName: data.indicatorName,
      indicatorDoc: data.indicatorDoc,
    };
  }
  return null;
}

function BasicDataComp({
  id,
  setInfos,
  invalidateInfos,
  setRef,
  states,
  allCitiesFromStates,
  serverData,
  disabledField,
  // getFormHandler,
}) {
  const [state, setState] = React.useState({
    isValidCPF: false,
    isValidEmail: false,
    isValidCEP: false,
    isCollaborator: false,
  });
  const validCoreInfos = state.isValidCPF && state.isValidEmail;

  const changeState = React.useCallback(
    (newState) => {
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState]
  );

  return (
    <FormFull
      onChange={invalidateInfos}
      formRef={setRef}
      onSubmit={setInfos}
      disabled={disabledField}
      actualValues={setActualValues(serverData)}
    >
      <div className="overflow-fix">
        {serverData !== null ? (
          <Row>
            <InputName
              disabled={true}
              name="registeredBy"
              colProps={{ sm: 12, md: 4 }}
              label="Usuário Responsável pelo cadastro"
            />
          </Row>
        ) : (
          <></>
        )}

        <BasicData1
          id={id}
          changeState={changeState}
          states={states}
          isCollaborator={state.isCollaborator}
        />

        {/* RG, Data de nascimento e Gênero */}
        <BasicData2 disabled={!id && !validCoreInfos} />
        {/* Número de celular, nome e nacionalidade */}
        <BasicData3
          disabled={!id && !validCoreInfos}
          isCollaborator={state.isCollaborator}
        />
        {/*CEP, Rua, Número, complemento */}
        <BasicData4
          changeState={changeState}
          disabled={!id && !validCoreInfos}
          isValidCEP={state.isValidCEP}
          states={states}
        />
        {/*Bairro, */}
        <BasicData5
          states={states}
          allCitiesFromStates={allCitiesFromStates}
          disabled={!id && !validCoreInfos}
          isValidCEP={state.isValidCEP}
        />
        {/* profissão e estado civil */}
        <BasicData6 disabled={!id && !validCoreInfos} />
        {/* Senha e confirmação de senha */}
        <BasicData7
          id={id}
          disabled={!id && !validCoreInfos}
          isCollaborator={state.isCollaborator}
        />
      </div>
      <div className="flex-justify-end">
        <FormButton
          name="submit"
          variant="primary"
          actionType="submit"
          disabled={disabledField}
        >
          {id ? "Atualizar" : "Prosseguir"}
        </FormButton>
      </div>
    </FormFull>
  );
}

export default React.memo(BasicDataComp);
