import React from "react";
import { Row } from "react-bootstrap";
import { InputDate } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";

function PensionRegister2() {
  return (
    <Row>
      <InputDate
        label="Qual a data do óbito?"
        name={pensionFormNames.dateOfDeath}
        required={required.default}
        maxDate={new Date()}
        minDate={new Date("1900-01-01")}
      />
    </Row>
  );
}

export default React.memo(PensionRegister2);
