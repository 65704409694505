import React from "react";
import { Row } from "react-bootstrap";
import { RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { defaultThreeOptions } from "../models/selects";

function PensionRegister4() {
  return (
    <Row>
      <RadioGroup
        label="O(A) falecido(a) estava trabalhando, data do óbito?"
        name={pensionFormNames.workingInDateOfDeath}
        options={defaultThreeOptions.map((item) => ({
          label: item,
          value: item,
        }))}
        required={required.default}
        inline
      />
    </Row>
  );
}

export default React.memo(PensionRegister4);
