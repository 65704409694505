import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  postMagapay,
  postAsaasPay,
  getAsaasPix,
  getAsaasIsPay,
  updateTransaction,
} from "../../services/RequestService";
import InputMask from "react-input-mask";
import { alertActions } from "../../store/actions/AlertActions";
import { connect } from "react-redux";
import "./ModalPayments.css";
import { isValid, getCreditCardNameByNumber } from "creditcard.js";
import amex from "./cards/amex.jpg";
import aura from "./cards/aura.jpg";
import diners from "./cards/diners.jpg";
import discover from "./cards/discover.jpg";
import enroute from "./cards/enroute.jpg";
import hipercard from "./cards/hipercard.jpg";
import jcb from "./cards/jcb.jpg";
import mastercard from "./cards/mastercard.jpg";
import visa from "./cards/visa.jpg";
import voyager from "./cards/voyager.jpg";
import { location, validations } from "../../utils";
import CEP from "cep-promise/dist/cep-promise-browser.min";
import Negociation from "./Negociation";
import { masks } from "../../utils";

class ModalPayment extends Component {
  constructor(props) {
    super(props);
    const holder_name = props.process && props.process.customer ? props.process.customer.name : ''

    this.state = {
      paymentCustomParcels: props.paymentCustomParcels,
      amount: props.amount !== undefined ? props.amount : "",
      paymentType: props.paymentType !== undefined ? props.paymentType : "",
      pix: undefined,
      isPayed: props.pixPayed,
      textPixButton:
        props.transactions.length === 0
          ? "Gerar PIX"
          : "Verificando Pagamento...",
      firstType: props.paymentType !== undefined ? props.paymentType : "",
      processId: props.processId !== undefined ? props.processId : "",
      customerId: props.customerId !== undefined ? props.customerId : "",
      allParcel: [],
      parcel: props.amount !== undefined ? "Em 1x de R$ " + props.amount : "",
      transactions: props.transactions || [],
      card_number: "",
      card_name: null,
      holder_name,
      expiration_month: "",
      expiration_year: "",
      security_code: "",
      thirdDocumentNumber: "",
      thirdEmail: "",
      thirdFullName: "",
      thirdBirthday: "",
      thirdPhoneNumber: "",
      thirdCEP: "",
      thirdAddress: "",
      thirdNumberAddress: "",
      thirdNeighborhood: "",
      thirdComplement: "",
      thirdState: "",
      thirdCity: "",
      isThirdData: false,
      isBtnDisabled: false,
      validFields: {
        isCardNumberValid: undefined,
        isHolderNameValid: undefined,
        isExpirationMonthValid: undefined,
        isExpirationYearValid: undefined,
        isSecurityCodeValid: undefined,
        isThirdDocumentNumberValid: undefined,
        isThirdEmailValid: undefined,
        isThirdFullNameValid: undefined,
        isThirdBirthdayValid: undefined,
        isThirdPhoneNumberValid: undefined,
        isThirdCEPValid: undefined,
        isThirdAddressValid: undefined,
        isThirdNumberAddressValid: undefined,
        isThirdNeighborhoodValid: undefined,
        isThirdComplementValid: undefined,
        isThirdStateValid: undefined,
        isThirdCityValid: undefined,
      },
      paymentTypeDescription: props.paymentCustomParcels.length > 0 ? [...new Set(props.paymentCustomParcels.map((cp) => cp.paymentType))].join("/") : props.paymentType
    };

    if (this.state.paymentTypeDescription.toUpperCase().includes("PIX")) {
      this.getTimer();
    }
  }

  componentDidMount = () => {
    const { amount, paymentTypeDescription } = this.state;
    let allParcel = [];
    for (let i = 1; i <= 4; i++) {
      allParcel = allParcel.concat("Em " + i + "x de R$ " + this.floorFigure(amount / i));
    }

    if (
      this.props.transactions.length !== 0 &&
      paymentTypeDescription.toUpperCase().includes("PIX")
    ) {
      const { processId } = this.state;
      getAsaasPix({ processId }).then((res) => {
        if (res.ok) {
          this.setState({ pix: res.data });
        } else if (res.data.log === "Esse processo não tem o método de pagamento pix selecionado ou um pagamento pix anteriormente gerado") {
          this.generateQRCode();
        }
      });
    }
    this.setState((state) => ({ ...state, allParcel }));
  };

  floorFigure = (figure, decimals) => {
    if (!decimals) decimals = 2;
    var d = Math.pow(10, decimals);
    return (parseInt(figure * d) / d).toFixed(decimals);
  };

  newTransection = () => {
    const {
      paymentType,
      isThirdData,
      processId,
      customerId,
      amount,
      card_number,
      holder_name,
      expiration_month,
      expiration_year,
      security_code,
      thirdDocumentNumber,
      thirdEmail,
      thirdFullName,
      thirdBirthday,
      thirdPhoneNumber,
      thirdCEP,
      thirdAddress,
      thirdNumberAddress,
      thirdNeighborhood,
      thirdComplement,
      thirdState,
      thirdCity,
    } = this.state;
    this.setState({ isBtnDisabled: true });
    let data = {
      customerId,
      transaction: {
        processId,
        maxParcel: this.props.parcel,
        isPaymentTypeCredit: paymentType.toUpperCase() === "CARTÃO DE CRÉDITO",
        amount,
      },
    };
    if (paymentType.toUpperCase() === "CARTÃO DE CRÉDITO") {
      data["card"] = {
        card_number,
        holder_name,
        expiration_month,
        expiration_year,
        security_code,
      };
    }
    if (isThirdData) {
      data["ThirdData"] = {
        thirdDocumentNumber,
        thirdEmail,
        thirdFullName,
        thirdBirthday,
        thirdPhoneNumber,
        address: {
          address: thirdAddress,
          number: thirdNumberAddress,
          complement: thirdComplement,
          neighborhood: thirdNeighborhood,
          zip_code: thirdCEP,
          city: thirdCity,
          state: thirdState,
        },
      };
    }
    postMagapay(data).then((res) => {
      if (res.ok) {
        this.props.onAddAlert(
          "Pagamento efetuado/gerado com sucesso!",
          "success",
          true
        );
        this.setState({ isBtnDisabled: false });
        this.props.onHide();
      } else {
        console.error("response error", res);
        try {
          let message = res.data.log.split("display_message");
          message = message[1].split("\\");
          message = message[0].split(".");
          this.props.onAddAlert(message[1] + ".", "danger", true);
        } catch (e) {
          this.props.onAddAlert(res.data.log, "danger", true);
        }
        this.setState({ isBtnDisabled: false });
      }
    });
  };

  generateQRCode = () => {
    const { processId } = this.props;
    this.setState({ isBtnDisabled: true });
    postAsaasPay({
      processId: parseInt(processId),
    }).then((res) => {
      this.setState({ isBtnDisabled: false });
      if (res.ok) {
        this.setState((state) => ({
          ...state,
          pix: res.data,
        }));
        this.setState({
          isBtnDisabled: true,
          textPixButton: "Verificando Pagamento...  ",
        });
      } else {
        this.props.onAddAlert(res.data.log, "danger", true);
      }
    });
  };

  getTimer = () => {
    if (!this.checkPayment()) {
      let t = 60,
        minutes,
        seconds;
      const interval = setInterval(() => {
        minutes = parseInt(t / 60, 10);
        seconds = parseInt(t % 60, 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.setState({
          textPixButton: `Verificando Pagamento...  ${minutes}:${seconds}`,
        });
        if (--t < 0) {
          t = 60;
          if (this.checkPayment()) {
            clearInterval(interval);
          }
        }
      }, 1000);
    }
  };

  checkPayment = async () => {
    const res = await getAsaasIsPay({
      processId: parseInt(this.props.processId),
    });
    if (res.ok) {
      this.setState({
        isPayed: res.data,
        textPixButton: res.data
          ? "Pagamento verificado!"
          : "Pagamento não encontrado, iremos continuar verificando!",
      });
      return true;
    }
    return false;
  };

  handleInputChange = (e) => {
    e.persist();
    if (e.target.name === "parcel") {
      this.handleParcels(e.target.value);
    }
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  cardIsValid = () => {
    const {
      isCardNumberValid,
      isExpirationMonthValid,
      isExpirationYearValid,
      isHolderNameValid,
      isSecurityCodeValid,
    } = this.state.validFields;
    return ((isCardNumberValid ?? false) && isExpirationMonthValid && isExpirationYearValid && isHolderNameValid && isSecurityCodeValid);
  };

  thirdDataIsValid = () => {
    const {
      isThirdDocumentNumberValid,
      isThirdEmailValid,
      isThirdFullNameValid,
      isThirdBirthdayValid,
      isThirdPhoneNumberValid,
      isThirdCEPValid,
      isThirdAddressValid,
      isThirdNumberAddressValid,
      isThirdNeighborhoodValid,
      isThirdComplementValid,
      isThirdStateValid,
      isThirdCityValid,
    } = this.state.validFields;
    return ( isThirdDocumentNumberValid && isThirdEmailValid && isThirdFullNameValid && isThirdBirthdayValid && isThirdPhoneNumberValid &&
      isThirdCEPValid && isThirdAddressValid && isThirdNumberAddressValid && isThirdNeighborhoodValid && isThirdComplementValid &&
      isThirdStateValid && isThirdCityValid);
  };

  isCardNumberValid = () => {
    const { card_number, validFields } = this.state;
    let isCardNumberValid = isValid(card_number);

    let card_name = isCardNumberValid ? getCreditCardNameByNumber(card_number).toUpperCase() : null;
    this.setState((state) => ({
      ...state,
      card_name,
      validFields: { ...validFields, isCardNumberValid },
    }));
  };

  isExpirationMonthValid = () => {
    const { expiration_month } = this.state;
    let isExpirationMonthValid = Number(expiration_month) <= 12;
    this.handleValidationChange("isExpirationMonthValid",isExpirationMonthValid);
  };

  isExpirationYearValid = () => {
    const { expiration_year } = this.state;
    let dateNow = new Date();
    let year = Number(expiration_year);
    let isExpirationYearValid = year >= dateNow.getFullYear();
    this.handleValidationChange("isExpirationYearValid", isExpirationYearValid);
  };

  isHolderNameValid = () => {
    const { holder_name } = this.state;

    let isHolderNameValid = holder_name
      .trim()
      .match(/^[A-zÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-Ÿ][A-zÀ-ÿ']+$/);
    this.handleValidationChange("isHolderNameValid", isHolderNameValid);
  };

  isSecurityCodeValid = () => {
    const { security_code } = this.state;
    let code = security_code.replace("_", "");
    let isSecurityCodeValid = code.length === 3;
    this.handleValidationChange("isSecurityCodeValid", isSecurityCodeValid);
  };

  handleValidationChange = (field, value) => {
    const { validFields } = this.state;
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, [field]: value },
    }));
  };

  setAddress = () => {
    CEP(this.state.thirdCEP).then((res) => {
      const selectedState = location.states.find(
        (item) => item.value === res.state
      );
      const { validFields } = this.state;
      this.setState({
        thirdAddress: res.street,
        thirdNeighborhood: res.neighborhood,
        thirdState: selectedState.label,
        thirdCity: res.city,
        validFields: {
          ...validFields,
          isThirdAddressValid: true,
          isThirdNeighborhoodValid: true,
          isThirdStateValid: true,
          isThirdCityValid: true,
        },
      });
    });
  };

  convertDate = (date) => {
    let a = date.split("T")[0].split("-");
    return a[2] + "/" + a[1] + "/" + a[0];
  };

  updateStatusTransaction(transaction) {
    updateTransaction(transaction).then(({ ok }) => {
      if (ok) {
        const transactions = this.state.transactions.map((t) => {
          if (t.id === transaction.id) {
            t.status = transaction.status;
          }
          return t;
        });
        this.setState({ transactions });
      }
    });
  }

  getCardIconName = (cardName) => {
    switch (cardName.toLowerCase()) {
      case "amex":
        return amex;
      case "aura":
        return aura;
      case "diners":
        return diners;
      case "discover":
        return discover;
      case "enroute":
        return enroute;
      case "hipercard":
        return hipercard;
      case "jcb":
        return jcb;
      case "mastercard":
        return mastercard;
      case "visa":
        return visa;
      case "voyager":
        return voyager;
      default:
    }
  };
  //TODO: FEEDBACK NO BOTÃO DE SELECIONAR METODO DE PAGAMENTO

  render() {
    const {
      paymentType,
      pix,
      isPayed,
      textPixButton,
      card_number,
      card_name,
      holder_name,
      expiration_month,
      expiration_year,
      security_code,
      thirdDocumentNumber,
      thirdEmail,
      thirdFullName,
      thirdBirthday,
      thirdPhoneNumber,
      thirdCEP,
      thirdAddress,
      thirdNumberAddress,
      thirdNeighborhood,
      thirdComplement,
      thirdState,
      thirdCity,
      isThirdData,
      validFields,
      transactions,
      isBtnDisabled,
      paymentCustomParcels,
      paymentTypeDescription
    } = this.state;
    const { show, onHide, parcel, amount } = this.props;
    let jwt = require("jwt-decode");
    let decodedToken = jwt(localStorage.getItem("token"));

    const showPix = ((paymentCustomParcels || []).filter(t => t.paymentType.toUpperCase() === 'PIX').length > 0  || paymentType.toUpperCase() === 'PIX')
        && ((transactions || []).filter(t => t.typePayment.toUpperCase() === "PIX" && t.status === "RECEIVED").length === 0)

    let valorPix = 0
    if (showPix){
      for (const t of paymentCustomParcels || []){
        if (t.paymentType === 'Pix') valorPix += t.parcelValue
      }
      if (valorPix <= 0) valorPix = amount
    }

    const showCartao = ((paymentCustomParcels || []).filter(t => t.paymentType.toUpperCase() === "CARTÃO DE CRÉDITO").length > 0  || paymentType.toUpperCase() === "CARTÃO DE CRÉDITO")
        && ((transactions || []).filter(t => t.typePayment === "credit" && t.status === "succeeded").length === 0)

    const showBoleto = (((paymentCustomParcels || []).filter(t => t.paymentType.toUpperCase() === "BOLETO").length > 0  || paymentType.toUpperCase() === "BOLETO")
        && ((transactions || []).filter(t => t.typePayment.toUpperCase() === "BOLETO" && t.status !== "canceled").length === 0)) && !showPix && !showCartao

    const transactionsBoleto = (transactions || []).filter(t => (t.typePayment || '').toUpperCase() === "BOLETO")


    return (
      <Modal show={show} onHide={onHide} centered size={"lg"} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {paymentType.toUpperCase() === "CARTÃO DE CRÉDITO" ? (
              <i className="fa fa-credit-card pr-2"></i>
            ) : null}
            Pagamento por {paymentTypeDescription}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* SE FOR USUARIO INTERNO OU CLIENTE QUE IRA EFETUAR O PAGAMENTO*/}
          {((decodedToken?.is_cell_admin || decodedToken?.is_admin || decodedToken?.is_cell_manager) &&
            transactions.length === 0) || (decodedToken.is_customer && decodedToken.customer_id === this.props.customerId) ? (
            <div>
              {(showPix || showCartao || showBoleto) && paymentCustomParcels.length > 0 && (
                  <Negociation parcel={parcel} amount={amount} paymentCustomParcels={paymentCustomParcels} />
              )}

              {showPix && (
                  <div>
                    <h4>PIX</h4><hr />
                      <Row>
                        {pix?.CNPJKey && (
                          <Col>
                            <div className={"div-form-group"}>
                              <Form.Group>
                                <Form.Label>Chave (CNPJ)</Form.Label>
                                <Form.Control value={pix?.CNPJKey} disabled={!pix?.CNPJKey}></Form.Control>
                              </Form.Group>
                            </div>
                          </Col>
                        )}
                        <Col>
                          <div className={"div-form-group"}>
                            <Form.Group>
                              <Form.Label>Valor</Form.Label>
                              <Form.Control value={"R$ " + this.floorFigure(valorPix)} disabled={true}></Form.Control>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                      {pix?.payload && (
                        <div className={"div-form-group"}>
                          <Form.Group>
                            <Form.Label>Código URL</Form.Label>
                            <Form.Control onChange={() => {
                            }} value={pix?.payload} disabled={!pix?.payload}></Form.Control>
                          </Form.Group>
                        </div>
                      )}
                      {pix?.encodedImage && (
                        <div className={"div-form-group"}>
                          <Form.Group>
                            <Form.Label>Código QR</Form.Label>
                              <div className="d-flex justify-content-center px-1 py-4 login-image-container">
                                <Image src={"data:image/png;base64, " + pix?.encodedImage} disabled={true}/>
                              </div>
                          </Form.Group>
                        </div>
                      )}
                      <Button variant={ textPixButton === "Pagamento verificado!" ? "success" : "warning"}
                          onClick={() => this.generateQRCode()}
                          disabled={isBtnDisabled || isPayed || transactions.length !== 0}
                          className="w-100 button-without-rounded"
                      >
                        {textPixButton}
                      </Button>
                  </div>
              )}

              {showCartao && (
                  <div>
                    <br/>
                    <h4>Cartão de Crédito</h4>
                    <hr/>
                    <div className={"div-form-group"}>
                      <Form.Group>
                        <Form.Label>Nome Completo</Form.Label>
                        <InputMask
                            name="holder_name"
                            placeholder="Nome Completo"
                            value={holder_name}
                            onChange={this.handleInputChange}
                            onBlur={() => this.isHolderNameValid()}
                        >
                          <Form.Control type="text" name="rg" className={validFields.isHolderNameValid !== undefined ? validFields.isHolderNameValid ? "is-valid" : "is-invalid" : ""}/>
                        </InputMask>
                      </Form.Group>
                    </div>
                    <Row>
                      <Col>
                        <div className={"div-form-group"}>
                          <Form.Group>
                            <Form.Label>Número do cartão</Form.Label>
                            <InputGroup>
                              <InputMask
                                  name="card_number"
                                  value={card_number}
                                  onChange={this.handleInputChange}
                                  onBlur={() => this.isCardNumberValid()}
                                  placeholder="1234 1234 1234 1234"
                                  mask="9999 9999 9999 9999"
                              >
                                <Form.Control type="text" name="rg"
                                              className={validFields.isCardNumberValid !== undefined ? validFields.isCardNumberValid ? "is-valid" : "is-invalid" : ""}/>
                              </InputMask>
                              {card_name ? (
                                  <InputGroup.Text>
                                    <div className="pr-1">{card_name}</div>
                                    <img src={this.getCardIconName(card_name)} alt=""/>
                                  </InputGroup.Text>
                              ) : null}
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col>
                        <div className={"div-form-group pl-2 pr-2"}>
                          <Form.Label>Data de validade </Form.Label>
                          <Row>
                            <Col className="pb-2">
                              <InputMask
                                  name="expiration_month"
                                  value={expiration_month}
                                  onChange={this.handleInputChange}
                                  onBlur={() => this.isExpirationMonthValid()}
                                  placeholder="mês"
                                  mask="99"
                              >
                                <Form.Control type="text" name="rg"
                                              className={validFields.isExpirationMonthValid !== undefined ? validFields.isExpirationMonthValid ? "is-valid" : "is-invalid" : ""}/>
                              </InputMask>
                            </Col>
                            <Col className="pb-2">
                              <InputMask
                                  name="expiration_year"
                                  value={expiration_year}
                                  onChange={this.handleInputChange}
                                  onBlur={() => this.isExpirationYearValid()}
                                  placeholder="ano"
                                  mask="9999"
                              >
                                <Form.Control type="text" name="rg"
                                              className={validFields.isExpirationYearValid !== undefined ? validFields.isExpirationYearValid ? "is-valid" : "is-invalid" : ""}/>
                              </InputMask>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className={"div-form-group"}>
                          <Form.Group>
                            <Form.Label>Código de segurança </Form.Label>
                            <InputMask
                                name="security_code"
                                value={security_code}
                                onChange={this.handleInputChange}
                                onBlur={() => this.isSecurityCodeValid()}
                                placeholder="123"
                                mask="999"
                            >
                              <Form.Control type="text" name="rg"
                                            className={validFields.isSecurityCodeValid !== undefined ? validFields.isSecurityCodeValid ? "is-valid" : "is-invalid" : ""}/>
                            </InputMask>
                          </Form.Group>
                        </div>
                      </Col>
                      {paymentCustomParcels.length === 0 && (
                          <Col>
                            <div className={"div-form-group"}>
                              <Form.Group>
                                <Form.Label>Parcelas</Form.Label>
                                <Form.Control readOnly={true}
                                              value={"Em " + parcel + "x de R$ " + this.floorFigure(amount / parcel)}/>
                              </Form.Group>
                            </div>
                          </Col>
                      )}
                    </Row>
                    <Form.Check value={isThirdData} label={"Dados de Terceiro"}
                                onChange={() => this.setState({isThirdData: !isThirdData})}/>
                    {isThirdData ? (
                        <div>
                          <Row>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Número do Documento</Form.Label>
                                  <InputMask
                                      name="thirdDocumentNumber"
                                      value={thirdDocumentNumber}
                                      placeholder="123.123.132-12"
                                      mask="999.999.999-99"
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdDocumentNumberValid", validations.validateThirdDocumentNumber(thirdDocumentNumber))}
                                  >
                                    <Form.Control type="text" name="thirdDocumentNumber"
                                                  className={validFields.isThirdDocumentNumberValid !== undefined ? validFields.isThirdDocumentNumberValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Email</Form.Label>
                                  <InputMask
                                      name="thirdEmail"
                                      placeholder={"email@gmail.com"}
                                      value={thirdEmail}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdEmailValid", validations.validateThirdEmail(thirdEmail))}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdEmailValid !== undefined ? validFields.isThirdEmailValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Nome Completo</Form.Label>
                                  <InputMask
                                      name="thirdFullName"
                                      placeholder="Nome Completo"
                                      value={thirdFullName}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdFullNameValid", validations.validateThirdFullName(thirdFullName))}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdFullNameValid !== undefined ? validFields.isThirdFullNameValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Data de Nascimento</Form.Label>
                                  <InputMask
                                      name="thirdBirthday"
                                      placeholder="12/10/1970"
                                      mask={"99/99/9999"}
                                      value={thirdBirthday}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdBirthdayValid", validations.validateThirdBirthday(thirdBirthday))
                                      }
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdBirthdayValid !== undefined ? validFields.isThirdBirthdayValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Número do Telefone</Form.Label>
                                  <InputMask
                                      name="thirdPhoneNumber"
                                      placeholder="Telefone"
                                      mask={"(99) 9 9999-9999"}
                                      value={thirdPhoneNumber}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdPhoneNumberValid", validations.validateThirdPhoneNumber(thirdPhoneNumber))}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdPhoneNumberValid !== undefined ? validFields.isThirdPhoneNumberValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>CEP</Form.Label>
                                  <InputMask
                                      name="thirdCEP"
                                      placeholder="12312-123"
                                      mask={"99999-999"}
                                      value={thirdCEP}
                                      onChange={this.handleInputChange}
                                      onBlur={() => {
                                        let isThirdCEPValid = validations.validateThirdCEP(thirdCEP);
                                        if (isThirdCEPValid) {
                                          this.handleValidationChange("isThirdCEPValid", isThirdCEPValid);
                                          this.setAddress();
                                        }
                                      }}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdCEPValid !== undefined ? validFields.isThirdCEPValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Rua</Form.Label>
                                  <InputMask
                                      name="thirdAddress"
                                      value={thirdAddress}
                                      disabled={!validFields.isThirdCEPValid}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdAddressValid", thirdAddress !== "")}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdAddressValid !== undefined ? validFields.isThirdAddressValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Numero</Form.Label>
                                  <InputMask
                                      name="thirdNumberAddress"
                                      value={thirdNumberAddress}
                                      disabled={!validFields.isThirdCEPValid}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdNumberAddressValid", thirdNumberAddress !== "")}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdNumberAddressValid !== undefined ? validFields.isThirdNumberAddressValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Bairro</Form.Label>
                                  <InputMask
                                      name="thirdNeighborhood"
                                      value={thirdNeighborhood}
                                      disabled={!validFields.isThirdCEPValid}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isTirdNeighborhoodValid", thirdNeighborhood !== "")}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdNeighborhoodValid !== undefined ? validFields.isThirdNeighborhoodValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Complemento</Form.Label>
                                  <InputMask
                                      name="thirdComplement"
                                      value={thirdComplement}
                                      disabled={!validFields.isThirdCEPValid}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdComplementValid", thirdComplement !== "")}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdComplementValid !== undefined ? validFields.isThirdComplementValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Estado</Form.Label>
                                  <Form.Control
                                      as="select"
                                      name="thirdState"
                                      value={thirdState}
                                      disabled={!validFields.isThirdCEPValid}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdStateValid", thirdState !== "")}
                                      className={validFields.isThirdStateValid === undefined ? "" : validFields.isThirdStateValid ? "is-valid" : "is-invalid"}
                                  >
                                    <option/>
                                    {location.states.map((item, key) => (<option key={key}>{item.label}</option>))}
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </Col>
                            <Col>
                              <div className={"div-form-group"}>
                                <Form.Group>
                                  <Form.Label>Município</Form.Label>
                                  <InputMask
                                      name="thirdCity"
                                      value={thirdCity}
                                      disabled={!validFields.isThirdCEPValid}
                                      onChange={this.handleInputChange}
                                      onBlur={() => this.handleValidationChange("isThirdCityValid" !== "")}
                                  >
                                    <Form.Control type="text" name="rg"
                                                  className={validFields.isThirdCityValid !== undefined ? validFields.isThirdCityValid ? "is-valid" : "is-invalid" : ""}/>
                                  </InputMask>
                                </Form.Group>
                              </div>
                            </Col>
                          </Row>
                        </div>
                    ) : null}
                    <Button
                        variant="warning"
                        onClick={() => this.newTransection()}
                        disabled={ !this.cardIsValid() || (isThirdData && !this.thirdDataIsValid())}
                        className="w-100 button-without-rounded"
                    >
                      {"Efetuar pagamento"}
                    </Button>
                  </div>
              )}

              {showBoleto && (
                  <div className={"table-boleto-size"}>
                    {/*<br/>*/}
                    {/*<h4>Boleto</h4>*/}
                    {/*<hr/>*/}
                    {transactionsBoleto.length > 0 && (
                      <Table className={"table-responsible"}>
                        <thead>
                        <tr>
                          <th>Status</th>
                          <th>Boletos</th>
                          <th>Data de vencimento</th>
                        </tr>
                        </thead>
                        <tbody>
                          {transactionsBoleto.map((t, key) => (
                              <tr key={key}>
                                <td>{t.status.toUpperCase() === "pending" ? "Pendente" : t.status.toUpperCase() === "CREATE" ? "Criado" : "Pago"}</td>
                                {t.urlBoleto && (
                                    <td><a href={t.urlBoleto} target="_blank" rel="noopener noreferrer"> Visualizar </a>
                                    </td>)}
                                <td>{this.convertDate(t.paymentDate)}</td>
                              </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                    <Button
                        variant="warning"
                        onClick={() => this.newTransection()}
                        disabled={transactionsBoleto.length !== 0 || isBtnDisabled}
                        className="w-100 button-without-rounded"
                    >
                      {"Gerar Boleto"}
                    </Button>
                  </div>
              )}

              {!showBoleto && !showCartao && !showPix && (
                  <div className="d-flex justify-content-center px-1 py-4 login-image-container"> {" "} Este pagamento já foi efetuado! </div>
              )}
            </div>
          ) : /* SENÃO MOSTRA A TABELA COM AS TRANSAÇÕES*/(
              <div>
                <Form.Group>
                  {transactions.length !== 0 ? (
                      <div className={"table-boleto-size"}>
                        <Table className={"table-responsible"}>
                          <thead>
                          <tr>
                            <th>Tipo</th>
                            <th>Status</th>
                            {paymentType.toUpperCase() === "BOLETO" && (
                                <th>Boletos</th>
                            )}
                            <th>Data de vencimento</th>
                          <th>Valor</th>
                          {(decodedToken.is_cell_manager || decodedToken.is_admin) && <th>Alterar Status</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {transactions &&
                          transactions.map((t, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  {(t?.typePayment || '').toUpperCase()}
                                </td>
                                <td>
                                  {t.status === "pending" ? "Pendente" : t.status === "create" ? "Criado"
                                    : t.status === "canceled" ? "Cancelado" : "Pago"}
                                </td>
                                <td>{(t.urlBoleto ? <a href={t.urlBoleto} target="_blank" rel="noopener noreferrer">Visualizar</a> : " ")}</td>
                                <td>{this.convertDate(t.paymentDate)}</td>
                                <td>{masks.money(t.amount)}</td>
                                {(decodedToken.is_cell_manager || decodedToken.is_admin) && (
                                  <th>
                                    <Button size={"sm"} variant="success" disabled={!["pending", "create", "canceled",].includes(t.status.toLowerCase())}
                                      onClick={() => this.updateStatusTransaction(Object.assign(t, { status: t?.typePayment === 'PIX' ? 'RECEIVED' : 'succeeded'}))}
                                      style={{ marginRight: "5px" }}
                                    >
                                      Pago
                                    </Button>
                                    <Button size={"sm"} variant="warning" disabled={!["pending", "create", "canceled",].includes(t.status.toLowerCase())}
                                      onClick={() => this.updateStatusTransaction(Object.assign(t, { status: "canceled" }))}
                                    >Cancelado</Button>
                                  </th>
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <h3 className={"style.text-center"}>
                    O Cliente ainda não efetuou o pagamento
                  </h3>
                )}
              </Form.Group>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalPayment);
