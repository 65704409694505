import React from "react";
import { Row } from "react-bootstrap";
import { InputCPF, RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { defaultTwoOptions } from "../models/selects";

function RenderReceivedBenefit() {
  return (
    <Row>
      <InputCPF
        label="CPF"
        name={pensionFormNames.hasDependentsUnder18CPF}
        maxLength={100}
        required="O campo não deve ser vazio e deve conter um CPF válido."
      />
    </Row>
  );
}

function PensionRegister11({ serverData }) {
  const [hasDependentsUnder18, setHasDependentsUnder18] = React.useState(null);
  const serverHasDependentsUnder18 = serverData?.hasDependentsUnder18;
  React.useEffect(() => {
    if (serverHasDependentsUnder18 === defaultTwoOptions[0]) {
      setHasDependentsUnder18(true);
    }
  }, [serverHasDependentsUnder18, setHasDependentsUnder18]);

  return (
    <>
      <Row>
        <RadioGroup
          label="A pessoa falecida tinha outros dependentes menores que 18 anos?"
          name={pensionFormNames.hasDependentsUnder18}
          options={defaultTwoOptions.map((item) => ({
            label: item,
            value: item,
          }))}
          required={required.default}
          inline
          onChange={(event, value) => {
            setHasDependentsUnder18(value === defaultTwoOptions[0]);
          }}
        />
      </Row>
      {hasDependentsUnder18 === true ? <RenderReceivedBenefit /> : null}
    </>
  );
}

export default React.memo(PensionRegister11);
