import React from "react";
import { Row } from "react-bootstrap";
import { Input, Select } from "../../../genericComponents";

function BasicData5({ disabled, isValidCEP, states }) {
  return (
    <Row>
      <Input
        label="Bairro"
        name="neighborhood"
        maxLength={100}
        inputMaskProps={{
          placeholder: "Bairro",
        }}
        disabled={disabled || !isValidCEP}
        required="O campo deve ser preenchido."
      />

      <Input
        colProps={{ lg: 12, xl: 4 }}
        label="Município"
        name="city"
        maxLength={100}
        inputMaskProps={{
          placeholder: "Cidade",
        }}
        disabled={disabled || !isValidCEP}
        required="O campo deve ser preenchido."
      />

      <Select
        colProps={{ lg: 12, xl: 4 }}
        label="Estado"
        name="state"
        required='O valor do campo "Estado" deve ser selecionado.'
        disabled={disabled || !isValidCEP}
        placeholder="Selecione um estado"
        options={states.map((item) => ({
          label: item.label,
          value: item.label,
        }))}
      />
    </Row>
  );
}

export default React.memo(BasicData5);
