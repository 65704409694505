import React, { Component } from "react";
import {
  getAllOutsource,
  getAllAccredited,
} from "../../../services/RequestService";
import "./PartnerPage.css";
import ModalPartner from "../../../components/settings/partner/ModalPartner";
import {
  Header,
  Loading,
  PageContainer,
  TableComponent,
} from "../../../genericComponents";
import { masks } from "../../../utils";
import ModalPartnerCells from "../../../components/settings/partner/ModalPartnerCells";

class PartnerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outsource: [],
      currentList: [],
      list: null,
      filterValue: "",
      show: false,
      showCells: false,
      data: undefined,
      id: undefined,
      cpf: "",
      loading: true,
    };
  }

  componentDidMount = () => {
    this.setState({ outsource: [], loading: true }, () => {
      getAllOutsource().then((res) => {
        if (res.ok) {
          let list = res.data;
          getAllAccredited().then((res) => {
            if (res.ok) {
              let listAll = [...list, ...res.data];
              this.setState({
                outsource: listAll,
                list: listAll
                  .map((item) => ({
                    id: item.id,
                    fullNameOrCompanyName: item.fullNameOrCompanyName,
                    email: item.email,
                    cpfOrCnpj: item.cpfOrCnpj,
                    type: item.isFinder
                      ? "Indicador"
                      : item.isOutsource
                      ? "Terceirizado"
                      : "Credenciado",
                  }))
                  .sort((a, b) => (a.id > b.id ? 1 : -1)),
                loading: false,
              });
            }
          });
        }
      });
    });
  };

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  setShowModal = (show) => {
    this.setState({ show, data: undefined });
    if (!show) {
      this.componentDidMount();
    }
  };

  setShowModalCells = (showCells, id) => {
    this.setState({ showCells, id });
  };

  setModalForEditing = (data) => {
    this.setState({ data, show: true });
  };

  filter = (properties) => (item) => {
    const { filterValue } = this.state;
    for (const property of properties) {
      if (
        item[property] &&
        item[property].toUpperCase().includes(filterValue.toUpperCase())
      ) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { outsource, filterValue, show, showCells, data, id, loading } =
      this.state;
    return (
      <Loading loading={loading}>
        <Header
          active="Parceiros"
          logged
          filter={{
            type: "text",
            className: "text-left",
            placeholder: "Buscar por ... ",
            name: "filterValue",
            value: filterValue,
            onChange: this.handleInputChange,
          }}
        />
        <PageContainer fixedHeight className="p-3">
          <TableComponent
            loading={loading}
            countLabel="parceiros"
            countLabelSingular="parceiro"
            startSorted="id"
            header={[
              { label: "#", sortProperty: "id" },
              { label: "Nome", sortProperty: "fullNameOrCompanyName" },
              { label: "Email", sortProperty: "email" },
              { label: "CNPJ/CPF", sortProperty: "cpfOrCnpj" },
              { label: "Tipo", sortProperty: "type" },
              { label: "Vínculos" },
            ]}
            currentList={this.state.currentList}
            setCurrentList={(currentList) => this.setState({ currentList })}
            list={this.state.list?.filter(
              this.filter(["fullNameOrCompanyName", "email", "cpfOrCnpj"])
            )}
            onItemClick={(itemList) => {
              const item = outsource.find((it) => it.id === itemList.id);
              this.setModalForEditing(item);
            }}
            forEach={(item) => [
              { label: item.id },
              { label: item.fullNameOrCompanyName },
              { label: item.email },
              {
                label: item.cpfOrCnpj
                  ? masks.CPFCNPJ(item.cpfOrCnpj)
                  : item.cpfOrCnpj,
              },
              { label: item.type },
              {
                onClick: () => this.setShowModalCells(true, item.id),
                label: (
                  <div className="text-center">
                    <i className="fas fa-users"></i>
                  </div>
                ),
              },
            ]}
          />
          {show && (
            <ModalPartner
              show={show}
              onHide={() => this.setShowModal(false)}
              data={data && data}
              from={"partner"}
              globalPrivileges={this.props.privileges}
            />
          )}

          {showCells && (
            <ModalPartnerCells
              show={showCells}
              onHide={() => this.setShowModalCells(false)}
              id={id && id}
            />
          )}
        </PageContainer>
      </Loading>
    );
  }
}

export default PartnerPage;
