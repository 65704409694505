import React, { Component } from "react";
import { alertActions } from "../../store/actions/AlertActions";
import { connect } from "react-redux";
import { Row, Form, Card, Col, Table, Button } from "react-bootstrap";
import "./DocumentsProcess.css";
import {
  getDocumentsByProcessId,
  getProcessById,
} from "../../services/RequestService";
import ModalInsertAttachmentDocument from "./ModalInsertAttachmentDocument";
import ModalSeeSeemDocument from "./ModalSeeSeemDocument";
import { Header } from "../../genericComponents";
import { masks } from "../../utils";
class DocumentsProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oneProcess: undefined,
      documents: [],
      documentsType: [],
      file: {},
      doc: null,

      showModal: false,
      showModalAttechmentDocument: false,
    };
  }

  componentDidMount = () => {
    const { id } = this.props;

    getProcessById({ processId: id }).then((res) => {
      if (res.ok) {
        getDocumentsByProcessId({ processId: id }).then((res1) => {
          if (res1.ok) {
            this.setState((state) => ({
              ...state,
              oneProcess: res.data,
              documentType: res.data.processType.documentsType,
              documents: res1.data,
            }));
          }
        });
      }
    });
  };

  convertDate = (date) => {
    let a = date.split("T")[0].split("-");
    return a[2] + "/" + a[1] + "/" + a[0];
  };

  setShowModalAttachmentDocument = (showModalAttechmentDocument) => {
    this.setState((state) => ({ ...state, showModalAttechmentDocument }));
    if (!showModalAttechmentDocument) {
      this.componentDidMount();
    }
  };

  setShowPdf = (showModal, doc) => {
    this.setState((state) => ({ ...state, showModal, doc }));
  };

  render() {
    const {
      oneProcess,
      documents,
      doc,
      showModal,
      showModalAttechmentDocument,
    } = this.state;
    return (
      <div className={"documents-process-body"}>
        <Header logged active="Meus Documentos" />
        <div>
          {/* Propostas/Contratos */}
          <Card className={"header-documents-card"}>
            <Form>
              <span>
                <strong>Propostas/Contratos</strong>
              </span>
              <div className={"mt-2 border-top"} />
              <div>
                {oneProcess && oneProcess.processContractList ? (
                  <Table className={"m-2"}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Contratos gerado</th>
                        <th>Contratos assinados</th>
                      </tr>
                    </thead>
                    <tbody>
                      {oneProcess.processContractList.map((t, key) => {
                        return (
                          <tr key={key}>
                            <td>{t.id}</td>
                            <td>
                              {t.generatedContract && (
                                <a
                                  href={
                                    "data:application/pdf;base64," +
                                    t.generatedContract
                                  }
                                  download
                                >
                                  <i className={"fas fa-download clickable "} />
                                </a>
                              )}
                            </td>
                            <td>
                              {t.signedContract && (
                                <a
                                  href={
                                    "data:application/pdf;base64," +
                                    t.signedContract
                                  }
                                  download
                                >
                                  <i className={"fas fa-download clickable "} />
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div className={"p-3 text-center"}>
                    <span>
                      Quando você possuir propostas ou/e contratos gerados
                      aparecerá aqui
                    </span>
                  </div>
                )}
              </div>
            </Form>
          </Card>

          {/* Pagamentos */}
          <Card className={"header-documents-card"}>
            <span>
              <strong>Pagamentos</strong>
            </span>
            <div className={"mt-2 border-top"} />
            <div>
              {oneProcess && oneProcess.transactions.length > 0 ? (
                <Table className={"m-2"}>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th></th>
                      <th>Data de vencimento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {oneProcess.transactions.map((t, key) => {
                      return (
                        <tr key={key}>
                          <td>
                            {t.status.toUpperCase() === "pending"
                              ? "Pendente"
                              : t.status.toUpperCase() === "CREATE"
                                ? "Criado"
                                : t.status.toUpperCase() === "canceled"
                                  ? "Cancelado"
                                  : "Pago"}
                          </td>
                          <td>
                            <a
                              href={t.urlBoleto}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Visualizar
                            </a>
                          </td>
                          <td>{this.convertDate(t.paymentDate)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className={"p-3 text-center"}>
                  <span>
                    Quando você possuir pagamentos cadastrados aparecerá aqui
                  </span>
                </div>
              )}
            </div>
          </Card>

          {/* Anexo */}
          <Card className={"header-documents-card"}>
            <Row>
              <Col>
                <span>
                  <strong>Anexo</strong>
                </span>
              </Col>
              <Col className="text-right">
                {oneProcess &&
                  oneProcess.processType &&
                  oneProcess.processType.documentsType.length > 0 && (
                    <Button
                      variant="primary"
                      onClick={() => this.setShowModalAttachmentDocument(true)}
                    >
                      {"Inserir documento do anexo"}
                    </Button>
                  )}
              </Col>
            </Row>
            <div className={"mt-2 border-top"} />
            <div>
              {oneProcess &&
                oneProcess.processType &&
                oneProcess.processType.documentsType.length > 0 ? (
                documents.length > 0 ? (
                  <Table className={"m-2"}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>tipo</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.map((t, key) => {
                        return (
                          <tr key={key}>
                            <td>{t.id}</td>
                            <td>{t.name}</td>
                            <td>{t.documentType && t.documentType.name}</td>
                            <td>
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={t.virtualFile}
                                download
                              >
                                <i className={"fas fa-download clickable "} />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div className={"p-3 text-center"}>
                    <span>
                      Quando você possuir documentos anexados aos pedidos no
                      contrato aparecerá aqui
                    </span>
                  </div>
                )
              ) : (
                <div className={"p-3 text-center"}>
                  <span>
                    Você não possui contratos que necessitem anexar outros
                    documentos
                  </span>
                </div>
              )}
            </div>
          </Card>
          {/* Cenário */}
          <Card className={"header-documents-card"}>
            <span>
              <strong>Cenário</strong>
            </span>
            <div className={"mt-2 border-top"} />
            {oneProcess &&
              oneProcess.seem &&
              oneProcess.seem.isScenarioSelected ? (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {oneProcess.seem.seemDocsList.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>
                            <Button
                              variant="link"
                              onClick={() => this.setShowPdf(true, item)}
                            >
                              visualizar
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Descrição</th>
                      <th>Data de aposentadoria</th>
                      <th>RMI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {oneProcess.seem.seemScenarioList.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.scenario.name}</td>
                          <td>{item.scenario.description}</td>
                          <td>{this.convertDate(item.retirementDate)}</td>
                          <td>{masks.money(item.rmi)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className={"p-3 text-center"}>
                <span>
                  Você não possui um cenário, quando suas opções estiverem
                  disponiveis e você escolher um cenário aparecerá aqui
                </span>
              </div>
            )}
          </Card>
        </div>
        {showModalAttechmentDocument && (
          <ModalInsertAttachmentDocument
            show={showModalAttechmentDocument}
            onHide={() => this.setShowModalAttachmentDocument(false)}
            processId={oneProcess.id}
            documentsType={oneProcess.processType.documentsType}
          />
        )}

        {showModal && doc !== null && (
          <ModalSeeSeemDocument
            doc={doc}
            show={showModal}
            onHide={() => this.setShowPdf(false, null)}
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};
export default connect(null, mapDispatchToProps)(DocumentsProcess);
