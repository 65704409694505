import React from "react";
import {Collapse, Layout} from "element-react";

function getDateBackgroundColor(data) {
  let nowDate = new Date().getTime();
  let deliveryDate = new Date(data.deliveryDate).getTime();
  let alertDate = new Date(data.alertDate).getTime();

  let className = ''//`${styles.actionCard} px-2 py-3 my-3 clickable`;
  if (data.done) {
    return `${className} action-success-color-background`;
  } else if (nowDate < alertDate) {
    return `${className} action-warning-color-background`;
  } else if (nowDate >= alertDate && nowDate < deliveryDate) {
    return `${className} action-attention-color-background`;
  } else if (nowDate >= deliveryDate) {
    return `${className} action-danger-color-background`;
  }
}

function getFormattedDate(date) {
  if (date === null || date === undefined) {
    return "Não possui";
  } else {
    let cdDate = new Date(date);
    return cdDate.toLocaleDateString("pt-BR");
  }
}

function getFormattedTime(date) {
  if (date !== null || date !== undefined) {
    let cdDate = new Date(date);
    let hour = cdDate.getHours();
    let minutes = cdDate.getMinutes();
    let seconds = cdDate.getSeconds() === 0 ? "00" : cdDate.getSeconds();
    return hour + ":" + minutes + ":" + seconds;
  }
}

function getTaskDone(tasks) {
  let tasksDoneCount = 0;
  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i].done) {
      tasksDoneCount++;
    }
  }

  return tasksDoneCount;
}

function getCurrentTask(tasks) {
  let currentTaskIndex;

  tasks.sort((a, b) => {
    if (a.index > b.index) {
      return 1;
    }
    if (a.index < b.index) {
      return -1;
    }
    return 0;
  });

  for (let i = 0; i < tasks.length; i++) {
    if (!tasks[i].done) {
      currentTaskIndex = i;
      break;
    }
  }

  return currentTaskIndex === undefined
    ? ""
    : tasks[currentTaskIndex].taskType.name;
}

function RenderItem(descricao, valor, classe = '') {
  if (!Boolean(valor)) return null
  const style = {
    label: {
      margin: '5px',
      fontWeight: 'bold'
    }
  }
  return (
    <Layout.Col xs={24} md={6}>
      <label style={style.label} className={classe}>{descricao}:</label>
      <b>{valor}</b>
    </Layout.Col>
  );
}

function ActionCard({action, setShowModalInsertAction, title, index}) {

  return (
    <div
      style={{marginBottom: 20}}
      className={getDateBackgroundColor(action)}
      /*onClick={() => setShowModalInsertAction(true, action)}*/
    >
      <Collapse style={{width: '100%'}} value={'index-0'}>
        <Collapse.Item name={index} title={
          <a onClick={() => setShowModalInsertAction(true, action)}>
            <span>{title.length > 40 ? title.substring(0, 40) : title}</span>
          </a>
        }>
          <Layout.Row gutter="20">
            {RenderItem(action.tasks.length > 0 ? "Solicitado por" : "Criado por", action.creator.name)}
            {RenderItem('Data/Hora de criação', getFormattedDate(action.createDate) + " - " + getFormattedTime(action.createDate))}
            {RenderItem('Agendado para', action?.responsibleUser?.name)}
            {RenderItem('Tarefa Atual', getCurrentTask(action.tasks) === "" ? null : getCurrentTask(action.tasks))}
            {RenderItem('Tarefas', action.tasks.length ? getTaskDone(action.tasks) + " / " + action.tasks.length : "N/A tarefas")}
            {RenderItem('Nível de acesso', action.privaty ? "Interno" : "Externo")}
            {RenderItem('Data de alerta', action.alertDate ? getFormattedDate(action.alertDate) : null, 'alert-color')}
            {RenderItem('Documentos', action.documents.length)}
            {RenderItem('Data de entrega', action.deliveryDate ? getFormattedDate(action.deliveryDate) : null, 'deadline-color')}
            <Layout.Col span={24}>
              <Collapse>
                <Collapse.Item title="Descrição" name="1">
                  <span dangerouslySetInnerHTML={{__html: action.additionalInformation}}/></Collapse.Item>
              </Collapse>
            </Layout.Col>
          </Layout.Row>
        </Collapse.Item>
      </Collapse>
    </div>
  );
}

export default ActionCard;
