import React, {useState} from "react";
import {Upload, Button} from 'element-react'
import {useAlert} from "react-alert";

export default function SimpleUpload({accept, onUpload, value}) {
  const alert = useAlert();
  const [fileUrl, setFileUrl] = useState(null);

  function upload(file) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (evt) => {
      setFileUrl(evt.target.result)
      onUpload(evt.target.result, file)
    }
  }

  function downloadFile() {
    const content = (fileUrl || value ).split(',')
    const byteCharacters = atob(content[1])
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const _file = new Blob([byteArray], { type: content[0].replace('data:', '') })
    const fileURL = URL.createObjectURL(_file)
    const popUp = window.open(fileURL)
    if (popUp == null || typeof (popUp) === 'undefined') {
      alert.show("'Desative o seu bloqueador de pop-up para visualizar o relatório!'", {type: "error"});
    }
  }

  return (
    <div style={{display: 'flex'}}>
      <Upload
        className="upload-demo"
        action="''"
        accept={accept}
        beforeUpload={upload}
        httpRequest={() => {}}
        showFileList={false}
      >
        <Button type="primary">Upload</Button>
      </Upload>
      {(fileUrl || value) && (<Button type="success" onClick={downloadFile} style={{marginLeft: '8px'}}>Download</Button>)}
    </div>
  )
}
