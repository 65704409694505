import React, { Component } from "react";
import { Modal, Row, Table, Button } from "react-bootstrap";
import { getPaymentDates, postMagapay } from "../../services/RequestService";
import { alertActions } from "../../store/actions/AlertActions";
import { connect } from "react-redux";

class ModalPaymentOnFinal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(2021, 12, 2),
      dates: [],
      payment: props.payment,
      isBtnDisabled: false,
    };
  }

  componentDidMount = () => {
    getPaymentDates().then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, dates: res.data }));
      }
    });
  };

  formatDate = (date) => {
    if (date !== undefined) {
      date = date.split("T")[0].split("-");
      return date[2] + "/" + date[1] + "/" + date[0];
    }
    return "";
  };

  submit = () => {
    const { /* paymentType,  */ payment } = this.state;
    const { processId, customerId } = this.props;
    let data = {
      customerId,
      transaction: {
        processId: parseInt(processId),
        maxParcel: this.props.parcel,
        isPaymentTypeCredit: false,
        amount: parseInt(payment["rmiConcedida"] * 100),
      },
    };

    if (this.state.isBtnDisabled) {
      console.log("Request bloqueada");
      return;
    }
    this.setState({ isBtnDisabled: true });

    postMagapay(data).then((res) => {
      if (res.ok) {
        this.props.onAddAlert(
          "Pagemento efetuado/gerado com sucesso!",
          "success",
          true
        );
        this.setState({ isBtnDisabled: false });
        this.props.onHide();
      } else {
        this.props.onAddAlert(res.data.log, "danger", true);
        this.setState({ isBtnDisabled: false });
      }
    });

    this.forceUpdate();
  };

  render() {
    const { dates, isBtnDisabled } = this.state;
    const { payment, show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="sm" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Valor</th>
                <th>Data de Vencimento</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Boleto</td>
                <td>{"R$" + parseFloat(payment["rmiConcedida"]).toFixed(2)}</td>
                <td>{this.formatDate(dates[0])}</td>
              </tr>
              <tr>
                <td>Boleto</td>
                <td>{"R$" + parseFloat(payment["rmiConcedida"]).toFixed(2)}</td>
                <td>{this.formatDate(dates[1])}</td>
              </tr>
              <tr>
                <td>Boleto</td>
                <td>
                  {"R$" +
                    (
                      payment["complementoPositivo"] *
                      (payment["complementoPositivoHonorarios"] / 100)
                    ).toFixed(2)}
                </td>
                <td>{this.formatDate(dates[0])}</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Button
              variant="warning"
              className="w-100 button-without-rounded"
              onClick={this.submit}
              disabled={isBtnDisabled}
            >
              {"Pagar"}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalPaymentOnFinal);
