export const reasonsForRequest = ["Óbito", "Morte Presumida"];

export const degreeOfKinship = [
  "Casado(a) com certidão de casamento",
  "Convivia em união estável",
  "Separado(a)/divorciado(a)",
  "Filho(a), menor de 21 (vinte e um) anos",
  "Filho(a), maior de 21 (vinte e um) anos, inválido ou que tenha deficiência intelectual ou mental ou deficiência grave",
  "Pai/mãe que dependiam financeiramente do pessoal falecida",
  "Irmão(a) maior de 21 anos que dependiam financeiramente do pessoal falecida",
  "Outros",
];

export const deceasedActivityOptions = [
  "Empregado(a)",
  "Agricultor(a)/Pescador(a)",
  "Autônomo(a)",
  "Servidor(a) Público(a)",
  "Desempregado / recebendo seguro desemprego",
  "Desempregado",
];

export const defaultTwoOptions = ["Sim", "Não"];

export const defaultThreeOptions = ["Sim", "Não", "Não sei responder"];
