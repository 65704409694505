import { getCustomerById } from "../../../services/RequestService";
import { epiNames, epiValues } from "../models/formArrayNames";
import { pensionFormNames } from "../models/pensionFormNames";
import { format as fnsFormat } from "date-fns";

export default async function getCustomer(
  id,
  isClient,
  onAddAlert,
  finishCallback
) {
  if (id) {
    getCustomerById({ userId: id }).then((res) => {
      if (res.ok) {
        function parse(value) {
          try {
            return JSON.parse(value);
          } catch (e) {
            return value;
          }
        }

        const a = res.data;
        const contactWay = {};
        a.contactWay.split(",").map((item) => {
          contactWay[item] = true;
          return null;
        });
        const basicData = {
          cpf: a.cpf,
          email: a.email,
          rg: a.rg,
          birthday: a.birthday,
          gender: a.gender,
          cellphone: a.phone,
          alternativePhone: a.alternativePhone,
          fullname: a.fullname,
          nationality: a.nationality,
          occupation: a.occupation,
          maritalStatus: a.maritualStatus,
          cep: a.addressZipCode,
          street: a.addressStreet,
          placeNumber: a.addressNumber,
          complement: a.addressComplement,
          neighborhood: a.addressDistrict,
          city: a.addressCity,
          state: a.addressState,
          registeredBy: a.registeredBy,

          indication: a.indication || 'Não',
          indicatorName: a.indicatorName || '',
          indicatorDoc: a.indicatorDoc || '',
        };

        let isPension = false;
        let surveyData;
        if (a.retirement && (!isPension || isClient)) {
          const benefit = parse(a.retirement.benefitNumber);
          const regime = parse(a.retirement.regime);
          const military = parse(a.retirement.militaryService);
          const technicalSchool = parse(
            a.retirement.technicalAgricultureSchool
          );
          const danger = parse(a.retirement.unhealthyDangerousness);
          let dangerNoise = [];
          if (danger?.agents?.ruido) {
            dangerNoise = danger.agents.ruido
              ? danger.agents.ruido.split("'/'")
              : ["", ""];
          }
          const epi = parse(a.retirement.epi);
          const epiServerValues = {};
          if (epi?.whichAre) {
            epi.whichAre.map((item) => {
              const epiIndex = epiValues.findIndex(
                (arrayItem) => arrayItem === item
              );
              epiServerValues[epiNames[epiIndex]] = true;
              return null;
            });
          }
          const agriculture = parse(a.retirement.agriculture);
          const cooperative = parse(a.retirement.cooperative).value;
          const activityIncome = parse(a.retirement.activityIncome).value;
          const activityOrExtraIncome = parse(
            a.retirement.activityOrExtraIncome
          ).value;
          const doubleBurden = parse(a.retirement.doubleBurden);
          const disabled = parse(a.retirement.disabled);
          const accidentHelp = parse(a.retirement.disasterAssistance);
          const inss = parse(a.retirement.myInssPassword);

          let weddingDateFishing;
          if (a.retirement.fishing?.weddingDate) {
            const date = new Date(a.retirement.fishing.weddingDate);
            weddingDateFishing = fnsFormat(date, "dd/MM/yyyy");
          }
          surveyData = {
            isRetired: a.retirement.isRetired,
            benefitNumber: benefit.number,
            benefitYear: benefit.year,
            publicServer: a.retirement.publicServer,
            regime: regime.value,
            tryRetirement: a.retirement.retirementProcess,
            retirementStudy: a.retirement.retirementStudy,
            workProcess: a.retirement.lawsuit,
            militaryService: military.option,
            militaryYearStart: military.date.from,
            militaryYearEnd: military.date.to,
            parentsFarmer: a.retirement.parentsFarmer,
            employeeOrServiceProvider: a.retirement.employeeOrServiceProvider,
            disabledWorker: a.retirement.disabledWorker,
            technicalSchool: technicalSchool?.option,
            technicalSchoolYearStart: technicalSchool?.date.from,
            technicalSchoolYearEnd: technicalSchool?.date.to,
            optionDangerous: danger.option,
            dangerousNoiseCompany: dangerNoise[0],
            dangerousNoiseMachine: dangerNoise[1],
            dangerousHeat: danger.agents?.calor,
            dangerousCold: danger.agents?.frio,
            dangerousElectricity: danger.agents?.eletricidade,
            dangerousChemicalAgents: danger.agents?.agentesQuimicos,
            dangerousHeight: danger.agents?.trabalhoEmAltura,
            dangerousGunBearing: danger.agents?.porteDeArma,
            dangerousBiologicalAgents: danger.agents?.agentesBiologicos,
            optionEPI: epi.option,
            ...epiServerValues,
            beforeAprilNineFive: a.retirement.beforeAprilNineFive,
            optionAgriculture: agriculture.option,
            weddingDate: agriculture.weddingDate,
            periodWorked: agriculture.periodWorked,
            haveDocumentsForProof: agriculture.haveDocumentsForProof,
            condition: agriculture.condition,
            terrainState: agriculture.uf,
            terrainCity: agriculture.city,
            propertyArea: agriculture.propertyArea,
            exploredArea: agriculture.exploredArea,
            resources: agriculture.resources,
            familyEconomyRegime:
              a.retirement.fishing?.familyEconomyRegime ?? "Não",
            weddingDateFishing: weddingDateFishing,
            artisanalFishing: a.retirement.fishing?.artisanalFishing,
            haveDocumentsForProofFishing:
              a.retirement.fishing?.hasDocumentoForProof,
            vesselType: a.retirement.fishing?.vesselType,
            vesselMeasure: a.retirement.fishing?.vesselMeasure,
            ownVessel: a.retirement.fishing?.ownVessel,
            fishSpecies: a.retirement.fishing?.fishSpecies,
            howFishWasDone: a.retirement.fishing?.howFishWasDone,
            whoWorksWithYou: a.retirement.fishing?.whoWorksWithYou,
            otherIncome: a.retirement.fishing?.otherIncome,
            cooperative: a.retirement.fishing?.cooperative,
            haveAnEmployeeOrServiceProvider:
              a.retirement.fishing?.haveAnEmployeeOrServiceProvider,
            fishingParents: a.retirement.fishing?.fishingParents,
            optionCooperative: cooperative,
            optionActivityOrExtraIncome: activityOrExtraIncome,
            optionActivityIncome: activityIncome,
            optionTwoPlaces: doubleBurden.option,
            twoPlaceCompanies: doubleBurden.name,
            deficiency: disabled.option,
            deficiencyName: disabled.deficiency.whichOne,
            deficiencyYearStart: disabled.deficiency.from,
            accidentHelp: accidentHelp.option,
            accidentHelpYearStart: accidentHelp.date.from,
            accidentHelpYearEnd: accidentHelp.date.to,
            inss: inss.option,
            inssPassword: inss.password,
            additionalInformation: a.additionalInformation,
            contactWay,
          };
        } else if (a.pension) {
          isPension = true;
          const data = a.pension;
          const economicDependencyDocuments = parse(
            data[pensionFormNames.economicDependencyDocuments]
          );
          const kinshipDocuments = parse(
            data[pensionFormNames.kinshipDocuments]
          );

          const hasDependentsUnder18 = parse(
            data[pensionFormNames.hasDependentsUnder18]
          );

          const youGetSomeBenefit = parse(
            data[pensionFormNames.youGetSomeBenefit]
          );

          const regimeOnDateOfDeath = parse(
            data[pensionFormNames.regimeOnDateOfDeath]
          );
          const wasRetired = parse(data[pensionFormNames.wasRetired]);

          surveyData = {
            [pensionFormNames.degreeOfKinship]:
              data[pensionFormNames.degreeOfKinship],
            [pensionFormNames.dateOfDeath]: data[pensionFormNames.dateOfDeath],
            [pensionFormNames.deathCertificate]:
              data[pensionFormNames.deathCertificate],
            [pensionFormNames.causeOfDeath]:
              data[pensionFormNames.causeOfDeath],
            [pensionFormNames.occurInTheExerciseOfFunction]:
              data[pensionFormNames.occurInTheExerciseOfFunction],
            [pensionFormNames.workingInDateOfDeath]:
              data[pensionFormNames.workingInDateOfDeath],
            [pensionFormNames.deceasedActivity]:
              data[pensionFormNames.deceasedActivity],
            [pensionFormNames.kinshipDocuments]: kinshipDocuments.option,
            [pensionFormNames.kinshipDocumentsDescription]:
              kinshipDocuments.description,
            [pensionFormNames.economicDependencyDocuments]:
              economicDependencyDocuments.option,
            [pensionFormNames.economicDependencyDocumentsDescription]:
              economicDependencyDocuments.description,
            [pensionFormNames.regimeOnDateOfDeath]: regimeOnDateOfDeath.option,
            [pensionFormNames.regimeOnDateOfDeathDescription]:
              regimeOnDateOfDeath.description,
            [pensionFormNames.wasRetired]: wasRetired.option,
            [pensionFormNames.wasRetiredBenefitNumber]:
              wasRetired.benefitNumber,
            [pensionFormNames.receivedBenefit]:
              data[pensionFormNames.receivedBenefit],
            additionalInformation: a.additionalInformation,
            contactWay,
            [pensionFormNames.youGetSomeBenefit]: youGetSomeBenefit?.option,
            [pensionFormNames.youGetSomeBenefitDescription]:
              youGetSomeBenefit?.which,
            [pensionFormNames.requestReason]:
              data[pensionFormNames.requestReason],
            [pensionFormNames.hasDisability]:
              data[pensionFormNames.hasDisability],
            [pensionFormNames.hasDependentsUnder18]:
              hasDependentsUnder18?.option,
            [pensionFormNames.hasDependentsUnder18CPF]:
              hasDependentsUnder18?.cpf,
            [pensionFormNames.deceasedINSSPassword]:
              data[pensionFormNames.deceasedINSSPassword],
            [pensionFormNames.applicantINSSPassword]:
              data[pensionFormNames.applicantINSSPassword],
          };
        } else {
          onAddAlert(
            isClient
              ? "Seus dados estão desatualizados, por favor, atualize-os ou entre em contato para maiores informações"
              : "Os dados do cliente estão desatualizados. Entre em contato com o mesmo para atualizá-los.",
            "warning"
          );
        }

        finishCallback({
          customerId: a.id,
          basicData,
          surveyData,
          isPension,
          isFetchingData: false,
          serverData: {
            ...basicData,
            ...surveyData,
          },
        });
      }
    });
  }
}
