import React from "react";
import { Row } from "react-bootstrap";
import { Input, RadioGroup } from "../../../genericComponents";

function CustomerSurvey9({ serverData }) {
  const [towPlace, setTwoPlaces] = React.useState(false);

  const optionTwoPlaces = serverData?.optionTwoPlaces;
  React.useEffect(() => {
    if (optionTwoPlaces === "Sim") {
      setTwoPlaces(true);
    }
  }, [optionTwoPlaces, setTwoPlaces]);
  return (
    <Row>
      <RadioGroup
        colProps={{ xs: 12 }}
        label="Trabalhou em dois locais simultaneamente?"
        name="optionTwoPlaces"
        options={["Sim", "Não", "Não sei responder"].map((item) => ({
          label: item,
          value: item,
        }))}
        required='O campo "Trabalhou em dois locais simultaneamente?" é obrigatório.'
        inline
        onChange={(event, value) => {
          setTwoPlaces(value === "Sim");
        }}
      />
      {towPlace ? (
        <Input
          colProps={{ xs: 12 }}
          label="Quais empresas?"
          maxLength={100}
          name="twoPlaceCompanies"
          required='O campo "Quais empresas?" é obrigatório.'
          inputMaskProps={{
            placeholder: "Informe as empresas nas quais você trabalhou",
          }}
        />
      ) : null}
    </Row>
  );
}

export default React.memo(CustomerSurvey9);
