import React, { Component } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import FileBase64 from "react-file-base64";
import { postNewContract } from "../../../../services/RequestService";
import { connect } from "react-redux";
import { alertActions } from "../../../../store/actions/AlertActions";

class ModalInsertDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64: "",
      needSignature: false,
      needADMSignature: false,
      btnDisabled: true,
    };
  }

  handleChooseDoc = (e) => {
    let nameSplit = e.name.split(".");
    let type = nameSplit[nameSplit.length - 1];
    if (type === "pdf" || type === "png" || type === "jpg") {
      this.setState((state) => ({
        ...state,
        base64: e.base64.replace(
          "data:" +
            (type === "pdf" ? "application" : "image") +
            "/" +
            (type === "jpg" ? "jpeg" : type) +
            ";base64,",
          ""
        ),
        btnDisabled: e.base64 === "",
      }));
    } else {
      this.props.onAddAlert(
        "Arquivo não suportado. Arquivos com formato pdf, png, jpg são os unicos aceitos",
        "danger",
        true
      );
    }
  };

  handleNeedSignature = () => {
    const { needSignature } = this.state;
    this.setState((state) => ({ ...state, needSignature: !needSignature }));
  };

  handleNeedADMSignature = () => {
    const { needADMSignature } = this.state;
    this.setState((state) => ({
      ...state,
      needADMSignature: !needADMSignature,
    }));
  };

  sendFile = () => {
    const { base64, needSignature, needADMSignature } = this.state;
    const { processId } = this.props;
    let data = {
      processId: processId,
      generatedContract: base64,
      needSignature: needSignature,
      needAdminApproval: needADMSignature,
    };
    this.setState((state) => ({ ...state, btnDisabled: true }));
    postNewContract(data).then((res) => {
      if (res.ok) {
        this.props.onAddAlert(
          "Contrato adicionado com sucesso",
          "success",
          false
        );
        this.setState((state) => ({ ...state, btnDisabled: false }));
        this.props.onHide();
      } else {
        this.setState((state) => ({ ...state, btnDisabled: false }));
        this.props.onAddAlert(
          "Houve um erro ao tentar adicionar o contrato, tente novamente.",
          "danger",
          true
        );
      }
    });
  };

  render() {
    const { needSignature, needADMSignature, btnDisabled } = this.state;
    const { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar contrato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Escolha um documento</Form.Label>
                <br />
                <FileBase64
                  multiple={false}
                  onDone={this.handleChooseDoc.bind(this)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                label={"Precisa de assinatura?"}
                name={"needSignature"}
                value={needSignature}
                onChange={this.handleNeedSignature}
                checked={needSignature}
              />
            </Col>
            <Col>
              <Form.Check
                label={"Precisa de assinatura de um administrador?"}
                name={"needADMSignature"}
                value={needADMSignature}
                onChange={this.handleNeedADMSignature}
                checked={needADMSignature}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.sendFile} disabled={btnDisabled}>
            Enviar Contrato
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};
export default connect(null, mapDispatchToProps)(ModalInsertDocument);
