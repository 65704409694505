import React, { Component } from 'react'
import { Button, Col, Form, Modal } from 'react-bootstrap'
import { postAlertDoneMessage } from '../../../services/RequestService'

class ModalReadAlertMessage extends Component {
    render () {

        const {show, onHide, alertId, description} = this.props

        let tmp = document.createElement("DIV")
        tmp.innerHTML = description

        return (
            <Modal show={show} onHide={onHide} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Confirma leitura do alerta?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={tmp.innerText}
                                    readOnly={true}
                                />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={()=>{
                        postAlertDoneMessage({alertId})
                            .then(res=>{
                                if(res.ok){
                                    onHide()
                                }
                            })
                    }
                    }>Confirmar leitura</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalReadAlertMessage