/* eslint-disable react/style-prop-object */
import React, {Component} from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal
} from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {
  deletePrivilegeCell,
  hasCellAvailable,
  postCellUpdate,
  postCellUpdateSinglePrivilege,
  setUserCellManager,
} from "../../../services/RequestService";
import "./ModalCell.css";
import ModalUser from "../user/ModalUser";
import ModalRemoveWorkGrupCell from "./ModalRemoveWorkGrupCell";
import {connect} from "react-redux";
import {alertActions} from "../../../store/actions/AlertActions";
import ModalInsertSinglePrivilege from "./ModalInsertSinglePrivilege";
import {TableComponent} from "../../../genericComponents";
import {Loading, Tabs, Dialog, Layout} from "element-react";

class ModalInfoCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editing: false,
      isCellNameValid: undefined,
      name: props["cell"]["name"],
      user: null,
      privileges: {},
      isCellAdmin: false,
      isCellManager: false,
      isAdmin: false,
      cell: props.cell,
      allCell: [],
      internSiblingCells: props.internSiblingCells,
      currentPrivileges: [],

      sort: {
        id: null,
        name: null,
        users: null,
      },

      currentCell: null,
      cellSelected: null,
      privilegeId: null,
      missingPrivileges: [],

      showModalAddPartner: false,
      showInfoModal: false,
      showRemoveModal: false,
      showInsertSinglePrivilegeModal: false,
      showRemovePrivilegeModal: false,
    };
  }

  componentDidMount = () => {
    const {cell, internSiblingCells} = this.props;
    const jwt = require("jwt-decode");
    let token = jwt(localStorage.getItem("token"));
    this.setState((state) => ({
      ...state,
      isCellAdmin: token["is_cell_admin"],
      isCellManager: token["is_cell_manager"],
      isAdmin: token["is_admin"],
      allCell: cell.cells,
      childCells: cell.cells?.map((item) => ({
        id: item.id,
        name: item.name,
        usersCount: item.users.length,
        isIntern: item.isIntern,
      })),
      cell,
      internSiblingCells,
      users: cell.users?.map((item) => ({
        id: item.id,
        name: item.name,
        type: item.isCellAdmin ? "Parceiro" : "Usuário",
        isCellAdmin: item.isCellAdmin,
        isCellManager: item.isCellManager,
      })),
    }));
  };

  handleInputChange = (e) => {
    e.persist();
    const {name, value} = e.target;
    this.setState({[name]: value});
  };

  updateCellPrivileges = (e, item, index) => {
    let name = e.target.name.split(".")[1];
    item[name] = !item[name];
    const newItem = {...item};
    this.setState(
      (state) => {
        state.cell.privileges[index] = item;
        return {cell: state.cell};
      },
      () => {
        delete newItem["name"];
        postCellUpdateSinglePrivilege(newItem); // TODO tratamento de erro
      }
    );
  };

  removeCellPrivilege = (privilegeId) => {
    let privileges = this.state.cell.privileges;
    if (this.props.from === "workGroup") {
      privileges.forEach((element) => {
        if (element.id === privilegeId) {
          if (
            element.name === "configuracoes/grupo_de_trabalho" ||
            element.name === "configuracoes/parceiros"
          ) {
            privileges.forEach((priv) => {
              if (
                priv.name === "configuracoes/grupo_de_trabalho" ||
                priv.name === "configuracoes/parceiros"
              ) {
                this.deletePrivilege(priv.id);
              }
            });
          } else {
            this.deletePrivilege(privilegeId);
          }
        }
      });
    } else {
      this.deletePrivilege(privilegeId);
    }
  };

  deletePrivilege = (id) => {
    deletePrivilegeCell({id}).then((res) => {
      if (res.ok) {
        this.props.onAddAlert(res.data.log, "success", false);
        this.props.onHide(true);
      } else {
        this.props.onAddAlert(res.data.log, "danger", false);
      }
    });
  };

  isCellNameValid = (_) => {
    const {name} = this.state;
    let isCellNameValid = name !== "";
    hasCellAvailable({name}).then((res) => {
      if (res.ok) {
        isCellNameValid = isCellNameValid && res.data;
        this.setState((state) => ({...state, isCellNameValid}));
      }
    });
  };

  changeRoleName = (_) => {
    const {name} = this.state;
    postCellUpdate({
      name,
      id: this.props.cell["id"],
    }).then((res) => {
      if (res.ok) {
        this.setState({
          editing: false,
          isCellNameValid: undefined,
        });
      }
    });
  };

  cancelChangeRoleName = (_) => {
    this.setState({
      editing: false,
      isCellNameValid: undefined,
      name: this.props.cell["name"],
    });
  };

  showInsertSinglePrivilegeModal = (show, wasUpdated) => {
    this.setState({showInsertSinglePrivilegeModal: show});
    if (!show && wasUpdated) {
      this.props.onHide(true);
    }
  };

  showModalAddPartner = (show, wasUpdated) => {
    this.setState({showModalAddPartner: show});
    if (!show && wasUpdated) {
      this.props.onHide(true);
    }
  };

  setShowModalInfo = (showInfoModal, currentCell) => {
    this.setState({showInfoModal, currentCell});
  };

  setOpenRemoveModal = (showRemoveModal, cellSelected, wasUpdated) => {
    this.setState({showRemoveModal, cellSelected});
    if (!showRemoveModal && wasUpdated) {
      this.componentDidMount();
    }
  };

  render() {
    const {
      loading,
      editing,
      isCellNameValid,
      cell,
      allCell,
      name,
      privileges,
      currentCell,
      cellSelected,
      privilegeId,
      showModalAddPartner,
      showInfoModal,
      showRemoveModal,
      showRemovePrivilegeModal,
      showInsertSinglePrivilegeModal,
      isAdmin,
      isCellAdmin,
    } = this.state;
    const {show, onHide, user, isIntern, isUserIntern, from} = this.props;
    return (
      <Loading loading={loading}>
        <Dialog
          title="Informações da célula"
          visible={show}
          onCancel={onHide}
          size={'large'}
        >
          <Dialog.Body>
            {user !== null && privileges !== null && (
              <Tabs type="card" value="1">
                <Tabs.Pane label="Usuários" name="1">
                  <Form.Group>
                    <Form.Label>Nome da célula</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="name"
                        value={name}
                        disabled={!editing}
                        onChange={this.handleInputChange}
                        onBlur={this.isCellNameValid}
                        className={isCellNameValid === undefined ? "" : isCellNameValid ? "is-valid" : "is-invalid"}
                      />
                      {isIntern !== null && (
                        <InputGroup.Append>
                          {!editing ? (
                            <Button variant="primary" onClick={() => this.setState((state) => ({
                              ...state,
                              editing: true,
                            }))}>Editar</Button>
                          ) : (
                            <div>
                              <Button variant="primary" onClick={this.changeRoleName}
                                      disabled={!isCellNameValid}>Atualizar</Button>
                              <Button variant="primary" onClick={this.cancelChangeRoleName}> Cancelar </Button>
                            </div>
                          )}
                        </InputGroup.Append>
                      )}
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {isCellNameValid === undefined ? "" : isCellNameValid ? "" : "Um nome disponível deve ser fornecido e não pode ser vazio."}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Nome da parceiro</Form.Label>
                    <InputGroup>
                      <Form.Control disabled={true} value={cell?.outsource?.fullNameOrCompanyName}/>
                    </InputGroup>
                  </Form.Group>
                  <Layout.Col>
                    <TableComponent
                      loading={loading}
                      countLabel="usuários"
                      countLabelSingular="usuário"
                      emptyPlaceholder="Nenhum usuário encontrado."
                      header={[
                        {label: "Nome"},
                        {label: "Tipo"},
                        {label: "É administrador?"},
                        {label: "É gerente?"},
                      ]}
                      currentList={this.state.currentUsers}
                      setCurrentList={(currentUsers) => this.setState({currentUsers})}
                      list={this.state.users}
                      forEach={(item) => [
                        {label: item.name},
                        {label: item.type},
                        {
                          label: (
                            <BootstrapSwitchButton
                              checked={item["isCellAdmin"]}
                              onstyle="success"
                              offstyle="danger"
                              style="w-75 mx-3 disabled"
                              disabled
                              onlabel="Sim"
                              offlabel="Não"
                              size="sm"
                            />
                          ),
                        },
                        {
                          label: (
                            <div className="w-100">
                              <BootstrapSwitchButton
                                checked={item["isCellManager"]}
                                onstyle="success"
                                offstyle="danger"
                                style={`w-75 mx-3 ${item["isCellAdmin"] || isIntern === null ? "disabled" : ""}`}
                                disabled={
                                  item["isCellAdmin"] || isIntern === null
                                }
                                onlabel="Sim"
                                offlabel="Não"
                                size="sm"
                                onChange={() => {
                                  this.setState({loading: true});
                                  setUserCellManager({
                                    userId: item["id"],
                                  }).then((res) => {
                                    if (res.ok) item["isCellManager"] = !item["isCellManager"];
                                    this.setState({
                                      loading: false,
                                    });
                                  });
                                }}
                              />
                            </div>
                          ),
                        },
                      ]}
                    />

                    {isIntern !== null && (
                      <div className={"button-center"}>
                        <Button onClick={() => this.showModalAddPartner(true)}>Adicionar Usuário</Button>
                      </div>
                    )}
                  </Layout.Col>
                </Tabs.Pane>
                {allCell?.length !== 0 && (
                  <Tabs.Pane label="Células Filhas" name="2">
                    <TableComponent
                      containerClassName="mt-4"
                      loading={loading}
                      countLabel="células"
                      countLabelSingular="célula"
                      emptyPlaceholder="Nenhuma célula encontrada."
                      header={[
                        {label: "#", sortProperty: "id"},
                        {label: "Editar"},
                        {label: "Nome", sortProperty: "name"},
                        {label: "Usuários", sortProperty: "usersCount"},
                        {label: "Excluir"},
                      ]}
                      currentList={this.state.currentChildCells}
                      setCurrentList={(currentChildCells) => this.setState({currentChildCells})}
                      list={this.state.childCells}
                      forEach={(item) => [
                        {label: item.id},
                        {
                          onClick: () => {
                            const selected = allCell.find((it) => it.id === item.id);
                            this.setShowModalInfo(true, selected);
                          },
                          label: (<div className="text-center"><i className="fas fa-edit"></i></div>),
                        },
                        {label: item.name},
                        {label: item.usersCount},
                        {
                          onClick: () => {
                            const selected = allCell.find((it) => it.id === item.id);
                            this.setOpenRemoveModal(true, selected);
                          },
                          label: (
                            <div className="text-center"><i className="fas fa-trash-alt is-required"></i></div>),
                        },
                      ]}
                    />
                  </Tabs.Pane>
                )}
                <Tabs.Pane label="Privilégios" name="2">
                  <TableComponent
                    containerClassName="mt-4"
                    countLabel="privilégios"
                    countLabelSingular="privilégio"
                    emptyPlaceholder="Nenhum privilégio encontrado."
                    header={[
                      {label: ""},
                      {label: "Criar"},
                      {label: "Ler"},
                      {label: "Editar"},
                      {label: "Excluir"},
                      isIntern !== null &&
                      (user.isAdmin || user.isCellAdmin)
                        ? {label: "Remover Privilégio"}
                        : null,
                    ]}
                    currentList={this.state.currentPrivileges}
                    setCurrentList={(currentPrivileges) => this.setState({currentPrivileges})}
                    list={Object.keys(cell.privileges).map((item) => ({
                      name: cell.privileges[item].name, ...cell.privileges[item],
                    }))}
                    forEach={(item, index) => [
                      {label: item.name},
                      {
                        label: (
                          <Form.Check
                            name={item.name + ".insert"}
                            value={!item.insert}
                            checked={item.insert}
                            disabled={item.insert === null ? true : isIntern !== null && isAdmin ? false : !isCellAdmin}
                            onChange={(e) => this.updateCellPrivileges(e, item, index)}
                          />
                        ),
                      },
                      {
                        label: (
                          <Form.Check
                            name={item.name + ".read"}
                            value={!item.read}
                            checked={item.read}
                            disabled={item.read === null ? true : isIntern !== null && isAdmin ? false : !isCellAdmin}
                            onChange={(e) => this.updateCellPrivileges(e, item, index)}
                          />
                        ),
                      },
                      {
                        label: (
                          <Form.Check
                            name={item.name + ".update"}
                            value={!item.update}
                            checked={item.update}
                            disabled={item.update === null ? true : isIntern !== null && isAdmin ? false : !isCellAdmin}
                            onChange={(e) => this.updateCellPrivileges(e, item, index)}
                          />
                        ),
                      },
                      {
                        label: (
                          <Form.Check
                            name={item.name + ".delete"}
                            value={!item.delete}
                            checked={item.delete}
                            disabled={item.delete === null ? true : isIntern !== null && isAdmin ? false : !isCellAdmin}
                            onChange={(e) => this.updateCellPrivileges(e, item, index)}
                          />
                        ),
                      },
                      isIntern !== null && (user.isAdmin || user.isCellAdmin) ? {
                          onClick: () =>
                            this.setState({showRemovePrivilegeModal: true, privilegeId: item.id}),
                          label: (<div className="text-center"><i className="fas fa-trash-alt is-required"></i></div>),
                        }
                        : null,
                    ]}
                  />
                  {isIntern !== null && (
                    <div className={"button-center"}>
                      <Button onClick={() => this.showInsertSinglePrivilegeModal(true)}> Adicionar
                        Privilégio
                      </Button>
                    </div>
                  )}
                </Tabs.Pane>
              </Tabs>
            )}

            {showInsertSinglePrivilegeModal && (
              <ModalInsertSinglePrivilege
                show={showInsertSinglePrivilegeModal}
                onHide={(wasUpdated) => this.showInsertSinglePrivilegeModal(false, wasUpdated)}
                cellId={cell.id}
                from={from}
              />
            )}

            <Modal show={showRemovePrivilegeModal}>
              <Modal.Body className="align-self-center text-center">
                <Layout.Row>Deseja remover esse privilégio?</Layout.Row>
                <Layout.Row>
                  <Layout.Col> {" "}
                    <Button onClick={() => this.removeCellPrivilege(privilegeId)}> Sim </Button>
                  </Layout.Col>
                  <Layout.Col> {" "}
                    <Button onClick={() => this.setState((state) => ({
                      ...state,
                      showRemovePrivilegeModal: !showRemovePrivilegeModal,
                      privilegeId: null,
                    }))}>
                      Não
                    </Button>
                  </Layout.Col>
                </Layout.Row>
              </Modal.Body>
            </Modal>

            {showRemoveModal && (
              <ModalRemoveWorkGrupCell
                show={showRemoveModal}
                onHide={(wasUpdated) => this.setOpenRemoveModal(false, null, wasUpdated)}
                cell={cellSelected}
                isIntern={isUserIntern}
              />
            )}
            {showModalAddPartner && (
              <ModalUser
                show={showModalAddPartner}
                onHide={(wasUpdated) => this.showModalAddPartner(false, wasUpdated)}
                cellName={name}
                cellId={cell.id}
              ></ModalUser>
            )}
            {showInfoModal && currentCell !== null && (
              <ModalInfoCell
                show={showInfoModal}
                onHide={(wasUpdated) => this.setShowModalInfo(true, null, wasUpdated)}
                cell={currentCell}
                user={user}
                isIntern={null}
              />
            )}
          </Dialog.Body>
        </Dialog>
      </Loading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalInfoCell);
