import React, { Component } from "react";
import { Form, Modal, Row } from "react-bootstrap";

import "../../../pages/settings/process/SettingsProcessPage.css";
import { Button } from "react-bootstrap";
import {
  modalTaskTypeMount,
  submitTaskType,
  validateAlertDay,
  validateDeadlineDay,
  validateOtherOnBlur,
  validateTaskTypeValid,
} from "./controllers/ModalTaskTypeControllers";
import {
  Input,
  InputNumber,
  RadioGroup,
  Select,
} from "../../../genericComponents";
import { FormFull } from "form-full";

class ModalTaskType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledField: true,
      allTaskType: [],
      users: [],
      cells: [],
      scheduledToType: "",
      scheduledToId: "",
      name: "",
      alertDays: "",
      deadlineDays: "",
    };
  }

  componentDidMount = () => {
    modalTaskTypeMount(this.props, (state) => this.setState(state));
  };

  render() {
    const {
      users,
      cells,
      scheduledToType,
      scheduledToId,
      disabledField,
      name,
      alertDays,
      deadlineDays,
    } = this.state;
    const { show, onHide, id } = this.props;

    return (
      <FormFull
        actualValues={{
          scheduledToType,
          scheduledToId,
          name,
          alertDays,
          deadlineDays,
        }}
        formRef={(ref) => (this.formRef = ref)}
        onSubmit={(data) =>
          submitTaskType(data, this.props.id, this.props.onHide)
        }
      >
        <Modal size="md" show={show} onHide={onHide} centered backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Tipo de Tarefa</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Row>
                <Input
                  label="Nome"
                  name="name"
                  required="O campo não pode ser vazio"
                  disabled={disabledField}
                  validation={(value) =>
                    validateTaskTypeValid(
                      value,
                      this.state.allTaskType,
                      this.state.name
                    )
                  }
                />
              </Form.Row>
              <Form.Row>
                <InputNumber
                  label="Alertar em"
                  name="alertDays"
                  required="O campo não pode ser vazio"
                  disabled={disabledField}
                  validation={validateAlertDay}
                  onBlur={validateOtherOnBlur("deadlineDays")}
                />
              </Form.Row>
              <Form.Row>
                <InputNumber
                  label="Entregar em"
                  name="deadlineDays"
                  required="O campo não pode ser vazio"
                  disabled={disabledField}
                  validation={validateDeadlineDay}
                  onBlur={validateOtherOnBlur("alertDays")}
                />
              </Form.Row>
              <Form.Row>
                <RadioGroup
                  label="Agendamento para"
                  name="scheduledToType"
                  inline
                  required="Campo obrigatório"
                  disabled={disabledField}
                  onChange={(event, scheduledToType) =>
                    this.setState({ scheduledToType })
                  }
                  options={[
                    { value: "user", label: "Usuário" },
                    { value: "cell", label: "Célula" },
                  ]}
                />
              </Form.Row>
              <Form.Row>
                <Select
                  label={
                    !scheduledToType
                      ? "Responsável"
                      : `${
                          scheduledToType === "cell" ? "Célula" : "Usuário"
                        } Responsável`
                  }
                  name="scheduledToId"
                  required="O valor do campo gênero deve ser Masculino ou Feminino."
                  placeholder="Selecione um responsável"
                  disabled={!scheduledToType || disabledField}
                  options={
                    !scheduledToType
                      ? []
                      : (scheduledToType === "cell" ? cells : users).map(
                          (item) => ({ value: item.id, label: item.name })
                        )
                  }
                />
              </Form.Row>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="p-0">
            <Row className="w-100 m-0">
              <Button
                variant="primary"
                disabled={disabledField}
                className="w-100 button-without-rounded"
                onClick={() => this.formRef.submit()}
              >
                {id === undefined || id === null ? "Cadastrar" : "Atualizar"}
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      </FormFull>
    );
  }
}

export default ModalTaskType;
