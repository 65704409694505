import React, { Component } from "react";
import { connect } from "react-redux";
import { alertActions } from "../../store/actions/AlertActions";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import {
  getAllActionType,
  getAllUser,
  putInsertAction,
  postUpdateAction,
  postDoneAction,
  getAllActionByProcessId,
  deleteAction,
  putDocument,
  getDocumentsByProcessId,
  getCellGetAll,
  postDoneTask,
} from "../../services/RequestService";
import PDFViewer from '../../genericComponents/containers/PDFViewer'

import ModalDocument from "../customer/document/ModalDocument";
import "./SingleProcess.css";
import ModalJustify from "./ModalJustify";
import ModalInsertRule from "./ModalInsertRule";

class ModalInsertAction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privilegeUpdate: true,
      privilegeDelete: true,

      allActions: [],
      allActionData: [],
      allActionDataEdit: [],
      allUsers: [],
      allActionDoc: [],
      allActionDocAux: [],
      cells: [],
      access: ["Interno", "Externo"],
      disabledButton: false,
      showModalFileImageData: false,
      file: [],
      imageView: undefined,
      imageViewModal: undefined,
      fileViewModal: undefined,
      fileView: undefined,
      scheduledTo: "",
      scheduledToId: "",
      justification: "",
      showModalJustify: false,
      showModalRemoveAction: false,
      showModalCheckboxTask: false,
      showModalRules: false,
      taskCheked: null,
      taskIndex: null,
      showTasks: props.actionData !== undefined,
      showDocuments: false,
      showOptions: props.actionData === undefined,
      insertAction: {
        actionType:
          props.actionData !== undefined
            ? props.actionData.actionType.name
            : "",
        actionTypeData: undefined,
        creator:
          props.actionData !== undefined ? props.actionData.creator.name : "",
        scheduledToId: props.actionData?.responsibleUser?.id ?? "",
        additionalInformation:
          props.actionData !== undefined
            ? props.actionData.additionalInformation
            : "",
        deliveryDate:
          props.actionData !== undefined
            ? props.actionData.deliveryDate !== null
              ? this.getDateFormated(props.actionData.deliveryDate)
              : ""
            : "",
        alertDate:
          props.actionData !== undefined
            ? props.actionData.alertDate !== null
              ? this.getDateFormated(props.actionData.alertDate)
              : ""
            : "",
        done: props.actionData !== undefined ? props.actionData.done : false,
        isPrivate:
          props.actionData !== undefined
            ? props.actionData.privaty
              ? "Interno"
              : "Externo"
            : undefined,
        documents: [],
      },
      validFields: {
        isActionsTypeValid: undefined,
        isResponsibleValid: undefined,
        isDeliveryDateValid: undefined,
        isAlertDateValid: undefined,
        isPrivate: undefined,
        isScheduledToNameValid: undefined,
      },
    };
  }

  componentDidMount = () => {
    const jwtDecode = require("jwt-decode");
    let jwtUserId = jwtDecode(localStorage.getItem("token")).user_id;
    let id =
      this.props.actionData !== undefined
        ? this.props.actionData.process.id
        : this.props.processId;
    let vData = {};
    getAllActionType().then((res) => {
      let v = {};
      for (let i = 0; i < res.data.length; i++) {
        let a = res.data[i];
        v[a.name] = a.id;
        vData[a.name] = a;
      }
      this.setState((state) => ({ ...state, allActions: v }));
    });
    getAllUser().then((res) => {
      let jwtUserName = "";
      res.data.forEach((item) => {
        if (item.id === jwtUserId) {
          jwtUserName = item.name;
        }
      });
      let a = this.state.insertAction;
      a.creator = jwtUserName;
      this.setState((state) => ({
        ...state,
        allUsers: res.data,
        insertAction: a,
      }));
    });
    getDocumentsByProcessId({ processId: id }).then((res) => {
      const { insertAction } = this.state;
      const documents =
        this.props.actionData !== undefined
          ? this.props.actionData.documents
          : [];
      let doc = [];
      let docAux = {};
      let allDoc = res.data;
      if (res.ok) {
        for (let i = 0; i < allDoc.length; i++) {
          docAux[allDoc[i].name] = allDoc[i].id;
        }

        if (documents !== undefined) {
          for (let i = 0; i < documents.length; i++) {
            doc.push(documents[i].id);
          }
          for (let i = 0; i < allDoc.length; i++) {
            if (doc.includes(allDoc[i].id)) {
              insertAction.documents.push(allDoc[i]);
            }
          }
        }
        this.setState((state) => ({
          ...state,
          allActionDoc: allDoc,
          allActionDocAux: docAux,
          insertAction,
        }));
      }
    });

    getCellGetAll().then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, cells: res.data }));
      }
    });

    if (this.props.actionData) {
      const { insertAction, validFields } = this.state;
      const { responsibleUser, responsibleCell } = this.props.actionData;
      let scheduledToId = "",
        scheduledTo = "";
      if (responsibleUser !== null) {
        scheduledTo = "usuario";
        scheduledToId = responsibleUser.id;
      } else {
        if (responsibleCell !== null) {
          scheduledTo = "celula";
          scheduledToId = responsibleCell.id;
        }
      }
      let privilegeUpdate = false;
      let privilegeDelete = false;
      if (!jwtDecode(localStorage.getItem("token")).is_customer) {
        let privileges = JSON.parse(
          jwtDecode(localStorage.getItem("token")).privileges
        );

        privilegeUpdate = true;
        privilegeDelete = true;
        if (insertAction.isPrivate === "Interno") {
          privilegeUpdate = privileges["andamento_interno"]?.update;
          privilegeDelete = privileges["andamento_interno"]?.delete;
        } else if (insertAction.isPrivate === "Externo") {
          privilegeUpdate = privileges["andamento_externo"]?.update;
          privilegeDelete = privileges["andamento_externo"]?.delete;
        }
      }

      this.setState(
        (state) => ({
          ...state,
          privilegeUpdate,
          privilegeDelete,
          scheduledTo,
          scheduledToId,
          allActionData: this.props.actionData.tasks,
          validFields: {
            ...validFields,
            isActionTypeValid: insertAction.actionType !== "",
            isResponsibleValid: insertAction.responsible !== "",
            isPrivate: insertAction.isPrivate !== "",
          },
        }),
        () => this.sortAllActionDataAscending()
      );
    } else {
      this.setState((state) => ({ ...state, allActionData: vData }));
    }
  };

  getDateFormated = (date) => {
    if (date === null || date === undefined) {
      return "Não possui";
    } else {
      let cdDate = new Date(date);
      return cdDate.toLocaleDateString("pt-BR");
    }
  };

  setActionComplete = () => {
    const jwtDecode = require("jwt-decode");
    let jwtUserId = jwtDecode(localStorage.getItem("token")).user_id;

    postDoneAction({
      actionId: this.props.actionData.id,
      doneUserId: jwtUserId,
      additionalInformation: this.props.actionData.additionalInformation,
    }).then((res) => {
      if (res.ok) {
        this.props.onAddAlert(
          "Andamento concluido com sucesso.",
          "success",
          false
        );
        this.props.onHide();
      } else if (res.status === 400) {
        this.props.onAddAlert(res.data.log, "warning", false);
      } else {
        this.props.onAddAlert("Erro ao concluiro andamento.", "danger", false);
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({
      ...state,
      insertAction: {
        ...this.state.insertAction,
        [e.target.name]: e.target.value,
      },
    }));
  };

  handleInputChangeScheduledTo = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  handleTaskCheckChange = (t) => {
    let { allActionData, showModalCheckboxTask } = this.state;
    t.done = !t.done;
    this.setState((state) => ({
      ...state,
      allActionData,
      taskCheked: t,
      showModalCheckboxTask: !showModalCheckboxTask,
    }));
  };

  doneTask = (id) => {
    const { showModalCheckboxTask } = this.state;
    postDoneTask({ id: id }).then((res) => {
      if (res.ok) {
        let process_id =
          this.props.actionData !== undefined
            ? this.props.actionData.process.id
            : this.props.processId;
        getAllActionByProcessId({ process_id: process_id }).then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].id === this.props.actionData.id) {
              let allActionData = res.data[i].tasks;

              this.setState(
                (state) => ({
                  ...state,
                  showModalCheckboxTask: !showModalCheckboxTask,
                  allActionData,
                }),
                () => this.sortAllActionDataAscending()
              );
              break;
            }
          }
        }, this.props.onAddAlert("Tarefa concluida.", "success", false));
      }
    });
  };

  actionTypeHandleInputChange = (e) => {
    let actionType = this.state.allActionData[e.target.value];
    let scheduledTo = "";
    let scheduledToId = "";
    let deliveryDate = "";
    let alertDate = "";

    if (actionType !== undefined) {
      if (actionType.user !== null) {
        scheduledTo = "usuario";
        scheduledToId = actionType.user.id;
      } else {
        if (actionType.cell !== null) {
          scheduledTo = "celula";
          scheduledToId = actionType.cell.id;
        }
      }

      let dtDeliveryDate = new Date();
      dtDeliveryDate.setDate(
        dtDeliveryDate.getDate() + actionType.deadlineDays
      );
      deliveryDate = this.dateToDDMMYYYY(dtDeliveryDate);

      let dtAlertDate = new Date();
      dtAlertDate.setDate(dtAlertDate.getDate() + actionType.alertDays);
      alertDate = this.dateToDDMMYYYY(dtAlertDate);
    }

    e.persist();
    this.setState((state) => ({
      ...state,
      scheduledTo: scheduledTo,
      scheduledToId,
      insertAction: {
        ...this.state.insertAction,
        [e.target.name]: e.target.value,
        deliveryDate: deliveryDate,
        alertDate: alertDate,
        actionTypeData: actionType,
      },
    }));
  };

  isActionTypeValid = () => {
    const { insertAction, validFields } = this.state;
    this.setState((state) => ({
      ...state,
      validFields: {
        ...validFields,
        isActionTypeValid: insertAction.actionType !== "",
      },
    }));
  };

  isResponsibleValid = () => {
    const { validFields, scheduledToId } = this.state;
    this.setState((state) => ({
      ...state,
      validFields: {
        ...validFields,
        isResponsibleValid: scheduledToId !== "",
      },
    }));
  };

  isDeliveryDateValid = () => {
    const { deliveryDate } = this.state.insertAction;
    let isDeliveryDateValid = !isNaN(
      this.toDDMMYYYYToDate(deliveryDate).getTime()
    );
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isDeliveryDateValid },
    }));
  };

  isAlertDateValid = () => {
    const { alertDate } = this.state.insertAction;
    let isAlertDateValid = !isNaN(this.toDDMMYYYYToDate(alertDate).getTime());
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isAlertDateValid },
    }));
  };

  isPrivate = () => {
    const { insertAction, validFields } = this.state;
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isPrivate: insertAction.isPrivate !== "" },
    }));
  };

  isDataValid = () => {
    const { insertAction, validFields } = this.state;
    let additionalInformation =
      insertAction.additionalInformation === ""
        ? false
        : insertAction.additionalInformation !== "";
    let actionType =
      validFields.isActionTypeValid === undefined
        ? false
        : validFields.isActionTypeValid;
    let isPrivate =
      validFields.isPrivate === undefined ? false : validFields.isPrivate;

    let isScheduledToNameValid =
      this.getTaskLength() > 0
        ? validFields.isScheduledToNameValid === undefined
          ? false
          : validFields.isScheduledToNameValid
        : true;
    return (
      additionalInformation && actionType && isPrivate && isScheduledToNameValid
    );
  };

  toDDMMYYYYToDate = (date) => {
    const [day, month, year] = date.split("/");
    return new Date(year, month - 1, day);
  };

  dateToDDMMYYYY = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();

    return dd + "/" + mm + "/" + yyyy;
  };

  getFormattedDate = (date) => {
    if (date === null || date === undefined) {
      return "Não possui";
    } else {
      let cdDate = new Date(date);
      return cdDate.toLocaleDateString("pt-BR");
    }
  };

  cleanAddAction = () => {
    const { insertAction, validFields } = this.state;
    this.setState((state) => ({
      ...state,
      insertAction: {
        ...insertAction,
        actionType: "",
        creatorId: "",
        responsible: "",
        additionalInformation: "",
        deliveryDate: "",
        alertDate: "",
        done: false,
        isPrivate: undefined,
      },
      validFields: {
        ...validFields,
        isActionTypeValid: undefined,
        isResponsibleValid: undefined,
        isDeliveryDateValid: undefined,
        isPrivate: undefined,
      },
      isAddAction: false,
      taskId: null,
    }));
  };

  setShowModalJustifyAction = (showModalJustify, taskId) => {
    this.setState((state) => ({ ...state, showModalJustify, taskId }));
  };

  setShowModalFileImageData = (showModalFileImageData) => {
    this.setState((state) => ({ ...state, showModalFileImageData }));
  };

  setShowModalRules = (showModalRules) => {
    if (showModalRules) {
      this.setState((state) => ({ ...state, showModalRules }));
    } else {
      this.setState((state) => ({ ...state, showModalRules }));
      this.props.onHide();
    }
  };

  addFile = (f) => {
    const { insertAction } = this.state;
    f.processId = this.props?.processId || this.props?.actionData?.process?.id;
    this.setState(
      (state) => ({ ...state, disabledButton: true }),
      () =>
        putDocument(f).then((res) => {
          if (res.ok) {
            insertAction.documents.push(res.data);
            this.setState((state) => ({
              ...state,
              disabledButton: false,
              insertAction,
            }));
          }
        })
    );
  };

  addDoc = (e) => {
    e.persist();
    const { insertAction, allActionDoc, allActionDocAux } = this.state;
    let aux = false;
    for (let i = 0; i < insertAction.documents.length; i++) {
      if (insertAction.documents[i].id === allActionDocAux[e.target.value]) {
        aux = true;
        this.props.onAddAlert("Documento já está na lista.", "danger", false);
      }
    }
    if (!aux) {
      for (let i = 0; i < allActionDoc.length; i++) {
        if (allActionDoc[i].id === allActionDocAux[e.target.value]) {
          insertAction.documents.push(allActionDoc[i]);
          break;
        }
      }
      this.setState((state) => ({ ...state, insertAction }));
    }
  };

  removeDoc = (f) => {
    const { insertAction } = this.state;
    insertAction.documents.splice(insertAction.documents.indexOf(f), 1);
    this.setState((state) => ({ ...state, insertAction }));
  };

  submitInsertAction = () => {
    const { insertAction, allActions, allUsers } = this.state;
    const jwtDecode = require("jwt-decode");
    let jwtUserId = jwtDecode(localStorage.getItem("token")).user_id;
    let deliveryDate = this.toDDMMYYYYToDate(
      insertAction.deliveryDate
    ).getTime();
    let alertDate = this.toDDMMYYYYToDate(insertAction.alertDate).getTime();
    let actionRequisiteId = null;
    let data;
    let documentsId = [];

    for (let i = 0; i < insertAction.documents.length; i++) {
      documentsId.push(insertAction.documents[i].id);
    }

    // para usuário
    const responsibleUserId = insertAction.scheduledToId
      ? insertAction.scheduledToId
      : null;

    // para célula
    // const responsibleCellId = insertAction.scheduledToId? insertAction.scheduledToId : null;

    if (this.isDataValid()) {
      if (this.props.actionData === undefined) {
        data = {
          additionalInformation: insertAction.additionalInformation,
          actionTypeId: allActions[insertAction.actionType],
          privaty:
            insertAction.isPrivate === "Interno"
              ? true
              : insertAction.isPrivate === "Externo"
                ? false
                : null,
          processId: this.props.processId,
          creatorId: jwtUserId,
          responsibleCellId: null, // para célula, retirado
          responsibleUserId,
          deliveryDate: deliveryDate,
          alertDate: alertDate,
          actionRequisiteId: actionRequisiteId,
          documentsId: documentsId,
        };
        putInsertAction(data).then((res) => {
          if (res.ok) {
            this.props.onAddAlert("Andamento adicionado.", "success", false);
            this.props.onHide();
          } else {
            this.props.onAddAlert(res.data.log, "danger", false);
          }
        });
      } else {
        data = {
          id: this.props.actionData.id,
          actionTypeId: allActions[insertAction.actionType],
          creatorId: jwtUserId,
          responsibleId: allUsers[insertAction.responsible],
          additionalInformation: insertAction.additionalInformation,
          privaty:
            insertAction.isPrivate === "Interno"
              ? true
              : insertAction.isPrivate === "Externo"
                ? false
                : null,
          deliveryDate: deliveryDate,
          alertDate: alertDate,
          actionRequisiteId: actionRequisiteId,
          documentsId: documentsId,
          responsibleCellId: null, // para célular, retirado
          responsibleUserId,
        };
        postUpdateAction(data).then((res) => {
          if (res.ok) {
            this.props.onAddAlert("Andamento atualizado.", "success", false);
            this.props.onHide();
          } else {
            this.props.onAddAlert(res.data.log, "danger", false);
          }
        });
      }
    } else {
      this.props.onAddAlert(
        "Os campos marcados com * devem ser preenchidos",
        "danger",
        false
      );
    }
  };

  deleteAction = (i) => {
    let data = { deleteId: i };
    deleteAction(data).then((res) => {
      if (res.ok) {
        this.props.onAddAlert("Andamento removido.", "success", false);
        this.props.onHide();
      }
    });
  };

  sortAllActionDataAscending = () => {
    let { allActionData, taskIndex } = this.state;
    allActionData.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0;
    });

    for (let i = 0; i < allActionData.length; i++) {
      if (!allActionData[i].done) {
        taskIndex = allActionData[i].index;
        break;
      }
    }

    this.setState((state) => ({ ...state, allActionData, taskIndex }));
  };

  getTaskLength = () => {
    if (this.props.actionData !== undefined) {
      return this.props.actionData.tasks.length;
    }

    if (this.state.insertAction.actionType in this.state.allActionData) {
      return this.state.allActionData[this.state.insertAction.actionType][
        "taskType"
      ].length;
    }

    return 0;
  };

  getTaskColor = (task, curretTaskIndex) => {
    if (task.done || curretTaskIndex !== task.index) {
      return "task-secondary";
    }
    return "";
  };

  isScheduledToNameValid = () => {
    const { scheduledToId } = this.state.insertAction;
    if (this.getTaskLength() > 0) {
      let isScheduledToNameValid = scheduledToId !== "";
      this.setState((state) => ({
        validFields: { ...state.validFields, isScheduledToNameValid },
      }));
    }
  };

  render() {
    const {
      privilegeUpdate,
      privilegeDelete,
      insertAction,
      validFields,
      access,
      allActions,
      disabledButton,
      showModalFileImageData,
      fileView,
      fileViewModal,
      imageView,
      imageViewModal,
      allActionDocAux,
      scheduledToId,
      showModalJustify,
      showModalRemoveAction,
      allActionData,
      showTasks,
      showDocuments,
      showModalCheckboxTask,
      showModalRules,
      taskIndex,
      taskCheked,
      showOptions,
      taskId,
      // cells,
      allUsers,
    } = this.state;
    const { show, onHide } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="xl"
        backdrop="static"
        className="loading-auto-height d-flex align-items-stretch"
      // dialogClassName="content-h-100"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.actionData === undefined ? "Inserir" : "Atualizar"}{" "}
            andamento
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row>
              <Col
                sm="auto"
                md="auto"
                lg="auto"
                xs="auto"
                className="text-left"
              >
                <b>Opções</b>
              </Col>
              <Col />
              <Col
                sm="auto"
                md="auto"
                lg="auto"
                xs="auto"
                className="text-right"
              >
                <Button
                  variant="link"
                  onClick={() =>
                    this.setState((state) => ({
                      ...state,
                      showOptions: !this.state.showOptions,
                    }))
                  }
                >
                  {showOptions ? "Ocultar" : "Mostrar"}
                </Button>
              </Col>
            </Row>
            <hr className="m-3" />
            {showOptions ? (
              <div>
                <Form.Row>
                  <Form.Group as={Col} className="mb-0" xs={12} lg={6}>
                    <Form.Label>Tipo de andamento</Form.Label>
                    <Form.Label className="danger-color pl-1">*</Form.Label>
                    <Form.Control
                      as="select"
                      type="text"
                      name="actionType"
                      value={insertAction.actionType}
                      onChange={this.actionTypeHandleInputChange}
                      onBlur={() => this.isActionTypeValid()}
                      className={
                        validFields.isActionTypeValid === undefined
                          ? ""
                          : validFields.isActionTypeValid
                            ? "is-valid"
                            : "is-invalid"
                      }
                      disabled={
                        this.props.actionData !== undefined &&
                        this.props.actionData !== null
                      }
                    >
                      <option />
                      {Object.keys(allActions).map((value, key) => {
                        return <option key={key}>{value}</option>;
                      })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} xs={12} lg={6}>
                    <Form.Label>Solicitado por</Form.Label>
                    <Form.Label className="danger-color pl-1">*</Form.Label>
                    <Form.Control
                      as="select"
                      type="text"
                      name="creator"
                      value={insertAction.creator}
                      disabled={true}
                      onChange={this.handleInputChange}
                      onBlur={this.isResponsibleValid}
                    >
                      <option>{insertAction.creator}</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} xs={12} lg={6}>
                    <Form.Label>Nível de acesso</Form.Label>
                    <Form.Label className="danger-color pl-1">*</Form.Label>
                    <Form.Control
                      as="select"
                      type="text"
                      name="isPrivate"
                      value={insertAction.isPrivate}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isPrivate()}
                      disabled={
                        !privilegeUpdate && this.props.actionData !== undefined
                      }
                      className={
                        validFields.isPrivate === undefined
                          ? ""
                          : validFields.isPrivate
                            ? "is-valid"
                            : "is-invalid"
                      }
                    >
                      <option />
                      {Object.keys(access).map((value, key) => {
                        return <option key={key}>{access[value]}</option>;
                      })}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} lg={6}>
                    <Form.Label>Agendado para</Form.Label>
                    {this.getTaskLength() > 0 && (
                      <Form.Label className="danger-color pl-1">*</Form.Label>
                    )}
                    <Form.Control
                      as="select"
                      value={insertAction.scheduledToId}
                      name="scheduledToId"
                      onChange={this.handleInputChange}
                      onBlur={() => this.isScheduledToNameValid()}
                      className={
                        this.getTaskLength() > 0
                          ? validFields.isScheduledToNameValid === undefined
                            ? ""
                            : validFields.isScheduledToNameValid
                              ? "is-valid"
                              : "is-invalid"
                          : ""
                      }
                    >
                      <option />
                      {Boolean(allUsers)
                        ? allUsers.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))
                        : null}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <hr className="m-3" />
              </div>
            ) : null}
            <Form.Row>
              <Col>
                <b>Tarefas ({this.getTaskLength()})</b>
              </Col>
              <Col className="text-right">
                <Button
                  variant={"link"}
                  onClick={() =>
                    this.setState((state) => ({
                      ...state,
                      showTasks: !this.state.showTasks,
                    }))
                  }
                >
                  {showTasks ? "Ocultar" : "Mostrar"}
                </Button>
              </Col>
            </Form.Row>
            {showTasks ? (
              <div>
                {this.getTaskLength() <= 0 ? (
                  <div className="p-1 text-center">
                    <span>Nenhuma tarefa</span>
                  </div>
                ) : (
                  <Form.Row className="w-100 overflow-auto">
                    <Table className="m-0">
                      <thead>
                        <tr>
                          <th />
                          <th>Tarefas</th>
                          <th>Alertar em</th>
                          <th>Entregar em</th>
                          <th>Finalizada em</th>
                          {this.props.actionData !== undefined &&
                            privilegeUpdate && <th>Adiar</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.actionData === undefined
                          ? insertAction.actionType !== "" &&
                          Object.keys(allActionData).includes(
                            insertAction.actionType
                          ) &&
                          allActionData[insertAction.actionType][
                          "taskType"
                          ] !== undefined &&
                          allActionData[insertAction.actionType][
                          "taskType"
                          ] !== null &&
                          allActionData[insertAction.actionType][
                            "taskType"
                          ].map((item) => {
                            return (
                              <tr key={item.id}>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  <Form.Check
                                    checked={item.done}
                                    onChange={() =>
                                      this.handleTaskCheckChange(item)
                                    }
                                    disabled={
                                      item.done || taskIndex !== item.index
                                    }
                                  />
                                </td>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  {item.name}
                                </td>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  {item.alertDays}
                                </td>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  {item.deadlineDays}
                                </td>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                />
                              </tr>
                            );
                          })
                          : allActionData.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  <Form.Check
                                    checked={item.done}
                                    onChange={() =>
                                      this.handleTaskCheckChange(item)
                                    }
                                    disabled={
                                      item.done ||
                                      taskIndex !== item.index ||
                                      !privilegeUpdate
                                    }
                                  />
                                </td>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  {item.taskType.name}
                                </td>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  {this.getFormattedDate(item.alertDate)}
                                </td>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  {this.getFormattedDate(item.deliveryDate)}
                                </td>
                                <td
                                  className={this.getTaskColor(
                                    item,
                                    taskIndex
                                  )}
                                >
                                  {this.getFormattedDate(item.doneDate)}
                                </td>
                                <td>
                                  {taskIndex === item.index &&
                                    !item.done &&
                                    privilegeUpdate && (
                                      <Button
                                        variant="warning"
                                        disabled={!privilegeUpdate}
                                        onClick={() => {
                                          this.setShowModalJustifyAction(
                                            true,
                                            item.id
                                          );
                                        }}
                                      >
                                        Adiar
                                      </Button>
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Form.Row>
                )}
              </div>
            ) : null}

            <hr className="m-3" />

            <Form.Group>
              <Form.Label className="my-1">Descrição</Form.Label>
              <Form.Label className="danger-color"> *</Form.Label>
              <Form.Control
                as="textarea"
                rows="4"
                type="text"
                name="additionalInformation"
                value={insertAction.additionalInformation}
                onChange={this.handleInputChange}
                disabled={!privilegeUpdate}
              />
            </Form.Group>

            <hr className="m-3" />

            <Row className="mb-2">
              <Col>
                <Row>
                  <Form.Label className="pt-2 pl-3">
                    <b>Documentos ({insertAction.documents.length})</b>
                  </Form.Label>
                  {showDocuments && (
                    <Button
                      variant="link"
                      className={"ml-3"}
                      disabled={disabledButton}
                      onClick={() => this.setShowModalFileImageData(true)}
                    >
                      Anexar
                    </Button>
                  )}
                </Row>
              </Col>
              <Col className="text-right">
                <Button
                  variant="link"
                  onClick={() =>
                    this.setState((state) => ({
                      ...state,
                      showDocuments: !this.state.showDocuments,
                    }))
                  }
                >
                  {showDocuments ? "Ocultar" : "Mostrar"}
                </Button>
              </Col>
            </Row>
            {showDocuments ? (
              <Form.Group>
                <Form.Control
                  as="select"
                  type="text"
                  name="responsible"
                  value={scheduledToId}
                  onChange={this.addDoc}
                  className={
                    validFields.isResponsibleValid === undefined
                      ? ""
                      : validFields.isResponsibleValid
                        ? "is-valid"
                        : "is-invalid"
                  }
                >
                  <option />
                  {Object.keys(allActionDocAux).map((value, key) => {
                    return <option key={key}>{value}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            ) : null}

            {showDocuments ? (
              <Table style={{ marginTop: "5px", textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>Nome do arquivo</th>
                    <th>Tipo</th>
                    <th>Download</th>
                    <th>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {insertAction.documents.map((f, key) => {
                    return (
                      <tr key={key}>
                        <td>{f.name}</td>
                        <td>{f.extension}</td>
                        <td>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={f.url}
                          >
                            <i className="fas fa-download clickable" />
                          </a>
                        </td>
                        <td>
                          <i
                            className="fas fa-trash clickable"
                            onClick={() => this.removeDoc(f)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : null}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Row className="w-100">
            <Col className="d-flex justify-content-start mt-2" xs={12} xl>
              {this.props.actionData !== undefined ? (
                privilegeUpdate ? (
                  <Button
                    className="w-100 button-without-rounded"
                    variant="primary"
                    onClick={this.submitInsertAction}
                    disabled={disabledButton || !this.isDataValid()}
                  >
                    {"Atualizar"}
                  </Button>
                ) : null
              ) : (
                <Button
                  className="w-100 button-without-rounded"
                  variant="primary"
                  onClick={this.submitInsertAction}
                  disabled={disabledButton || !this.isDataValid()}
                >
                  {"Inserir"}
                </Button>
              )}
            </Col>

            {this.props.actionData !== undefined &&
              this.props.actionData.done === false &&
              privilegeUpdate ? (
              <Col xs={12} xl className="mt-2">
                <Button
                  variant="success"
                  className="w-100 button-without-rounded"
                  onClick={() => this.setActionComplete()}
                >
                  {"Concluir"}
                </Button>
              </Col>
            ) : null}

            {this.props.actionData !== undefined &&
              this.props.actionData.actionType.timeCount &&
              privilegeUpdate ? (
              <Col className="d-flex justify-content-start mt-2" xs={12} xl>
                <Button
                  className="w-100 button-without-rounded"
                  variant="info"
                  onClick={() =>
                    this.setState((state) => ({
                      ...state,
                      showModalRules: true,
                    }))
                  }
                >
                  {"Regras"}
                </Button>
              </Col>
            ) : null}

            {this.props.actionData !== undefined && privilegeDelete ? (
              <Col className="d-flex justify-content-end mt-2" xs={12} xl>
                <Button
                  className="w-100 button-without-rounded"
                  variant="danger"
                  onClick={() =>
                    this.setState((state) => ({
                      ...state,
                      showModalRemoveAction: true,
                    }))
                  }
                >
                  {"Remover"}
                </Button>
              </Col>
            ) : null}
          </Row>
        </Modal.Footer>

        {/* Modal remove action */}
        <Modal show={showModalRemoveAction}>
          <Modal.Body>
            <Row>
              <Col>Certeza que deseja remover o andamento?</Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  variant="danger"
                  onClick={() =>
                    this.setState((state) => ({
                      ...state,
                      showModalRemoveAction: false,
                    }))
                  }
                >
                  Não
                </Button>
              </Col>
              <Col className="text-center">
                <Button
                  variant="primary"
                  onClick={() => this.deleteAction(this.props.actionData.id)}
                >
                  Sim
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        {/* Modal checkbox task */}
        <Modal show={showModalCheckboxTask}>
          <Modal.Body>
            <Row>
              <Col>Certeza que deseja finalizar a tarefa?</Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button
                  variant="danger"
                  onClick={() => this.handleTaskCheckChange(taskCheked)}
                >
                  Não
                </Button>
              </Col>
              <Col className="text-center">
                <Button
                  variant="primary"
                  onClick={() => this.doneTask(taskCheked.id)}
                >
                  Sim
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        {/* Modal set rule */}
        {showModalRules && (
          <ModalInsertRule
            show={showModalRules}
            processId={
              this.props.actionData !== undefined
                ? this.props.actionData.process.id
                : this.props.processId
            }
            onHide={() => this.setShowModalRules(false)}
          />
        )}

        {showModalFileImageData && (
          <ModalDocument
            show={showModalFileImageData}
            onHide={() => this.setShowModalFileImageData(false)}
            addFile={(f) => this.addFile(f)}
            item={null}
            editing={false}
          />
        )}
        {showModalJustify && (
          <ModalJustify
            show={showModalJustify}
            taskId={taskId}
            onHide={() => this.setShowModalJustifyAction(false, null)}
          />
        )}
        {imageView && (
          <Modal
            size="xl"
            show={imageViewModal}
            onHide={() =>
              this.setState((state) => ({
                ...state,
                imageViewModal: !imageViewModal,
              }))
            }
          >
            <Modal.Header closeButton>
              {imageView.name + "." + imageView.extension}
            </Modal.Header>
            <Modal.Body className={"modal-file"}>
              <img src={imageView.virtualFile} alt="" />
            </Modal.Body>
          </Modal>
        )}
        {fileView && (
          <Modal
            size="xl"
            show={fileViewModal}
            onHide={() =>
              this.setState((state) => ({
                ...state,
                fileViewModal: !fileViewModal,
                pageNum: 1,
              }))
            }
          >
            <Modal.Header closeButton>
              {fileView.name + "." + fileView.extension}
            </Modal.Header>
            <Modal.Body className={"modal-file"}>
              <PDFViewer document={{ url: fileView.virtualFile }} />
            </Modal.Body>
          </Modal>
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalInsertAction);
