import React, { Component } from "react";
import { Image, Card } from "react-bootstrap";
import logo from "../../res/LogoQualitte.png";
import RegisterChoose from "../../components/registerChoose/containers/RegisterChoose";

class RegisterChoicePage extends Component {
  render() {
    return (
      <div className="background-login">
        <div className="login-form w-100 h-100 d-flex">
          <div className="w-100 m-2">
            <Card className="login-form-wrapper">
              <div className="d-flex justify-content-center p-4">
                <Image src={logo} fluid />
              </div>
              <RegisterChoose from="login" />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterChoicePage;
