import React from "react";
import { Modal } from "react-bootstrap";
import TableComponent from "../../../../genericComponents/others/tableComponent/TableComponent";
import { masks } from "../../../../utils";

export default function FifthGroupDetails({
  show,
  lateProcesses,
  onHide,
  history,
}) {
  const [currentList, setCurrentList] = React.useState([]);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        <TableComponent
          countLabel="processos"
          countLabelSingular="processo"
          startSorted="id"
          header={[
            { label: "Id", sortProperty: "id" },
            { label: "Cliente", sortProperty: "customerName" },
            { label: "Valor", sortProperty: "lateAmount" },
          ]}
          totalItems={lateProcesses.length}
          list={lateProcesses}
          setCurrentList={setCurrentList}
          currentList={currentList}
          forEach={(item) => [
            { label: item.id },
            { label: item.customerName },
            { label: masks.money(item.lateAmount) },
          ]}
          onItemClick={(item) =>
            history.push("/processo/" + item.id + "/processos")
          }
        />
      </Modal.Body>
    </Modal>
  );
}
