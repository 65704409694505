import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  getIsScenarioAvaliable,
  putInsertScenario,
  postUpdateScenario,
} from "../../../services/RequestService";

class ModalScenario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props?.name ?? "",
      description: props?.description ?? "",
      isNameValid: undefined,
      isNameUnique: undefined,
      isDescriptionValid: undefined,
      isRetirementDateValid: undefined,
    };
  }

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  isNameValid = () => {
    const { name } = this.state;
    let isNameValid = name !== "";
    let isNameUnique = true;
    if (isNameValid) {
      getIsScenarioAvaliable({ name: name }).then((res) => {
        if (res.ok) {
          isNameUnique = res.data;
        }
      });
    }
    this.setState((state) => ({ ...state, isNameValid, isNameUnique }));
  };

  isDescriptionValid = () => {
    const { description } = this.state;
    let isDescriptionValid = description !== "";
    this.setState((state) => ({ ...state, isDescriptionValid }));
  };

  /*isRetirementDateValid = () => {
        const {retirementDate} = this.state
        let isRetirementDateValid = retirementDate !== '';
        this.setState(state => ({...state, isRetirementDateValid}));
    }*/

  nameClassNameOrMessage = (isClassName) => {
    const { isNameValid, isNameUnique } = this.state;
    if (isNameUnique === undefined || isNameValid === undefined) {
      return "";
    } else {
      if (isNameValid) {
        if (isNameUnique) {
          return isClassName ? "is-valid" : "";
        } else {
          return isClassName
            ? "is-invalid"
            : "O Cenário digitado já está cadastrado";
        }
      } else {
        return isClassName ? "is-invalid" : "O campo nome não pode ser vazio";
      }
    }
  };

  submit = (_) => {
    const { name, description } = this.state;
    const { id } = this.props;
    if (id === null) {
      putInsertScenario({ name, description }).then((res) => {
        if (res.ok) {
          this.props.onHide(true);
        }
      });
    } else {
      postUpdateScenario({ name, description, id }).then((res) => {
        if (res.ok) {
          this.props.onHide(true);
        }
      });
    }
  };

  isBtnDisabled = () => {
    const { name, description } = this.state;
    return !name || !description;
  };

  render() {
    const {
      name,
      description,
      isDescriptionValid,
      // isRetirementDateValid
    } = this.state;
    const { show, onHide, id } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Cenário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                name="name"
                value={name}
                onChange={this.handleInputChange}
                onBlur={this.isNameValid}
                className={this.nameClassNameOrMessage(true)}
              />
              <Form.Text className="is-required">
                {this.nameClassNameOrMessage(false)}
              </Form.Text>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={description}
                onChange={this.handleInputChange}
                onBlur={this.isDescriptionValid}
                rows={10}
                className={
                  isDescriptionValid === undefined
                    ? ""
                    : isDescriptionValid
                    ? "is-valid"
                    : "is-invalid"
                }
              />
              <Form.Text className="is-required">
                {isDescriptionValid === undefined
                  ? ""
                  : isDescriptionValid
                  ? ""
                  : "O campo descrição não pode ser vazio"}
              </Form.Text>
            </Form.Group>
          </Form.Row>
          {/*<Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Data de aposentadoria</Form.Label>
                            <Form.Control 
                                type="date"
                                value={retirementDate}
                                onChange={this.handleInputChange}
                                name="retirementDate"
                                onBlur={this.isRetirementDateValid}
                                className={
                                    isRetirementDateValid === undefined ?
                                        '' : isRetirementDateValid ?
                                            'is-valid' : 'is-invalid'
                                }
                            />
                            <Form.Text>
                                {
                                    isRetirementDateValid === undefined ?
                                        '' : isRetirementDateValid ?
                                        '' : 'O campo data de aposentadoria não pode ser vazio.'
                                }
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>*/}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Button
              variant="primary"
              className="w-100 button-without-rounded"
              disabled={this.isBtnDisabled()}
              onClick={this.submit}
            >
              {id === null ? "Cadastrar" : "Atualizar"}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalScenario;
