import React from "react";
import { Row } from "react-bootstrap";
import { RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { defaultThreeOptions } from "../models/selects";

function PensionRegister14() {
  return (
    <Row>
      <RadioGroup
        label="Você possui a Certidão de óbito, ou documento que comprove a morte presumida do falecido?"
        name={pensionFormNames.deathCertificate}
        options={defaultThreeOptions.map((item) => ({
          label: item,
          value: item,
        }))}
        required={required.default}
        inline
      />
    </Row>
  );
}

export default React.memo(PensionRegister14);
