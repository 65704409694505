import {
  getAllActionType,
  getAllProcessStatus,
  getAllTaskType,
  postProcessUpdateActionType,
  putProcessInsertActionType,
} from "../../../../services/RequestService";

export function isActionTypeValid(name, allActionType, actualValue) {
  const existActionType = allActionType.find((item) => item.name === name);
  return (Boolean(actualValue) && name === actualValue) || !existActionType
    ? null
    : "O valor digitado já é um tipo de ação";
}

export function setNewTask(newTask, ffHandler, setState) {
  if (newTask) {
    setState(
      (state) => {
        let selectedTask;
        const allTaskType = state.allTaskType.filter((item) => {
          if (String(item.id) === String(newTask)) selectedTask = item;
          return String(item.id) !== String(newTask);
        });
        const taskTypes = [...state.taskTypes];
        taskTypes.push(selectedTask);
        return {
          taskTypes,
          allTaskType,
        };
      },
      () => ffHandler.setValue("selectedTaskType", "")
    );
  }
}

export function removeTask(task, setState) {
  setState((state) => {
    const allTaskType = state.allTaskType;
    allTaskType.push(task);
    const taskTypes = state.taskTypes.filter(
      (item) => String(item.id) !== String(task.id)
    );
    return {
      taskTypes,
      allTaskType,
    };
  });
}

export function submitModalActionType(data, props, taskTypes) {
  const taskTypeId = [];
  taskTypes.forEach((element, key) => {
    taskTypeId.push({ id: element.id, index: key });
  });

  const { id, onHide, onAddAlert } = props;

  const request =
    id === null ? putProcessInsertActionType : postProcessUpdateActionType;

  request({
    id: id === null ? undefined : id,
    name: data.name,
    processStatusId: data.processStatusId,
    blockProcessDeadlineDate: data.blockProcessDeadlineDate ?? false,
    unblockProcessDeadlineDate: data.unblockProcessDeadlineDate ?? false,
    timeCount: data.timeCount ?? false,
    taskType: taskTypeId,
  }).then((res) => {
    if (res.ok) {
      onAddAlert(
        `Tipo de andamento ${id === null ? "cadastrado" : "atualizado"}`,
        "success"
      );
      onHide(true);
    }
  });
}

export function modalActionTypeMount(props, setState) {
  const {
    id,
    taskType,
    name,
    unblockProcessDeadlineDate,
    blockProcessDeadlineDate,
    processStatusId,
    timeCount,
    privileges,
  } = props;

  const disabledField =
    !privileges["configuracoes/andamentos"][
      id === undefined || id === null ? "create" : "update"
    ];

  let taskTypes = [];

  if (id !== null) {
    taskTypes = taskType;
  }
  setState({
    taskTypes,
    disabledField,
    name,
    processStatusId: String(processStatusId),
    unblockProcessDeadlineDate,
    blockProcessDeadlineDate,
    timeCount,
  });

  getAllActionType().then((res) => {
    if (res.ok) {
      setState((state) => ({ ...state, allActionType: res.data }));
    }
  });
  getAllProcessStatus().then((res) => {
    if (res.ok) {
      setState((state) => ({ ...state, allProcessStatus: res.data }));
    }
  });

  getAllTaskType().then((res) => {
    if (res.ok) {
      setState((state) => ({ ...state, allTaskType: res.data }));
    }
  });
}
