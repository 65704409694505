import React, { Component } from 'react'
import { Modal, Form,  Col, Table, Button } from 'react-bootstrap'
import {
    deleteRuleValue,
    putRuleValue, putUpdateRuleValue,
    ruleGetAll,
    ruleValueGetAll
} from '../../services/RequestService'
class ModalInsertRule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            allRules: [],
            allRuleValues:[],
            selectedRules: [],
            currentRule: '',
            value: '',
            rulesAlreadyRegistered: [],
        }
    }

    componentDidMount = () => {
        ruleGetAll().then(res => {
            if (res.ok) {
                let allRules = {}
                res.data.forEach(item => {
                    item['ruleKeyList'].forEach(j => {j['value'] = ''})
                    allRules[item['name']] = {
                        'list': item['ruleKeyList'],
                        'id': item['id']
                    }
                });
                ruleValueGetAll({ 'processId': this.props.processId }).then(res1 => {
                    if (res1.ok) {
                        const {allRules} = this.state
                        let selectedRules = []
                        let rulesAlreadyRegistered = []
                        res1.data.forEach(item => {
                            let j = []
                            item['ruleValue'].forEach(item2 => {
                                let i = {
                                    "ruleId": item2['rule'].id,
                                    "ruleKeyListId": item2.id,
                                    "id": item2.ruleKey.id,
                                    "name": item2.ruleKey.name,
                                    "yearMonthDay": item2.ruleKey.yearMonthDay,
                                    'value': item2.value
                                }
                                j.push(i)
                            })
                            allRules[item['rule']] = {'list': j}
                            selectedRules.push(item['rule'])
                            rulesAlreadyRegistered.push(item['rule'])
                        })
                        this.setState(state => ({ ...state, allRules, selectedRules, rulesAlreadyRegistered}))
                    }
                })
                this.setState(state => ({ ...state, allRules }))
            }
        })


    }

    handleInputChange = e => {
        e.persist()
        const {name, value} = e.target
        this.setState(state => ({ ...state, [name]: value}))
    }

    handleSelectRule = e => {
        e.persist()
        const {value} = e.target
        const {selectedRules} = this.state
        let r = selectedRules
        if(selectedRules.includes(value)){
            r = r.filter(item => item !== value);
        }else{
            r.push(value)
        }
        this.setState(state => ({ ...state, selectedRules: r}))
    }

    handleValueChange = (currentRule, id, e) => {
        const {allRules} = this.state
        const {value} = e.target
        allRules[currentRule]['list'].forEach(item => {
            if(item.id === id){
                item['value'] = value
            }
        })
        this.setState(state => ({ ...state, allRules}))
    }

    isBtnDisabled = () => {
        const {selectedRules, rulesAlreadyRegistered} = this.state
        return selectedRules.length === 0 && rulesAlreadyRegistered.length === 0
    }

    insertRules = () => {
        const {allRules, selectedRules, rulesAlreadyRegistered} = this.state
        let list = []
        let ruleValueList = []
        selectedRules.forEach(item => {
            allRules[item]['list'].forEach(i => {
                if(!rulesAlreadyRegistered.includes(item)) {
                    list.push({
                        'ruleId': allRules[item].id,
                        'ruleKeyId': i.id,
                        'value': i.value
                    })
                }else{
                    ruleValueList.push({'id': i.ruleKeyListId, 'value': i.value})
                }
            })
        })
        rulesAlreadyRegistered.forEach(item => {
            if(!selectedRules.includes(item)){
                allRules[item]['list'].forEach(j => {
                    deleteRuleValue({'ruleId': j.ruleId})
                        .then(_ => {})
                })

            }
        })
        putRuleValue({'processId': this.props.processId, list}).then(res => {
            if(res.ok){
                putUpdateRuleValue({ruleValueList})
                    .then(res1 => {
                        if(res1.ok){
                            this.props.onHide()
                        }
                    })
            }
        })
    }

    renderRow(row){
      return (
        <Form.Row key={row.id}>
          <Form.Label as={Col} >{row.ruleKey.name}</Form.Label>
          <Form.Control as={Col} type="number" value={row.value}/>
        </Form.Row>
      )
  }

    render() {
        const { allRules, selectedRules, currentRule } = this.state;
        const { show, onHide } = this.props;

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>Regras #2</Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Escolher</th>
                                <th>Regra</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            Object.keys(allRules).map((item, key) => {
                                return <tr key={key}>
                                    <td>
                                        <Form.Check
                                            type="checkbox"
                                            value={item}
                                            checked={selectedRules.includes(item)}
                                            onChange={this.handleSelectRule}
                                        />
                                    </td>
                                    <td>{item}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                    <hr className="m-1"/>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Control
                                as="select"
                                name="currentRule"
                                value={currentRule}
                                onChange={this.handleInputChange}
                            >
                                <option/>
                                {
                                    selectedRules.map((item, key) => {
                                        return <option key={key}>{item}</option>
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            currentRule !== '' ?
                            allRules[currentRule]['list'].map(item => {
                                return <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>
                                        <Form.Control
                                            value={item.value}
                                            onChange={(e) => this.handleValueChange(currentRule, item.id, e)}
                                        />
                                    </td>
                                </tr>
                            }): null
                        }
                        </tbody>
                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" className="w-100 button-without-rounded"
                            onClick={() => this.insertRules()} disabled={this.isBtnDisabled()} >
                        Inserir/Editar
                      </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalInsertRule
