/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import { Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import { alertActions } from "../../../store/actions/AlertActions";
import ModalCell from "../../../components/settings/cell/ModalCell";

import {
  getAllInternCells,
  getCellByUserId,
  getUserById,
  postCellUpdateSinglePrivilege,
  setUserCellManager,
} from "../../../services/RequestService.js";
import { connect } from "react-redux";

import "./CellPage.css";
import ModalUserCell from "../../../components/settings/cell/ModalUserCell";
import ModalInfoCell from "../../../components/settings/cell/ModalInfoCell";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ModalRemoveWorkGrupCell from "../../../components/settings/cell/ModalRemoveWorkGrupCell";
import {
  Header,
  Loading,
  PageContainer,
  TableComponent,
} from "../../../genericComponents";

class CellPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allCell: [],
      cellSelected: undefined,
      currentCell: null,
      showAddModal: false,
      showRemoveModal: false,
      showInfoModal: false,
      userCell: null,
      user: null,
      cellId: null,
      showAddUserModal: false,
      allCellToTransfer: [],
      loadingCount: null,
      internSiblingCells: []
    };
  }

  fetchRequests = () => {
    this.setState(
      (state) => ({
        loadingCount: !state.loadingCount ? 3 : state.loadingCount + 3,
      }),
      () => {
        getCellByUserId().then((res) => {
          if (res.ok) {
            let privileges = {};
            res.data.privileges.forEach((item) => {
              privileges[item.name] = item;
            });
            console.log(privileges);
            res.data.privileges = privileges;
            let allCell = res.data.cells;
            console.log(allCell);
            this.setState((state) => ({
              userCell: res.data,
              users: res.data.users
                ?.map((item) => ({
                  id: item.id,
                  name: item.name,
                  isCellAdmin: item.isCellAdmin,
                  isCellManager: item.isCellManager,
                }))
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
              childCells: res.data.cells
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  usersCount: item.users.length,
                  isIntern: item.isIntern,
                }))
                .sort((a, b) => (a.name > b.name ? 1 : -1)),
              allCell,
              loadingCount: res.data.isIntern && res.data.name !== "ADMIN"? state.loadingCount - 1 : state.loadingCount - 2,
            }));
            if (this.state.userCell.isIntern && this.state.userCell.name !== "ADMIN") {
              getAllInternCells().then((res) => { 
                console.log(res)               
                if (res.ok === false) {
                  return;
                }
                console.log(res.data);
                this.setState((state) => ({
                  loadingCount: state.loadingCount - 1,
                  internSiblingCells: res.data
                    .map((item) => ({
                      id: item.id,
                      name: item.name,
                      users: item.users,
                      usersCount: item.users.length,
                      isIntern: item.isIntern,
                      privileges: item.privileges                     
                    }))                  
                }))
              });
            }
          }          
        });
        getUserById().then((res) => {
          if (res.ok) {
            this.setState((state) => ({
              ...state,
              user: res.data,
              loadingCount: state.loadingCount - 1,
            }));
          }
        });
      }
    );
  };

  componentDidMount = () => {
    this.fetchRequests();
  };

  setOpenAddModal = (showAddModal, cellSelected, wasUpdated) => {
    this.setState({ showAddModal, cellSelected });
    if (!showAddModal && wasUpdated) {
      this.fetchRequests();
    }
  };

  setOpenRemoveModal = (showRemoveModal, cellSelected, wasUpdated) => {
    const { allCell } = this.state;
    var allCellToTransfer = allCell;
    if (showRemoveModal) {
      allCellToTransfer = allCellToTransfer.filter(
        (cell) => cell !== cellSelected
      );
    }
    this.setState({
      showRemoveModal,
      cellSelected,
      allCellToTransfer,
    });
    if (!showRemoveModal && wasUpdated) {
      this.fetchRequests();
    }
  };

  setOpenAddUserModal = (showAddUserModal, cellId, wasUpdated) => {
    this.setState({ showAddUserModal, cellId });
    if (!showAddUserModal && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowModalInfo = (showInfoModal, currentCell, wasUpdated) => {
    console.log(currentCell);
    this.setState({ showInfoModal, currentCell });
    if (!showInfoModal && wasUpdated) {
      this.componentDidMount();
    }
  };

  updateCellPrivileges = (e, item) => {
    const { userCell } = this.state;
    let temp = e.target.name.split(".");
    let update = { ...item };
    update[temp[1]] = e.target.value === "true";
    userCell["privileges"][temp[0]] = update;
    let c = { ...update };
    delete c["name"];
    this.setState({ userCell });
    postCellUpdateSinglePrivilege(c);
    // TODO tratar erro
  };

  getPermissions = () => {
    const p = this.props.privileges;

    const canUpdateCell = p["configuracoes/celulas"]?.update;
    const canDeleteCell = p["configuracoes/celulas"]?.delete;

    return {
      canUpdateCell,
      canDeleteCell,
    };
  };

  render() {
    const {
      allCell,
      showAddModal,
      showRemoveModal,
      cellSelected,
      userCell,
      user,
      showAddUserModal,
      cellId,
      showInfoModal,
      currentCell,
      loadingCount,
      allCellToTransfer,
    } = this.state;

    const { canUpdateCell, canDeleteCell } = this.getPermissions();

    return (
      <Loading loading={loadingCount !== 0}>
        <Header
          active="Configurações de células"
          logged
          buttons={[
            userCell !== null &&
            user !== null &&
            (userCell["cellAdmin"] === null ||
              (userCell["cellAdmin"]["id"] === 1 && !userCell["isIntern"]))
              ? {
                  onClick: () => this.setOpenAddModal(true),
                  icon: "fas fa-plus-circle",
                  label: "Adicionar",
                }
              : null,
          ]}
        />
        <PageContainer withoutCard>
          {userCell !== null && (
            <Row className="cell-page-container">
              <Col
                xl={5}
                className="m-2 p-3 card cell-page-left-card cell-page-card"
              >
                <Form.Group>
                  <Form.Label>Minha célula</Form.Label>
                  <Form.Control value={userCell["name"]} readOnly={true} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>É interno?</Form.Label>
                  <Form.Control
                    value={userCell["isIntern"] ? "Sim" : "Não"}
                    readOnly={true}
                  />
                </Form.Group>

                <TableComponent
                  loading={loadingCount !== 0}
                  countLabel="usuários"
                  countLabelSingular="usuário"
                  emptyPlaceholder="Nenhum usuário encontrado."
                  header={[
                    { label: "Nome" },
                    { label: "É administrador?" },
                    { label: "É gerente?" },
                  ]}
                  currentList={this.state.currentUsers}
                  setCurrentList={(currentUsers) =>
                    this.setState({ currentUsers })
                  }
                  list={this.state.users}
                  forEach={(item) => [
                    { label: item.name },
                    {
                      label: (
                        <BootstrapSwitchButton
                          checked={item["isCellAdmin"]}
                          onstyle="success"
                          offstyle="danger"
                          style="w-75 mx-3 disabled"
                          disabled
                          onlabel="Sim"
                          offlabel="Não"
                          size="sm"
                        />
                      ),
                    },
                    {
                      label: (
                        <div className="w-100">
                          <BootstrapSwitchButton
                            checked={item["isCellManager"]}
                            onstyle="success"
                            offstyle="danger"
                            style={`w-75 mx-3 ${
                              item["isCellAdmin"] ? "disabled" : ""
                            }`}
                            disabled={item["isCellAdmin"]}
                            onlabel="Sim"
                            offlabel="Não"
                            size="sm"
                            onChange={() => {
                              this.setState({ loading: true }, () => {
                                setUserCellManager({
                                  userId: item["id"],
                                }).then((res) => {
                                  if (res.ok) {
                                    item["isCellManager"] =
                                      !item["isCellManager"];
                                    this.setState({
                                      loading: false,
                                    });
                                  }
                                });
                              });
                            }}
                          />
                        </div>
                      ),
                    },
                  ]}
                />
              </Col>
              <Col className="m-2 p-3 card cell-page-card">
                <Tabs
                  defaultActiveKey={ userCell["isIntern"] && userCell["name"] !== "ADMIN" ? "Células Irmãs" : "Células Filhas"}
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="Células Filhas" title="Células Filhas">
                    <TableComponent
                      containerClassName="mt-4"
                      loading={loadingCount !== 0}
                      countLabel="células"
                      countLabelSingular="célula"
                      emptyPlaceholder="Nenhuma célula encontrada."
                      startSorted="name"
                      header={[
                        { label: "#", sortProperty: "id" },
                        // PERMISSÃO - apenas se tiver permissão para editar célula
                        canUpdateCell ? { label: "Editar" } : null,
                        { label: "Nome", sortProperty: "name" },
                        { label: "Usuários", sortProperty: "usersCount" },
                        // PERMISSÃO - apenas se tiver permissão para deletar célula
                        canDeleteCell ? { label: "Excluir" } : null,
                      ]}
                      currentList={this.state.currentChildCells}
                      setCurrentList={(currentChildCells) =>
                        this.setState({ currentChildCells })
                      }
                      list={this.state.childCells.filter(
                        (e) => e.id !== userCell.id && e["isIntern"]
                      )}
                      forEach={(item) => [
                        { label: item.id },
                        // PERMISSÃO - apenas se tiver permissão para editar célula
                        canUpdateCell
                          ? {
                              onClick: () => {
                                const selected = allCell.find(
                                  (it) => it.id === item.id
                                );
                                this.setShowModalInfo(true, selected);
                              },
                              label: (
                                <div className="text-center">
                                  <i className="fas fa-edit"></i>
                                </div>
                              ),
                            }
                          : null,
                        { label: item.name },
                        { label: item.usersCount },
                        // PERMISSÃO - apenas se tiver permissão para deletar célula
                        canDeleteCell
                          ? {
                              onClick: () => {
                                const selected = allCell.find(
                                  (it) => it.id === item.id
                                );
                                this.setOpenRemoveModal(true, selected);
                              },
                              label: (
                                <div className="text-center">
                                  <i className="fas fa-trash-alt is-required"></i>
                                </div>
                              ),
                            }
                          : null,
                      ]}
                    />
                  </Tab>
                  {this.state.userCell.isIntern && this.state.userCell.name !== "ADMIN" && this.state?.internSiblingCells.length !== 0 && (

                    <Tab eventKey="Células Irmãs" title="Células Irmãs">
                    <TableComponent
                      containerClassName="mt-4"
                      loading={loadingCount !== 0}
                      countLabel="células"
                      countLabelSingular="célula"
                      emptyPlaceholder="Nenhuma célula encontrada."
                      startSorted="name"
                      header={[
                        { label: "#", sortProperty: "id" },
                        // PERMISSÃO - apenas se tiver permissão para editar célula
                        canUpdateCell ? { label: "Editar" } : null,
                        { label: "Nome", sortProperty: "name" },
                        { label: "Usuários", sortProperty: "usersCount" },
                        // PERMISSÃO - apenas se tiver permissão para deletar célula
                        canDeleteCell ? { label: "Excluir" } : null,
                      ]}
                      currentList={this.state.currentInternSiblingCells}
                      setCurrentList={(currentInternSiblingCells) =>
                        this.setState({ currentInternSiblingCells })
                      }
                      list={this.state.internSiblingCells.filter(
                        (e) => e.id !== userCell.id
                      )}
                      forEach={(item) => [
                        { label: item.id },
                        // PERMISSÃO - apenas se tiver permissão para editar célula
                        canUpdateCell
                          ? {
                              onClick: () => {
                                const selected = this.state.internSiblingCells.find(
                                  (it) => it.id === item.id
                                );
                                this.setShowModalInfo(true, selected);
                              },
                              label: (
                                <div className="text-center">
                                  <i className="fas fa-edit"></i>
                                </div>
                              ),
                            }
                          : null,
                        { label: item.name },
                        { label: item.usersCount },
                        // PERMISSÃO - apenas se tiver permissão para deletar célula
                        canDeleteCell
                          ? {
                              onClick: () => {
                                const selected = this.state.internSiblingCells.find(
                                  (it) => it.id === item.id
                                );
                                this.setOpenRemoveModal(true, selected);
                              },
                              label: (
                                <div className="text-center">
                                  <i className="fas fa-trash-alt is-required"></i>
                                </div>
                              ),
                            }
                          : null,
                      ]}
                    />
                  </Tab>
                  )}
                  <Tab eventKey="Privilégios" title="Privilégios">
                    <TableComponent
                      containerClassName="mt-4"
                      loading={loadingCount !== 0}
                      countLabel="privilégios"
                      countLabelSingular="privilégio"
                      emptyPlaceholder="Nenhum privilégio encontrado."
                      // startSorted="name"
                      header={[
                        { label: "" },
                        { label: "Criar" },
                        { label: "Ler" },
                        { label: "Editar" },
                        { label: "Excluir" },
                      ]}
                      currentList={this.state.currentPrivileges}
                      setCurrentList={(currentPrivileges) =>
                        this.setState({ currentPrivileges })
                      }
                      list={Object.keys(userCell["privileges"]).map((item) => ({
                        name: item,
                        ...userCell["privileges"][item],
                      }))}
                      forEach={(item) => [
                        { label: item.name },
                        {
                          label: (
                            <Form.Check
                              name={item.name + ".insert"}
                              value={!item.insert}
                              checked={item.insert}
                              disabled
                            />
                          ),
                        },
                        {
                          label: (
                            <Form.Check
                              name={item.name + ".read"}
                              value={!item.read}
                              checked={item.read}
                              disabled
                            />
                          ),
                        },
                        {
                          label: (
                            <Form.Check
                              name={item.name + ".update"}
                              value={!item.update}
                              checked={item.update}
                              disabled
                            />
                          ),
                        },
                        {
                          label: (
                            <Form.Check
                              name={item.name + ".delete"}
                              value={!item.delete}
                              checked={item.delete}
                              disabled
                            />
                          ),
                        },
                      ]}
                    />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          )}
          {showAddModal && (
            <ModalCell
              show={showAddModal}
              onHide={(wasUpdated) =>
                this.setOpenAddModal(false, null, wasUpdated)
              }
              cell={cellSelected}
              isIntern={true}
              privileges={userCell.privileges}
              cellAdmin={userCell.id}
            />
          )}

          {showRemoveModal && (
            <ModalRemoveWorkGrupCell
              show={showRemoveModal}
              onHide={(wasUpdated) =>
                this.setOpenRemoveModal(false, null, wasUpdated)
              }
              cell={cellSelected}
              isIntern={userCell.isIntern}
              from={"cellPage"}
              allCell={allCellToTransfer}
            />
          )}
          {showAddUserModal && cellId !== null && (
            <ModalUserCell
              show={showAddUserModal}
              cellId={cellId}
              onHide={(wasUpdated) =>
                this.setOpenAddUserModal(false, null, wasUpdated)
              }
            />
          )}
          {showInfoModal && currentCell !== null && (
            <ModalInfoCell
              show={showInfoModal}
              onHide={(wasUpdated) =>
                this.setShowModalInfo(false, null, wasUpdated)
              }
              cell={currentCell}
              internSiblingCells={this.state.internSiblingCells}
              user={user}
              from={"cell"}
            />
          )}
        </PageContainer>
      </Loading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(CellPage);
