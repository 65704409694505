import React, { Component } from "react";
import { Form, Table } from "react-bootstrap";
import { masks, fn } from "../../utils";

class Negociation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { parcel, amount, paymentCustomParcels } = this.props;
    return (
      <div>
        <Form.Group>
          {(paymentCustomParcels || []).length > 0 ? (
            <>
              <Form.Label>
                Conforme negociado, o pagamento será gerado
              </Form.Label>
              <Table className={"table-responsible"}>
                <thead>
                  <tr>
                    <th>Valor</th>
                    <th>Tipo do Pagamento</th>
                    <th>Data de vencimento</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentCustomParcels.map((t, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {masks.money(t.parcelValue)}
                          {t.paymentType === "Cartão de Crédito" && (
                            <span> / {t.creditCardParcels}x</span>
                          )}
                        </td>
                        <td>{t.paymentType}</td>
                        <td>{masks.convertDateToBr(t.dueDate)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              <Form.Label>
                Conforme escolhido anteriormente, o pagamento será realizado
              </Form.Label>
              <Form.Control
                readOnly={true}
                value={
                  "Em " + parcel + "x de R$ " + fn.floorFigure(amount / parcel)
                }
              />
            </>
          )}
        </Form.Group>
      </div>
    );
  }
}

export default Negociation;
