import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Form, Button, Row, Table } from "react-bootstrap";
import { alertActions } from "../../../store/actions/AlertActions";
import "../../../pages/settings/action/SettingsActionPage.css";
import "../../../pages/settings/process/SettingsProcessPage.css";
import {
  isActionTypeValid,
  modalActionTypeMount,
  removeTask,
  setNewTask,
  submitModalActionType,
} from "./controllers/ModalActionTypeControllers";
import { Input, Select, Checkbox } from "../../../genericComponents";
import { FormFull } from "form-full";

class ModalActionType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledField: true,
      allActionType: [],
      allProcessStatus: [],
      allTaskType: [],
      taskTypes: [],
    };
  }

  componentDidMount = () => {
    modalActionTypeMount(this.props, (state) => this.setState(state));
  };

  render() {
    const { allTaskType, disabledField } = this.state;
    const { show, onHide } = this.props;

    return (
      <FormFull
        actualValues={{
          name: this.state.name,
          unblockProcessDeadlineDate: this.state.unblockProcessDeadlineDate,
          blockProcessDeadlineDate: this.state.blockProcessDeadlineDate,
          processStatusId: this.state.processStatusId,
          timeCount: this.state.timeCount,
        }}
        formRef={(ref) => (this.formRef = ref)}
        onSubmit={(data) =>
          submitModalActionType(data, this.props, this.state.taskTypes)
        }
      >
        <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Tipo de andamento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Row>
                <Input
                  name="name"
                  label="Nome"
                  required="O campo não pode ser vazio"
                  validation={(value) =>
                    isActionTypeValid(
                      value,
                      this.state.allActionType,
                      this.state.name
                    )
                  }
                />
              </Form.Row>
              <Form.Row>
                <Select
                  name="processStatusId"
                  label="Mover status do projeto para"
                  placeholder="Selecione uma opção"
                  options={this.state.allProcessStatus.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Row>
              <Form.Row>
                <Checkbox name="timeCount" label="Contagem de tempo" />
              </Form.Row>
              <Form.Row>
                <Checkbox
                  name="blockProcessDeadlineDate"
                  label="Bloquear o prazo do processo"
                />
              </Form.Row>
              <Form.Row>
                <Checkbox
                  name="unblockProcessDeadlineDate"
                  label="Desbloquear o prazo do processo"
                />
              </Form.Row>
              <hr className="mx-1 my-3" />
              <Form.Row>
                <Select
                  name="selectedTaskType"
                  label="Tipos de tarefa"
                  placeholder="Selecione uma tarefa"
                  options={allTaskType.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  onChange={(event, value, ffHandler) =>
                    setNewTask(value, ffHandler, (state, callback) =>
                      this.setState(state, callback)
                    )
                  }
                />
              </Form.Row>
              <Form.Row>
                <Table
                  striped
                  bordered
                  hover
                  id="myTable"
                  className="text-center action-table-responsible"
                  responsive={true}
                >
                  <thead>
                    <tr>
                      <th>nome</th>
                      <th>alerta</th>
                      <th>entrega</th>
                      <th>agenda</th>
                      <th>remover</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.taskTypes.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{item["name"]}</td>
                          <td>{item["alertDays"]}</td>
                          <td>{item["deadlineDays"]}</td>
                          {item["cell"] !== null ? (
                            <td>{item["cell"]["name"]}</td>
                          ) : item["user"] !== null ? (
                            <td>{item["user"]["name"]}</td>
                          ) : (
                            <td />
                          )}
                          <td
                            onClick={() =>
                              removeTask(item, (state) => this.setState(state))
                            }
                          >
                            <i className="fas fa-trash-alt process-type-action-type-trash-icon clickable" />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Form.Row>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="p-0">
            <Row className="w-100 m-0">
              <Button
                variant="primary"
                disabled={disabledField}
                className="w-100 button-without-rounded"
                onClick={() => this.formRef.submit()}
              >
                {this.props.id === null ? "Cadastrar" : "Editar"}
              </Button>
            </Row>
          </Modal.Footer>
        </Modal>
      </FormFull>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalActionType);
