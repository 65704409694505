import React from "react";

export default function PDFViewer({document}){
  return (
    <div style={{textAlign: 'center', border: '1px solid #ddd', minHeight: 400, marginBottom: 10}}>
      {document ? (<iframe src={document} title="Visualizar" width="100%" height="600px"/>)
        : (<label style={{padding: 10}}>Sem docmento para exibir</label>)}
    </div>
  )
}
