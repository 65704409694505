import React, { Component } from "react";
import { Modal, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import {
  // getAllRoles,
  // getCellGetAll,
  insertUser,
  // updateUser,
  getAllUser,
  // hasEmailAvailable,
  // hasCpfAvailable,
} from "../../../services/RequestService";
import { alertActions } from "../../../store/actions/AlertActions";
import { connect } from "react-redux";
import InputMask from "react-input-mask";

class ModalUserCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledField: false,
      email: "",
      name: "",
      password: "",
      cpf: "",
      enable: undefined,
      role: "",
      cell: "",
      // allRoles: [],
      allCells: [],
      disabledSubmitBtn: false,
      type: "password",
      isAdmin: false,
      validFields: {
        isEmailValid: undefined,
        isEmailUnique: undefined,
        isPasswordValid: undefined,
        isNameValid: undefined,
        isEnableValid: undefined,
        // isRoleValid: undefined,
        isRoleCell: undefined,
        isCPFValid: undefined,
      },
    };
  }

  componentDidMount = () => {
    /*getAllRoles()
			.then((res) => {
				this.setState(state => ({ ...state, allRoles: res.data }))
			})*/
    // getCellGetAll()
    // 	.then(res => {
    // 		if (res.ok) {
    // 			let allCells = {}
    // 			res.data.forEach(item => {
    // 				allCells[item['id']] = item['name']
    // 			})
    // 			this.setState(state => ({
    // 				...state,
    // 				allCells
    // 			}))
    // 		}
    // 	})
  };

  passwordHandleClick = () =>
    this.setState(({ type }) => ({
      type: type === "password" ? "text" : "password",
    }));

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  checkAllFieldsIsValid = () => {
    const {
      email,
      name,
      password,
      // role,
      cell,
      data,
      enable,
    } = this.state;
    let isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
    let isEmailUnique = true;
    let isPasswordValid = password !== undefined && password !== "";
    /*let isRoleValid = role !== undefined && role !== '';*/
    let isCellValid = cell !== undefined && cell !== "";
    let isNameValid = name !== undefined && name !== "";
    let isEnableValid = data !== undefined ? enable !== undefined : true;

    getAllUser().then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].email === email) {
          isEmailUnique = false;
          break;
        }
      }
    });
    this.setState((state) => ({
      ...state,
      validFields: {
        ...this.state.validFields,
        isEmailValid,
        /*isRoleValid,*/
        isCellValid,
        isEmailUnique,
        isPasswordValid,
        isNameValid,
        isEnableValid,
      },
    }));
  };

  isEmailValid = () => {
    const { email } = this.state;
    let isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
    getAllUser().then((res) => {
      let isEmailUnique = true;
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].email === email) {
          isEmailUnique = false;
          break;
        }
      }
      this.setState((state) => ({
        ...state,
        validFields: { ...this.state.validFields, isEmailValid, isEmailUnique },
      }));
    });
  };

  isPasswordValid = () => {
    const { password } = this.state;
    let isPasswordValid = password !== undefined && password !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isPasswordValid },
    }));
  };

  isNameValid = () => {
    const { name } = this.state;
    let isNameValid = name !== undefined && name !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isNameValid },
    }));
  };

  /*isRoleValid = () => {
		const { role } = this.state
		let isRoleValid = role !== undefined && role !== ''
		this.setState(state => ({ ...state, validFields: { ...this.state.validFields, isRoleValid } }))
	}*/

  isEnableValid = () => {
    const { enable, data } = this.state;
    let isEnableValid = data !== undefined ? enable !== undefined : true;
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isEnableValid },
    }));
  };

  isCPFValid = () => {
    const { cpf, validFields } = this.state;
    let isCPFValid = cpf !== undefined && this.validateCPF(cpf);
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isCPFValid },
    }));
  };

  validateCPF = (cpf) => {
    cpf = cpf.replace(/\./g, "");
    cpf = cpf.replace(/-/g, "");

    let ar = cpf.split("");
    let count = 0;
    let sum = 0;
    for (let i = 10; i >= 2; i--) {
      sum += parseInt(ar[count]) * i;
      count++;
    }
    let restoPrimeiro = sum % 11;
    restoPrimeiro < 2
      ? (restoPrimeiro = 0)
      : (restoPrimeiro = 11 - restoPrimeiro);
    let primeiroDigito = restoPrimeiro === parseInt(ar[9]);
    count = 0;
    sum = 0;
    for (let i = 11; i >= 2; i--) {
      sum += parseInt(ar[count]) * i;
      count++;
    }
    let restoSegundo = sum % 11;
    restoSegundo < 2 ? (restoSegundo = 0) : (restoSegundo = 11 - restoSegundo);
    let segundoDigito = restoSegundo === parseInt(ar[10]);
    return primeiroDigito && segundoDigito;
  };

  isBtnDisable = (_) => {
    const { isEmailValid, isPasswordValid, isNameValid, isCPFValid } =
      this.state.validFields;
    return !isEmailValid || !isPasswordValid || !isNameValid || !isCPFValid;
  };

  submit = () => {
    const {
      email,
      password,
      name,
      cpf,
      // cell, isAdmin,
      validFields,
    } = this.state;
    const { data, cellId } = this.props;

    this.setState(
      (state) => ({ ...state, disabledSubmitBtn: true }),
      () => {
        if (data === undefined) {
          if (
            validFields.isNameValid &&
            validFields.isEmailUnique &&
            validFields.isEmailValid &&
            validFields.isPasswordValid
          ) {
            let v = {
              cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
              password: password,
              isAdmin: false,
              isCustomer: false,
              isOutsource: false,
              isAccredited: false,
              name: name,
              email: email,
              // 'role': role,
              cell: cellId,
            };

            insertUser(v)
              .then((res) => {
                if (res.ok) {
                  this.props.onHide(true);
                  this.props.onAddAlert(
                    "Usuário cadastrado.",
                    "success",
                    false
                  );
                } else {
                  this.setState((state) => ({
                    ...state,
                    disabledSubmitBtn: false,
                  }));
                }
              })
              .catch(() => {
                this.setState((state) => ({
                  ...state,
                  disabledSubmitBtn: false,
                }));
              });
          } else {
            this.props.onAddAlert(
              "Todos os campos devem ser preenchidos corretamente.",
              "danger",
              false
            );
            this.setState((state) => ({ ...state, disabledSubmitBtn: false }));
          }
        }
      }
    );
  };

  render() {
    const {
      email,
      password,
      name,
      cpf,
      // role,
      // cell,
      validFields,
      // allRoles,
      allCells,
      disabledSubmitBtn,
      enable,
      type,
      // isAdmin,
      disabledField,
    } = this.state;
    const { show, onHide, data, cellId } = this.props;

    const cpfValidate = cpf.replace(/_/g, "");
    return (
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="md"
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>
                CPF <Form.Label className="is-required">*</Form.Label>
              </Form.Label>
              <InputMask
                mask="999.999.999-99"
                value={cpf}
                onChange={this.handleInputChange}
                onBlur={() => this.isCPFValid()}
                placeholder="123.123.123-12"
                disabled={this.props.data !== undefined}
              >
                <Form.Control
                  type="text"
                  name="cpf"
                  className={
                    validFields.isCPFValid === undefined
                      ? ""
                      : validFields.isCPFValid
                      ? "is-valid"
                      : "is-invalid"
                  }
                />
              </InputMask>
              <Form.Text className="is-required">
                {validFields.isCPFValid === undefined
                  ? ""
                  : validFields.isCPFValid
                  ? ""
                  : cpfValidate.length === 14
                  ? "O CPF informado é invalido."
                  : "O campo CPF tem que conter 11 números e não pode estar vazio."}
              </Form.Text>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  placeholder="teste@gmail.com"
                  onChange={this.handleInputChange}
                  onBlur={() => {
                    this.isEmailValid();
                  }}
                  className={
                    data === undefined
                      ? validFields.isEmailUnique === undefined ||
                        validFields.isEmailValid === undefined
                        ? ""
                        : validFields.isEmailValid
                        ? validFields.isEmailUnique
                          ? "is-valid"
                          : "is-invalid"
                        : "is-invalid"
                      : ""
                  }
                  disabled={data !== undefined}
                />
                <Form.Text className="is-required">
                  {data === undefined
                    ? validFields.isEmailUnique !== undefined
                      ? validFields.isEmailValid
                        ? validFields.isEmailUnique
                          ? ""
                          : "E-mail já cadastrado, por favor, ensira outro e-mail"
                        : "O campo e-mail deve ser conter um e-mail válido"
                      : ""
                    : ""}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  onBlur={() => this.isNameValid()}
                  className={
                    validFields.isNameValid === undefined
                      ? ""
                      : validFields.isNameValid
                      ? "is-valid"
                      : "is-invalid"
                  }
                  disabled={disabledField}
                />
                <Form.Text className="is-required">
                  {validFields.isNameValid === undefined
                    ? ""
                    : validFields.isNameValid
                    ? ""
                    : "O campo nome não deve ser vazio"}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          {data === undefined && (
            <Row className="mt-1">
              <Col>
                <Form.Label>Senha</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={type}
                    name="password"
                    value={password}
                    placeholder="senha"
                    onChange={this.handleInputChange}
                    onBlur={() => {
                      this.isPasswordValid();
                    }}
                    className={
                      validFields.isPasswordValid !== undefined
                        ? validFields.isPasswordValid
                          ? "is-valid"
                          : "is-invalid"
                        : ""
                    }
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={this.passwordHandleClick}
                    >
                      {type === "password" ? (
                        <i className="fas fa-eye" />
                      ) : (
                        <i className="fas fa-eye-slash" />
                      )}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Text className="is-required">
                  {validFields.isPasswordValid !== undefined
                    ? validFields.isPasswordValid
                      ? ""
                      : "O campo senha não pode ser vazio"
                    : ""}
                </Form.Text>
              </Col>
            </Row>
          )}
          {/*<Row className="mt-1">
						<Col>
							<Form.Group>
								<Form.Label>Papel</Form.Label>
								<Form.Control as="select"
											  value={role}
											  name="role"
											  onChange={this.handleInputChange}
											  onBlur={() => this.isRoleValid()}
											  className={validFields.isRoleValid === undefined ? '' :
												  validFields.isRoleValid ? 'is-valid' : 'is-invalid'}
											  disabled={disabledField}
								>
									<option></option>
									{
										allRoles && allRoles.map(value => {
											return <option key={value.id} value={value.id}>{value.name}</option>
										})
									}
								</Form.Control>
								<Form.Text className="is-required">
									{
										validFields.isRoleValid === undefined ? '' :
											validFields.isRoleValid ? '' : 'O campo papel deve conter um valor que não seja vazio.'
									}
								</Form.Text>
							</Form.Group>
						</Col>
					</Row>*/}

          <Row className="mt-1">
            <Col>
              <Form.Group>
                <Form.Label>Célula</Form.Label>
                <Form.Control
                  value={allCells.length === 0 ? "" : allCells[cellId]}
                  readOnly={true}
                  className={"is-valid"}
                />
              </Form.Group>
            </Col>
          </Row>

          {data !== undefined && (
            <div>
              <Row>
                <Col className="text-left d-flex">
                  <Form.Label>Status</Form.Label>

                  <Form.Check
                    className="ml-3"
                    label="Ativo"
                    name="enable"
                    value={true}
                    onChange={() =>
                      this.setState((state) => ({ ...state, enable: true }))
                    }
                    onBlur={() => this.isEnableValid()}
                    id="radio1"
                    checked={enable === true}
                    disabled={disabledField}
                  />

                  <Form.Check
                    className="ml-3"
                    label="Inativo"
                    name="enable"
                    value={false}
                    onChange={() =>
                      this.setState((state) => ({ ...state, enable: false }))
                    }
                    onBlur={() => this.isEnableValid()}
                    id="radio2"
                    checked={enable === false}
                    disabled={disabledField}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Col className=" p-0">
              <Button
                variant="primary"
                className="w-100 modal-user-button-without-rounded"
                onClick={() => {
                  this.submit();
                }}
                disabled={this.isBtnDisable() || disabledSubmitBtn}
              >
                {disabledSubmitBtn ? "Adicionando ..." : "Adicionar"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalUserCell);
