import React from "react";
import { Row } from "react-bootstrap";
import { CheckboxGroup } from "../../../genericComponents";
import { contactNames } from "../models/formArrayNames";

function CustomerSurvey14() {
  return (
    <Row>
      <CheckboxGroup
        name="contactWay"
        label="Quais os métodos preferenciais para entrarmos em contato?"
        options={contactNames.map((name) => ({
          label: name,
          value: name,
          checked: true,
        }))}
      />
    </Row>
  );
}

export default React.memo(CustomerSurvey14);
