import { FormFull } from "form-full";
import React from "react";
import { Form, Modal, Row } from "react-bootstrap";
import { FormButton, Input } from "../../../genericComponents";
import { postUpdatePaymentMethod } from "../../../services/RequestService";

function ModalPaymentMethod({ show, onHide, paymentMethod }) {
  function submit({ parcels }) {
    postUpdatePaymentMethod({ id: paymentMethod.id, parcels })
      .then((res) => onHide(true))
      .catch((e) => {
        // TODO error treatment
        console.log(e);
      });
  }

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <FormFull onSubmit={submit} actualValues={paymentMethod}>
        <Modal.Header closeButton>
          <Modal.Title>Método de Pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Input
              label="Método"
              name="name"
              disabled
              required="Campo obrigatório"
            />
          </Form.Row>

          <Form.Row>
            <Input
              label="Número de Parcelas"
              name="parcels"
              required="Campo obrigatório"
            />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <FormButton
              name="submit"
              actionType="submit"
              className="w-100 button-without-rounded"
            >
              Atualizar
            </FormButton>
          </Row>
        </Modal.Footer>
      </FormFull>
    </Modal>
  );
}

export default ModalPaymentMethod;
