import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  postPauseProcess,
  postResumeProcess,
} from "../../../../services/RequestService";
import { alertActions } from "../../../../store/actions/AlertActions";
import { connect } from "react-redux";

class ModalPauseOrResumeProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processStatus: undefined,
      isProcessStatusValid: undefined,
    };
  }

  handleChangeProcessStatus = (e) => {
    e.persist();
    this.setState((state) => ({
      ...state,
      processStatus: e.target.value,
    }));
  };

  isProcessStatusValid = () => {
    const { processStatus } = this.state;
    this.setState({ isProcessStatusValid: processStatus !== "" });
  };

  submit = () => {
    const { processStatus, isProcessStatusValid } = this.state;
    const { processId, allProcessStatus, paused, onHide } = this.props;
    if (isProcessStatusValid) {
      let processStatusId = allProcessStatus[processStatus];
      if (!paused) {
        postPauseProcess({ processId, processStatusId }).then((res) => {
          if (res.ok) {
            this.props.onAddAlert("Processo pausado.", "success", false);
            onHide();
          } else {
            this.props.onAddAlert(
              "Houve um erro ao tentar pausar o processo.",
              "danger",
              false
            );
            onHide();
          }
        });
      } else {
        postResumeProcess({ processId, processStatusId }).then((res) => {
          if (res.ok) {
            this.props.onAddAlert("Processo retomado.", "success", false);
            onHide();
          } else {
            this.props.onAddAlert(
              "Houve um erro ao tentar retomar o processo.",
              "danger",
              false
            );
            onHide();
          }
        });
      }
    } else {
      this.props.onAddAlert(
        "Todos os campos devem ser preenchidos.",
        "danger",
        false
      );
      this.setState({ isProcessStatusValid: false });
    }
  };

  render() {
    const { processStatus, isProcessStatusValid } = this.state;
    const { show, onHide, allProcessStatus, paused } = this.props;
    return (
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header>
          <Modal.Title>
            {(paused ? "Retomar" : "Pausar") + " processo"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group>
              <Form.Label>
                Selecione um status
                <Form.Label className="is-required">*</Form.Label>
              </Form.Label>
              <Form.Control
                as="select"
                name="processStatus"
                value={processStatus}
                size="sm"
                onChange={this.handleChangeProcessStatus}
                onBlur={this.isProcessStatusValid}
                className={
                  isProcessStatusValid === undefined
                    ? ""
                    : isProcessStatusValid
                    ? "is-valid"
                    : "is-invalid"
                }
              >
                <option />
                {Object.keys(allProcessStatus).map((value, key) => {
                  return <option key={key}>{value}</option>;
                })}
              </Form.Control>
              {isProcessStatusValid === false && (
                <Form.Label className="is-required">
                  {" "}
                  É preciso selecionar um status
                </Form.Label>
              )}
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="secondary" onClick={() => onHide(false)}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                // disabled={}
                onClick={this.submit}
              >
                {paused ? "Retomar" : "Pausar"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalPauseOrResumeProcess);
