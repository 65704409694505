async function caller(methodFunc, endpoint, body, axiosConfig) {
  const response = await methodFunc(endpoint, body, axiosConfig);
  if (response.ok) {
    return response;
  } else {
    const { status, config } = response;
    const { baseURL, url, method, data, params, headers } = config;
    throw Object.assign(new Error("error"), {
      message: `Error on endpoint: "${baseURL}${url};\nMethod: ${method};\nStatus: ${status}\n\n;Headers: ${
        headers ? JSON.stringify(headers) : "none"
      };\n\nData: ${data ? JSON.stringify(data) : "none"};\n\nParams: ${
        params ? JSON.stringify(params) : "none"
      }"`,
      ...response,
      errorType: endpoint,
    });
  }
}

export default caller;
