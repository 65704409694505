import React, { Component } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import "./InputAutoCompleteComp.css";

class InputAutoCompleteGeneric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      element: "",
      showList: false,
      isValid: undefined,
    };
  }

  componentDidMount = () => {
    let element = this.props.id
      ? this.props.list.find((element) => element.id === this.props.id)?.name
      : "";
    this.setState({
      isValid: element !== "" ? element !== "" : undefined,
      element,
    });
  };

  componentDidUpdate = () => {
    const { id, list } = this.props;
    let { element } = this.state;
    element = id ? list.find((element) => element.id === id)?.name : "";
    if (this.state.element !== element && this.state.element === "") {
      this.setState({
        isValid: element !== "" ? element !== "" : undefined,
        element,
      });
    }
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
      isValid: false,
    }));
    this.props.elementFunction(null);
  };

  returnElement = (e) => {
    this.setState({ element: e.name, showList: false, isValid: e.name !== "" });
    this.props.elementFunction(e.id, e.name !== "");
  };

  render() {
    const { element, showList, isValid } = this.state;
    const { field, list, placeholderText } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <InputGroup>
              <Form.Control
                placeholder={placeholderText}
                name="element"
                aria-disabled={true}
                value={element}
                onChange={this.handleInputChange}
                autoComplete="off"
                as="input"
                onFocus={() => this.setState({ showList: true })}
                className={
                  isValid === undefined
                    ? ""
                    : isValid
                    ? "is-valid"
                    : "is-invalid"
                }
              />
              <InputGroup.Text>
                <div className="fas fa-search"></div>
              </InputGroup.Text>
            </InputGroup>

            {showList ? (
              list.filter((e) =>
                e[field]?.toUpperCase().includes(element.toUpperCase())
              ).length > 0 ? (
                <Row>
                  <Col className="input-auto-complete-box-list clickable">
                    {list
                      .filter((e) =>
                        e[field].toUpperCase().includes(element.toUpperCase())
                      )
                      .map((value, key) => {
                        return (
                          <Row
                            key={key}
                            onClick={() => this.returnElement(value)}
                          >
                            <Col>{value[field]}</Col>
                          </Row>
                        );
                      })}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col className="input-auto-complete-box-list">
                    Valor digitado não encontrado
                  </Col>
                </Row>
              )
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default InputAutoCompleteGeneric;
