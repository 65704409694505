import React from "react";
import { Row } from "react-bootstrap";
import { Input } from "../../../genericComponents";

function CustomerSurvey7() {
  return (
    <Row>
      <Input
        label="Trabalhou, antes de 28/04/1995, em quais profissões e atividades?"
        name="beforeAprilNineFive"
        maxLength={200}
      />
    </Row>
  );
}

export default React.memo(CustomerSurvey7);
