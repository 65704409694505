import React from "react";
import { Row } from "react-bootstrap";
import { InputPassword } from "../../../genericComponents";

function BasicData7({ id, disabled, isCollaborator }) {
  return id === undefined && !isCollaborator ? (
    <Row>
      <InputPassword
        colProps={{ xs: 12, md: 6 }}
        label="Senha"
        name="password"
        maxLength={20}
        inputMaskProps={{ placeholder: "Senha" }}
        required='O campo "Senha" não pode ser vazio.'
        disabled={disabled}
        onBlur={(event, value, ffHandler) => {
          if (ffHandler.getValue("confirmPassword")) {
            ffHandler.testFieldError("confirmPassword", true);
          }
        }}
      />
      <InputPassword
        colProps={{ xs: 12, md: 6 }}
        label="Confirmar senha"
        name="confirmPassword"
        maxLength={20}
        inputMaskProps={{ placeholder: "Senha" }}
        required='O campo "Confirmar senha" deve ser preenchido e igual ao campo "Senha".'
        disabled={disabled}
        validation={(value, ffHandler) => {
          const isValid = ffHandler.getValue("password") === value;
          return isValid ? null : "Os valores de senha não conferem.";
        }}
      />
    </Row>
  ) : null;
}

export default React.memo(BasicData7);
