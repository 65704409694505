import React from "react";
import { Table } from "react-bootstrap";

export default function ReportTableFooter({ tableData, filterType }) {
  return (
    <div>
      <Table
        striped
        bordered
        hover
        className="table-bordered bms-color-text"
        responsive
      >
        <thead>
          <tr>
            <td>
              <b>Total de Registros</b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{tableData.length}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
