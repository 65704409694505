import {
  // getAllPaymentMethods,
  getAllUser,
} from "../../../../services/RequestService";

export function modalPartnerMount(props, setState) {
  const { globalPrivileges, privileges, data, isIntern } = props;

  const disabledField = !data
    ? !globalPrivileges["configuracoes/parceiros"].create
    : !globalPrivileges["configuracoes/parceiros"].update;

  let ticketParcels = [];
  let creditCardParcels = [];
  for (let i = 1; i <= 10; i++) {
    ticketParcels.push(i);
    creditCardParcels.push(i);
  }
  setState({
    ticketParcels,
    creditCardParcels,
  });

  getAllUser().then((res) => {
    setState({ allUsers: res.data });
  });
  if (props?.data?.beginDate && props?.data?.endDate) {
    let beginDate = props?.data?.beginDate?.split("-");
    beginDate =
      beginDate[2].slice(0, 2) + "/" + beginDate[1] + "/" + beginDate[0];
    let endDate = props?.data?.endDate?.split("-");
    endDate = endDate[2].slice(0, 2) + "/" + endDate[1] + "/" + endDate[0];
    setState({ beginDate, endDate });
  }

  if (privileges) {
    let auxPrivileges = {};
    Object.values(privileges).forEach((element) => {
      // uma célula neta não pode ter permissões de parceiro
      if (isIntern) {
        if (element.name !== "configuracoes/celulas") {
          auxPrivileges[element.name] = {
            name: element.name,
            privilege: element.privilege,
            insert: element.insert === false ? null : true,
            read: element.read === false ? null : true,
            delete: element.delete === false ? null : true,
            update: element.update === false ? null : true,
          };
        }
      }
      // um grupo de trabalho não pode ter as permissões de célula.
      else if (
        element.name !== "configuracoes/celulas" &&
        element.name !== "configuracoes/parceiros"
      ) {
        auxPrivileges[element.name] = {
          name: element.name,
          privilege: element.privilege,
          insert: element.insert === false ? null : true,
          read: element.read === false ? null : true,
          delete: element.delete === false ? null : true,
          update: element.update === false ? null : true,
        };
      }
    });
    setState({
      privileges: auxPrivileges,
      disabledField,
    });
  } else {
    setState({
      disabledField,
    });
  }
}
