import PDFViewer from '../../genericComponents/containers/PDFViewer'
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

export default class ModalSeeSeemDocument extends Component {

    render() {

        const { doc, show, onHide } = this.props

        return (
            <Modal show={show} onHide={onHide} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{doc.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PDFViewer document={{ url: "data:" + doc.type + ";base64," + doc.document }} />
                </Modal.Body>
            </Modal>
        )
    }
}
