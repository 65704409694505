import React from "react";
import { Row } from "react-bootstrap";
import { Header, PageContainer } from "../../../../genericComponents";
import { useGeneralPanelMount } from "../../controllers";

import {
  FifthGroup,
  FirstGroup,
  FourthGroup,
  SecondGroup,
  SeventhGroup,
  SixthGroup,
  ThirdGroup,
} from "../presentations";

function GeneralPanelPage({ history }) {
  useGeneralPanelMount();
  return (
    <>
      <Header active="Painel Geral" logged />
      <PageContainer withoutCard>
        <Row className="m-n2">
          {/* Cadastros */}
          <FirstGroup colProps={{ lg: 12, xl: 6 }} />
          {/* Entrada de clientes */}
          <SecondGroup colProps={{ lg: 12, xl: 6 }} />
          {/* Propostas */}
          <ThirdGroup colProps={{ lg: 12, xl: 4 }} />
          {/* Contratos */}
          <FourthGroup colProps={{ lg: 12, xl: 4 }} />
          {/* Recebíveis */}
          <FifthGroup colProps={{ lg: 12, xl: 4 }} history={history} />
          {/* Agenda */}
          <SixthGroup colProps={{ lg: 12, xl: 4 }} />
          {/* Produtos Comercializados */}
          <SeventhGroup colProps={{ lg: 12, xl: 8 }} />
        </Row>
      </PageContainer>
    </>
  );
}

export default GeneralPanelPage;
