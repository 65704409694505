import React, { useEffect, useState, useImperativeHandle } from "react";
import { Loading, Button, Select, Input, Card, Table, Layout } from "element-react";
import { Header } from "../../genericComponents";
import DinamicFilter from "./dinamic-filter/DinamicFilter";
import "./css-dinamic-filter.css";

function DataTableDinamicFilter({ fields, columns, ajaxData, onSelect, actions, pageTile, pageForm }, ref) {
  document.querySelectorAll('.el-table .cell').forEach(e => e.style.wordBreak = 'break-word')

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState({
    list: [],
    filters: [],
    totalRecords: 0,
    totalPages: 0,
    limit: 20,
    page: 1,
    offset: 0,
  });

  useImperativeHandle(ref, () => ({
    refreshDataTable() {
      refresh();
    },
  }));

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refresh(pageParams) {
    let p = page;
    if (pageParams) {
      p = Object.assign(page, pageParams);
    }
    p.offset = (p.page - 1) * p.limit;

    const params = {
      limit: p.limit,
      offset: p.offset,
      filters: p.filters,
    };
    setLoading(true)
    ajaxData(params).then((result) => {
      p.list = result.data?.records || [];
      p.totalRecords = result.data?.totalRecords;
      p.totalPages = Math.ceil(
        (result.data?.totalRecords || 1) / p.limit
      );
      setPage(Object.create(p));
      setLoading(false)
    }).catch(e => alert(e));
  }

  return (
    <Loading loading={loading}>
      <Header active={pageTile} logged />
      <div style={{ height: window.innerHeight - 100 + "px", overflowX: "auto" }}>
        <div>
          {pageForm && (
            <Card style={{ margin: '15px' }} className="dinamic-filter box-card" header={(<div className="clearfix" >Inserir/Alterar</div>)}>
              {pageForm}
            </Card>
          )}
          <DinamicFilter fields={fields} filterFn={(f) => refresh({ filters: f || [] })} actions={actions} afterClearFilterFn={() => refresh({ page: 1, filters: [] })} />
          <Card className="box-card" style={{ margin: '15px' }}>
            <div id="ds-data-table">
              <div>
                {window.innerWidth > 991 ? (
                  <Table style={{ width: '100%' }} height={600} stripe={true}
                    columns={columns}
                    emptyText="Não há dados"
                    data={page.list}
                    onSelectChange={(selection) => { if (onSelect) onSelect(selection) }}
                    onSelectAll={(selection) => { if (onSelect) onSelect(selection) }}
                  />
                ) : (
                  <table className="mobile-table el-table">
                    <tbody >
                      {page.list.map((row, idx) => (
                        <React.Fragment key={idx}>
                          {columns.map((col, index) => (
                            <tr key={index}>
                              <th>{col.label}</th>
                              <td>{col.render ? (<div className="cell">{col.render(row)}</div>) : (<div className="cell">{row[col.prop]}</div>)}</td>
                            </tr>
                          ))}
                          <tr><td colSpan={2}>&nbsp;</td></tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <br />
              <Layout.Row gutter="20" className="dinamic-filter-paginator">
                <Layout.Col xs={24} sm={8} md={4}>
                  <label>Registros por página:</label>
                  <Select value={page.limit} style={{ width: "65px" }} size="small" onChange={(limit) => refresh({ limit })}>
                    {[20, 50, 100, 200, 500, 1000].map((el, key) => (<Select.Option key={key} label={el} value={el} />))}
                  </Select>
                </Layout.Col>
                <Layout.Col xs={24} sm={6} md={3}>
                  <label>Página:</label>
                  <Input value={page.page} size="small" style={{ width: "100px" }} onChange={(page) => refresh({ page })} />
                </Layout.Col>
                <Layout.Col xs={24} sm={10} md={6}>
                  <Button type="primary" icon="d-arrow-left" size="small" disabled={page.page <= 1} onClick={() => refresh({ page: 1 })} />
                  <Button type="primary" icon="arrow-left" size="small" disabled={page.page <= 1} onClick={() => refresh({ page: page.page - 1 })} />
                  <Button type="primary" icon="arrow-right" size="small" disabled={page.page >= page.totalPages} onClick={() => refresh({ page: page.page + 1 })} />
                  <Button type="primary" icon="d-arrow-right" size="small" disabled={page.page >= page.totalPages} onClick={() => refresh({ page: page.totalPages })} />
                  <Button type="primary" size="small" onClick={refresh}>{"Atualizar"}</Button>
                </Layout.Col>
                <Layout.Col xs={24} sm={24} md={11} style={{ textAlign: 'right' }}>
                  <label>Exibindo {page.offset + 1} -{" "}
                    {page.limit > page.totalRecords ? page.totalRecords : page.limit} de{" "}
                    {page.totalRecords} registro(s)</label>
                </Layout.Col>
              </Layout.Row>
            </div>
          </Card>
        </div>
      </div>
    </Loading>
  );
}

export default React.forwardRef(DataTableDinamicFilter);
