import React, { Component } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import {
  getDocumentsByProcessId,
  putDocument,
} from "../../../../services/RequestService";
import FileBase64 from "react-file-base64";
import { connect } from "react-redux";
import { alertActions } from "../../../../store/actions/AlertActions";
import { Table } from "react-bootstrap";
import ModalAttachmentDocumentDelete from "./ModalAttachmentDocumentDelete.js";
import masks from "../../../../utils/masks";
export class ModalInsertAttachmentDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base64: "",
      filename: "",
      extension: "",
      documentsType: this.props.documentsType,
      documentChoice: "",
      documents: [],
      file: {},
      isFilenameValid: undefined,
      isDocumentChoiceValid: undefined,
      btnDisabled: true,
      showModalAttachmentDocumentDelete: false,
      selectedDocument: null,
    };
  }

  componentDidMount = () => {
    getDocumentsByProcessId({ processId: this.props.processId }).then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, documents: res.data }));
      }
    });
  };

  handleChooseDoc = (e) => {
    let nameSplit = e.name.split(".");
    let name = "";

    for (let i = 0; i < nameSplit.length - 1; i++) {
      name = name + nameSplit[i];
    }

    let type = nameSplit[nameSplit.length - 1];
    if (type === "pdf" || type === "jpg" || type === "png") {
      this.setState((state) => ({
        ...state,
        base64: e.base64,
        filename: name,
        extension: nameSplit[nameSplit.length - 1],
        isFilenameValid: name !== "",
      }));
    } else {
      this.props.onAddAlert(
        "Arquivo não suportado. Arquivos com formato pdf, png, jpg são os unicos aceitos",
        "danger",
        true
      );
    }
  };

  handleInput = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  isFilenameValid = () => {
    const { filename, isDocumentChoiceValid } = this.state;
    const isFilenameValid = filename !== "";
    this.setState((state) => ({
      ...state,
      isFilenameValid,
      btnDisabled: this.checkBtnDisabled(
        isFilenameValid,
        isDocumentChoiceValid
      ),
    }));
  };

  isDocumentChoiceValid = () => {
    const { documentChoice, isFilenameValid } = this.state;
    const isDocumentChoiceValid = documentChoice !== "";
    this.setState((state) => ({
      ...state,
      isDocumentChoiceValid,
      btnDisabled: this.checkBtnDisabled(
        isFilenameValid,
        isDocumentChoiceValid
      ),
    }));
  };

  validFields = () => {
    const { base64, filename, documentChoice } = this.state;
    let valid = base64 !== "" && filename !== "" && documentChoice !== "";
    return valid;
  };

  sendFile = () => {
    if (this.validFields) {
      const { base64, filename, extension, documentChoice, documentsType } =
        this.state;
      const { processId } = this.props;
      let documentTypeId = "";
      documentsType.forEach((dt) => {
        if (dt.name === documentChoice) {
          documentTypeId = dt.id;
        }
      });
      let data = {
        name: filename,
        extension: extension,
        documentTypeId: documentTypeId,
        virtualFile: base64,
        // physicLocation: "",
        processId: processId,
      };
      putDocument(data).then((res) => {
        if (res.ok) {
          this.componentDidMount();
          this.props.onAddAlert(
            "Arquivo adicionado com sucesso",
            "success",
            true
          );
        } else {
          this.props.onAddAlert(
            "Houve algum erro ao tentar enviar o arquivo",
            "danger",
            true
          );
        }
      });
    }
  };

  setShowModalAttachmentDocumentDelete = (
    showModalAttachmentDocumentDelete,
    selectedDocument,
    wasUpdated
  ) => {
    this.setState({
      showModalAttachmentDocumentDelete,
      selectedDocument,
    });
    if (!showModalAttachmentDocumentDelete && wasUpdated) {
      this.componentDidMount();
    }
  };

  checkBtnDisabled = (isFilenameValid, isDocumentChoiceValid) => {
    return !(isFilenameValid && isDocumentChoiceValid);
  };

  render() {
    const { show, onHide } = this.props;
    const {
      documentsType,
      documentChoice,
      filename,
      documents,
      isFilenameValid,
      isDocumentChoiceValid,
      btnDisabled,
      showModalAttachmentDocumentDelete,
      selectedDocument,
    } = this.state;
    return (
      <Modal show={show} onHide={onHide} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar documentos do anexo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Escolha um documento</Form.Label>
                <br />
                <FileBase64
                  multiple={false}
                  onDone={this.handleChooseDoc.bind(this)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Row>
                <Form.Group>
                  <Form.Label>
                    Nome do documento{" "}
                    <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={filename}
                    name={"filename"}
                    onChange={this.handleInput}
                    onBlur={() => this.isFilenameValid()}
                    className={
                      isFilenameValid === undefined
                        ? ""
                        : isFilenameValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  />
                  <Form.Text className="is-required">
                    {isFilenameValid === undefined
                      ? ""
                      : isFilenameValid
                      ? ""
                      : "O nome do arquivo não pode ser vazio"}
                  </Form.Text>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group>
                  <Form.Label>
                    Tipo do documento{" "}
                    <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    name="documentChoice"
                    value={documentChoice}
                    as="select"
                    size="sm"
                    onChange={this.handleInput}
                    onBlur={() => this.isDocumentChoiceValid()}
                    className={
                      isDocumentChoiceValid === undefined
                        ? ""
                        : isDocumentChoiceValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  >
                    <option />
                    {documentsType
                      ? documentsType.map((value) => {
                          return <option key={value.id}>{value.name}</option>;
                        })
                      : null}
                  </Form.Control>
                  <Form.Text className="is-required">
                    {isDocumentChoiceValid === undefined
                      ? ""
                      : isDocumentChoiceValid
                      ? ""
                      : "O tipo do documento deve ser selecionado"}
                  </Form.Text>
                </Form.Group>
              </Row>
              <Row>
                <Col />
                <Col>
                  <Button onClick={this.sendFile} disabled={btnDisabled}>
                    Adicionar arquivo
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className={"table-boleto-size"}>
            <Table className={"table-responsible"}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Extensão</th>
                  <th>Data</th>
                  <th>Download</th>
                  <th>Remover</th>
                </tr>
              </thead>
              <tbody>
                {documents &&
                  documents.map((t, key) => {
                    return (
                      <tr key={key}>
                        <td>{t.id}</td>
                        <td>{t.name}</td>
                        <td>{t.documentType && t.documentType.name}</td>
                        <td>{masks.convertDateTimeToBr(t.createdAt)}</td>
                        <td>
                          <a
                            href={t.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className={"fas fa-download clickable "} />
                          </a>
                        </td>
                        <td>
                          <i
                            className="fas fa-trash-alt is-required clickable"
                            onClick={() =>
                              this.setShowModalAttachmentDocumentDelete(true, t)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          {showModalAttachmentDocumentDelete && (
            <ModalAttachmentDocumentDelete
              show={showModalAttachmentDocumentDelete}
              onHide={(wasUpdated) =>
                this.setShowModalAttachmentDocumentDelete(
                  false,
                  null,
                  wasUpdated
                )
              }
              document={selectedDocument}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};
export default connect(null, mapDispatchToProps)(ModalInsertAttachmentDocument);
