import React, { Component } from "react";
import { Button, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../login/LoginPage.css";
import logo from "../../res/LogoQualitte.png";
import { connect } from "react-redux";
import { alertActions } from "../../store/actions/AlertActions";
import { InputPassword } from "../../genericComponents";
import {
  newPasswordValidation,
  passwordValidation,
  submitNewPassword,
} from "./controllers/PasswordResetConfirmationPageControllers";
import { Form } from "react-bootstrap";
import { FormFull } from "form-full";

class PasswordResetConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonLoading: false,
    };
  }

  render() {
    const { buttonLoading } = this.state;

    return (
      <div className="background-login">
        <div className="login-form w-100 h-100 d-flex">
          <div className="w-100 m-2">
            <Card className="login-form-wrapper">
              <div className="d-flex justify-content-center px-1 py-4 login-image-container">
                <Image
                  src={logo}
                  className="login-image on-load-image"
                  onLoad={(event) => {
                    event.target.style.opacity = 1;
                  }}
                />
              </div>
              <FormFull
                formRef={(ref) => (this.formRef = ref)}
                onSubmit={(data) =>
                  submitNewPassword(
                    data,
                    this.props,
                    this.state.buttonLoading,
                    (state, callback) => this.setState(state, callback)
                  )
                }
              >
                <Form.Row>
                  <InputPassword
                    inputMaskProps={{
                      placeholder: "Digite sua nova senha",
                    }}
                    name="password"
                    required="Campo obrigatório"
                    onBlur={passwordValidation}
                  />
                </Form.Row>
                <Form.Row>
                  <InputPassword
                    inputMaskProps={{
                      placeholder: "Digite sua nova senha",
                    }}
                    name="newPassword"
                    required="Campo obrigatório"
                    validation={newPasswordValidation}
                  />
                </Form.Row>
                <div className="pt-3">
                  <Button
                    className="btn-block"
                    onClick={() => this.formRef.submit()}
                    variant="primary"
                    type="submit"
                    disabled={buttonLoading}
                  >
                    {!buttonLoading ? (
                      "Confirmar"
                    ) : (
                      <div className="login-icon-size">
                        <i className="fas fa-circle-notch login-fa-spin-custom" />
                      </div>
                    )}
                  </Button>
                </div>
                <div className="d-flex justify-content-center pt-3">
                  <Link to="/">voltar</Link>
                </div>
              </FormFull>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(PasswordResetConfirmation);
