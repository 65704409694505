/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { TableComponent } from "../../../genericComponents";
import { getAllProcessesDocuments } from "../../../services/RequestService";

class CustomerDocumentComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: null,
      currentAllDocuments: [],
    };
    this.elementRef = React.createRef(null);
  }

  setList = (documents) => {
    return documents.map((item) => ({
      id: item.id,
      name: item.name,
      extension: item.extension,
      url: item.url,
    }));
  };

  componentDidMount = () => {
    getAllProcessesDocuments({ userId: this.props.id }).then((res) => {
      if (res.ok) {
        let documents = res.data.map((item) => ({
          id: item.id,
          name: item.name,
          extension: item.extension,
          url: item.url,
        }));
        this.setState({ documents });
      }
    });
  };

  render() {
    const { documents, currentAllDocuments } = this.state;
    return (
      <>
        <TableComponent
          countLabel="documentos"
          countLabelSingular="documento"
          header={[
            { label: "#", sortProperty: "id" },
            { label: "Nome", sortProperty: "name" },
            { label: "Extensão", sortProperty: "extension" },
            { label: "Download" },
          ]}
          currentList={currentAllDocuments}
          setCurrentList={(currentAllDocuments) =>
            this.setState({ currentAllDocuments })
          }
          list={documents}
          forEach={(item) => [
            { label: item.id },
            { label: item.name },
            { label: item.extension },
            {
              label: (
                <a
                  className="text-center"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={item.url}
                >
                  <i className="fas fa-download"></i>
                </a>
              ),
            },
          ]}
        />
        <a
          download
          ref={this.elementRef}
          style={{
            position: "absolute",
            top: -999999,
          }}
        />
      </>
    );
  }
}
export default CustomerDocumentComp;
