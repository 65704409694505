import React, { useState, useRef, useEffect } from "react";
import { Collapse, Loading, Form, Card, Select, Layout, Radio, Button, Input } from 'element-react'
import { useAlert } from "react-alert";
import { Header } from "../../../genericComponents";
import EmailEditor from 'react-email-editor'
import { getAttendancesListComboBox, getChannelsListComboBox, getMessageConfigId, getMessageConfigListComboBox, postMessageConfig, putMessageConfig } from "../../../services/RequestService";

export default function WideChatConfig() {
  const alert = useAlert();
  const formRef = useRef();
  const emailEditorRef = useRef(null);

  const rules = {
    templateName: [{ required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    whatsappTemplate: [{ required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    attendenceId: [{ required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    channelId: [{ required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    status: [{ type: 'enum', enum: ['ACTIVE', 'INACTIVE'], required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    shooting: [{ type: 'enum', enum: ['MANUAL', 'RADAR_MONTH', 'BIRTHDAY'], required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    closeSession: [{ type: 'integer', required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    emailSubject: [{ required: true, message: 'Campo Obrigatório', trigger: 'change' }]
  }
  const closeSessionList = [
    { value: 0, label: "A sessão continuará aberta" },
    { value: 1, label: "A sessão será finalizada" },
    { value: 2, label: "A sessão será transferida para a espera quando o contato" },
    { value: 3, label: "A sessão se manterá no status atual: se aberta, continuará aberta, se finalizada continuará finalizada e se em atendimento, continuará em atendimento" }
  ]
  const shootingList = [
    { value: 'MANUAL', label: "Manual" },
    { value: 'RADAR_MONTH', label: "Radar Mensal" },
    { value: 'BIRTHDAY', label: "Aniversário" }
  ]
  const modelForm = {
    id: null,
    templateName: null,
    shooting: null,
    status: null,
    channelId: null,
    attendenceId: null,
    whatsappTemplate: null,
    closeSession: null,
    emailSubject: null,
    emailBody: null,
    emailDesign: null,
  }

  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(Object.assign({}, modelForm));
  const [templates, setTemplates] = useState(null);
  const [wideChatChannel, setWideChatChannel] = useState([]);
  const [wideChatAttendances, setWideChatAttendances] = useState([]);

  useEffect(() => {
    if (templates === null) {
      setLoading(true)
      getMessageConfigListComboBox().then(({ data, ok }) => {
        if (ok) setTemplates(data)
        getAttendancesListComboBox().then(({ data, ok }) => {
          if (ok) setWideChatAttendances(data)
          getChannelsListComboBox().then(({ data, ok }) => {
            if (ok) setWideChatChannel(data)
            setLoading(false)
          })
        })
      })
    }
  }, [templates, setTemplates, setWideChatChannel, setWideChatAttendances]);


  function handleSubmit(e) {
    e.preventDefault();
    formRef.current.validate(async (valid) => {
      if (valid) {
        setLoading(true)
        emailEditorRef.current.editor.exportHtml(async ({ html, design }) => {
          form.emailBody = html
          form.emailDesign = JSON.stringify(design)

          if (!form.id) {
            const { data, ok } = await postMessageConfig(form)
            if (ok) {
              onChange('id', data.id)
              templates.push({ label: form.templateName, value: data.id })
              setTemplates(templates)
              alert.success('Layout adicionado com sucesso');
            } else {
              alert.error('Erro ao adicionar Layout, ' + data?.log);
            }
          } else {
            const { ok, data } = await putMessageConfig(form, form.id)
            if (ok) alert.success('Layout alterado com sucesso');
            else alert.error('Erro ao alterar Layout, ' + data?.log);
          }
          setLoading(false)
        })
      } else {
        return false;
      }
    })
  }

  function onChange(key, value) {
    setForm(Object.assign({}, form, { [key]: value }));
  }

  async function onFilter(key, id) {
    if (id) {
      setLoading(true)
      const { ok, data } = await getMessageConfigId(id)
      if (ok) setForm(data);
      setLoading(false)
    } else {
      clearForm()
    }
  }

  function clearForm() {
    setForm(Object.assign({}, modelForm))
    emailEditorRef.current.editor.loadBlank({ backgroundColor: '#e7e7e7' })
  }

  function onReadyEmailEditor() {
    emailEditorRef.current.editor.loadDesign(typeof form.emailDesign === 'string' ? JSON.parse(form.emailDesign) : form.emailDesign)
  }

  return (
    <Loading loading={loading}>
      <Header active="Configuração Wide Chat" logged />
      <div style={{ height: window.innerHeight - 100 + "px", overflowX: "auto" }}>
        <Form ref={formRef} model={form} rules={rules} labelPosition="top">
          <Card style={{ margin: '15px' }} className="box-card" header={(
            <Layout.Row gutter="20">
              <Layout.Col xs={24} sm={16} md={16} style={{ padding: '0' }}>Layout de Mensagens</Layout.Col>
              <Layout.Col xs={24} sm={16} md={8} style={{ padding: '0' }}>
                <Select value={form.id} placeholder="Filtrar" clearable={true} filterable={true} onChange={onFilter.bind(this, 'id')}>
                  {(templates || []).map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
                </Select>
              </Layout.Col>
            </Layout.Row>
          )}>
            <Layout.Row gutter="20">
              <Layout.Col xs={24} sm={12} md={6}>
                <Form.Item label="Nome do Template" prop="templateName">
                  <Input value={form.templateName} onChange={onChange.bind(this, 'templateName')} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={12} md={6}>
                <Form.Item label="Disparo" prop="shooting">
                  <Select value={form.shooting} placeholder="Selecione" clearable={true} filterable={true} onChange={onChange.bind(this, 'shooting')}>
                    {shootingList.map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={12} md={6}>
                <Form.Item label="Situação" prop="status">
                  <Radio.Group value={form.status} onChange={onChange.bind(this, 'status')}>
                    <Radio value="ACTIVE">Ativo</Radio>
                    <Radio value="INACTIVE">Inativo</Radio>
                  </Radio.Group>
                </Form.Item>
              </Layout.Col>

              <Layout.Col>
                <small><b>Wide Chat Whatsapp</b></small>
                <hr style={{ marginTop: '1px' }} />
              </Layout.Col>
              <Layout.Col xs={24} sm={12} md={6}>
                <Form.Item label="Nome do Template Wide Chat" prop="whatsappTemplate">
                  <Input value={form.whatsappTemplate} onChange={onChange.bind(this, 'whatsappTemplate')} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={12} md={6}>
                <Form.Item label="ID Canal" prop="channelId">
                  <Select value={form.channelId} placeholder="Filtrar" clearable={true} filterable={true} onChange={onChange.bind(this, 'channelId')}>
                    {wideChatChannel.map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={12} md={6}>
                <Form.Item label="ID Equipe" prop="attendenceId">
                  <Select value={form.attendenceId} placeholder="Filtrar" clearable={true} filterable={true} onChange={onChange.bind(this, 'attendenceId')}>
                    {wideChatAttendances.map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={12} md={6}>
                <Form.Item label="Ação após o envio da mensagem" prop="closeSession">
                  <Select value={form.closeSession} placeholder="Filtrar" clearable={true} filterable={true} onChange={onChange.bind(this, 'closeSession')}>
                    {closeSessionList.map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col>
                <small><b>E-mail</b></small>
                <hr style={{ marginTop: '1px' }} />
              </Layout.Col>
              <Layout.Col xs={24} sm={12} md={6}>
                <Form.Item label="Assunto" prop="emailSubject">
                  <Input value={form.emailSubject} onChange={onChange.bind(this, 'emailSubject')} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={24}>
                <Form.Item label="Corpo do E-mail" prop="emailBody">
                  <Collapse value="0" accordion>
                    <Collapse.Item title="Variáveis do Layout" name="1">
                      <pre>{`{{ idProcesso }}, {{ tipoProcesso }}, {{ statusProcesso }}, {{ idCliente }}, {{ nome }}, {{ aniversario }}, {{ email }}, {{ telefone }}, {{ dataEntrega }}, {{ dataCriacaoProcesso }}, {{ dataPausaProcesso }},\n{{ processoPausado }} , {{ processoFinalizado }}, {{ valorPagamento }}, {{ rmiSimulada }}, {{ rmiConcedida }}, {{ complementoPositivo }}, {{ tipoPagamento }}, {{ urlBoleto }}, {{ tempoAposentadoriaMeses }}, {{ cenario }}`}</pre>
                    </Collapse.Item>
                  </Collapse>
                  <br />
                  <EmailEditor
                    ref={emailEditorRef}
                    onReady={onReadyEmailEditor}
                    minHeight={'800px'}
                    options={{ locale: 'pt-BR' }}
                    style={{ border: '1px solid #bfcbd9', borderRadius: '4px' }}
                  />
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
            <Form.Item>
              <Button type="default" onClick={clearForm}>Limpar</Button>
              <Button type="primary" onClick={handleSubmit.bind(this)}>Gravar</Button>
            </Form.Item>
          </Card>
        </Form>
      </div>
    </Loading>
  );
}
