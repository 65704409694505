import React, { useEffect, useState } from "react"
import { Select } from 'element-react'

/**
 * Create input component with custom combobox with remote search
 * @param {value, ajaxFunction} props 
 * @returns 
 */
export default function SelectRemoteSearch(props) {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.value) {
      props.ajaxFunction(props.value).then(({ ok, data }) => {
        if (ok) setOptions(data)
      })
    }
  }, [props]);

  function onSearch(query) {
    if (query !== '') {
      setLoading(true)

      props.ajaxFunction(query).then(({ ok, data }) => {
        if (ok) setOptions(data)
      })
      setLoading(false)
    } else {
      setOptions([])
    }
  }

  return (
    <Select
      {...props}
      placeholder="Digite aqui para filtrar"
      value={props.value}
      filterable={true}
      remote={true}
      remoteMethod={onSearch.bind(this)}
      loading={loading}>
      {options.map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
    </Select>
  )
}