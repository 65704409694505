import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { reducerKeys } from "../../../../store/reducers/reducer";
import {
  Card,
  CardTitle,
  RenderItemContainer,
  RenderValue,
} from "../components";
import styles from "../GeneralPanelPage.module.css";

function FirstGroup({ colProps, infos, loading }) {
  return (
    <Card colProps={colProps}>
      <CardTitle title="CADASTROS" />
      <RenderItemContainer withBorder className="p-3">
        <i className={`fas fa-id-badge ${styles.icon} mr-2`} />
        <RenderValue
          subtitle="Total de clientes"
          title={loading ? null : infos.allClients}
          large
        />
      </RenderItemContainer>

      <Row className="px-3 pt-3 mx-n1">
        <Col className="px-1">
          <RenderItemContainer className="p-3">
            <RenderValue
              subtitle="Cadastrados hoje"
              title={loading ? null : infos.registeredToday}
            />
          </RenderItemContainer>
        </Col>
        <Col className="px-1">
          <RenderItemContainer className="p-3">
            <RenderValue
              subtitle="Cadastrados ontem"
              title={loading ? null : infos.registeredYesterday}
            />
          </RenderItemContainer>
        </Col>
      </Row>
    </Card>
  );
}

function stateToProps(state) {
  const { firstGroupInfos, firstGroupLoading } =
    state[reducerKeys.generalPanel];
  return { infos: firstGroupInfos, loading: firstGroupLoading };
}

export default connect(stateToProps)(FirstGroup);
