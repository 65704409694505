import React, { Component } from "react";
import { Button, Col, Form } from "react-bootstrap";

class DateFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: "",
      endDate: "",
      filterType: "",
      month: "",
      months: {
        january: 1,
        february: 2,
        march: 3,
        april: 4,
        may: 5,
        june: 6,
        july: 7,
        august: 8,
        september: 9,
        october: 10,
        november: 11,
        december: 12,
      },
      monthsNumbers: undefined,
      startDateTimestamp: "",
      endDateTimestamp: "",
    };
  }

  componentDidMount = () => {
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    let monthsNumbers = [
      [1, "Janeiro", "january"],
      [2, "Fevereiro", "february"],
      [3, "Março", "march"],
      [4, "Abril", "april"],
      [5, "Maio", "may"],
      [6, "Junho", "june"],
      [7, "Julho", "july"],
      [8, "Agosto", "august"],
      [9, "Setembro", "september"],
      [10, "Outubro", "october"],
      [11, "Novembro", "november"],
      [12, "Dezembro", "december"],
    ];
    let sliceForward = monthsNumbers.slice(month, 12);
    sliceForward.forEach((item) => {
      item.push(year - 1);
    });
    let sliceBackward = monthsNumbers.slice(0, month);
    sliceBackward.forEach((item) => {
      item.push(year);
    });
    monthsNumbers = sliceForward.concat(sliceBackward);
    this.setState((state) => ({ ...state, monthsNumbers }));
  };

  handleInputChange = (e) => {
    const { setDateInterval } = this.props;
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
    if (name === "startDate") {
      let a = value.split("-");
      let sd = new Date(
        parseInt(a[0]),
        parseInt(a[1]) - 1,
        parseInt(a[2]),
        0,
        0,
        0
      );
      setDateInterval("start", sd);
    } else if (name === "endDate") {
      let b = value.split("-");
      let ed = new Date(
        parseInt(b[0]),
        parseInt(b[1]) - 1,
        parseInt(b[2]),
        23,
        59,
        59
      );
      setDateInterval("end", ed);
    }
  };

  chooseMonth = (month, year) => {
    const { months } = this.state;
    const { setDateInterval } = this.props;
    this.setState((state) => ({ ...state, month }));
    let date = new Date(year, months[month] - 1, 1);
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
      23,
      59,
      59
    );
    let startDateTimestamp = firstDay;
    let endDateTimestamp = lastDay;
    this.setState((state) => ({
      ...state,
      startDateTimestamp,
      endDateTimestamp,
    }));
    setDateInterval("start", startDateTimestamp);
    // setDateInterval("end", endDateTimestamp);
  };

  render() {
    const { startDate, /*endDate,*/ filterType, month, monthsNumbers } =
      this.state;
    return (
      <div>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Check
                inline
                type="radio"
                name="filterType"
                value="custom"
                label="Customizado"
                id={"radioCustom" + this.props.radiosNumber}
                onChange={this.handleInputChange}
              />
              <Form.Check
                inline
                type="radio"
                name="filterType"
                value="monthly"
                label="Mensal"
                id={"radioMensal" + this.props.radiosNumber}
                onChange={this.handleInputChange}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Data inicial</Form.Label>
              <Form.Control
                value={startDate}
                onChange={this.handleInputChange}
                disabled={filterType !== "custom" || filterType === ""}
                type="date"
                name="startDate"
              />
            </Form.Group>
          </Col>
          {/* <Col>
            <Form.Group>
              <Form.Label>Data final</Form.Label>
              <Form.Control
                value={endDate}
                onChange={this.handleInputChange}
                disabled={
                  filterType !== "custom" ||
                  filterType === "" ||
                  startDate === ""
                }
                type="date"
                name="endDate"
              />
            </Form.Group>
          </Col> */}
        </Form.Row>
        <Form.Row className="mb-2">
          {monthsNumbers !== undefined &&
            monthsNumbers.slice(0, 3).map((item) => {
              return (
                <Col key={item[0]}>
                  <Button
                    variant={month === item[2] ? "primary" : "outline-primary"}
                    className="w-100"
                    onClick={() => this.chooseMonth(item[2], item[3])}
                    disabled={filterType !== "monthly" || filterType === ""}
                  >
                    {item[1]}
                  </Button>
                </Col>
              );
            })}
        </Form.Row>
        <Form.Row className="mb-2">
          {monthsNumbers !== undefined &&
            monthsNumbers.slice(3, 6).map((item) => {
              return (
                <Col key={item[0]}>
                  <Button
                    variant={month === item[2] ? "primary" : "outline-primary"}
                    className="w-100"
                    onClick={() => this.chooseMonth(item[2], item[3])}
                    disabled={filterType !== "monthly" || filterType === ""}
                  >
                    {item[1]}
                  </Button>
                </Col>
              );
            })}
        </Form.Row>
        <Form.Row className="mb-2">
          {monthsNumbers !== undefined &&
            monthsNumbers.slice(6, 9).map((item) => {
              return (
                <Col key={item[0]}>
                  <Button
                    variant={month === item[2] ? "primary" : "outline-primary"}
                    className="w-100"
                    onClick={() => this.chooseMonth(item[2], item[3])}
                    disabled={filterType !== "monthly" || filterType === ""}
                  >
                    {item[1]}
                  </Button>
                </Col>
              );
            })}
        </Form.Row>
        <Form.Row className="mb-2">
          {monthsNumbers !== undefined &&
            monthsNumbers.slice(9, 12).map((item) => {
              return (
                <Col key={item[0]}>
                  <Button
                    variant={month === item[2] ? "primary" : "outline-primary"}
                    className="w-100"
                    onClick={() => this.chooseMonth(item[2], item[3])}
                    disabled={filterType !== "monthly" || filterType === ""}
                  >
                    {item[1]}
                  </Button>
                </Col>
              );
            })}
        </Form.Row>
      </div>
    );
  }
}

export default DateFilter;
