import React, { Component } from "react";
import { Modal, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import {
  // getAllRoles,
  // getCellGetAll,
  insertUser,
  updateUser,
  hasEmailAvailable,
  hasCpfAvailable,
  getCellByUserId,
} from "../../../services/RequestService";
import { alertActions } from "../../../store/actions/AlertActions";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { validations } from "../../../utils";

class ModalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledField: false,
      isOutsource: props?.data
        ? props.data?.isFinder
          ? "Indicador"
          : props.data?.isOutsource
          ? "Terceirizado"
          : "Credenciado"
        : "",
      email: props.data !== undefined ? props.data.email : "",
      name: props.data !== undefined ? props.data.name : "",
      password: "",
      cpf: props.data !== undefined ? props.data.cpf : "",
      enable:
        props?.data?.status === "Ativo"
          ? true
          : props?.data?.status === "Inativo"
          ? false
          : "",
      cell: props.cellId ?? props?.data?.cell ?? "",
      allCells: [],
      disabledSubmitBtn: false,
      type: "password",
      isAdmin: props.data !== undefined ? props.data.isAdmin : false,
      isIntern: true,
      validFields: {
        isCPFValid: props.data !== undefined ? true : undefined,
        isCPFUnique: props.data !== undefined ? true : undefined,
        isEmailValid: props.data !== undefined ? true : undefined,
        isEmailUnique: props.data !== undefined ? false : undefined,
        isPasswordValid: undefined,
        isNameValid: undefined,
        isEnableValid: undefined,
        isRoleValid: undefined,
        isRoleCell: undefined,
      },
    };
  }

  componentDidMount = () => {
    const jwt = require("jwt-decode");
    let token = jwt(localStorage.getItem("token"));
    let privileges = JSON.parse(jwt(localStorage.getItem("token")).privileges);
    if (this.props.data === undefined) {
      this.setState((state) => ({
        ...state,
        disabledField: !privileges?.["configuracoes/usuarios"]?.create,
      }));
    } else {
      this.setState((state) => ({
        ...state,
        disabledField: !privileges?.["configuracoes/usuarios"]?.update,
      }));
    }
    const { cell } = this.state;
    getCellByUserId({ userId: token.userId }).then((res) => {
      let isIntern = false;
      if (res.ok) {
        let allCells = res.data.cells;
        allCells.push({
          id: res.data.id,
          name: res.data.name,
          isIntern: res.data.isIntern,
          cellAdmin: res.data.cellAdmin,
        });
        res.data.cells.forEach((cells) => {
          if (cells?.cells) {
            cells.cells.forEach((cell) => {
              allCells.push(cell);
            });
          }
          if (cell === cells.id) {
            isIntern = cells.isIntern;
          }
          if (cells.name === this.state.cell) {
            this.setState({ cell: cells.id });
          }
        });
        this.setState((state) => ({ ...state, allCells, isIntern }));
      }
    });
  };

  passwordHandleClick = () =>
    this.setState(({ type }) => ({
      type: type === "password" ? "text" : "password",
    }));

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "name") {
      this.setState((state) => ({ ...state, [name]: value.toUpperCase() }));
    } else {
      this.setState((state) => ({ ...state, [name]: value }));
    }
  };

  handleCellChange = (e) => {
    e.persist();
    const { value } = e.target;
    const { allCells } = this.state;
    let isIntern = false;
    allCells.forEach((res) => {
      if (res.id.toString() === value) {
        isIntern = res.isIntern;
      }
    });
    this.setState((state) => ({ ...state, cell: value, isIntern }));
  };

  errorTreatment = (update) => {
    this.props.onAddAlert(
      `Erro ao tentar ${update ? "atualizar" : "criar"} usuário.`,
      "danger"
    );
  };

  sendRequest = () => {
    const { data } = this.props;
    const {
      isIntern,
      isOutsource,
      email,
      password,
      name,
      cpf,
      cell,
      // allCells,
      isAdmin,
      enable,
      validFields,
    } = this.state;

    const isValid =
      validFields.isNameValid &&
      validFields.isEmailUnique &&
      validFields.isEmailValid &&
      validFields.isCPFValid &&
      validFields.isCPFUnique &&
      validFields.isPasswordValid &&
      validFields.isCellValid;
    this.setState(
      (state) => ({ ...state, disabledSubmitBtn: true }),
      () => {
        if (!data) {
          if (isValid) {
            let v = {
              cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
              password: password,
              isAdmin: isAdmin,
              isCustomer: false,
              isOutsource: isIntern ? false : isOutsource === "Terceirizado",
              isAccredited: isIntern ? false : isOutsource === "Credenciado",
              isFinder: isIntern ? false : isOutsource === "Finder",
              name: name.trim(),
              email: email,
              cell,
              enable,
            };
            insertUser(v)
              .then((res) => {
                if (res.ok) {
                  this.props.onHide(true);
                  this.props.onAddAlert(
                    "Usuário cadastrado.",
                    "success",
                    false
                  );
                } else {
                  this.errorTreatment();
                  this.setState({
                    disabledSubmitBtn: false,
                  });
                }
              })
              .catch(() => {
                this.errorTreatment();
                this.setState({
                  disabledSubmitBtn: false,
                });
              });
          } else {
            this.props.onAddAlert(
              "Todos os campos devem ser preenchidos corretamente.",
              "danger",
              false
            );
            this.setState((state) => ({ ...state, disabledSubmitBtn: false }));
          }
        } else {
          let v = {
            id: data.id,
            cpf: cpf.replace("-", "").replace(".", "").replace(".", ""),
            password: password,
            isAdmin: isAdmin,
            isCustomer: false,
            isOutsource: isIntern ? false : isOutsource === "Terceirizado",
            isAccredited: isIntern ? false : isOutsource === "Credenciado",
            isFinder: isIntern ? false : isOutsource === "Finder",
            name: name.trim(),
            email: email,
            cell,
            enable,
          };
          updateUser(v)
            .then((res) => {
              if (res.ok) {
                this.props.onHide(true);
                this.props.onAddAlert("Usuário atualizado.", "success", false);
              } else {
                this.errorTreatment(true);
                this.setState((state) => ({
                  ...state,
                  disabledSubmitBtn: false,
                }));
              }
            })
            .catch(() => {
              this.errorTreatment(true);
              this.setState((state) => ({
                ...state,
                disabledSubmitBtn: false,
              }));
            });
        }
      }
    );
  };

  submit = () => {
    this.checkAllFieldsIsValid(this.sendRequest);
  };

  checkAllFieldsIsValid = (callback) => {
    const {
      // cpf,
      // email,
      name,
      password,
      cell,
      data,
      enable,
      validFields,
    } = this.state;

    let isPasswordValid = password !== undefined && password !== "";
    let isCellValid = cell !== undefined && cell !== "";
    let isNameValid =
      name !== undefined &&
      name
        .trim()
        .match(/^[A-zÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-Ÿ][A-zÀ-ÿ']+$/);
    let isEnableValid = data !== undefined ? enable !== undefined : true;
    let isCPFValid =
      validFields.isCPFValid !== undefined ? validFields.isCPFValid : false;
    let isCPFUnique =
      validFields.isCPFUnique !== undefined ? validFields.isCPFUnique : false;
    let isEmailValid =
      validFields.isEmailValid !== undefined ? validFields.isEmailValid : false;
    let isEmailUnique =
      validFields.isEmailUnique !== undefined
        ? validFields.isEmailUnique
        : false;

    this.setState(
      (state) => ({
        ...state,
        validFields: {
          ...validFields,
          isCPFValid,
          isCPFUnique,
          isEmailValid,
          isEmailUnique,
          isCellValid,
          isPasswordValid,
          isNameValid,
          isEnableValid,
        },
      }),
      callback
    );
  };

  isEmailValid = () => {
    const { email } = this.state;
    let isEmailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
    let isEmailUnique = undefined;
    if (isEmailValid) {
      hasEmailAvailable({ email: email }).then((res) => {
        if (res.ok) {
          isEmailUnique = res.data;
          this.setState((state) => ({
            ...state,
            validFields: {
              ...this.state.validFields,
              isEmailValid,
              isEmailUnique,
            },
          }));
        }
      });
    } else {
      this.setState((state) => ({
        ...state,
        validFields: { ...this.state.validFields, isEmailValid, isEmailUnique },
      }));
    }
  };

  isPasswordValid = () => {
    const { password } = this.state;
    let isPasswordValid = password !== undefined && password !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isPasswordValid },
    }));
  };

  isNameValid = () => {
    const { name, validFields } = this.state;
    let isNameValid = !validations.isValidFullname(name);
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isNameValid },
    }));
  };

  isCellValid = () => {
    const { cell } = this.state;
    let isCellValid = cell !== undefined && cell !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isCellValid },
    }));
  };

  isEnableValid = () => {
    const { enable, data } = this.state;
    let isEnableValid = data !== undefined ? enable !== undefined : true;
    this.setState((state) => ({
      ...state,
      validFields: { ...this.state.validFields, isEnableValid },
    }));
  };

  isCPFValid = () => {
    const { cpf, validFields } = this.state;
    let isCPFValid = cpf !== undefined && this.validateCPF(cpf);
    let isCPFUnique = false;
    if (isCPFValid) {
      hasCpfAvailable({ cpf: cpf.replace(/\./g, "").replace(/-/g, "") }).then(
        (res) => {
          if (res.ok) {
            isCPFUnique = res.data;
            this.setState((state) => ({
              ...state,
              validFields: { ...validFields, isCPFValid, isCPFUnique },
            }));
          }
        }
      );
    } else {
      this.setState((state) => ({
        ...state,
        validFields: { ...validFields, isCPFValid, isCPFUnique },
      }));
    }
  };

  validateCPF = (cpf) => {
    cpf = cpf.replace(/\./g, "");
    cpf = cpf.replace(/-/g, "");

    let ar = cpf.split("");
    let count = 0;
    let sum = 0;
    for (let i = 10; i >= 2; i--) {
      sum += parseInt(ar[count]) * i;
      count++;
    }
    let restoPrimeiro = sum % 11;
    restoPrimeiro < 2
      ? (restoPrimeiro = 0)
      : (restoPrimeiro = 11 - restoPrimeiro);
    let primeiroDigito = restoPrimeiro === parseInt(ar[9]);
    count = 0;
    sum = 0;
    for (let i = 11; i >= 2; i--) {
      sum += parseInt(ar[count]) * i;
      count++;
    }
    let restoSegundo = sum % 11;
    restoSegundo < 2 ? (restoSegundo = 0) : (restoSegundo = 11 - restoSegundo);
    let segundoDigito = restoSegundo === parseInt(ar[10]);
    return primeiroDigito && segundoDigito;
  };

  render() {
    const {
      isOutsource,
      email,
      password,
      name,
      cpf,
      cell,
      validFields,
      // allRoles,
      allCells,
      disabledSubmitBtn,
      enable,
      type,
      isAdmin,
      isIntern,
      disabledField,
    } = this.state;
    const { show, onHide, data, cellId, cellName } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="md"
        scrollable
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-1">
            <Col>
              <Form.Group>
                <Form.Label>
                  CPF <Form.Label className="is-required">*</Form.Label>
                </Form.Label>
                <InputMask
                  mask="999.999.999-99"
                  value={cpf}
                  onChange={this.handleInputChange}
                  onBlur={() => this.isCPFValid()}
                  placeholder="123.123.123-12"
                  disabled={this.props.data !== undefined}
                >
                  <Form.Control
                    type="text"
                    name="cpf"
                    className={
                      validFields.isCPFUnique === undefined &&
                      validFields.isCPFValid === undefined
                        ? ""
                        : validFields.isCPFValid
                        ? validFields.isCPFUnique
                          ? "is-valid"
                          : "is-invalid"
                        : "is-invalid"
                    }
                  />
                </InputMask>
                <Form.Text className="is-required">
                  {validFields.isCPFUnique === undefined &&
                  validFields.isCPFValid === undefined
                    ? ""
                    : validFields.isCPFValid
                    ? validFields.isCPFUnique
                      ? ""
                      : "CPF já cadastrado, por favor, ensira outro CPF"
                    : "O campo CPF deve ser conter um CPF válido"}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Form.Group>
                <Form.Label>
                  E-mail <Form.Label className="is-required">*</Form.Label>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  placeholder="teste@gmail.com"
                  onChange={this.handleInputChange}
                  onBlur={() => {
                    this.isEmailValid();
                  }}
                  className={
                    data === undefined
                      ? validFields.isEmailUnique === undefined &&
                        validFields.isEmailValid === undefined
                        ? ""
                        : validFields.isEmailValid
                        ? validFields.isEmailUnique
                          ? "is-valid"
                          : "is-invalid"
                        : "is-invalid"
                      : ""
                  }
                  disabled={data !== undefined}
                />
                <Form.Text className="is-required">
                  {data === undefined
                    ? validFields.isEmailUnique === undefined &&
                      validFields.isEmailValid === undefined
                      ? ""
                      : validFields.isEmailValid
                      ? validFields.isEmailUnique
                        ? ""
                        : "E-mail já cadastrado, por favor, ensira outro e-mail"
                      : "O campo e-mail deve ser conter um e-mail válido"
                    : ""}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col>
              <Form.Group>
                <Form.Label>
                  Nome Completo{" "}
                  <Form.Label className="is-required">*</Form.Label>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  onBlur={() => this.isNameValid()}
                  className={
                    validFields.isNameValid === undefined
                      ? ""
                      : validFields.isNameValid
                      ? "is-valid"
                      : "is-invalid"
                  }
                  disabled={disabledField}
                />
                <Form.Text className="is-required">
                  {validFields.isNameValid === undefined
                    ? ""
                    : validFields.isNameValid
                    ? ""
                    : "O campo deve conter um nome completo valido. Com nome, sobrenome e não pode conter números."}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          {data === undefined && (
            <Row className="mt-1">
              <Col>
                <Form.Group>
                  <Form.Label>
                    Senha <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={type}
                      name="password"
                      value={password}
                      placeholder="senha"
                      onChange={this.handleInputChange}
                      onBlur={() => {
                        this.isPasswordValid();
                      }}
                      className={
                        validFields.isPasswordValid !== undefined
                          ? validFields.isPasswordValid
                            ? "is-valid"
                            : "is-invalid"
                          : ""
                      }
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        onClick={this.passwordHandleClick}
                      >
                        {type === "password" ? (
                          <i className="fas fa-eye" />
                        ) : (
                          <i className="fas fa-eye-slash" />
                        )}
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Text className="is-required">
                    {validFields.isPasswordValid !== undefined
                      ? validFields.isPasswordValid
                        ? ""
                        : "O campo senha não pode ser vazio"
                      : ""}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row className="mt-1">
            {console.log(allCells.find((x) => x.id === cell)?.name)}
            <Col>
              {!cellId ? (
                <Form.Group>
                  <Form.Label>
                    Célula <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="cell"
                    onChange={this.handleCellChange}
                    onBlur={() => this.isCellValid()}
                    className={
                      validFields.isCellValid === undefined
                        ? ""
                        : validFields.isCellValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                    disabled={disabledField}
                  >
                    {!cell ? (
                      <option></option>
                    ) : (
                      <option key={cell} value={cell}>
                        {allCells.find((x) => x.id === cell)?.name}
                      </option>
                    )}
                    {allCells &&
                      allCells.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <Form.Text className="is-required">
                    {validFields.isCellValid === undefined
                      ? ""
                      : validFields.isCellValid
                      ? ""
                      : "O campo célula deve conter um valor que não seja vazio."}
                  </Form.Text>
                </Form.Group>
              ) : (
                <Form.Group>
                  <Form.Label>
                    Célula <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="cellName"
                      value={cellName}
                      className={"is-valid"}
                      disabled={true}
                    />
                  </InputGroup>
                </Form.Group>
              )}
            </Col>
          </Row>
          {!isIntern && (
            <>
              <Form.Label>
                Informe o tipo de usuário
                <Form.Label className="is-required">*</Form.Label>
              </Form.Label>
              <Row className="text-center">
                <Col>
                  <Form.Check
                    id={"outsourced"}
                    type={"radio"}
                    label={"Terceirizado"}
                    value={"Terceirizado"}
                    name={"isOutsource"}
                    onChange={(e) => this.handleInputChange(e)}
                    checked={isOutsource === "Terceirizado"}
                    disabled={disabledField}
                  />
                </Col>
                <Col>
                  <Form.Check
                    id={"accredited"}
                    type={"radio"}
                    label={"Credenciado"}
                    value={"Credenciado"}
                    name={"isOutsource"}
                    onChange={(e) => this.handleInputChange(e)}
                    checked={isOutsource === "Credenciado"}
                    disabled={disabledField}
                  />
                </Col>
                <Col>
                  <Form.Check
                    id={"finder"}
                    type={"radio"}
                    label={"Indicador"}
                    value={"Indicador"}
                    name={"isOutsource"}
                    onChange={(e) => this.handleInputChange(e)}
                    checked={isOutsource === "Indicador"}
                    disabled={disabledField}
                  />
                </Col>
              </Row>
            </>
          )}
          {data !== undefined && (
            <div>
              <Row>
                <Col className="text-left d-flex">
                  <Form.Label>Admin</Form.Label>

                  <Form.Check
                    className="ml-3"
                    value={isAdmin}
                    name="isAdmin"
                    onChange={() =>
                      this.setState((state) => ({
                        ...state,
                        isAdmin: !isAdmin,
                      }))
                    }
                    if="isAdminCheck"
                    checked={isAdmin === true}
                    disabled={disabledField}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="text-left d-flex">
                  <Form.Label>Status</Form.Label>

                  <Form.Check
                    className="ml-3"
                    label="Ativo"
                    name="enable"
                    value={true}
                    onChange={() =>
                      this.setState((state) => ({ ...state, enable: true }))
                    }
                    onBlur={() => this.isEnableValid()}
                    id="radio1"
                    checked={enable === true}
                    disabled={disabledField}
                  />

                  <Form.Check
                    className="ml-3"
                    label="Inativo"
                    name="enable"
                    value={false}
                    onChange={() =>
                      this.setState((state) => ({ ...state, enable: false }))
                    }
                    onBlur={() => this.isEnableValid()}
                    id="radio2"
                    checked={enable === false}
                    disabled={disabledField}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Col className=" p-0">
              <Button
                variant="primary"
                className="w-100 modal-user-button-without-rounded"
                onClick={() => {
                  this.submit();
                }}
                disabled={disabledSubmitBtn || disabledField}
              >
                {this.props.data !== undefined ? "Atualizar" : "Adicionar"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalUser);
