import React from "react";
import { connect } from "react-redux";
import { Modal, Form, Row } from "react-bootstrap";
import { alertActions } from "../../../store/actions/AlertActions";
import {
  putDocumentTypeInsert,
  postDocumentTypeUpdate,
  getAllDocumentType,
  getAllDocumentSubTypes,
} from "../../../services/RequestService";
import "../../../pages/settings/process/SettingsProcessPage.css";
import { FormButton, Input, TableComponent } from "../../../genericComponents";
import { hooks } from "../../../utils";
import { FormFull } from "form-full";

let allDocumentTypes = [];
let documentSubtypesForSubmit = [];
let selectedIdsForSubmit = {};
function ModalDocumentType({
  show,
  onHide,
  privileges,
  id,
  name,
  subTypes,
  onAddAlert,
}) {
  const [loadingTypes, setLoadingTypes] = React.useState(true);
  const [loadingSubTypes, setLoadingSubTypes] = React.useState(true);
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const [documentSubtypes, setDocumentSubtypes] = React.useState(null);
  const [currentList, setCurrentList] = React.useState(null);
  const [selectedIds, setSelectedIds] = React.useState({});

  hooks.useMount(() => {
    getAllDocumentType()
      .then((res) => {
        if (res.ok) {
          allDocumentTypes = res.data;
        }
      })
      .finally(() => {
        setLoadingTypes(false);
      });

    getAllDocumentSubTypes()
      .then((res) => {
        setDocumentSubtypes(res.data);
        documentSubtypesForSubmit = res.data;
        const selectedIds = {};
        res.data.forEach((item) => {
          selectedIds[item.id] = Boolean(
            subTypes.find((el) => el.id === item.id)
          );
        });
        selectedIdsForSubmit = selectedIds;
        setSelectedIds(selectedIds);
      })
      .catch((e) => {
        // TODO error treatment
        console.log(e);
      })
      .finally(() => {
        setLoadingSubTypes(false);
      });
  });

  function validateNameField(name) {
    for (let i = 0; i < allDocumentTypes.length; i++) {
      let b = allDocumentTypes[i];
      if (b.name === name) {
        return id === b.id
          ? null
          : "O valor digitado já é um tipo de documento";
      }
    }
    return null;
  }

  function submit({ name }) {
    setLoadingSubmit(true);
    const subTypes = [];
    documentSubtypesForSubmit.forEach((item) => {
      if (selectedIdsForSubmit[item.id]) subTypes.push(item.id);
    });
    const requestCallback = id ? postDocumentTypeUpdate : putDocumentTypeInsert;

    requestCallback({
      id: id ?? undefined,
      name,
      subTypes,
    })
      .then((res) => {
        if (res.ok) {
          onAddAlert(
            `Tipo de documento ${
              id ? "atualizado" : "adicionado"
            } com sucesso.`,
            "success"
          );
          onHide(true);
        } else {
          onAddAlert(
            `Ocorreu um erro ao tentar ${
              id ? "atualizar" : "adicionar"
            } o tipo de documento.`,
            "danger"
          );
        }
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  }

  function setNewSelectedIdList(id) {
    const newSelectedIds = { ...selectedIds };
    newSelectedIds[id] = !selectedIds[id];
    selectedIdsForSubmit[id] = !selectedIds[id];
    setSelectedIds(newSelectedIds);
  }
  const disabledField = React.useMemo(() => {
    return (
      !privileges["configuracoes/andamentos"].create ||
      !privileges["configuracoes/andamentos"].update
    );
  }, [privileges]);

  return (
    <Modal size="xl" show={show} onHide={onHide} centered backdrop="static">
      <FormFull
        onSubmit={submit}
        actualValues={{ name }}
        disabled={
          disabledField || loadingTypes || loadingSubTypes || loadingSubmit
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Tipo de documento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Input
              label="Nome"
              name="name"
              required="Campo obrigatório"
              validation={(name) => validateNameField(name)}
              maskToSubmit={(name) => name.trim()}
            />
          </Form.Row>

          <Form.Label className="w-100 text-nowrap overflow-hidden">
            Subtipos do Documento
          </Form.Label>

          <TableComponent
            loading={loadingSubTypes}
            countLabel="subtipos"
            countLabelSingular="subtipo"
            startSorted="id"
            header={[
              { label: "#", sortProperty: "id" },
              { label: "Nome", sortProperty: "name" },
              { label: "Selecionar" },
            ]}
            currentList={currentList}
            setCurrentList={setCurrentList}
            list={documentSubtypes}
            forEach={(item, index) => [
              { label: item.id },
              { label: item.name },
              {
                onClick: () => setNewSelectedIdList(item.id),
                label: (
                  <Form.Check
                    name={item.name + "selection"}
                    checked={selectedIds[item.id]}
                    value={!selectedIds[item.id]}
                    onChange={(e) => setNewSelectedIdList(item.id)}
                    onClick={(e) => e.stopPropagation()}
                  />
                ),
              },
            ]}
          />
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <FormButton
              name="submit"
              actionType="submit"
              disabled={
                disabledField ||
                loadingTypes ||
                loadingSubTypes ||
                loadingSubmit
              }
              className="w-100 button-without-rounded"
            >
              {id === undefined || id === null ? "Cadastrar" : "Atualizar"}
            </FormButton>
          </Row>
        </Modal.Footer>
      </FormFull>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalDocumentType);
