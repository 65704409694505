import React from "react";
import { Row } from "react-bootstrap";
import { InputName, InputCPF, InputEmail } from "../../../genericComponents";
import { isCPFValid, isEmailValid, getEmail } from "../validations";
import jwt from "jwt-decode";
import InputCpfCnpj from "../../../genericComponents/inputs/InputCpfCnpj";

const stripCPFMask = (cpfValue) => {
  return cpfValue
    .replaceAll("_", "")
    .replaceAll(".", "")
    .replaceAll("-", "");
}


function BasicData1({ id, changeState, states, isCollaborator, disabled }) {

  let showDiamondCard = false
  try {
    const { is_user_and_customer, is_customer } = jwt(localStorage.getItem("token"));
    showDiamondCard = !is_customer || (is_user_and_customer && is_customer)
  } catch (e) { }

  return (
    <>
      <Row>
        <InputCPF
          colProps={{ sm: 12, md: 4 }}
          name="cpf"
          label="CPF"
          required="O campo não deve ser vazio e deve conter um CPF válido."
          disabled={id !== undefined}
          asyncValidation={
            id ? null : async (cpf) => {
              const errorMessage = await isCPFValid(cpf);
              changeState({ isValidCPF: !errorMessage });
              return errorMessage;
            }
          }
          onChange={async (event, cpfValue, ffHandler) => {
            ffHandler.setValue("email", "");
            ffHandler.setValue("fullname", "");
          }}
          onBlur={async (event, cpfValue, ffHandler) => {
            if (stripCPFMask(cpfValue).length === 0) {
              return
            }
            const isCollaborator = await getEmail(
              cpfValue,
              ffHandler,
              "cpf",
              states
            );
            changeState({ isCollaborator });
          }}
        />
        <InputEmail
          colProps={{ sm: 12, md: 4 }}
          name="email"
          label="Email"
          required="O campo não deve ser vazio e deve conter um e-mail válido."
          maxLength={200}
          disabled={isCollaborator}
          asyncValidation={async (email, ffHandler) => {
            const confirmEmail = ffHandler.getValue("confirmEmail")
            if (confirmEmail) {
              return confirmEmail === email ? null : "Os e-mails devem ser iguais"
            }

            if (!id || ffHandler.getActualValue("email") !== email) {
              const errorMessage = await isEmailValid(email, ffHandler);
              changeState({ isValidEmail: !errorMessage });
              return errorMessage;
            }
            return null;
          }}
        />
        <InputEmail
          colProps={{ sm: 12, md: 4 }}
          label="Confirmar e-mail"
          name="confirmEmail"
          maxLength={200}
          required='O campo "Confirmar e-mail" deve ser preenchido e igual ao campo "E-mail".'
          disabled={disabled}
          asyncValidation={(value, ffHandler) => {
            const isValid = ffHandler.getValue("email") === value;
            return isValid ? null : "Os e-mails devem ser iguais.";
          }}
          onBlur={(event, value, ffHandler) => {
            if (ffHandler.getValue("confirmEmail")) {
              ffHandler.testFieldError("email", true)
            }
          }}
        />
      </Row>

      {showDiamondCard && (
        <div style={{ background: '#dddddd7d', padding: '10px', borderRadius: '5px' }}>
          <Row>
            <InputName
              colProps={{ xs: 12, sm: 7, md: 8, lg: 4 }}
              label="Indicador Parceiro"
              name="indicatorName"
              maxLength={100}
            />

            <InputCpfCnpj
              colProps={{ xs: 12, sm: 7, md: 8, lg: 4 }}
              label="CPF/CNPJ"
              name="indicatorDoc"
              maxLength={18}
            />
          </Row>
        </div>
      )}
    </>
  );
}

export default React.memo(BasicData1);
