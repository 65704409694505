import React, { useEffect, useRef, useState } from "react";
import { Layout, Loading, Button, Select, DatePicker, Form } from 'element-react'
import CurrencyInput from "../../components/fields/MoneyField";
import { getAllUserComissionConfig, saveComissionReport, getComissionReport, getComission, printReportComission, deleteComission } from "../../services/RequestService";
import { masks, fn } from "../../utils";
import { useAlert } from "react-alert";
import DataTableDinamicFilter from "../../genericComponents/datatable/DataTableDinamicFilter";

export default function ComissionPage() {
  const alert = useAlert();
  const formRef = useRef();
  const dataTable = useRef();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    userId: null,
    period: null,
    cellbonus: null
  });

  const filter = [
    { label: "ID", key: "id" },
    { label: "Usuário", key: "user.name" },
    { label: "Período", key: "period" },
    { label: "Data", key: "createDate" },
  ];

  const columns = [
    { label: "ID", prop: "id" },
    { label: "Usuário", prop: "user" },
    { label: "Período", prop: "period" },
    { label: "Data", prop: "createDate", render: (r) => masks.convertDateToBr(r.createDate) },
    {
      label: "Ações", type: "action", render: (r) => (
        <>
          <Button icon='delete' type="danger" onClick={remove.bind(this, r)} />
          <Button type="primary" onClick={print.bind(this, r)} ><i className="fa fa-print"></i></Button>
        </>
      )
    },
  ];

  useEffect(() => {
    let listUsers = [];
    getAllUserComissionConfig().then((resp) => {
      if (resp.ok) {
        for (const item of resp.data) {
          if (listUsers.filter((u) => u.value === item.userId).length <= 0)
            listUsers.push({ label: item.userName, value: item.userId });
        }
        setUsers([...listUsers]);
      }
    });
  }, []);

  function print(row) {
    printReportComission(row.id).then((resp) => {
      if (resp.ok) {
        fn.downloadFile(resp.data.file);
      } else {
        alert.show(resp.data.log, { type: "error" });
      }
    });
  }

  function remove(row) {
    deleteComission(row.id).then((resp) => {
      if (resp.ok) {
        dataTable.current.refreshDataTable();
        alert.show("Relatório removido com sucesso!", { type: "success" });
      } else {
        alert.show(resp.data.log, { type: "error" });
      }
    });
  }

  async function printReport(e) {
    e.preventDefault()
    setLoading(true);
    const [year, month] = form.period.toISOString().split("-")
    const resp = await getComissionReport({ ...form, bonus: fn.float(form.bonus), period: `${month}/${year}` });
    if (resp.ok) {
      fn.downloadFile(resp.data.file);
    } else {
      alert.show("Não existem comissões para o usuário/período selecionados!", { type: "error" });
    }
    setLoading(false);
  }

  async function saveReport(e) {
    e.preventDefault()
    setLoading(true);
    const [year, month] = form.period.toISOString().split("-")
    const resp = await saveComissionReport({ ...form, bonus: fn.float(form.bonus), period: `${month}/${year}` });
    dataTable.current.refreshDataTable();
    alert.show(resp.ok ? "Relatório salvo com sucesso" : resp.data.log, { type: resp.ok ? "success" : "error" });
    setLoading(false);
  }

  function onChange(key, value) {
    setForm(Object.assign({}, form, { [key]: value }));
  }

  return (
    <Loading loading={loading}>
      <DataTableDinamicFilter
        ref={dataTable}
        fields={filter}
        ajaxData={getComission}
        columns={columns}
        pageTile={"Comissão"}
        pageForm={
          <Form className='dinamic-filter-form' ref={formRef} model={form} labelPosition="top">
            <Layout.Row gutter="20">
              <Layout.Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item label="Usuário" prop="userId">
                  <Select value={form.userId} clearable={true} filterable={true} onChange={onChange.bind(this, 'userId')}>
                    {(users || []).map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item label="Período" prop="period">
                  <DatePicker value={form.period} selectionMode="month" placeholder="Selecione a data" format="MM/yyyy" onChange={onChange.bind(this, 'period')} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item label="Bonus" prop="bonus">
                  <CurrencyInput className={"form-control"} placeholder={"R$ 0,00"} type="text" value={form.bonus} onChange={(e) => onChange('bonus', e.target.value)} />
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={6} lg={6}>
                <Form.Item label="Ações">
                  <Button type="primary" onClick={saveReport.bind(this)}>Gravar</Button>
                  <Button type="info" onClick={printReport.bind(this)}>Imprimir</Button>
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        }
      />
    </Loading >
  );
}
