import React, { Component } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import {
  getAllProcess,
  putInsertPayment,
  postUpdatePayment,
  postPayPayment,
  getAllPayment,
  deletePayment,
} from "../../services/RequestService";

class ModalFinancial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name !== null ? props.name : "",
      processId: "",
      value: props.value !== null ? props.value : "",
      payValue: 0.1,
      processes: [],
      validFields: {
        isNameValid: undefined,
        isProcessIdValid: undefined,
        isValueValid: undefined,
        isPayValueValid: undefined,
      },
    };
  }

  componentDidMount = () => {
    const { modalType } = this.props;
    if (modalType === "add") {
      getAllPayment().then((resPayment) => {
        if (resPayment.ok) {
          let ids = [];
          resPayment.data.forEach((item) => {
            ids.push(item.process.id);
          });
          getAllProcess().then((res) => {
            if (res.ok) {
              let processes = [];
              res.data.forEach((item) => {
                if (!ids.includes(item.id)) {
                  processes.push(item);
                }
              });
              this.setState((state) => ({ ...state, processes }));
            }
          });
        }
      });
    } else if (modalType === "edit") {
      this.isNameValid();
    }
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  isNameValid = () => {
    const { name, validFields } = this.state;
    let isNameValid = name !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isNameValid },
    }));
  };

  isProcessIdValid = () => {
    const { processId, validFields } = this.state;
    let isProcessIdValid = processId !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isProcessIdValid },
    }));
  };

  isValueValid = () => {
    const { value, validFields } = this.state;
    let isValueValid = value !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isValueValid },
    }));
  };

  isPayValueValid = () => {
    const { payValue, value, validFields } = this.state;
    let isPayValueValid =
      parseInt(payValue) > 0.01 &&
      parseInt(payValue) <= value - this.props.payValue;
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isPayValueValid },
    }));
  };

  isPayButtonDisabled = () => {
    const { payValue, value } = this.state;
    return parseInt(payValue) > value - this.props.payValue;
  };

  submitInsert = () => {
    const { name, processId, value } = this.state;
    let p = parseInt(processId.split("-")[0].trim());
    putInsertPayment({ name, processId: p, value }).then((res) => {
      if (res.ok) {
        this.props.onHide();
      }
    });
  };

  submitUpdate = () => {
    const { name } = this.state;
    const { id } = this.props;
    postUpdatePayment({ id, name }).then((res) => {
      if (res.ok) {
        this.props.onHide();
      }
    });
  };

  submitPay = () => {
    const { payValue } = this.state;
    const { id } = this.props;
    postPayPayment({ id, payValue: parseFloat(payValue) }).then((res) => {
      if (res.ok) {
        this.props.onHide();
      }
    });
  };

  submitDelete = () => {
    const { id } = this.props;
    deletePayment({ deleteId: id }).then((res) => {
      if (res.ok) {
        this.props.onHide();
      }
    });
  };

  render() {
    const { name, processId, value, processes, validFields, payValue } =
      this.state;
    const { modalType, show, onHide } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="sm" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "add"
              ? "Adicionar"
              : modalType === "edit"
              ? "Editar"
              : modalType === "pay"
              ? "Pagar"
              : modalType === "delete"
              ? "Deletar"
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "add" ? (
            <div>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      name="name"
                      value={name}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isNameValid()}
                      className={
                        validFields.isNameValid === undefined
                          ? ""
                          : validFields.isNameValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Processo</Form.Label>
                    <Form.Control
                      as="select"
                      name="processId"
                      value={processId}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isProcessIdValid()}
                      className={
                        validFields.isProcessIdValid === undefined
                          ? ""
                          : validFields.isProcessIdValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                    >
                      <option />
                      {processes.map((item) => {
                        return (
                          <option key={item.id}>
                            {item.id + " - " + item.customer.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Valor</Form.Label>
                    <Form.Control
                      name="value"
                      value={value}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isValueValid()}
                      className={
                        validFields.isValueValid === undefined
                          ? ""
                          : validFields.isValueValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
          ) : modalType === "edit" ? (
            <div>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      name="name"
                      value={name}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isNameValid()}
                      className={
                        validFields.isNameValid === undefined
                          ? ""
                          : validFields.isNameValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
          ) : modalType === "pay" ? (
            <div>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Valor a ser pago</Form.Label>
                    <Form.Control
                      type="number"
                      name="payValue"
                      value={payValue}
                      step="0.01"
                      min={0.01}
                      max={value - this.props.payValue}
                      onChange={this.handleInputChange}
                      onBlur={() => this.isPayValueValid()}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group controlId="formBasicRange" className="mb-0">
                    <Form.Control
                      type="range"
                      name="payValue"
                      step="0.01"
                      min={0.01}
                      max={value - this.props.payValue}
                      onChange={this.handleInputChange}
                      value={payValue}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
            </div>
          ) : modalType === "delete" ? (
            <h5>Você tem certeza que deseja excluir esse lançamento?</h5>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {modalType === "add" ? (
            <Button
              className="w-100 m-0"
              variant="primary"
              disabled={
                !validFields.isNameValid ||
                !validFields.isProcessIdValid ||
                !validFields.isValueValid
              }
              onClick={this.submitInsert}
            >
              Adicionar
            </Button>
          ) : modalType === "edit" ? (
            <Button
              className="w-100 m-0"
              variant="primary"
              disabled={!validFields.isNameValid}
              onClick={this.submitUpdate}
            >
              Atualizar
            </Button>
          ) : modalType === "pay" ? (
            <Button
              className="w-100 m-0"
              variant="primary"
              onClick={this.submitPay}
              disabled={this.isPayButtonDisabled()}
            >
              Pagar
            </Button>
          ) : modalType === "delete" ? (
            <Button
              className="w-100 m-0"
              variant="danger"
              onClick={this.submitDelete}
            >
              Deletar
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalFinancial;
