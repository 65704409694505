import React, {Component} from "react";
import {Sidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./NavigationComp.css";
import logoSmall from "../../res/LogoQualitteWhite.png";
import {getCurrentProcessByUserId,} from "../../services/RequestService";
import {Button} from "react-bootstrap";
import {setView, isCustomerView, getView} from "../../utils/storage";
import fn from "../../utils/functions"

class NavigationComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      menuVisible: "",
      is_customer: this.setInitialView()
    };
  }

  componentDidMount = () => {
    if (this.props.decodedToken.is_customer) {
      getCurrentProcessByUserId().then((res) => {
        if (res.ok) {
          this.setState((state) => ({...state, processId: res.data}));
        }
      });
    }
  };

  setInitialView = () => {
    if (getView()) {
      return isCustomerView();
    } else if (this.props.decodedToken.is_user_and_customer) {
      return false;
    }
    return this.props.decodedToken.is_customer;
  };

  handleChangeView = () => {
    const is_customer = !this.state.is_customer;
    setView(is_customer);
    this.setState({is_customer});
  };

  renderLink = (itemName, path, iconClassName) => {
    return <MenuItem active={this.props.selectedPath === path} icon={<i className={iconClassName}/>}
                     component={<Link to={path}/>}> {itemName} </MenuItem>
  };

  render() {
    const {children, decodedToken} = this.props;
    const {processId, is_customer, toggled} = this.state;
    const is_user_and_customer = decodedToken?.is_user_and_customer;
    const is_admin = decodedToken?.is_admin;

    const isMobile = fn.isMobileDevice();
    const link = isMobile ? 'https://api.whatsapp.com/send/?phone=5508004540606' : 'https://web.whatsapp.com/send?phone=+5508004540606'
    return (
      <div style={{display: 'flex', height: '100%', minHeight: '400px'}}>
        <Sidebar onBackdropClick={() => this.setState((state) => ({...state, toggled: !toggled}))}
                 toggled={toggled} breakPoint={isMobile ? 'always' : ''} backgroundColor={'#3e3e3e'}>
          <div style={{background: '#383737', textAlign: "center"}}>
            <Link to="/"><Image src={logoSmall} style={{width: 115, margin: 15}}/></Link>
          </div>

          {is_user_and_customer && (
            <div className="navi-sidebar-view-button">
              <Link to="/">
                <Button onClick={this.handleChangeView}>
                  <i className="fas fa-random pr-3"/>
                  {"Ver Área "} {is_customer ? "Colaborador" : "Cliente"}
                </Button>
              </Link>
            </div>
          )}

          <Menu>
            {(!is_customer && is_admin) && this.renderLink("Painel Geral", "/painel_geral", "fas fa-chart-bar")}
            {(!is_customer && this.props.privileges?.agenda?.read) && this.renderLink("Agenda", "/agenda", "fas fa-calendar-alt")}
            {(!is_customer && this.props.privileges?.processo?.read) && this.renderLink("Processos", "/processos", "fas fa-project-diagram")}
            {is_customer && this.renderLink("Meus Processos", `/processo/${processId}/agenda`, "fas fa-project-diagram")}
            {is_customer && this.renderLink("Meus Documentos", `/processo/${processId}/documentos`, "fas fa-folder")}
            {is_customer && this.renderLink("Meus Dados", `/meus_dados/processo/${this.props.decodedToken.user_id}`, "fas fa-user")}
            {(!is_customer && this.props.privileges?.clientes?.read) && this.renderLink("Clientes", `/clientes`, "fas fa-users")}
            {(!is_customer && this.props.privileges?.financeiro?.read) && this.renderLink("Financeiro", `/financeiro`, "fas fa-coins")}
            {(!is_customer && this.props.privileges?.relatorios?.read) && this.renderLink("Relatórios", `/relatorio`, "fas fa-file-alt")}
            {is_admin && this.renderLink("Comissão", `/comissao`, "fas fa-file-alt")}
            <MenuItem icon={<i className="fas fa-comments navi-item-icon"/>}
                      component={<a href={link}  rel="noopener noreferrer" target="_blank"/>}> Fale Conosco </MenuItem>

            {is_admin && this.renderLink("Enviar Mensagens", `/enviar-mensagens`, 'fab fa-whatsapp')}
            {!is_customer && (this.props.privileges?.["configuracoes/andamentos"]?.read || this.props.privileges?.["configuracoes/celulas"]?.read ||
              this.props.privileges?.["configuracoes/grupo_de_trabalho"]?.read || this.props.privileges?.["configuracoes/usuarios"]?.read ||
              this.props.privileges?.["configuracoes/processos"]?.read || this.props.privileges?.["configuracoes/parceiros"]?.read) && (
              <SubMenu icon={<i className="fas fa-cog"/>} label="Configurações">
                {(!is_customer && this.props.privileges?.["configuracoes/andamentos"]?.read) && this.renderLink("Andamentos", `/configuracoes/andamentos`)}
                {(!is_customer && this.props.privileges?.["configuracoes/celulas"]?.read) && this.renderLink("Células", `/configuracoes/celulas`)}
                {(!is_customer && this.props.privileges?.["configuracoes/grupo_de_trabalho"]?.read) && this.renderLink("Grupo de trabalho", `/configuracoes/grupo_de_trabalho`)}
                {this.props.privileges?.["configuracoes/usuarios"]?.read && this.renderLink("Usuários", `/configuracoes/usuarios`)}
                {this.props.privileges?.["configuracoes/processos"]?.read && this.renderLink("Processos", `/configuracoes/processos`)}
                {this.props.privileges?.["configuracoes/parceiros"]?.read && this.renderLink("Parceiros", `/configuracoes/parceiros`)}
                {is_admin && this.renderLink("Comissão", `/configuracoes/comissao-usuario`)}
                {is_admin && this.renderLink("Wide Chat", `/configuracoes/wide-chat`)}
                {is_admin && this.renderLink("Gerador Documento", `/configuracoes/gerador-documento`)}
              </SubMenu>
            )}
            <MenuItem icon={<i className="fas fa-door-open"/>}
                      component={<a href="/" onClick={() => localStorage.clear()}/>}> Sair </MenuItem>
          </Menu>
        </Sidebar>
        <main id="main-body" style={{width: '100%', position: 'relative'}}>
          {isMobile && (
            <Button variant="primary" style={{position: 'absolute', margin: 20}}
                    onClick={() => this.setState((state) => ({...state, toggled: !toggled}))}>
              <i className="fas fa-bars"/>
            </Button>
          )}
          {children}
        </main>
      </div>
    )
  }
}

export default NavigationComp;
