import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { reducerKeys } from "../../../../store/reducers/reducer";
import ProposalsChart from "../charts/ProposalsChart";
import {
  Card,
  CardTitle,
  RenderDoughnutValue,
  RenderItemContainer,
  RenderValue,
} from "../components";
import styles from "../GeneralPanelPage.module.css";

function ThirdGroup({ colProps, infos, loading }) {
  return (
    <Card colProps={colProps}>
      <CardTitle title="PROPOSTAS" />
      <Row className="m-0">
        <div className={styles.doughnutContainer}>
          <ProposalsChart chartData={loading ? null : infos.chartData} />
        </div>
        <Col xs className="pr-3">
          <RenderItemContainer className="flex-column">
            <RenderItemContainer withBorder className="w-100 p-2 px-3">
              <RenderValue
                subtitle={loading ? null : infos.totalProposals}
                title="Total"
              />
            </RenderItemContainer>
            <Row className="w-100 d-flex justify-content-center px-3 pb-3 pt-2">
              <div className="d-flex flex-row flex-wrap justify-content-between pr-1 w-100">
                <RenderDoughnutValue
                  title="Tramitando"
                  subtitle={loading ? null : infos.proposalsInProgress}
                  dotColor="#FFC107"
                />
                <RenderDoughnutValue
                  title="Aceitas"
                  subtitle={loading ? null : infos.acceptedProposals}
                  dotColor="#2ECC71"
                />
                <RenderDoughnutValue
                  title="Recusadas"
                  subtitle={loading ? null : infos.rejectedProposals}
                  dotColor="#F0166D"
                />
              </div>
            </Row>
          </RenderItemContainer>
        </Col>
      </Row>
    </Card>
  );
}

function stateToProps(state) {
  const { thirdGroupInfos, thirdGroupLoading } =
    state[reducerKeys.generalPanel];
  return { infos: thirdGroupInfos, loading: thirdGroupLoading };
}

export default connect(stateToProps)(ThirdGroup);
