import React from "react";
import { Row } from "react-bootstrap";
import { Input, Select } from "../../../genericComponents";

function BasicData6({ disabled }) {
  return (
    <Row>
      <Input
        colProps={{ xs: 12, md: 6 }}
        label="Profissão"
        name="occupation"
        maxLength={100}
        inputMaskProps={{ placeholder: "Profissão" }}
        disabled={disabled}
        required='O campo "Profissão" pode ser vazio.'
      />

      <Select
        colProps={{ xs: 12, md: 6 }}
        label="Estado Civil"
        name="maritalStatus"
        required='O valor do campo "Estado Civil" deve ser selecionado.'
        disabled={disabled}
        placeholder="Selecione um estado civil"
        options={[
          "Solteiro(a)",
          "Casado(a)",
          "Separado(a)",
          "Divorciado(a)",
          "Viúvo(a)",
          "União Estável",
        ].map((item) => ({ label: item, value: item }))}
      />
    </Row>
  );
}

export default BasicData6;
