import React, {Component} from "react";
import {ativarInativarUsuario, getUserBySearchBar} from "../../../services/RequestService";
import "./UserPage.css";
import ModalUser from "../../../components/settings/user/ModalUser";
import ModalDeleteUser from "../../../components/settings/user/ModalDeleteUser";
import {Header, Loading, PageContainer, TableComponentWithPagination} from "../../../genericComponents";
import {masks} from "../../../utils";

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      filterValue: localStorage?.userFilter || "",
      show: false,
      showDelete: false,
      user: null,
      data: undefined,
      loading: true,
      totalItems: 0,
      fixedTotalItems: 0,
      totalPages: 0,
      page: parseInt(localStorage?.userPageNumber || '0'),
    };
  }

  componentDidMount = () => {
    this.setState({loading: true});
    this.getByFilter();
  };

  getByFilter = () => {
    this.setState({loading: true}, () => {
      const {filterValue, page} = this.state;
      getUserBySearchBar({searchItem: filterValue, page}).then(({ok, data}) => {
        if (ok)
          this.setState({
            list: data.content
              .map((item) => ({
                id: item.id,
                name: item.name,
                email: item.email,
                cpf: item.cpf,
                status: item.active ? "Ativo" : "Inativo",
                cell: item.cellName,
              })).sort((a, b) => (b.id > a.id ? 1 : -1)),
            page: data.currentPage,
            totalItems: data.total,
            totalPages: data.totalPages,
            fixedTotalItems: data.fixedTotalItems,
            loading: false,
          });
      });
    });
  };

  pagination = (pageNumber) => {
    this.setState({page: pageNumber}, () => {
      console.log(pageNumber, this.state.page);
      localStorage.userPageNumber = pageNumber;
      this.getByFilter();
    });
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({...state, [e.target.name]: e.target.value, page: 0}));
    localStorage.userFilter = e.target.value
    localStorage.userPageNumber = 0
    console.log(this.state)
  };

  handleSearch = (e) => {
    if (e.key === "Enter") this.getByFilter();
  };

  setShowModal = (show) => {
    this.setState((state) => ({...state, show, data: undefined}));
    if (!show) this.componentDidMount();
  };

  setShowModalDelete = (showDelete, user, wasUpdated) => {
    this.setState((state) => ({...state, showDelete, user}));
    if (!showDelete && wasUpdated) this.componentDidMount();
  };

  ativarInativarUsuario = (item) => {
    ativarInativarUsuario(item.id, item.status === 'Ativo').then(({ok}) => {
      if (ok) this.getByFilter()
    })
  };

  setModalForEditing = (i) => {
    this.setState((state) => ({...state, data: i, show: true}));
  };

  render() {
    const {
      filterValue, show, data, showDelete, user, loading, totalItems, totalPages, page, fixedTotalItems, list,
    } = this.state;
    return (<Loading loading={loading}>
      <Header
        active="Usuários"
        logged
        filter={{
          type: "text",
          className: "text-left",
          placeholder: "Buscar por ... ",
          name: "filterValue",
          value: filterValue,
          onChange: this.handleInputChange,
          onKeyPress: this.handleSearch,
          onClick: () => this.getByFilter(),
        }}
        buttons={[this.props.privileges !== undefined && this.props.privileges["configuracoes/usuarios"].create ? {
          onClick: () => this.setShowModal(true), icon: "fas fa-plus-circle", label: "Adicionar",
        } : null,]}
      />
      <PageContainer fixedHeight className="p-3">
        <TableComponentWithPagination
          loading={loading}
          countLabel="usuários"
          countLabelSingular="usuário"
          startSorted="id"
          header={[
            {label: "#", sortProperty: "id"},
            {label: "Nome", sortProperty: "name"},
            {label: "Email", sortProperty: "email"},
            {label: "CPF", sortProperty: "cpf"},
            {label: "Status", sortProperty: "status"},
            {label: "Célula", sortProperty: "cell"},
            this.props.privileges !== undefined && this.props.privileges["configuracoes/usuarios"].delete ? {label: "Excluir"} : null,
            {label: "Ativar/Inativar"}
          ]}
          totalItems={totalItems}
          fixedTotalItems={fixedTotalItems}
          totalPages={totalPages}
          page={page}
          list={list}
          onItemClick={(itemList) => {
            if (itemList.id === 1) return;
            const item = itemList;
            this.setModalForEditing(item);
          }}
          forEach={(item) => [
            {label: item.id},
            {label: item.name},
            {label: item.email},
            {label: item.cpf ? masks.CPF(item.cpf) : item.cpf},
            {
              label: (
                <div className={item.status === "Ativo" ? "status-enable" : "status-inactive"}>{item.status}</div>)
            },
            {label: item.cell},
            this.props.privileges !== undefined && this.props.privileges["configuracoes/usuarios"].delete ? {
              onClick: item.id === 1 ? null : () => this.setShowModalDelete(true, item),
              label: (
                <div className="text-center">
                  {item.id !== 1 && (<i className="fas fa-trash-alt is-required"></i>)}
                </div>
              ),
            } : null,
            {
              onClick: item.id === 1 ? null : () => this.ativarInativarUsuario(item),
              label: (
                <div className="text-center" title={item.status === 'Inativo' ? 'Ativar' : 'Inativar'}>
                  {item.id !== 1 && (<i className={item.status === 'Inativo' ? "fa fa-check-circle" : "fa fa-times"} aria-hidden="true"></i>)}
                </div>
              ),
            }
          ]}
          onChangePage={this.pagination}
        />

        {show && (<ModalUser
          show={show}
          onHide={() => this.setShowModal(false)}
          data={data && data}
        />)}
        {showDelete && (<ModalDeleteUser
          show={showDelete}
          onHide={(wasUpdated) => this.setShowModalDelete(false, null, wasUpdated)}
          user={user}
        />)}
      </PageContainer>
    </Loading>);
  }
}

export default UserPage;
