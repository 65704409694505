import React from "react";
import styles from "../GeneralPanelPage.module.css";
import RenderValue from "./RenderValue";

export default function RenderDoughnutValue({ title, subtitle, dotColor }) {
  return (
    <div className="d-flex align-items-start py-1">
      <i
        className={`fas fa-circle ${styles.smallIcon} mx-2 mt-2`}
        style={{ color: dotColor }}
      />
      <RenderValue subtitle={subtitle} title={title} />
    </div>
  );
}
