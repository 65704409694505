import React, { Component } from "react";
import DocumentsProcess from "../../components/process/DocumentsProcess";

class CustomerDocumentsPage extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      id: params.id !== undefined ? params.id : undefined,
      from: params.from !== undefined ? params.from : undefined,
    };
  }

  render() {
    const { id, from } = this.state;
    return (
      <>
        <DocumentsProcess id={id} from={from} />
      </>
    );
  }
}

export default CustomerDocumentsPage;
