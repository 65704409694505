import { parse } from "date-fns";
import React from "react";
import { Row } from "react-bootstrap";
import { InputDate, RadioGroup } from "../../../genericComponents";
import { validations } from "../../../utils";

function CustomerSurvey4({ serverData }) {
  const [studied, setStudied] = React.useState(false);

  const technicalSchool = serverData?.technicalSchool;
  React.useEffect(() => {
    if (technicalSchool === "Sim") {
      setStudied(true);
    }
  }, [technicalSchool, setStudied]);

  return (
    <Row className="customer-row-margin align-items-end">
      <RadioGroup
        colProps={{ xs: 12 }}
        label="Fez escola Técnica?"
        name="technicalSchool"
        options={[
          {
            value: "Sim",
            label: "Sim",
          },
          {
            value: "Não",
            label: "Não",
          },
          {
            value: "Não sei responder",
            label: "Não sei responder",
          },
        ]}
        required='O campo "Fez escola Técnica?" é obrigatório.'
        inline
        onChange={(event, value) => {
          setStudied(value === "Sim");
        }}
      />
      {studied ? (
        <>
          <InputDate
            colProps={{ md: 6 }}
            label="Data de início"
            name="technicalSchoolYearStart"
            required='O campo "Data de início" é obrigatório.'
            inputMaskProps={{ placeholder: "Data de inicio do curso" }}
            minDate={new Date("01/01/1900")}
            maxDate={new Date()}
            onBlur={(event, value, ffHandler) => {
              const dateToCompare = ffHandler.getValue(
                "technicalSchoolYearEnd"
              );
              if (dateToCompare !== "__/__/____") {
                ffHandler.testFieldError("technicalSchoolYearEnd");
              }
            }}
            validation={(value, ffHandler) => {
              const result = validations.isDATE(
                parse(value, "dd/MM/yyyy", new Date()),
                null,
                parse(
                  ffHandler.getValue("technicalSchoolYearEnd"),
                  "dd/MM/yyyy",
                  new Date()
                ),
                "dd/MM/yyyy"
              );
              return result;
            }}
          />
          <InputDate
            colProps={{ md: 6 }}
            label="Data de término"
            name="technicalSchoolYearEnd"
            required='O campo "Data de término" é obrigatório.'
            inputMaskProps={{
              placeholder: "Data de término do curso",
            }}
            minDate={new Date("01/01/1900")}
            maxDate={new Date()}
            onBlur={(event, value, ffHandler) => {
              const dateToCompare = ffHandler.getValue(
                "technicalSchoolYearStart"
              );
              if (dateToCompare !== "__/__/____") {
                ffHandler.testFieldError("technicalSchoolYearStart");
              }
            }}
            validation={(value, ffHandler) => {
              const result = validations.isDATE(
                parse(value, "dd/MM/yyyy", new Date()),
                parse(
                  ffHandler.getValue("technicalSchoolYearStart"),
                  "dd/MM/yyyy",
                  new Date()
                ),
                null
              );
              return result;
            }}
          />
        </>
      ) : null}
    </Row>
  );
}

export default React.memo(CustomerSurvey4);
