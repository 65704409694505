import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { reducerKeys } from "../../../../store/reducers/reducer";
import CalendarChart from "../charts/CalendarChart";
import { Card, CardTitle } from "../components";

function SixthGroup({ colProps, infos, loading }) {
  return (
    <Card colProps={colProps}>
      <CardTitle title="AGENDA - MÉDIA DE DIAS" />
      <Row className="px-3">
        <CalendarChart infos={loading ? null : infos} />
      </Row>
    </Card>
  );
}

function stateToProps(state) {
  const { sixthGroupInfos, sixthGroupLoading } =
    state[reducerKeys.generalPanel];
  return { infos: sixthGroupInfos, loading: sixthGroupLoading };
}

export default connect(stateToProps)(SixthGroup);
