import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Select } from "../../../../genericComponents";
import CurrencyInput from "../../../../components/fields/MoneyField";
import { parse } from "date-fns";
import {
  postUpdatePayment,
  putInsertPayment,
} from "../../../../services/RequestService";
import { masks, fn } from "../../../../utils";

class ModalPaymentExtra extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parcels: 2,
      showNegociation: false,
      amountTotal: 0,
      amountNegociatedRestant: 0,
      rmiSimulada:
        props.rmiSimulada === null
          ? ""
          : props.rmiSimulada.toString().replace(".", ","),
      rmiConcedida: "",
      complementoPositivo: "",
      complementoPositivoHonorarios: "",
      showTableParcels: false,
      negociationParcels: [],
      validFields: {
        isRmiSimuladaValid: undefined,
        isRmiConcedidaValid: undefined,
        isComplementoPositivoValid: undefined,
        isComplementoPositivoHonorariosNotEmptyValid: undefined,
        isComplementoPositivoHonorariosValueValid: undefined,
      },
    };
  }

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({
      ...state,
      [name]: value,
      showNegociation: false,
    }));
  };

  isRmiSimuladaValid = () => {
    const { rmiSimulada, validFields } = this.state;
    let isRmiSimuladaValid = rmiSimulada !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isRmiSimuladaValid },
    }));
  };

  isRmiConcedidaValid = () => {
    const { rmiConcedida, validFields } = this.state;
    let isRmiConcedidaValid = rmiConcedida !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isRmiConcedidaValid },
    }));
  };

  isComplementoPositivoValid = () => {
    const { complementoPositivo, validFields } = this.state;
    let isComplementoPositivoValid = complementoPositivo !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isComplementoPositivoValid },
    }));
  };

  isComplementoPositivoHonorariosValid = () => {
    const { complementoPositivoHonorarios, validFields } = this.state;
    let isComplementoPositivoHonorariosNotEmptyValid =
      complementoPositivoHonorarios !== "";
    let isComplementoPositivoHonorariosValueValid;
    try {
      let n = parseInt(complementoPositivoHonorarios);
      isComplementoPositivoHonorariosValueValid = n >= 0 && n <= 100;
    } catch (e) {
      isComplementoPositivoHonorariosValueValid = false;
    }
    this.setState((state) => ({
      ...state,
      validFields: {
        ...validFields,
        isComplementoPositivoHonorariosNotEmptyValid,
        isComplementoPositivoHonorariosValueValid,
      },
    }));
  };

  complementoPositivoClassNameMsg = (isClassName) => {
    const {
      isComplementoPositivoHonorariosNotEmptyValid,
      isComplementoPositivoHonorariosValueValid,
    } = this.state.validFields;
    if (
      isComplementoPositivoHonorariosValueValid === undefined ||
      isComplementoPositivoHonorariosNotEmptyValid === undefined
    ) {
      return isClassName ? "form-control" : "";
    } else {
      if (isComplementoPositivoHonorariosNotEmptyValid) {
        if (isComplementoPositivoHonorariosValueValid) {
          return isClassName ? "form-control is-valid" : "";
        } else {
          return isClassName
            ? "form-control is-invalid"
            : "O campo deve ser preenchido com um valor " +
                "númerico de 0 a 100";
        }
      } else {
        return isClassName
          ? "form-control is-invalid"
          : "O campo não pode ser vazio";
      }
    }
  };

  submit = () => {
    const {
      rmiSimulada,
      rmiConcedida,
      complementoPositivoHonorarios,
      complementoPositivo,
      parcels,
      negociationParcels,
    } = this.state;
    const { processId, type, id } = this.props;
    if (type) {
      let data = {
        processId: processId,
        amount: 0,
        paymentType: "Boleto",
        selectedParcel: 2,
        rmiSimulada: fn.float(rmiSimulada),
      };
      putInsertPayment(data).then((res) => {
        if (res.ok) {
          this.props.onHide();
        }
      });
    } else {
      const negociation = [];
      if (negociationParcels.length > 0) {
        if (
          negociationParcels.filter(
            (f) => f.paymentSelectedType === "Cartão de crédito"
          ).length > 1
        ) {
          this.props.onAddAlert(
            `O Tipo de pagamento Cartão de Crédito pode ser selecionado apenas uma vez, altere o campo para Boleto ou Pix`,
            "danger"
          );
          return;
        }
        if (
          negociationParcels.filter((f) => f.paymentSelectedType === "Pix")
            .length > 1
        ) {
          this.props.onAddAlert(
            `O Tipo de pagamento Pix pode ser selecionado apenas uma vez, altere o campo para Boleto ou Cartão de crédito`,
            "danger"
          );
          return;
        }

        for (const iterator of negociationParcels) {
          const d = parse(iterator.dueDate, "dd/MM/yyyy", new Date());
          if (
            d.toString() === "Invalid Date" ||
            d.getTime() < new Date().getTime()
          ) {
            this.props.onAddAlert(
              `A data informada (${iterator.dueDate}) não é uma data válida`,
              "danger"
            );
            return;
          }
          let v = fn.float(iterator.value);
          if (v < 0) {
            this.props.onAddAlert(
              `O valor (${iterator.value}) não é um valor válido`,
              "danger"
            );
            return;
          }
          negociation.push({
            dueDate: d.toISOString().substring(0, 10),
            parcelValue: fn.float(v),
            paymentType: iterator.paymentSelectedType || "Boleto",
            creditCardParcels: iterator.creditCardParcels || 1,
          });
        }
      }
      let data = {
        id: id,
        rmiSimulada: fn.float(rmiSimulada),
        rmiConcedida: fn.float(rmiConcedida),
        complementoPositivo: fn.float(complementoPositivo),
        complementoPositivoHonorarios: fn.float(complementoPositivoHonorarios),
        parcels,
        negociationParcels: negociation,
      };
      postUpdatePayment(data).then((res) => {
        if (res.ok) {
          this.props.onHide();
        }
      });
    }
  };

  getAmountTotal() {
    const { complementoPositivoHonorarios, rmiConcedida, complementoPositivo } =
      this.state;
    return (
      fn.float(rmiConcedida) +
      fn.float(rmiConcedida) +
      (fn.float(complementoPositivo) *
        fn.float(complementoPositivoHonorarios)) /
        100
    );
  }

  showNegociation() {
    const { showNegociation } = this.state;
    let date = new Date();
    date = fn.addDaysToDate(date, 9);
    this.setState({
      amountNegociatedRestant: this.getAmountTotal(),
      amountTotal: this.getAmountTotal(),
      showNegociation: !showNegociation,
      negociationParcels: [
        {
          paymentType: [
            { label: "Boleto", value: "Boleto" },
            { label: "Cartão de Crédito", value: "Cartão de Crédito" },
            { label: "Pix", value: "Pix" },
          ],
          creditCardParcels: 1,
          paymentSelectedType: "",
          value: 0.0,
          dueDate: masks.convertDateToBr(date),
        },
      ],
    });
  }

  addNegociationLine() {
    let date = new Date();
    date = fn.addDaysToDate(date, 9);
    const { negociationParcels } = this.state;
    negociationParcels.push({
      paymentType: [
        { label: "Boleto", value: "Boleto" },
        { label: "Cartão de Crédito", value: "Cartão de Crédito" },
      ],
      creditCardParcels: 1,
      paymentSelectedType: "",
      value: 0.0,
      dueDate: masks.convertDateToBr(date),
    });
    this.setState({ negociationParcels });
    this.loadNegociationRestant();
  }

  getParcels(valor) {
    const parcels = [{ label: "Selecione", value: "{}" }];
    for (var i = 1; i <= 10; i++) {
      parcels.push({
        label: `Em ${i}x de ${masks.money(
          fn.floorFigure(fn.float(valor) / i)
        )}`,
        value: i,
      });
    }
    return parcels;
  }

  loadNegociationRestant() {
    const { amountTotal, negociationParcels } = this.state;
    const amountNegociatedRestant =
      amountTotal -
      negociationParcels.reduce((ac, v) => {
        if (v.paymentSelectedType === "Cartão de crédito")
          return fn.float(v.value) * fn.float(v.creditCardParcels) + ac;
        return fn.float(v.value) + ac;
      }, 0);
    this.setState({ amountNegociatedRestant });
  }

  render() {
    const jwt = require("jwt-decode");
    const { is_admin, is_cell_manager, is_cell_admin } = jwt(
      localStorage.getItem("token")
    );
    const {
      rmiSimulada,
      rmiConcedida,
      complementoPositivo,
      complementoPositivoHonorarios,
      showNegociation,
      negociationParcels,
      amountTotal,
      amountNegociatedRestant,
    } = this.state;
    const {
      isRmiSimuladaValid,
      isRmiConcedidaValid,
      isComplementoPositivoHonorariosNotEmptyValid,
      isComplementoPositivoHonorariosValueValid,
      isComplementoPositivoValid,
    } = this.state.validFields;
    const { show, onHide, type } = this.props;

    return (
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Liberar pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>RMI Simulada</Form.Label>
              <CurrencyInput
                placeholder={"R$0.00"}
                type="text"
                name="rmiSimulada"
                value={rmiSimulada}
                onChange={this.handleInputChange}
                onBlur={this.isRmiSimuladaValid}
                disabled={!type}
                className={
                  isRmiSimuladaValid === undefined
                    ? "form-control"
                    : isRmiSimuladaValid
                    ? "is-valid form-control"
                    : "is-invalid form-control"
                }
              />
              <Form.Text className="is-required">
                {isRmiSimuladaValid === undefined
                  ? ""
                  : isRmiSimuladaValid
                  ? ""
                  : "O campo não pode ser vazio"}
              </Form.Text>
            </Form.Group>
          </Form.Row>
          {!type && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>RMI Concedida</Form.Label>
                <CurrencyInput
                  placeholder={"R$0.00"}
                  type="text"
                  name="rmiConcedida"
                  value={rmiConcedida}
                  onChange={this.handleInputChange}
                  onBlur={this.isRmiConcedidaValid}
                  className={
                    isRmiConcedidaValid === undefined
                      ? "form-control"
                      : isRmiConcedidaValid
                      ? "is-valid form-control"
                      : "is-invalid form-control"
                  }
                />
                <Form.Text className="is-required">
                  {isRmiConcedidaValid === undefined
                    ? ""
                    : isRmiConcedidaValid
                    ? ""
                    : "O campo não pode ser vazio"}
                </Form.Text>
              </Form.Group>
            </Form.Row>
          )}
          {!type && (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Complemento Positivo</Form.Label>
                <CurrencyInput
                  placeholder={"R$0.00"}
                  type="text"
                  name="complementoPositivo"
                  value={complementoPositivo}
                  onChange={this.handleInputChange}
                  onBlur={this.isComplementoPositivoValid}
                  className={
                    isComplementoPositivoValid === undefined
                      ? "form-control"
                      : isComplementoPositivoValid
                      ? "is-valid form-control"
                      : "is-invalid form-control"
                  }
                />
                <Form.Text className="is-required">
                  {isComplementoPositivoValid === undefined
                    ? ""
                    : isComplementoPositivoValid
                    ? ""
                    : "O campo não pode ser vazio"}
                </Form.Text>
              </Form.Group>
            </Form.Row>
          )}
          {!type && (
            <>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Complemento Positivo - Honorários</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="complementoPositivoHonorarios"
                      value={complementoPositivoHonorarios}
                      onChange={this.handleInputChange}
                      onBlur={this.isComplementoPositivoHonorariosValid}
                      className={this.complementoPositivoClassNameMsg(true)}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Form.Text className="is-required">
                    {this.complementoPositivoClassNameMsg(false)}
                  </Form.Text>
                </Form.Group>
              </Form.Row>
            </>
          )}
          {!type && (is_admin || is_cell_manager || is_cell_admin) && (
            <Form.Row>
              <Form.Group as={Col}>
                <InputGroup>
                  <Form.Check
                    type="switch"
                    label="Negociação"
                    id="neg"
                    onChange={() => this.showNegociation()}
                    disabled={
                      !isComplementoPositivoHonorariosValueValid ||
                      !isRmiConcedidaValid ||
                      !isComplementoPositivoHonorariosNotEmptyValid ||
                      !isComplementoPositivoValid
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>
          )}
          {showNegociation > 0 && (
            <div>
              <h5>
                <Row>
                  <Col>
                    Total: <b>{masks.money(amountTotal)}</b>
                  </Col>
                  {amountNegociatedRestant !== 0 && (
                    <Col>
                      Restante Não Negociado:{" "}
                      <b style={{ color: "red" }}>
                        {masks.money(amountNegociatedRestant)}
                      </b>
                    </Col>
                  )}
                </Row>
              </h5>
              <Table className={"table-responsible"}>
                <tbody>
                  {negociationParcels.map((t, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <Form.Group>
                            <Form.Label>Valor</Form.Label>
                            <CurrencyInput
                              className={"form-control"}
                              placeholder={"R$0.00"}
                              type="text"
                              value={t.value + ""}
                              onChange={(e) => {
                                negociationParcels[key].value = e.target.value;
                                this.setState({ negociationParcels });
                              }}
                              onFocus={(e) => e.target.select()}
                              onBlur={() => this.loadNegociationRestant()}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Select
                            label="Tipo Pagamento"
                            options={t.paymentType}
                            onChange={(e) => {
                              negociationParcels[key].paymentSelectedType =
                                e.target.value;
                              this.setState({ negociationParcels });
                            }}
                          />
                        </td>
                        <td>
                          {(negociationParcels[key].paymentSelectedType ||
                            "Boleto") === "Boleto" && (
                            <Form.Group>
                              <Form.Label>Vencimento</Form.Label>
                              <Form.Control
                                value={t.dueDate}
                                onChange={(e) => {
                                  const v = e.target.value
                                    .replace(/\D/g, "")
                                    .slice(0, 10);
                                  if (v.length >= 5) {
                                    let d = `${v.slice(0, 2)}/${v.slice(
                                      2,
                                      4
                                    )}/${v.slice(4)}`;
                                    if (d.length > 10) {
                                      d = d.substring(0, 10);
                                    }
                                    negociationParcels[key].dueDate = d;
                                  } else if (v.length >= 3) {
                                    negociationParcels[
                                      key
                                    ].dueDate = `${v.slice(0, 2)}/${v.slice(
                                      2
                                    )}`;
                                  } else {
                                    negociationParcels[key].dueDate = v;
                                  }
                                  this.setState({ negociationParcels });
                                }}
                              />
                            </Form.Group>
                          )}
                          {negociationParcels[key].paymentSelectedType ===
                            "Cartão de Crédito" && (
                            <Select
                              label="Parcelas"
                              disabled={
                                fn.float(negociationParcels[key].value) <= 0
                              }
                              options={this.getParcels(
                                negociationParcels[key].value
                              )}
                              onChange={(e) => {
                                negociationParcels[key].creditCardParcels =
                                  e.target.value;
                                this.setState({ negociationParcels });
                              }}
                            />
                          )}
                        </td>
                        <td style={{ minWidth: "115px", paddingTop: "48px" }}>
                          <Button
                            variant="primary"
                            disabled={negociationParcels.some(
                              (v) => fn.float(v.value) <= 0
                            )}
                            onClick={() => this.addNegociationLine()}
                          >
                            <i className="fas fa-solid fa-plus mr-1" />
                          </Button>
                          <Button
                            variant="danger"
                            disabled={negociationParcels.length < 2}
                            onClick={() => {
                              delete negociationParcels[key];
                              this.setState({ negociationParcels });
                              this.loadNegociationRestant();
                            }}
                          >
                            <i className="fas fa-solid fa-minus mr-1" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Button
              variant="primary"
              disabled={
                type
                  ? !isRmiSimuladaValid
                  : !isComplementoPositivoHonorariosValueValid ||
                    !isRmiConcedidaValid ||
                    !isComplementoPositivoHonorariosNotEmptyValid ||
                    !isComplementoPositivoValid ||
                    amountNegociatedRestant > 0.5
              }
              className="w-100 button-without-rounded"
              onClick={this.submit}
            >
              {"Cadastrar"}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalPaymentExtra;
