import React, { Component } from "react";
import { Modal, Row, Col, Table, Form, Button } from "react-bootstrap";
import {
  // getAllScenario,
  postSelectSeemScenario,
} from "../../../../services/RequestService";
import { alertActions } from "../../../../store/actions/AlertActions";
import { connect } from "react-redux";
import { masks } from "../../../../utils";

class ModalSelectScenario extends Component {
  constructor(props) {
    super(props);

    let selectedScenarioId = undefined
    try { selectedScenarioId = this.props.seem.seemScenarioList.filter(s => s.selectedByCustomer)[0].id } catch (e) { }

    this.state = {
      selectedScenarioId,
      sending: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: parseInt(value) }));
  };

  formatDate = (date) => {
    date = date.split("T")[0].split("-");
    return date[2] + "/" + date[1] + "/" + date[0];
  };

  submit = () => {
    const { selectedScenarioId } = this.state;
    const { seem } = this.props;
    this.setState((state) => ({ ...state, sending: true }));
    postSelectSeemScenario({
      seemId: seem.id,
      scenarioId: selectedScenarioId,
    }).then((res) => {
      this.setState((state) => ({ ...state, sending: false }));
      if (res.ok) {
        this.props.onHide();
      }
    });
  };

  render() {
    const { seem, show, onHide } = this.props;
    const { sending, selectedScenarioId } = this.state;

    return (
      <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Selecionar Cenário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h5>Documentos</h5>
              {
                <Table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {seem["seemDocsList"].map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>
                            <Button
                              variant="link"
                              onClick={() => this.setShowPdf(true, item)}
                            >
                              visualizar
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              }
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Data de aposentadoria</th>
                <th>RMI</th>
                <th>Selecionar</th>
              </tr>
            </thead>
            <tbody>
              {seem["seemScenarioList"].map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.scenario.name}</td>
                    <td>{item.scenario.description}</td>
                    <td>{this.formatDate(item.retirementDate)}</td>
                    <td>{masks.money(item.rmi)}</td>
                    <td>
                      <Form.Check
                        name="selectedScenarioId"
                        type="radio"
                        id={"scenario" + item.id}
                        onChange={this.handleInputChange}
                        value={item.id}
                        checked={selectedScenarioId === item.id}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Col className=" p-0">
              <Button
                variant="primary"
                className="w-100 button-without-rounded"
                onClick={this.submit}
                disabled={sending}
              >
                {sending ? "Selecionando ..." : "Selecionar"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalSelectScenario);
