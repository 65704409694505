import React, { Component } from "react";
import ModalFinancial from "../../components/financial/ModalFinancial";
import "./FinancialPage.css";
import { getAllPayment } from "../../services/RequestService";
import ModalFilterFinancial from "../../components/financial/ModalFilterFinancial";
import {
  Header,
  Loading,
  PageContainer,
  TableComponent,
} from "../../genericComponents";
import { masks } from "../../utils";
import ModalUploadTxtFile from "../../components/process/ModalUploadTxtFile";

class FinancialPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showModalFilter: false,
      modalType: null,
      id: null,
      name: null,
      processId: null,
      createdDate: null,
      value: null,
      payValue: null,
      payments: [],
      list: [],
      currentList: [],
      usingFilter: false,
      filterValue: "",
      loading: true,
      showBoletoConfirmationUpload: false,
    };
  }

  setList = (payments) => {
    return payments
      .map((item) => ({
        id: item.id,
        name: item.process.customer.name,
        processId: item.process.id,
        processType: item.process.processType.name,
        paymentType: item.paymentType,
        amount: item.amount,
        rmiSimulada: item.rmiSimulada,
        rmiConcedida: item.rmiConcedida,
        complementoPositivo: item.complementoPositivo,
        complementoPositivoHonorarios: item.complementoPositivoHonorarios,
        transferValue: item.transferValue,
        paymentStatus: item.paymentStatus,
        partner: item.partner,
        feePaymentStatus: item.feePaymentStatus
      }))
      .sort((a, b) => (a.id > b.id ? 1 : -1));
  };

  componentDidMount = () => {
    getAllPayment(null).then((res) => {
      if (res.ok) {
        this.setState({
          payments: res.data,
          loading: false,
          list: this.setList(res.data),
        });
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  setModalOpen = (showModal, modalType, id, name, value, payValue) => {
    this.setState((state) => ({
      ...state,
      showModal,
      modalType,
      id,
      name,
      value,
      payValue,
    }));
    if (!showModal) {
      this.componentDidMount();
    }
  };

  setModalFilterOpen = (showModalFilter) => {
    this.setState((state) => ({ ...state, showModalFilter }));
  };

  setTimestampFilter = (startTimestamp, endTimestamp) => {
    this.setState(
      (state) => ({
        ...state,
        startTimestamp,
        endTimestamp,
        usingFilter: true,
      }),
      () => {
        const { startTimestamp, endTimestamp } = this.state;
        this.setModalFilterOpen(false);
        getAllPayment({
          startDate: startTimestamp,
          endDate: endTimestamp,
        }).then((res) => {
          if (res.ok) {
            res.data["paymentList"].forEach((item) => {
              let d = item.createdDate;
              d = d.split("T")[0].split("-");
              d = d[2].concat("/").concat(d[1]).concat("/").concat(d[0]);
              item.createdDate = d;
            });
            this.setState({
              payments: res.data,
              list: this.setList(res.data),
            });
          }
        });
      }
    );
  };

  // formatToReal = (value) => {
  //   if (value !== undefined && value !== null) {
  //     return (
  //       "$ " +
  //       value
  //         .toFixed(2) // casas decimais
  //         .replace(".", ",")
  //         .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
  //     );
  //   }
  //   return "$ 0,00";
  // };

  formatToProcessName = (process) => {
    return process.id + " \t " + process.customer.name;
  };

  filter = (properties) => (item) => {
    const { filterValue } = this.state;
    for (const property of properties) {
      if (item[property].toUpperCase().includes(filterValue.toUpperCase())) {
        return true;
      }
    }
    return false;
  };

  setShowBoletoConfirmationUpload = (showBoletoConfirmationUpload) => {
    this.setState((state) => ({ ...state, showBoletoConfirmationUpload}));
    if (!showBoletoConfirmationUpload) {
      this.componentDidMount();
    }
  };

  render() {
    const {
      showModal,
      modalType,
      id,
      name,
      processId,
      createdDate,
      value,
      payValue,
      showModalFilter,
      usingFilter,
      filterValue,
      loading,
      showBoletoConfirmationUpload,
    } = this.state;

    return (
      <Loading loading={loading}>
        <Header
          active="Financeiro"
          logged
          filter={{
            type: "text",
            className: "text-left",
            placeholder: "Buscar por ... ",
            name: "filterValue",
            value: filterValue,
            onChange: this.handleInputChange,
          }}
          buttons={[
            {
              icon: usingFilter ? "fas fa-times-circle" : "fas fa-filter",
              label: usingFilter ? "Remover filtro" : "Aplicar filtro",
              onClick: () => {
                if (usingFilter) {
                  this.setState(
                    (state) => ({
                      ...state,
                      usingFilter: false,
                      startTimestamp: null,
                      endTimestamp: null,
                    }),
                    () => {
                      this.componentDidMount();
                    }
                  );
                } else {
                  this.setModalFilterOpen(true);
                }
              },
            },
            {
              icon: "fas fa-plus-circle",
              label: "Confirmar Pagamento de Boletos",
              onClick: () => this.setShowBoletoConfirmationUpload(true)
            },
            this.props.privileges?.financeiro?.create
              ? {
                  icon: "fas fa-plus-circle",
                  label: "Adicionar",
                  onClick: () =>
                    this.setModalOpen(
                      true,
                      "add",
                      null,
                      null,
                      null,
                      null,
                      null
                    ),
                }
              : null,
          ]}
        />

        <PageContainer fixedHeight className="p-3">
          {/* <Row className="m-0 d-flex justify-content-around">
                      <Col sm={3} md={3} lg={2} xs={1} className="financial-values-card">
                          <span>Valor Total</span>
                          <h3>{this.formatToReal(payments['totalValue'])}</h3>
                      </Col>
                      <Col sm={3} md={3} lg={2} xs={1} className="financial-values-card">
                          <span>Valor recebido</span>
                          <h3 className="financial-value-payed-color">
                            {this.formatToReal(payments['totalValuePayed'])}
                          </h3>
                      </Col>
                      <Col sm={3} md={3} lg={3} xs={3} className="financial-values-card">
                          <span>Valor a Receber</span>
                          <h3 className="financial-value-to-pay-color">
                            {this.formatToReal(payments['totalValueToPay'])}
                          </h3>
                      </Col>
                    </Row>*/}

          <TableComponent
            loading={loading}
            countLabel="itens"
            countLabelSingular="item"
            startSorted="id"
            header={[
              { label: "#", sortProperty: "id" },
              { label: "Nome", sortProperty: "name" },
              { label: "Processo", sortProperty: "processId" },
              { label: "Tipo do processo", sortProperty: "processType" },
              { label: "Método de Pagamento", sortProperty: "paymentType" },
              { label: "Valor", sortProperty: "amount" },
              { label: "RMI Simulada", sortProperty: "rmiSimulada" },
              { label: "RMI Concedida", sortProperty: "rmiConcedida" },
              {
                label: "Complemento Positivo",
                sortProperty: "complementoPositivo",
              },
              {
                label: "Honorários",
                sortProperty: "complementoPositivoHonorarios",
              },
              {label: "Valor de repasse", sortProperty: "transferValue"},
              {label: "Parceiro", sortProperty: "partner"},
              {label: "Status do Pagamento", sortProperty: "paymentStatus"},
              {label: "Status do Honorário", sortProperty: "feePaymentStatus"},
            ]}
            list={this.state.list.filter(this.filter(["name"]))}
            currentList={this.state.currentList}
            setCurrentList={(currentList) => this.setState({ currentList })}
            forEach={(item) => [
              { label: item.id },
              { label: item.name },
              { label: item.processId },
              { label: item.processType },
              { label: item.paymentType },
              {
                label:
                  item.amount || item.amount === 0
                    ? masks.money(item.amount)
                    : item.amount,
              },

              {
                label:
                  item.rmiSimulada || item.rmiSimulada === 0
                    ? masks.money(item.rmiSimulada)
                    : item.rmiSimulada,
              },

              {
                label:
                  item.rmiConcedida || item.rmiConcedida === 0
                    ? masks.money(item.rmiConcedida)
                    : item.rmiConcedida,
              },

              {
                label:
                  item.complementoPositivo || item.complementoPositivo === 0
                    ? masks.money(item.complementoPositivo)
                    : item.complementoPositivo,
              },

              {
                label:
                  item.complementoPositivoHonorarios ||
                  item.complementoPositivoHonorarios === 0
                    ? masks.tax(item.complementoPositivoHonorarios)
                    : item.complementoPositivoHonorarios,
              },
              
              {
                label:
                  item.transferValue || item.transferValue === 0
                    ? masks.money(item.transferValue)
                    : "N/D",
              },
              
              {label: item.partner},              
              {label: item.paymentStatus},              
              {label: item.feePaymentStatus},              
            ]}
          />
        </PageContainer>
        {showModal && modalType && (
          <ModalFinancial
            show={showModal}
            modalType={modalType}
            onHide={() =>
              this.setModalOpen(false, null, null, null, null, null)
            }
            id={id}
            name={name}
            processId={processId}
            createdDate={createdDate}
            value={value}
            payValue={payValue}
          />
        )}
        {showModalFilter && (
          <ModalFilterFinancial
            show={showModalFilter}
            onHide={() => this.setModalFilterOpen(false)}
            setTimestampFilter={(a, b) => this.setTimestampFilter(a, b)}
          />
        )}
        { showBoletoConfirmationUpload && (
          <ModalUploadTxtFile
          show={showBoletoConfirmationUpload}
          onHide={() => this.setShowBoletoConfirmationUpload(false)}
          />
        )}
      </Loading>
    );
  }
}

export default FinancialPage;
