import { performePasswordResetCode } from "../../../services/RequestService";

function successAlert(onAddAlert) {
  onAddAlert("Código aprovado", "success");
}

function noConnectionAlert(onAddAlert) {
  onAddAlert("Sem acesso ao serviço", "danger");
}

function invalidCode(onAddAlert) {
  onAddAlert("Código inválido.", "danger");
}

function genericError(onAddAlert) {
  onAddAlert("Erro ao enviar o código", "danger");
}

export function sendCode(data, props, loading, setState) {
  if (!loading) {
    const code = data.code.toUpperCase();
    const { email } = props.location.state;
    setState({ buttonLoading: true }, () => {
      performePasswordResetCode({ code, email })
        .then((res) => {
          if (res.ok) {
            successAlert(props.onAddAlert);
            localStorage.removeItem("token");
            props.history.push("recuperar_senha_confirmacao", {
              email,
              token: res.headers.authorization,
            });
          } else if (res.status === 400) {
            invalidCode(props.onAddAlert);
          } else if (res.status === 500) {
            noConnectionAlert(props.onAddAlert);
          } else {
            genericError(props.onAddAlert);
          }
        })
        .catch(() => {
          genericError(props.onAddAlert);
        })
        .finally(() => {
          setState({ buttonLoading: false });
        });
    });
  }
}
