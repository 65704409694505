import React from "react";
import "./../SingleCustomerComp.css";
import CustomerSurvey1 from "./CustomerSurvey1";
import CustomerSurvey2 from "./CustomerSurvey2";
import CustomerSurvey3 from "./CustomerSurvey3";
import CustomerSurvey4 from "./CustomerSurvey4";
import CustomerSurvey5 from "./CustomerSurvey5";
import CustomerSurvey6 from "./CustomerSurvey6";
import CustomerSurvey7 from "./CustomerSurvey7";
import CustomerSurvey8 from "./CustomerSurvey8";
import CustomerSurvey19 from "./CustomerSurvey19";
import CustomerSurvey9 from "./CustomerSurvey9";
import CustomerSurvey10 from "./CustomerSurvey10";
import CustomerSurvey11 from "./CustomerSurvey11";
import CustomerSurvey12 from "./CustomerSurvey12";
import CustomerSurvey13 from "./CustomerSurvey13";
import CustomerSurvey14 from "./CustomerSurvey14";
import CustomerSurvey15 from "./CustomerSurvey15";
// import CustomerSurvey16 from "./CustomerSurvey16";
import CustomerSurvey17 from "./CustomerSurvey17";
import { epiNames } from "../models/formArrayNames";
import { FormButton } from "../../../genericComponents";
import { FormFull } from "form-full";

function setActualValues(data) {
  if (data) {
    const actualValues = {
      isRetired: data.isRetired,
      benefitNumber: data.benefitNumber,
      benefitYear: data.benefitYear,
      publicServer: data.publicServer,
      regime: data.regime,
      tryRetirement: data.tryRetirement,
      retirementStudy: data.retirementStudy,
      workProcess: data.workProcess,
      militaryService: data.militaryService,
      militaryYearStart: data.militaryYearStart,
      militaryYearEnd: data.militaryYearEnd,
      parentsFarmer: data.parentsFarmer,
      employeeOrServiceProvider: data.employeeOrServiceProvider,
      disabledWorker: data.disabledWorker,
      technicalSchool: data.technicalSchool,
      technicalSchoolYearStart: data.technicalSchoolYearStart,
      technicalSchoolYearEnd: data.technicalSchoolYearEnd,
      optionDangerous: data.optionDangerous,
      dangerousNoiseCompany: data.dangerousNoiseCompany,
      dangerousNoiseMachine: data.dangerousNoiseMachine,
      dangerousHeat: data.dangerousHeat,
      dangerousCold: data.dangerousCold,
      dangerousElectricity: data.dangerousElectricity,
      dangerousChemicalAgents: data.dangerousChemicalAgents,
      dangerousHeight: data.dangerousHeight,
      dangerousGunBearing: data.dangerousGunBearing,
      dangerousBiologicalAgents: data.dangerousBiologicalAgents,
      optionEPI: data.optionEPI,
      beforeAprilNineFive: data.beforeAprilNineFive,
      optionAgriculture: data.optionAgriculture,
      weddingDate: data.weddingDate,
      periodWorked: data.periodWorked,
      haveDocumentsForProof: data.haveDocumentsForProof,
      condition: data.condition,
      terrainState: data.terrainState,
      terrainCity: data.terrainCity,
      propertyArea: data.propertyArea,
      exploredArea: data.exploredArea,
      resources: data.resources,
      familyEconomyRegime: data.familyEconomyRegime,
      weddingDateFishing: data.weddingDateFishing,
      artisanalFishing: data.artisanalFishing,
      haveDocumentsForProofFishing: data.haveDocumentsForProofFishing,
      vesselType: data.vesselType,
      vesselMeasure: data.vesselMeasure,
      ownVessel: data.ownVessel,
      fishSpecies: data.fishSpecies,
      howFishWasDone: data.howFishWasDone,
      whoWorksWithYou: data.whoWorksWithYou,
      otherIncome: data.otherIncome,
      cooperative: data.cooperative,
      haveAnEmployeeOrServiceProvider: data.haveAnEmployeeOrServiceProvider,
      fishingParents: data.fishingParents,
      optionCooperative: data.optionCooperative,
      optionActivityOrExtraIncome: data.optionActivityOrExtraIncome,
      optionActivityIncome: data.optionActivityIncome,
      optionTwoPlaces: data.optionTwoPlaces,
      twoPlaceCompanies: data.twoPlaceCompanies,
      deficiency: data.deficiency,
      deficiencyName: data.deficiencyName,
      deficiencyYearStart: data.deficiencyYearStart,
      accidentHelp: data.accidentHelp,
      accidentHelpYearStart: data.accidentHelpYearStart,
      accidentHelpYearEnd: data.accidentHelpYearEnd,
      inss: data.inss,
      inssPassword: data.inssPassword,
      additionalInformation: data.additionalInformation,
      contactWay: data.contactWay,
    };

    epiNames.map((name) => {
      actualValues[name] = data[name] ? data[name] : false;
      return null;
    });

    return actualValues;
  }
  return null;
}

function CustomerSurveyComp({
  id,
  setInfos,
  states,
  allCitiesFromStates,
  invalidateInfos,
  setRef,
  serverData,
  disabledField,
  from,
  isAdmin,
}) {
  return (
    <FormFull
      onChange={invalidateInfos}
      formRef={setRef}
      onSubmit={setInfos}
      disabled={disabledField}
      actualValues={setActualValues(serverData)}
    >
      <div className="overflow-fix">
        {/* O senhor(a) já é aposentado ou gostaria de se aposentar? */}
        {/* Número do beneficio, Ano da concessão */}
        {/* Você é ou já foi servidor público? */}
        {/* Já fez alguma tentativa de encaminhamento de aposentadoria? */}
        {/* Já fez algum estudo sobre sua aposentadoria? */}
        <CustomerSurvey1 serverData={serverData} />
        {/* Já teve processo trabalhista, para reconhecimento de algum vínculo? */}
        <CustomerSurvey2 />
        {/* Já prestou serviço militar? */}
        <CustomerSurvey3 serverData={serverData} />
        {/* Fez escola Técnica/Agrícola? */}
        <CustomerSurvey4 serverData={serverData} />
        {/* Trabalhou com insalubridade e periculosidade? */}
        <CustomerSurvey5 serverData={serverData} />
        {/*EPI*/}
        <CustomerSurvey6 serverData={serverData} />
        {/*Trabalhou, antes de 28/04/1995, em quais profissões e atividades?*/}
        <CustomerSurvey7 />
        {/* Trabalhou na agricultura? */}
        {/* Possui empregado (s) ou prestador(es) de serviço: E Seus Pais se aposentaram como agricultores? */}
        {/* Participa de cooperativa? / Exerce ou exerceu outra atividade e/ou recebe/recebeu outra renda? */}
        {/* Recebe/recebeu outra renda nas seguintes atividades: atividade turística,
							artística, artesanal, dirigente sindical ou de cooperativa, mandato de vereador? */}
        <CustomerSurvey8
          states={states}
          allCitiesFromStates={allCitiesFromStates}
          serverData={serverData}
        />
        {/* Trabalhou na Pesca em Regime de Economia Familiar? */}
        <CustomerSurvey19 serverData={serverData} />
        {/* Trabalhou em dois locais simultaneamente? */}
        <CustomerSurvey9 serverData={serverData} />
        {/* Possui alguma deficiência? */}
        <CustomerSurvey10 serverData={serverData} />
        {/* Já trabalhou na condição de pessoa com deficiência? */}
        <CustomerSurvey11 />
        {/* Você já recebeu algum auxílio acidente? */}
        <CustomerSurvey12 serverData={serverData} />
        {/* Você quer informar sua senha do MEU INSS agora? */}
        <CustomerSurvey13 serverData={serverData} />
        {/* Qual o método preferencial para entrarmos em contato */}
        <CustomerSurvey14 />
        {/* Se você quiser inserir alguma informação adicional que possa orientar nossos consultores: */}
        <CustomerSurvey15 />
        {/* Escolha de tipo de aposentadoria */}
        {/* {from === "clientes" && !id && isAdmin ? <CustomerSurvey16 /> : null} */}
        {/* Termo de Compromisso */}
        {<CustomerSurvey17 id={id} />}
      </div>
      <div className="flex-justify-end">
        <FormButton
          variant="primary"
          actionType="submit"
          disabled={disabledField}
          name="submit"
        >
          {id ? "Atualizar" : "Cadastrar"}
        </FormButton>
      </div>
    </FormFull>
  );
}

export default React.memo(CustomerSurveyComp);
