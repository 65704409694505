import { performePasswordResetRequest } from "../../../services/RequestService";

function successAlert(onAddAlert) {
  onAddAlert("E-mail enviado com sucesso!", "success");
}

function noConnectionAlert(onAddAlert) {
  onAddAlert("Sem acesso ao serviço", "danger");
}

function notFoundAlert(onAddAlert) {
  onAddAlert("E-mail não cadastrado.", "danger");
}

function genericError(onAddAlert) {
  onAddAlert("Erro ao enviar o e-mail", "danger");
}

export function sendEmailToRequestCode(data, props, loading, setState) {
  if (!loading) {
    setState({ buttonLoading: true }, () => {
      performePasswordResetRequest({ cpf: data.cpf }).then((res) => {
        if (res.ok) {
          sessionStorage.passwordRecoveryCpf = data.cpf
          successAlert(props.onAddAlert);
          props.history.push("/recuperar_senha_codigo", { email: res.data.email });
        } else if (
          res.status === 400 &&
          res.data?.log === "Usuário email não cadastrado"
        ) {
          notFoundAlert(props.onAddAlert);
        } else if (res.status === 500) {
          noConnectionAlert(props.onAddAlert);
        } else {
          genericError(props.onAddAlert);
        }
      }).catch(() => {
        genericError(props.onAddAlert);
      }).finally(() => {
        setState({ buttonLoading: false });
      });
    });
  }
}
