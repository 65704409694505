import React, { Component } from 'react'
import { Form, Row, Col, InputGroup } from 'react-bootstrap'
import { getAllUser } from '../../services/RequestService'
import './InputAutoCompleteComp.css'

export class InputAutoCompleteAlert extends Component {

    constructor(props) {
        super(props)

        this.state = {
            allUsers: [],
            user: [],
            showList: false,
            responsibleId: [],

        }
    }

    componentDidMount = () => {
        getAllUser()
            .then((res) => {
                this.setState(state => ({ ...state, allUsers: res.data }))
            })
    }

    handleInputChange = (e) => {
        e.persist()
        this.setState(state => ({ ...state, [e.target.name]: e.target.value }))
    }

    sixBySixArray = () => {
        const { selectedUsers } = this.state
        let v = []
        let finalData = []
        for (let i = 0; i < selectedUsers.length; i++) {
            if (i !== 0 && i % 6 === 0) {
                finalData.push(v)
                v = []
            } else {
                v.push(selectedUsers[i])
            }
        }
        return finalData
    }

    addUser = (value) => {
        this.props.addUserToList(value)
        this.setState(state => ({ ...state, user: '', showList: false }))
    }

    render() {

        const { user, allUsers, showList } = this.state

        return (
            <div>
                <Row>
                    <Col>
                        <InputGroup>
                            <Form.Control
                                name="user" aria-disabled={true}
                                value={user} onChange={this.handleInputChange} autoComplete="off"
                                as="input"
                                onFocus={() => this.setState(state => ({ ...state, showList: true }))}
                            />

                        </InputGroup>

                        {
                        allUsers.filter(e => e.email.includes(user)).length > 0 ?
                            showList ?
                                <Row >
                                    <Col className="input-auto-complete-box-list clickable">
                                        {
                                            allUsers.filter(e => e.email.includes(user)).map((value, key) => {
                                                return <Row key={key} onClick={() => this.addUser(value)}>
                                                    <Col>{value.email}</Col>
                                                </Row>
                                            })
                                        }
                                    </Col>
                                </Row>

                                : null
                            :
                            <Row >
                                <Col className="input-auto-complete-box-list">
                                    e-mail digitado não encontrado
                                </Col>
                            </Row>
                        }

                    </Col>
                </Row>
            </div>
        )
    }
}

export default InputAutoCompleteAlert
