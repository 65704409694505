const floorFigure = (figure, decimals) => {
  if (!decimals) decimals = 2;
  var d = Math.pow(10, decimals);
  return (parseInt(figure * d) / d).toFixed(decimals);
};

const float = (v) => {
  try {
    if (typeof v === "number") return v;
    v = parseFloat(
      v
        .replaceAll(".", "")
        .replaceAll(",", ".")
        .replaceAll(/[^0-9 .]/g, "")
    );
    return isNaN(v) ? 0 : v;
  } catch (e) {
    return 0.0;
  }
};

const addDaysToDate = (date, days) => {
  date.setDate(date.getDate() + days);
  return new Date(date.getTime());
};

const addMonthsToDate = (date, month) => {
  date.setMonth(date.getMonth() + month);
  return new Date(date.getTime());
};

const downloadFile = (content) => {
  var byteCharacters = atob(content);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: "application/pdf;base64" });
  var fileURL = URL.createObjectURL(file);
  var popUp = window.open(fileURL);
  if (popUp == null || typeof popUp === "undefined") {
    alert("Desative o seu bloqueador de pop-up para visualizar o relatório!");
  }
};

const asyncPdfFileReader = (file, replaceContentType = false) => {
  return new Promise((resolve) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (evt) => {
      let content = evt.target.result
      if (replaceContentType) {
        content = content.split(',')[1]
      }
      resolve(content)
    }
  })
}

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const fn = {
  floorFigure,
  float,
  addDaysToDate,
  addMonthsToDate,
  downloadFile,
  asyncPdfFileReader,
  isMobileDevice
};

export default fn;
