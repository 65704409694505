import React, { Component } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import {
  getCellGetAll,
  deleteAndRelocateUsers,
  deleteCompleteCell,
  deleteAndDescendantsAndUsers,
  getCellForWorkgroup,
} from "../../../services/RequestService";
import { connect } from "react-redux";
import { alertActions } from "../../../store/actions/AlertActions";
import "../../../pages/settings/permission/PermissionsPage.css";

class ModalRemoveWorkGrupCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allCell: [],
      usersLength: undefined,
      selectedChangeCell: undefined,
      moveUserActionId: undefined,
      moveUserTaskTypeId: undefined,
      moveUserActionTypeId: undefined,
      moveUserCustomerOutsourceId: undefined,
      allUsersInCell: [],
      isCellValid: undefined,
      isMoveUserActionIdValid: undefined,
      isMoveUserTaskTypeIdValid: undefined,
      isMoveUserActionTypeIdValid: undefined,
      isMoveUserCustomerOutsourceIdValid: undefined,
      removeChoice: "SemEscolha",
    };
  }

  componentDidMount = () => {
    const { cell, allCell, isIntern, from } = this.props;
    let removeChoice =
      !cell.isIntern && cell.cellAdmin.id === 1 ? "SemEscolha" : "";
    const request = from === "cellPage" ? getCellGetAll : getCellForWorkgroup;
    isIntern
      ? request().then((res) => {
          if (res.ok) {
            let allCell = res.data.filter((e) => e.id !== cell.id);
            if (from === "cellPage") {
              allCell = allCell.filter((cell) => cell.isIntern);
            } else {
              allCell = allCell.filter((cell) => !cell.isIntern);
            }
            this.setState({
              allCell,
              removeChoice,
            });
          }
        })
      : this.setState({ allCell, removeChoice });
  };

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  handleFieldChange = (e) => {
    e.persist();
    const { allCell } = this.state;
    let name = e.target.value;
    let cell = allCell.find((e) => e.name === name);
    let allUsersInCell = cell && cell.users ? cell.users : [];
    this.setState((state) => ({
      ...state,
      selectedChangeCell: cell,
      allUsersInCell,
      isMoveUserActionIdValid: undefined,
      isMoveUserTaskTypeIdValid: undefined,
      isMoveUserActionTypeIdValid: undefined,
      isMoveUserCustomerOutsourceIdValid: undefined,
    }));
  };

  isCellValid = () => {
    const { selectedChangeCell } = this.state;
    let isCellValid =
      selectedChangeCell !== undefined && selectedChangeCell !== "";
    this.setState({ isCellValid });
  };

  isMoveUserActionIdValid = () => {
    const { moveUserActionId } = this.state;
    let isMoveUserActionIdValid =
      moveUserActionId !== undefined && moveUserActionId !== "";
    this.setState({ isMoveUserActionIdValid });
  };

  isMoveUserTaskTypeIdValid = () => {
    const { moveUserTaskTypeId } = this.state;
    let isMoveUserTaskTypeIdValid =
      moveUserTaskTypeId !== undefined && moveUserTaskTypeId !== "";
    this.setState({ isMoveUserTaskTypeIdValid });
  };

  isMoveUserActionTypeIdValid = () => {
    const { moveUserActionTypeId } = this.state;
    let isMoveUserActionTypeIdValid =
      moveUserActionTypeId !== undefined && moveUserActionTypeId !== "";
    this.setState({ isMoveUserActionTypeIdValid });
  };

  isMoveUserCustomerOutsourceIdValid = () => {
    const { moveUserCustomerOutsourceId } = this.state;
    let isMoveUserCustomerOutsourceIdValid =
      moveUserCustomerOutsourceId !== undefined &&
      moveUserCustomerOutsourceId !== "";
    this.setState({
      isMoveUserCustomerOutsourceIdValid,
    });
  };

  submit = () => {
    const {
      removeChoice,
      selectedChangeCell,
      moveUserActionId,
      moveUserTaskTypeId,
      moveUserActionTypeId,
      moveUserCustomerOutsourceId,
      allUsersInCell,
    } = this.state;
    const { cell } = this.props;

    let auxMoveUserActionId = allUsersInCell.find(
      (user) => user.name === moveUserActionId
    );
    let auxMoveUserTaskTypeId = allUsersInCell.find(
      (user) => user.name === moveUserTaskTypeId
    );
    let auxMoveUserActionTypeId = allUsersInCell.find(
      (user) => user.name === moveUserActionTypeId
    );
    let auxMoveUserCustomerOutsourceId = allUsersInCell.find(
      (user) => user.name === moveUserCustomerOutsourceId
    );

    if (removeChoice === "SemEscolha") {
      if (
        cell?.id &&
        selectedChangeCell?.id &&
        auxMoveUserActionId?.id &&
        auxMoveUserTaskTypeId?.id &&
        auxMoveUserActionTypeId?.id &&
        auxMoveUserCustomerOutsourceId?.id
      ) {
        deleteAndDescendantsAndUsers({
          cellId: cell.id,
          moveCellId: selectedChangeCell.id,
          moveUserActionId: auxMoveUserActionId.id,
          moveUserTaskTypeId: auxMoveUserTaskTypeId.id,
          moveUserActionTypeId: auxMoveUserActionTypeId.id,
          moveUserCustomerOutsourceId: auxMoveUserCustomerOutsourceId.id,
        }).then((res) => {
          if (res.ok) {
            this.props.onAddAlert(
              "Célula " + cell.name + " removida",
              "success",
              false
            );
            this.props.onHide(true);
          } else {
            this.props.onAddAlert(res.data.log, "danger", false);
          }
        });
      } else {
        this.props.onAddAlert(
          "É preciso selecionar um responsável para todos os vinculos",
          "danger",
          false
        );
      }
    } else {
      if (removeChoice === "RemoveAndRelocate") {
        if (cell?.id && selectedChangeCell?.id) {
          deleteAndRelocateUsers({
            deleteId: cell.id,
            moveId: selectedChangeCell.id,
          }).then((res) => {
            if (res.ok) {
              this.props.onAddAlert(
                "Célula " + cell.name + " removida",
                "success",
                false
              );
              this.props.onHide(true);
            } else {
              this.props.onAddAlert(res.data.log, "danger", false);
            }
          });
        } else {
          this.props.onAddAlert(
            "É preciso selecionar alguém para receber a relocação",
            "danger",
            false
          );
        }
      } else if (removeChoice === "RemoveAll") {
        if (
          cell?.id &&
          selectedChangeCell?.id &&
          auxMoveUserActionId?.id &&
          auxMoveUserTaskTypeId?.id &&
          auxMoveUserActionTypeId?.id &&
          auxMoveUserCustomerOutsourceId?.id
        ) {
          deleteCompleteCell({
            cellId: cell.id,
            moveCellId: selectedChangeCell.id,
            moveUserActionId: auxMoveUserActionId.id,
            moveUserTaskTypeId: auxMoveUserTaskTypeId.id,
            moveUserActionTypeId: auxMoveUserActionTypeId.id,
            moveUserCustomerOutsourceId: auxMoveUserCustomerOutsourceId.id,
          }).then((res) => {
            if (res.ok) {
              this.props.onAddAlert(
                "Célula " + cell.name + " removida",
                "success",
                false
              );
              this.props.onHide(true);
            } else {
              this.props.onAddAlert(res.data.log, "danger", false);
            }
          });
        } else {
          this.props.onAddAlert(
            "É preciso selecionar um responsável para todos os vinculos",
            "danger",
            false
          );
        }
      }
    }
  };

  render() {
    const { show, onHide, cell } = this.props;
    const {
      selectedChangeCell,
      allCell,
      allUsersInCell,
      isCellValid,
      isMoveUserActionIdValid,
      isMoveUserTaskTypeIdValid,
      isMoveUserActionTypeIdValid,
      isMoveUserCustomerOutsourceIdValid,
      removeChoice,
      moveUserActionId,
      moveUserTaskTypeId,
      moveUserActionTypeId,
      moveUserCustomerOutsourceId,
    } = this.state;
    return (
      <Modal
        show={show}
        onHide={onHide}
        size="sm"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Remover célula</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>Você tem certeza que deseja remover a célula {cell.name}?</p>
              <br />
              <p className={"is-required"}>
                Essa célula pode conter vinculos sensíveis dentro do sistema!
                {removeChoice === "SemEscolha"
                  ? " Escolha para qual célula e usuarios deseja atribuir."
                  : " Escolha a melhor ação de remoção e para qual célula e usuarios deseja atribuir."}
              </p>
            </Col>
          </Row>
          {removeChoice !== "SemEscolha" && (
            <div className={"justify-content-center pt-2"}>
              <Form.Row>
                <Form.Check
                  inline
                  type="radio"
                  className={"mb-2"}
                  label={
                    <Form.Label>
                      Excluir a células e realocar seus usuários{" "}
                      <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                  }
                  name="removeChoice"
                  value="RemoveAndRelocate"
                  onChange={this.handleInputChange}
                  id="radio1"
                  checked={removeChoice === "RemoveAndRelocate"}
                />
              </Form.Row>
              <Form.Row>
                <Form.Check
                  inline
                  type="radio"
                  className={"mb-4"}
                  label={
                    <Form.Label>
                      Excluir a célula, seus usuários e realocar suas
                      responsabilidades{" "}
                      <Form.Label className="is-required">*</Form.Label>
                    </Form.Label>
                  }
                  name="removeChoice"
                  value="RemoveAll"
                  onChange={this.handleInputChange}
                  id="radio2"
                  checked={removeChoice === "RemoveAll"}
                />
              </Form.Row>
            </div>
          )}
          <Form.Group>
            <Form.Label>
              Nova Célula<Form.Label className="is-required">*</Form.Label>
            </Form.Label>
            <Form.Control
              as="select"
              name="selectedChangeCell"
              value={
                selectedChangeCell === undefined ? "" : selectedChangeCell.name
              }
              onChange={this.handleFieldChange}
              onBlur={() => this.isCellValid()}
              className={
                isCellValid === undefined
                  ? ""
                  : isCellValid
                  ? "is-valid"
                  : "is-invalid"
              }
            >
              <option></option>
              {allCell.map((value) => {
                return <option key={value.id}>{value.name}</option>;
              })}
            </Form.Control>
            <Form.Text className="is-required">
              {isCellValid === undefined
                ? ""
                : isCellValid
                ? ""
                : "Uma Célula deve ser escolhida"}
            </Form.Text>
          </Form.Group>

          {selectedChangeCell !== undefined ? (
            allUsersInCell !== [] ? (
              <div>
                <Form.Group>
                  <Form.Label>
                    Novo responsável pelo Andamento
                    <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="moveUserActionId"
                    value={
                      moveUserActionId === undefined
                        ? ""
                        : moveUserActionId.name
                    }
                    onChange={this.handleInputChange}
                    onBlur={() => this.isMoveUserActionIdValid()}
                    className={
                      isMoveUserActionIdValid === undefined
                        ? ""
                        : isMoveUserActionIdValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  >
                    <option></option>
                    {allUsersInCell.map((value) => {
                      return <option key={value.id}>{value.name}</option>;
                    })}
                  </Form.Control>
                  <Form.Text className="is-required">
                    {isMoveUserActionIdValid === undefined
                      ? ""
                      : isMoveUserActionIdValid
                      ? ""
                      : "Um usuário deve ser escolhido"}
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Novo responsável pelo Tipo de Andamento
                    <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="moveUserActionTypeId"
                    value={
                      moveUserActionTypeId === undefined
                        ? ""
                        : moveUserActionTypeId.name
                    }
                    onChange={this.handleInputChange}
                    onBlur={() => this.isMoveUserActionTypeIdValid()}
                    className={
                      isMoveUserActionTypeIdValid === undefined
                        ? ""
                        : isMoveUserActionTypeIdValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  >
                    <option></option>
                    {allUsersInCell.map((value) => {
                      return <option key={value.id}>{value.name}</option>;
                    })}
                  </Form.Control>
                  <Form.Text className="is-required">
                    {isMoveUserActionTypeIdValid === undefined
                      ? ""
                      : isMoveUserActionTypeIdValid
                      ? ""
                      : "Um usuário deve ser escolhido"}
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Novo responsável pelo Tipo de Tarefa
                    <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="moveUserTaskTypeId"
                    value={
                      moveUserTaskTypeId === undefined
                        ? ""
                        : moveUserTaskTypeId.name
                    }
                    onChange={this.handleInputChange}
                    onBlur={() => this.isMoveUserTaskTypeIdValid()}
                    className={
                      isMoveUserTaskTypeIdValid === undefined
                        ? ""
                        : isMoveUserTaskTypeIdValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  >
                    <option></option>
                    {allUsersInCell.map((value) => {
                      return <option key={value.id}>{value.name}</option>;
                    })}
                  </Form.Control>
                  <Form.Text className="is-required">
                    {isMoveUserTaskTypeIdValid === undefined
                      ? ""
                      : isMoveUserTaskTypeIdValid
                      ? ""
                      : "Um usuário deve ser escolhido"}
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Novo responsável pelos Clientes
                    <Form.Label className="is-required">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="moveUserCustomerOutsourceId"
                    value={
                      moveUserCustomerOutsourceId === undefined
                        ? ""
                        : moveUserCustomerOutsourceId.name
                    }
                    onChange={this.handleInputChange}
                    onBlur={() => this.isMoveUserCustomerOutsourceIdValid()}
                    className={
                      isMoveUserCustomerOutsourceIdValid === undefined
                        ? ""
                        : isMoveUserCustomerOutsourceIdValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  >
                    <option></option>
                    {allUsersInCell.map((value) => {
                      return <option key={value.id}>{value.name}</option>;
                    })}
                  </Form.Control>
                  <Form.Text className="is-required">
                    {isMoveUserCustomerOutsourceIdValid === undefined
                      ? ""
                      : isMoveUserCustomerOutsourceIdValid
                      ? ""
                      : "Um usuário deve ser escolhido"}
                  </Form.Text>
                </Form.Group>
              </div>
            ) : (
              <p>Essa célula parece não conter usuários cadastrados!</p>
            )
          ) : null}
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Col className=" p-0">
              <Button
                variant="secondary"
                className="w-100 button-without-rounded"
                onClick={onHide}
              >
                Voltar
              </Button>
            </Col>
            <Col className="p-0">
              <Button
                variant="danger"
                className="w-100 button-without-rounded"
                onClick={() => this.submit()}
                disabled={
                  removeChoice === null ||
                  !isMoveUserActionIdValid ||
                  !isMoveUserTaskTypeIdValid ||
                  !isMoveUserActionTypeIdValid ||
                  !isMoveUserCustomerOutsourceIdValid
                  // || (isMoveUserActionTypeIdValid !== undefined ? !isMoveUserActionTypeIdValid : true)
                  // || (isMoveUserTaskTypeIdValid !== undefined ? !isMoveUserTaskTypeIdValid : true)
                  // || (isMoveUserActionIdValid !== undefined ? !isMoveUserActionIdValid : true)
                }
              >
                Remover
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalRemoveWorkGrupCell);
