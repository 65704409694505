import React from "react";
import { Col, Row } from "react-bootstrap";

const CardTitle = ({ title, onButtonClick }) => {
  return (
    <div className="px-3">
      <Row>
        <Col>
          <h5>{title}</h5>
          <hr />
        </Col>

        {/* {onButtonClick ? (
            <Col className="text-right my-2 ml-2">
              <Button variant="primary" onClick={onButtonClick}>
                <i className="fas fa-plus-circle pr-2" />
                <span>Adicionar</span>
              </Button>
            </Col>
          ) : null} */}
      </Row>
    </div>
  );
};

export default CardTitle;
