import React from "react";
import { connect } from "react-redux";
import { TableComponent } from "../../../../genericComponents";
import { reducerKeys } from "../../../../store/reducers/reducer";
import { Card, CardTitle } from "../components";
import styles from "../GeneralPanelPage.module.css";

function SeventhGroup({ colProps, infos, loading }) {
  const [currentDocument, setCurrentDocuments] = React.useState(null);
  return (
    <Card colProps={colProps}>
      <CardTitle title="PRODUTOS COMERCIALIZADOS" />
      <div className="px-3">
        <TableComponent
          loading={loading}
          containerClassName={styles.tableMaxHeight}
          countLabel="produtos"
          countLabelSingular="produto"
          header={[
            { label: "Tipo de Produto", sortProperty: "type" },
            { label: "Dia", sortProperty: "dayQuantity" },
            {
              label: "Mês Atual",
              sortProperty: "monthQuantity",
            },
            {
              label: "Mês Anterior",
              sortProperty: "prevMonthQuantity",
            },
            {
              label: "Trimestral",
              sortProperty: "quarterlyAverage",
            },
            { label: "Total", sortProperty: "total" },
          ]}
          currentList={currentDocument}
          setCurrentList={(newCurrentDocument) =>
            setCurrentDocuments(newCurrentDocument)
          }
          list={infos.marketedProducts}
          forEach={(item) => [
            { label: item.type },
            { label: item.dayQuantity },
            { label: item.monthQuantity },
            { label: item.prevMonthQuantity },
            { label: item.quarterlyAverage },
            { label: item.total },
          ]}
        />
      </div>
    </Card>
  );
}

function stateToProps(state) {
  const { seventhGroupInfos, seventhGroupLoading } =
    state[reducerKeys.generalPanel];
  return { infos: seventhGroupInfos, loading: seventhGroupLoading };
}

export default connect(stateToProps)(SeventhGroup);
