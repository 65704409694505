import React, { Component } from "react";
import { Modal, Form, Button, Row } from "react-bootstrap";
import {
  putProcessInsertProcessStatus,
  postProcessUpdateProcessStatus,
  getAllProcessStatus,
} from "../../../services/RequestService";
import "../../../pages/settings/process/SettingsProcessPage.css";

class ModalProcessStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processStatus:
        props.name === undefined || props.name === null ? "" : props.name,
      isProcessStatusValid: undefined,
      isProcessStatusUnique: undefined,
      allProcessStatus: [],
    };
  }

  componentDidMount = () => {
    getAllProcessStatus().then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, allProcessStatus: res.data }));
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  submit = () => {
    const { processStatus } = this.state;
    const { id } = this.props;

    if (id === undefined) {
      putProcessInsertProcessStatus({ name: processStatus }).then((res) => {
        if (res.ok) {
          this.props.onHide(true);
        }
      });
    } else {
      postProcessUpdateProcessStatus({ id: id, name: processStatus }).then(
        (res) => {
          if (res.ok) {
            this.props.onHide(true);
          }
        }
      );
    }
  };

  isProcessTypeValid = () => {
    const { processStatus, allProcessStatus } = this.state;
    let isProcessStatusValid = processStatus !== "";
    let isProcessStatusUnique = true;
    for (let i in allProcessStatus) {
      let b = allProcessStatus[i];
      if (b.name === processStatus) {
        isProcessStatusUnique = false;
        break;
      }
    }
    this.setState((state) => ({
      ...state,
      isProcessStatusValid,
      isProcessStatusUnique,
    }));
  };

  isBtnDisabled = () => {
    const { processStatus, allProcessStatus } = this.state;
    if (
      processStatus === "CRIADO" ||
      processStatus === "AGUARDANDO ACEITE DA PROPOSTA" ||
      processStatus === "AGUARDANDO ASSINATURA CONTRATO" ||
      processStatus === "AGUARDANDO PAGAMENTO" ||
      processStatus === "EM ANDAMENTO" ||
      processStatus === "FINALIZADO"
    )
      return true;

    for (let i in allProcessStatus) {
      let b = allProcessStatus[i];
      if (b.name === processStatus) {
        return true;
      }
    }

    return processStatus === "";
  };

  render() {
    const { processStatus, isProcessStatusValid, isProcessStatusUnique } =
      this.state;
    const { show, onHide, id } = this.props;

    return (
      <Modal size="sm" show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Status de processo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              Nome<Form.Label className="ml-1 is-required">*</Form.Label>
            </Form.Label>
            <Form.Control
              name="processStatus"
              value={processStatus}
              onChange={this.handleInputChange}
              onBlur={() => this.isProcessTypeValid()}
              className={
                isProcessStatusValid === undefined
                  ? ""
                  : isProcessStatusValid
                  ? isProcessStatusUnique === undefined
                    ? ""
                    : isProcessStatusUnique
                    ? "is-valid"
                    : "is-invalid"
                  : "is-invalid"
              }
              disabled={
                processStatus === "CRIADO" ||
                processStatus === "AGUARDANDO ACEITE DA PROPOSTA" ||
                processStatus === "AGUARDANDO ASSINATURA CONTRATO" ||
                processStatus === "AGUARDANDO PAGAMENTO" ||
                processStatus === "EM ANDAMENTO" ||
                processStatus === "FINALIZADO"
              }
            />

            <Form.Text className="is-required">
              {processStatus === "CRIADO" ||
              processStatus === "AGUARDANDO ACEITE DA PROPOSTA" ||
              processStatus === "AGUARDANDO ASSINATURA CONTRATO" ||
              processStatus === "AGUARDANDO PAGAMENTO" ||
              processStatus === "EM ANDAMENTO" ||
              processStatus === "FINALIZADO"
                ? "Para previnir erros no sistema, esse status é impedido de ser editado"
                : ""}
            </Form.Text>
            <Form.Text className="is-required">
              {isProcessStatusValid === undefined
                ? ""
                : isProcessStatusValid
                ? isProcessStatusUnique === undefined
                  ? ""
                  : isProcessStatusUnique
                  ? ""
                  : "O valor digitado já é um status de processo"
                : "O campo não pode ser vazio"}
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Button
              variant="primary"
              disabled={this.isBtnDisabled()}
              className="w-100 button-without-rounded"
              onClick={this.submit}
            >
              {id === undefined || id === null ? "Cadastrar" : "Atualizar"}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalProcessStatus;
