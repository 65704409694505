import React, { Component } from 'react'
import { Button, Col, Form, Modal } from 'react-bootstrap'
import '../../pages/financial/FinancialPage.css'

class ModalFilterFinancial extends Component {

    constructor (props) {
        super(props)

        this.state = {
            startDate: '',
            endDate: '',
            filterType: '',
            month: '',
            months: {
                'january': 1, 'february': 2, 'march': 3, 'april': 4, 'may': 5, 'june': 6,
                'july': 7, 'august': 8, 'september': 9, 'october': 10, 'november': 11, 'december': 12
            },
            monthsNumbers: undefined,
            startDateTimestamp: '',
            endDateTimestamp: ''
        }
    }

    componentDidMount = () => {
        let month = new Date().getMonth()+1
        let year = new Date().getFullYear()
        let monthsNumbers = [
            [1, "Janeiro", "january"], [2, "Fevereiro", "february"], [3, "Março", "march"],
            [4, "Abril", "april"], [5, "Maio", "may"], [6, "Junho", "june"],
            [7, "Julho", "july"], [8, "Agosto", "august"], [9, "Setembro", "september"],
            [10, "Outubro", "october"], [11, "Novembro", "november"], [12, "Dezembro", "december"],
        ]
        let sliceForward = monthsNumbers.slice(month, 12)
        sliceForward.forEach(item => {
            item.push(year-1)
        })
        let sliceBackward = monthsNumbers.slice(0, month)
        sliceBackward.forEach(item => {
            item.push(year)
        })
        monthsNumbers = sliceForward.concat(sliceBackward)
        this.setState(state => ({ ...state, monthsNumbers}))
    }

    handleInputChange = e => {
        e.persist()
        const {name, value} = e.target
        this.setState(state => ({ ...state, [name]: value}))
    }

    chooseMonth = (month, year) => {
        const {months} = this.state
        this.setState(state => ({ ...state, month}))
        let date = new Date(year, months[month]-1, 1);
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
        let startDateTimestamp = firstDay.getTime()
        let endDateTimestamp = lastDay.getTime()
        this.setState(state => ({ ...state, startDateTimestamp, endDateTimestamp}))
    }

    submitFilter = () => {
        const {filterType, startDateTimestamp, endDateTimestamp, startDate, endDate} = this.state
        if(filterType === 'custom'){
            let a = startDate.split("-")
            let sd = new Date(parseInt(a[0]), parseInt(a[1])-1, parseInt(a[2]), 0, 0, 0).getTime()
            let b = endDate.split("-")
            let ed = new Date(parseInt(b[0]), parseInt(b[1])-1, parseInt(b[2]), 23, 59, 59).getTime()
            this.props.setTimestampFilter(sd, ed)
        }else{
            this.props.setTimestampFilter(startDateTimestamp, endDateTimestamp)
        }
    }

    render () {

        const {startDate, endDate, filterType, month, monthsNumbers} = this.state
        const {show, onHide} = this.props

        return (
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Filtro</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row className="financial-bottom-divider">
                        <Col>
                            <Form.Group>
                                <Form.Label>Filtro:</Form.Label>
                                <br/>
                                <Form.Check
                                    inline
                                    type="radio"
                                    name="filterType"
                                    value="custom"
                                    label="Customizado"
                                    id="radio1"
                                    onChange={this.handleInputChange}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    name="filterType"
                                    value="monthly"
                                    label="Mensal"
                                    id="radio2"
                                    onChange={this.handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>

                        </Col>
                    </Form.Row>
                    <Form.Row className="financial-bottom-divider">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Data inicial</Form.Label>
                                    <Form.Control
                                        value={startDate}
                                        onChange={this.handleInputChange}
                                        disabled={filterType !== "custom" || filterType === ''}
                                        type="date" name="startDate"
                                    />
                                </Form.Group>

                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Data final</Form.Label>
                                    <Form.Control
                                        value={endDate}
                                        onChange={this.handleInputChange}
                                        disabled={filterType !== "custom" || filterType === ''}
                                        type="date" name="endDate"
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    <Form.Row className="mb-2">
                        {
                            monthsNumbers !== undefined &&
                            monthsNumbers.slice(0, 3).map(item => {
                                return <Col key={item[0]}>
                                    <Button variant={month===item[2] ? 'primary' : 'outline-primary'} className="w-100"
                                            onClick={()=>this.chooseMonth(item[2], item[3])}
                                            disabled={filterType !== "monthly" || filterType === ''}>
                                        {item[1]}
                                    </Button>
                                </Col>
                            })
                        }
                    </Form.Row>
                    <Form.Row className="mb-2">
                        {
                            monthsNumbers !== undefined &&
                            monthsNumbers.slice(3, 6).map(item => {
                                return <Col key={item[0]}>
                                    <Button variant={month===item[2] ? 'primary' : 'outline-primary'} className="w-100"
                                            onClick={()=>this.chooseMonth(item[2], item[3])}
                                            disabled={filterType !== "monthly" || filterType === ''}>
                                        {item[1]}
                                    </Button>
                                </Col>
                            })
                        }
                    </Form.Row>
                    <Form.Row className="mb-2">
                        {
                            monthsNumbers !== undefined &&
                            monthsNumbers.slice(6, 9).map(item => {
                                return <Col key={item[0]}>
                                    <Button variant={month===item[2] ? 'primary' : 'outline-primary'} className="w-100"
                                            onClick={()=>this.chooseMonth(item[2], item[3])}
                                            disabled={filterType !== "monthly" || filterType === ''}>
                                        {item[1]}
                                    </Button>
                                </Col>
                            })
                        }
                    </Form.Row>
                    <Form.Row className="mb-2">
                        {
                            monthsNumbers !== undefined &&
                            monthsNumbers.slice(9, 12).map(item => {
                                return <Col key={item[0]}>
                                    <Button variant={month===item[2] ? 'primary' : 'outline-primary'} className="w-100"
                                            onClick={()=>this.chooseMonth(item[2], item[3])}
                                            disabled={filterType !== "monthly" || filterType === ''}>
                                        {item[1]}
                                    </Button>
                                </Col>
                            })
                        }
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={
                            filterType === '' ? true:
                                filterType === 'monthly' ? month === '' :
                                    filterType === 'custom' ? startDate === '' || endDate === '' || startDate > endDate :
                                        false
                    } onClick={()=>this.submitFilter()}>
                        Filtrar
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalFilterFinancial
