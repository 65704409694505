import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  getAllUser,
  getPrivilegeGetAll,
  // putInsertAlertActionDelayDelivery,
  putInsertAlertProcessDelayDelivery,
  putInsertAlertTaskDelayDelivery,
} from "../../services/RequestService";

class ModalJustify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: "",
      responsible: "",
      responsibles: [],
    };
  }

  componentDidMount = () => {
    getPrivilegeGetAll().then((res) => {
      if (res.ok) {
        let privilegeId = null;
        res.data.forEach((item) => {
          if (item.name === "conceder_adiamento_prazo") {
            privilegeId = item.id;
          }
        });
        getAllUser({ privilegeId }).then((res1) => {
          if (res1.ok) {
            this.setState((state) => ({ ...state, responsibles: res1.data }));
          }
        });
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  submit = () => {
    const { description, responsible, responsibles } = this.state;
    const { taskId, processId } = this.props;
    let userId = null;
    responsibles.forEach((item) => {
      if (item.name === responsible) {
        userId = item.id;
      }
    });
    let data = {
      description,
      userId,
    };
    if (taskId !== null && taskId !== undefined) {
      data["taskId"] = taskId;
      putInsertAlertTaskDelayDelivery(data).then((_) => {
        this.props.onHide();
      });
    } else {
      if (processId !== null && processId !== undefined) {
        data["processId"] = processId;
        putInsertAlertProcessDelayDelivery(data).then((_) => {
          this.props.onHide();
        });
      }
    }
  };

  render() {
    const { description, responsible, responsibles } = this.state;
    const { show, onHide, taskId, processId } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header>
          <Modal.Title>
            Justificar adiamento do
            {taskId !== null && taskId !== undefined
              ? " tarefa"
              : processId !== null && processId !== undefined
              ? " processo"
              : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Justificativa</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={description}
                onChange={this.handleInputChange}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Responsável</Form.Label>
              <Form.Control
                as="select"
                name="responsible"
                value={responsible}
                onChange={this.handleInputChange}
              >
                <option />
                {responsibles.map((item) => {
                  return <option key={item.id}>{item.name}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="secondary" onClick={() => this.props.onHide()}>
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                disabled={description === "" || responsible === ""}
                onClick={this.submit}
              >
                Justificar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalJustify;
