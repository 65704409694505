import { parse } from "date-fns";
import React from "react";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import {
  CheckInputs,
  Input,
  InputDate,
  InputNumber,
  RadioGroup,
  Select,
} from "../../../genericComponents";
import { validations } from "../../../utils";

function CustomerSurvey8({ states, allCitiesFromStates, serverData }) {
  const [worked, setWorked] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState(null);

  React.useEffect(() => {
    if (serverData?.optionAgriculture) {
      setWorked(serverData.optionAgriculture === "Sim");
      setSelectedState(serverData.terrainState);
    }
  }, [serverData, setWorked, setSelectedState]);

  return (
    <div>
      <Row>
        <RadioGroup
          label="Trabalhou na agricultura?"
          name="optionAgriculture"
          options={[
            {
              value: "Sim",
              label: "Sim",
            },
            {
              value: "Não",
              label: "Não",
            },
          ]}
          required='O campo "Trabalhou na agricultura?" é obrigatório.'
          inline
          onChange={(event, value) => {
            setWorked(value === "Sim");
          }}
        />
      </Row>
      {worked && (
        <>
          <Row>
            <InputDate
              label="Data do casamento ou união estável:"
              name="weddingDate"
              required='O campo "Data do casamento ou união estável" é obrigatório.'
              minDate={new Date("01/01/1900")}
              maxDate={new Date()}
              validation={(value) => {
                const result = validations.isDATE(
                  parse(value, "dd/MM/yyyy", new Date()),
                  new Date("01/01/1900"),
                  new Date(),
                  "dd/MM/yyyy"
                );
                return result;
              }}
            />
          </Row>
          <Row>
            <Input
              label="Qual período trabalhou na agricultura"
              name="periodWorked"
              maxLength={255}
            />
          </Row>
          <Row>
            <RadioGroup
              label="Você possui documentos para comprovar o rural?"
              name="haveDocumentsForProof"
              options={[
                {
                  value: "Sim",
                  label: "Sim",
                },
                {
                  value: "Não",
                  label: "Não",
                },
              ]}
              required='O campo "Você possui documentos para comprovar o rural?" é obrigatório.'
            />
          </Row>
          <Row>
            <RadioGroup
              label="Condição em relação ao imóvel?"
              name="condition"
              options={[
                "Proprietário",
                "Possuidor",
                "Comodatário",
                "Arrendatário",
                "Parceiro",
                "Meeiro",
                "Usufrutuário",
                "Condômino",
                "Posseiro",
                "Assentado",
                "Acampado",
              ].map((item) => ({ label: item, value: item }))}
              required='O campo "Condição em relação ao imóvel?" é obrigatório.'
              inline
            />
          </Row>
          <Row>
            <Select
              label="Estado que o terreno se localiza"
              name="terrainState"
              required='O valor do campo "Estado que o terreno se localiza" deve ser selecionado.'
              placeholder="Selecione um estado"
              options={states}
              onChange={(event, value) => {
                setSelectedState(value);
              }}
            />

            <Select
              label="Cidade do terreno"
              name="terrainCity"
              disabled={!selectedState}
              required='O valor do campo "Cidade do terreno" deve ser selecionado.'
              placeholder="Selecione uma cidade"
              options={selectedState ? (allCitiesFromStates[selectedState] || []).map((city) => ({ label: city, value: city, })) : []}
            />
            <InputNumber
              label="Área total do imóvel"
              name="propertyArea"
              required='O campo "Área total do imóvel" é obrigatório.'
              maxLength={20}
            />

            <InputNumber
              label="Área explorada"
              name="exploredArea"
              required='O campo "Área explorada" é obrigatório.'
              maxLength={20}
            />
          </Row>
          <Row>
            <Input
              label="Informe o que explora/explorava na atividade rural e destinação (milho, feijão, porcos, etc.):"
              name="resources"
              required='O campo "Informe o que explora/explorava na atividade rural e destinação (milho, feijão, porcos, etc.):" é obrigatório.'
              maxLength={255}
            />
          </Row>
          <Row>
            <Col>
              <Row>
                <RadioGroup
                  label="Possui empregado (s) ou prestador(es) de serviço?"
                  name="employeeOrServiceProvider"
                  options={["Sim", "Não"].map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  required='O campo "Possui empregado (s) ou prestador(es) de serviço?" é obrigatório.'
                  inline
                />
              </Row>
            </Col>

            <Col>
              <Row>
                <RadioGroup
                  label="Seus Pais se aposentaram como agricultores?"
                  name="parentsFarmer"
                  options={["Sim", "Não"].map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  required='O campo "Seus Pais se aposentaram como agricultores?" é obrigatório.'
                  inline
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm="auto" md="auto" lg="auto" xl="auto">
              <Row>
                <CheckInputs
                  label="Participa de cooperativa?"
                  checked={Boolean(serverData?.optionCooperative)}
                  inputs={[
                    {
                      name: "optionCooperative",
                      required:
                        'O campo "Participa de cooperativa?" é obrigatório por estar selecionado.',
                      inputMaskProps: { placeholder: "Informe qual" },
                      maxLength: 100,
                    },
                  ]}
                />
              </Row>
            </Col>
            <Col>
              <Row>
                <CheckInputs
                  label="Exerce ou exerceu outra atividade e/ou recebe/recebeu outra renda?"
                  checked={Boolean(serverData?.optionActivityOrExtraIncome)}
                  inputs={[
                    {
                      name: "optionActivityOrExtraIncome",
                      required:
                        'O campo "Exerce ou exerceu outra atividade e/ou recebe/recebeu outra renda?" é obrigatório por estar selecionado.',
                      inputMaskProps: { placeholder: "Informe qual" },
                      maxLength: 220,
                    },
                  ]}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <CheckInputs
              label="Recebe/recebeu outra renda nas seguintes atividades: atividade turística, artística, artesanal, dirigente sindical ou de cooperativa, mandato de vereador?"
              checked={Boolean(serverData?.optionActivityIncome)}
              inputs={[
                {
                  name: "optionActivityIncome",
                  required:
                    'O campo "Recebe/recebeu outra renda nas seguintes atividades: atividade turística, artística, artesanal, dirigente sindical ou de cooperativa, mandato de vereador?" é obrigatório por estar selecionado.',
                  inputMaskProps: { placeholder: "Informe qual" },
                  maxLength: 220,
                },
              ]}
            />
          </Row>
        </>
      )}
    </div>
  );
}

export default React.memo(CustomerSurvey8);
