import React from "react";
import { Row } from "react-bootstrap";
import { Select } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { degreeOfKinship } from "../models/selects";

function PensionRegister9() {
  return (
    <Row>
      <Select
        label="Qual seu grau de parentesco com o(a) falecido(a)?"
        name={pensionFormNames.degreeOfKinship}
        required={required.default}
        placeholder="Selecione o grau de parentesco"
        options={degreeOfKinship.map((item) => ({
          label: item,
          value: item,
        }))}
      />
    </Row>
  );
}
export default React.memo(PensionRegister9);
