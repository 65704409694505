import React from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

function RegisterChoose({ from }) {
  const history = useHistory();
  return (
    <div>
      {from === "login" ? (
        <Link className="mb-4" to="/">
          Voltar
        </Link>
      ) : null}
      <p>
        {from === "login"
          ? "Qual serviço você busca?"
          : "Escolha o tipo de cliente que deseja adicionar"}
      </p>
      <Button
        variant="primary"
        onClick={() =>
          history.push(
            `/${from === "login" ? "cadastro" : "cliente"}/${
              from === "login" ? "aposentadoria" : "planejamento"
            }/${from}`
          )
        }
        block
      >
        {from === "login" ? "Aposentadoria" : "Planejamento Previdenciário"}
      </Button>
      {from !== "login" && (
        <>
          <Button
            variant="primary"
            onClick={() =>
              history.push(
                `/${
                  from === "login" ? "cadastro" : "cliente"
                }/encaminhamento/${from}`
              )
            }
            block
          >
            Encaminhamento de Aposentadoria
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              history.push(
                `/${
                  from === "login" ? "cadastro" : "cliente"
                }/encaminhamento_direto/${from}`
              )
            }
            block
          >
            Encaminhamento de Aposentadoria Direto
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              history.push(
                `/${
                  from === "login" ? "cadastro" : "cliente"
                }/pre_analise_aposentadoria/${from}`
              )
            }
            block
          >
            Pré Análise Aposentadoria
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              history.push(
                `/${
                  from === "login" ? "cadastro" : "cliente"
                }/pre_analise_pensao_morte/${from}`
              )
            }
            block
          >
            Pré Análise Pensão por Morte
          </Button>
        </>
      )}
      <Button
        variant="primary"
        onClick={() =>
          history.push(
            `/${from === "login" ? "cadastro" : "cliente"}/pensao/${from}`
          )
        }
        block
      >
        Pensão por Morte
      </Button>
    </div>
  );
}

export default RegisterChoose;
