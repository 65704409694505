import React from "react";
import { Col } from "react-bootstrap";

function CardComponent({ colProps = {}, children }) {
  return (
    <Col {...colProps} className="p-0 d-flex flex-column">
      <div className="py-3 m-2 card flex-fill">{children}</div>
    </Col>
  );
}

export default CardComponent;
