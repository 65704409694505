import { parse } from "date-fns";
import React from "react";
import { Row } from "react-bootstrap";
import { Input, InputDate, RadioGroup } from "../../../genericComponents";
import { validations } from "../../../utils";

function CustomerSurvey19({ serverData }) {
  const [worked, setWorked] = React.useState(false);

  React.useEffect(() => {
    if (serverData?.familyEconomyRegime) {
      setWorked(serverData.familyEconomyRegime === "Sim");
    }
  }, [serverData, setWorked]);

  return (
    <div>
      <Row>
        <RadioGroup
          label="Trabalhou na Pesca em Regime de Economia Familiar?"
          name="familyEconomyRegime"
          options={[
            {
              value: "Sim",
              label: "Sim",
            },
            {
              value: "Não",
              label: "Não",
            },
          ]}
          required='O campo "Trabalhou na Pesca em Regime de Economia Familiar?" é obrigatório.'
          inline
          onChange={(event, value) => {
            setWorked(value === "Sim");
          }}
        />
      </Row>
      {worked && (
        <>
          <Row>
            <InputDate
              label="Data do casamento ou união estável:"
              name="weddingDateFishing"
              required='O campo "Data do casamento ou união estável" é obrigatório.'
              minDate={new Date("01/01/1900")}
              maxDate={new Date()}
              validation={(value) => {
                const result = validations.isDATE(
                  parse(value, "dd/MM/yyyy", new Date()),
                  new Date("01/01/1900"),
                  new Date(),
                  "dd/MM/yyyy"
                );
                return result;
              }}
            />
          </Row>
          <Row>
            <Input
              label="Qual período trabalhou na pesca artesanal"
              name="artisanalFishing"
              maxLength={255}
            />
          </Row>
          <Row>
            <RadioGroup
              label="Você possui documentos para comprovar a pesca artesanal?"
              name="haveDocumentsForProofFishing"
              options={[
                {
                  value: "Sim",
                  label: "Sim",
                },
                {
                  value: "Não",
                  label: "Não",
                },
              ]}
              required='O campo "Você possui documentos para comprovar o rural?" é obrigatório.'
            />
          </Row>
          <Row>
            <Input
              label="Tipo da embarcação que era utilizada?"
              name="vesselType"
              maxLength={255}
            />
          </Row>
          <Row>
            <Input
              label="Qual as medidas da embarcação?"
              name="vesselMeasure"
              maxLength={255}
            />
          </Row>
          <Row>
            <Input
              label="A embarcação era própria ou de terceiros?"
              name="ownVessel"
              maxLength={255}
            />
          </Row>
          <Row>
            <Input
              label="Quais as espécies de peixe?"
              name="fishSpecies"
              maxLength={255}
            />
          </Row>
          <Row>
            <Input
              label="Descreva como era realizada a pesca?"
              name="howFishWasDone"
              maxLength={255}
            />
          </Row>
          <Row>
            <Input
              label="Quem trabalha com você na pesca?"
              name="whoWorksWithYou"
              maxLength={255}
            />
          </Row>
          <Row>
            <Input
              label="Recebe/recebeu outra renda nas seguintes atividades: atividade turística, artística, artesanal, dirigente sindical ou de cooperativa, mandato de vereador?"
              name="otherIncome"
              maxLength={255}
            />
          </Row>
          <Row>
            <Input
              label="Participavam de cooperativa?"
              name="cooperative"
              maxLength={255}
            />
          </Row>
          <Row>
            <RadioGroup
              label="Possui empregado (s) ou prestador(es) de serviço?"
              name="haveAnEmployeeOrServiceProvider"
              options={[
                {
                  value: "Sim",
                  label: "Sim",
                },
                {
                  value: "Não",
                  label: "Não",
                },
              ]}
              required='O campo "Possui empregado (s) ou prestador(es) de serviço?" é obrigatório.'
              inline
            />
          </Row>
          <Row>
            <RadioGroup
              label="Seus Pais se aposentaram como pescadores?"
              name="fishingParents"
              options={[
                {
                  value: "Sim",
                  label: "Sim",
                },
                {
                  value: "Não",
                  label: "Não",
                },
              ]}
              required='O campo "Seus Pais se aposentaram como pescadores?" é obrigatório.'
              inline
            />
          </Row>
        </>
      )}
    </div>
  );
}

export default React.memo(CustomerSurvey19);
