import React, { Component } from "react";
import { Col, Modal, Row, Form, Button } from "react-bootstrap";
import FileBase64 from "react-file-base64";
import {
  // getAllDocumentSubTypes,
  getAllDocumentType,
  postDocument,
} from "../../../services/RequestService";

class ModalDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      file: undefined,
      id: props.item === null ? "" : props.item.id,
      filename: props.item === null ? "" : props.item.name,
      fileExt: props.item === null ? "" : props.item.extension,
      filetype: props.item === null ? "" : props.item.type,
      isFilenameValid: undefined,
      isFiletypeValid: undefined,
      documentsType: undefined,
      currentProcessId:
        props.processIds !== undefined
          ? props.processIds.length > 0
            ? props.processIds[0]
            : undefined
          : undefined,
    };
  }

  componentDidMount = () => {
    getAllDocumentType().then((res) => {
      if (res.ok) {
        let documentsType = {};
        for (let i = 0; i < res.data.length; i++) {
          let b = res.data[i];
          documentsType[b.name] = b.id;
        }
        this.setState((state) => ({ ...state, documentsType }));
      }
    });
  };

  handleChooseDoc = (e) => {
    const { editing } = this.props;
    let nameSplit = e.name.split(".");
    let name = "";
    for (let i = 0; i < nameSplit.length - 1; i++) {
      name = name + nameSplit[i];
    }
    this.setState((state) => ({
      ...state,
      file: e,
      id: "",
      filename: name,
      fileExt: nameSplit[nameSplit.length - 1],
      filetype: editing ? this.state.filetype : "",
      isFilenameValid: true,
      isFiletypeValid: editing === true ? undefined : false,
      editEnable: false,
    }));
  };

  handleInput = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  addFile = () => {
    const {
      filename,
      fileExt,
      filetype,
      file,
      currentProcessId,
      documentsType,
    } = this.state;
    let f = {
      processId: currentProcessId,
      name: filename,
      extension: fileExt,
      documentTypeId: documentsType[filetype],
      virtualFile: file.base64,
    };
    this.props.addFile(f);
    this.setState((state) => ({
      ...state,
      filename: "",
      fileExt: "",
      filetype: "",
      isFilenameValid: undefined,
      isFiletypeValid: undefined,
      file: undefined,
    }));
    this.props.onHide();
  };

  updateFile = () => {
    const { documentsType, filetype, id } = this.state;
    const data = {
      id: id,
      name: this.state.filename,
      documentTypeId: documentsType[filetype],
    };
    postDocument(data).then((res) => {
      if (res.ok) {
        this.setState((state) => ({
          ...state,
          id: "",
          filename: "",
          fileExt: "",
          filetype: "",
          isFilenameValid: undefined,
          isFiletypeValid: undefined,
          editEnable: false,
        }));
        this.props.onHide();
      }
    });
  };

  isFilenameValid = () => {
    const { filename } = this.state;
    let isFilenameValid = filename !== "";
    this.setState((state) => ({ ...state, isFilenameValid }));
  };

  isFiletypeValid = () => {
    const { filetype } = this.state;
    let isFiletypeValid = filetype !== "";
    this.setState((state) => ({ ...state, isFiletypeValid }));
  };

  render() {
    const {
      filename,
      fileExt,
      filetype,
      file,
      isFilenameValid,
      isFiletypeValid,
      documentsType,
    } = this.state;
    const { show, onHide, editing, processIds } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {editing ? "Atualizar " : "Adicionar "} documento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Escolha um documento</Form.Label>
                <br />
                <FileBase64
                  multiple={false}
                  onDone={this.handleChooseDoc.bind(this)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  Nome do documento{" "}
                  <Form.Label className="is-required">*</Form.Label>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={filename}
                  name={"filename"}
                  onChange={this.handleInput}
                  onBlur={() => this.isFilenameValid()}
                  disabled={editing ? false : file === undefined}
                  className={
                    isFilenameValid === undefined
                      ? ""
                      : isFilenameValid
                      ? "is-valid"
                      : "is-invalid"
                  }
                />
                <Form.Text className="is-required">
                  {isFilenameValid === undefined
                    ? ""
                    : isFilenameValid
                    ? ""
                    : "O nome do arquivo não pode ser vazio"}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  Tipo de documento{" "}
                  <Form.Label className="is-required">*</Form.Label>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={filetype}
                  name="filetype"
                  onChange={this.handleInput}
                  onBlur={() => this.isFiletypeValid()}
                  disabled={editing ? true : file === undefined}
                  className={
                    isFiletypeValid === undefined
                      ? ""
                      : isFiletypeValid
                      ? "is-valid"
                      : "is-invalid"
                  }
                >
                  <option />
                  {documentsType !== undefined &&
                    Object.keys(documentsType).map((item, key) => {
                      return <option key={key}>{item}</option>;
                    })}
                </Form.Control>
                <Form.Text className="is-required">
                  {isFiletypeValid === undefined
                    ? ""
                    : isFiletypeValid
                    ? ""
                    : "O tipo de documento não pode ser vazio"}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="mb-3">Extensão do documento</Form.Label>
                <Form.Control
                  type="text"
                  value={fileExt}
                  name="fileExt"
                  onChange={this.handleInput}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {processIds !== undefined && processIds.length > 0 && (
              <Col sm={2} md={2} lg={2} xs={2}>
                <Form.Group>
                  <Form.Label>Processo</Form.Label>
                  <Form.Control
                    as="select"
                    name="currentProcessId"
                    onChange={this.handleInput}
                  >
                    {processIds.map((item, key) => {
                      return <option key={key}>{item}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={editing ? this.updateFile : this.addFile}>
            {editing ? "Atualizar" : "Adicionar"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalDocument;
