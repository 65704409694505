import React from "react";
import { Button, Col } from "react-bootstrap";
import { CustomModal } from "../../../../genericComponents";

function ModalUpdateStatus({
  showModalUpdateProcess,
  updatingProcessLoading,
  closeProcessUpdate,
  updateProcessSubmit,
}) {
  return (
    <CustomModal
      show={showModalUpdateProcess}
      onHide={closeProcessUpdate}
      title="Atualizar"
      footer={
        updatingProcessLoading ? (
          <Button
            variant="primary"
            disabled
            className="w-100 button-without-rounded"
          >
            Atualizando ...
          </Button>
        ) : (
          <>
            <Col>
              <Button
                variant="secondary"
                className="w-100 button-without-rounded"
                disabled={updatingProcessLoading}
                onClick={closeProcessUpdate}
              >
                Não
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                className="w-100 button-without-rounded"
                disabled={updatingProcessLoading}
                onClick={updateProcessSubmit}
              >
                Sim
              </Button>
            </Col>
          </>
        )
      }
    >
      <p>Você confirma que deseja atualizar os dados?</p>
    </CustomModal>
  );
}

export default ModalUpdateStatus;
