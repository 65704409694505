import React from "react";
import { Row } from "react-bootstrap";
import { RadioGroup } from "../../../genericComponents";

function CustomerSurvey11() {
  return (
    <Row>
      <RadioGroup
        label="Já trabalhou na condição de pessoa com deficiência"
        name="disabledWorker"
        options={["Sim", "Não", "Não sei responder"].map((item) => ({
          label: item,
          value: item,
        }))}
        required='O campo "Já trabalhou na condição de pessoa com deficiência" é obrigatório.'
        inline
      />
    </Row>
  );
}

export default React.memo(CustomerSurvey11);
