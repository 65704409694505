import React from "react";
import { Row } from "react-bootstrap";
import { RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { deceasedActivityOptions } from "../models/selects";

function PensionRegister5() {
  return (
    <Row>
      <RadioGroup
        label="Que tipo de atividade era exercida pelo(a) falecido?"
        name={pensionFormNames.deceasedActivity}
        options={deceasedActivityOptions.map((item) => ({
          label: item,
          value: item,
        }))}
        required={required.default}
        inline
      />
    </Row>
  );
}

export default React.memo(PensionRegister5);
