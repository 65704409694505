/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { putInsertPayment } from "../../../../services/RequestService";
import { alertActions } from "../../../../store/actions/AlertActions";
import { Select } from "../../../../genericComponents";
import CurrencyInput from "../../../../components/fields/MoneyField";
import { connect } from "react-redux";
import { fn } from "../../../../utils";

class ModalSelectPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: "",
      parcel: 0,
      enterParcel: 0,

      parcelsListTicket: [],
      parcelsListCreditCard: [],
      choosing: false,
    };
  }

  getPaymentTypes() {
    const { useCreditCard, usePix, useTicket } = this.props;
    const paymentTypes = [{ label: "Selecione", value: "" }];
    if (usePix) paymentTypes.push({ label: "Pix", value: "Pix" });
    if (useTicket) paymentTypes.push({ label: "Boleto", value: "Boleto" });
    if (useCreditCard)
      paymentTypes.push({
        label: "Cartão de Crédito",
        value: "Cartão de Crédito",
      });
    if (usePix && useTicket)
      paymentTypes.push({ label: "Pix + Boleto", value: "Pix + Boleto" });
    if (usePix && useCreditCard)
      paymentTypes.push({
        label: "Pix + Cartão de Crédito",
        value: "Pix + Cartão de Crédito",
      });
    if (useTicket && useCreditCard)
      paymentTypes.push({
        label: "Boleto + Cartão de Crédito",
        value: "Boleto + Cartão de Crédito",
      });
    return paymentTypes;
  }

  getParcels(amount) {
    const { paymentMethod } = this.state;
    const { maxTicketParcels, maxCreditCardParcels } = this.props;
    if (!paymentMethod) return [];
    const parcels = [{ value: "", label: "Selecione" }];
    if (paymentMethod === "Pix") {
      parcels.push({
        value: 1,
        label: `Em 1 x de R$ ${fn.floorFigure(amount)}`,
      });
      return parcels;
    }
    const limit = paymentMethod.includes("Cartão de Crédito")
      ? maxCreditCardParcels
      : maxTicketParcels;

    for (let i = 1; i <= limit; i++) {
      parcels.push({
        value: i,
        label: `Em ${i} x de R$ ${fn.floorFigure(amount / i)}`,
      });
    }
    return parcels;
  }

  isBtnDisabled = () => {
    const { paymentMethod, parcel, enterParcel } = this.state;
    if (paymentMethod.includes("+") && enterParcel <= 0) return true;
    return paymentMethod === "" || parcel <= 0;
  };

  submit = () => {
    const { processId, amount } = this.props;
    const { paymentMethod, parcel, enterParcel } = this.state;
    this.setState((state) => ({ ...state, choosing: true }));

    const negociationParcels = [];
    const paymentType = !paymentMethod.includes("+")
      ? paymentMethod
      : paymentMethod.includes("Cartão de Crédito")
        ? "Cartão de Crédito"
        : "Boleto";

    if (paymentMethod.includes("+")) {
      const typePayment = paymentMethod.split("+");
      const date = fn.addDaysToDate(new Date(), 9);

      negociationParcels.push({
        paymentType: typePayment[0].trim(),
        dueDate: date.toISOString().substring(0, 10),
        parcelValue: enterParcel,
        creditCardParcels: 1,
      });
      if (typePayment[1].trim() === "Cartão de Crédito") {
        negociationParcels.push({
          paymentType: typePayment[1].trim(),
          dueDate: date.toISOString().substring(0, 10),
          parcelValue: amount - enterParcel,
          creditCardParcels: parcel,
        });
      } else {
        let date = new Date();
        date = fn.addDaysToDate(date, 9);
        for (let i = 1; i <= parcel; i++) {
          date = fn.addMonthsToDate(date, 1);
          negociationParcels.push({
            paymentType: typePayment[1].trim(),
            dueDate: date.toISOString().substring(0, 10),
            parcelValue: (amount - enterParcel) / parcel,
            creditCardParcels: 1,
          });
        }
      }
    }
    let data = {
      processId,
      paymentType,
      amount,
      negociationParcels,
      selectedParcel: parseInt(parcel),
    };
    putInsertPayment(data).then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, choosing: false }));
        this.props.onAddAlert(
          "Escolha do pagamento cadastrado. \n" +
          "Efetue o pagamento para dar continuidade no processo!",
          "success",
          true
        );
        this.props.onHide();
      } else {
        this.setState((state) => ({ ...state, choosing: false }));
        this.props.onAddAlert("Erro ao escolher o pagamento.", "danger", true);
      }
    });
  };

  render() {
    const { show, onHide, amount } = this.props;
    const { choosing, paymentMethod, enterParcel } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        centered
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Escolher pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Total R$ {fn.floorFigure(amount)}</h4>
          <Form.Row>
            <Select
              label="Tipo Pagamento"
              options={this.getPaymentTypes()}
              onChange={(e) => this.setState({ paymentMethod: e.target.value })}
            />
          </Form.Row>
          <Form.Row>
            {!paymentMethod.includes("+") ? (
              <Select
                label="Parcelas"
                options={this.getParcels(amount)}
                onChange={(e) => this.setState({ parcel: e.target.value })}
              />
            ) : (
              <>
                <Form.Group>
                  <Form.Label>Valor de Entrada</Form.Label>
                  <CurrencyInput
                    className={"form-control"}
                    placeholder={"R$0.00"}
                    type="text"
                    onChange={(e) =>
                      this.setState({ enterParcel: fn.float(e.target.value) })
                    }
                    onFocus={(e) => e.target.select()}
                  />
                </Form.Group>
                <Select
                  label="Parcelas"
                  disabled={enterParcel <= 0}
                  options={this.getParcels(amount - enterParcel)}
                  onChange={(e) => this.setState({ parcel: e.target.value })}
                />
              </>
            )}
          </Form.Row>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Button
              variant="primary"
              disabled={this.isBtnDisabled() || choosing}
              className="w-100 button-without-rounded"
              onClick={this.submit}
            >
              {choosing ? "Escolhendo ..." : "Escolher"}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalSelectPayment);
