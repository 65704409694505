import React from "react";
import { Row } from "react-bootstrap";
import { Textarea } from "../../../genericComponents";

function PensionRegister20() {
  return (
    <Row>
      <Textarea
        label="Se você quiser inserir alguma informação adicional que possa orientar nossos consultores:"
        name="additionalInformation"
        maxLength={250}
      />
    </Row>
  );
}

export default React.memo(PensionRegister20);
