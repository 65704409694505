import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts a tickets argument
const ReportPDF = (reports, tableHead, fields) => {
  // initialize jsPDF
  const doc = new jsPDF({ orientation: "landscape" });

  // define the columns we want and their titles
  const tableColumn = tableHead;
  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
  if (reports?.[0]?.length) {
    reports[0].forEach((item) => {
      let vet = [];
      fields.forEach((field) => {
        vet.push(item[field]);
      });
      tableRows.push(vet);
    });
  }

  doc.autoTable(tableColumn, tableRows, { startY: 20 });
  // we define the name of our PDF file.
  doc.save(`report.pdf`);
};

export default ReportPDF;
