/* eslint-disable react/style-prop-object */
import React, {Component} from "react";
import {deleteProcess, getAllProcessType, getProcessBySearchBar} from "../../services/RequestService";
import "./ProcessPage.css";
import ModalInsertProcess from "../../components/process/ModalInsertProcess";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {Header, PageContainer, TableComponentWithPagination} from "../../genericComponents";
import {masks} from "../../utils";
import jwt from "jwt-decode";
import {Loading, MessageBox} from "element-react";
import {alertActions} from "../../store/actions/AlertActions";
import {connect} from "react-redux";

export class TableCalendarPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      done: false,
      showModal: false,

      filterValue: localStorage?.processFilter || "",
      showModalInsertProcess: false,
      loading: true,

      list: [],
      totalItems: 0,
      fixedTotalItems: 0,
      totalPages: 0,
      page: parseInt(localStorage?.processPageNumber || '0'),
      decodedToken: {},
      processTypes: [],
    };
  }

  componentDidMount = () => {
    const jsonToken = localStorage.getItem("token");
    this.setState({loading: true, decodedToken: jwt(jsonToken)});
    this.getByFilter();
    this.getProcessTypes();
  };

  getProcessTypes = () => {
    getAllProcessType().then((res) => {
      if (res.ok) {
        this.setState({processTypes: res.data});
      }
    });
  };

  getByFilter = () => {
    this.setState({loading: true}, () => {
      const {filterValue, page, done} = this.state;
      getProcessBySearchBar({searchItem: filterValue, page, done}).then(({ok, data}) => {
        if (ok)
          this.setState({
            list: data.content.map((item) => ({
              id: item.id,
              customerName: item.customerName ?? "",
              protocol: item.protocol ?? "",
              customerCPF: item.customerCPF ?? "",
              processStatusName: item.processStatusName ?? "",
              processTypeName: item.processTypeName ?? "",
              cellName: item.cellName ?? "",
              creatorCell: item.creatorCellName ?? "",
              actionsLength: item.actionsLength ?? "0",
              done: item.done,
              deliveryDate: item.deliveryDate,
            })).sort((a, b) => (b.id > a.id ? 1 : -1)),
            page: data.currentPage,
            totalPages: data.totalPages,
            fixedTotalItems: data.fixedTotalItems,
            loading: false,
            totalItems: data.total
          });
      });
    });
  };

  pagination = (pageNumber) => {
    this.setState({page: pageNumber}, () => {
      localStorage.processPageNumber = pageNumber;
      this.getByFilter();
    });
  };

  switchProcessDone = (done) => {
    this.setState({done, filterValue: "", page: 0}, () => this.getByFilter());
    localStorage.processFilter = ''
    localStorage.processPageNumber = 0
  };

  handleInputChange = (e) => {
    e.persist();
    this.setState((state) => ({...state, [e.target.name]: e.target.value, page: 0}));
    localStorage.processFilter = e.target.value
  };

  handleSearch = (e) => {
    if (e.key === "Enter") {
      this.getByFilter();
    }
  };

  setShowModalInsertProcess = (showModalInsertProcess) => {
    this.setState((state) => ({...state, showModalInsertProcess}));
    if (!showModalInsertProcess) {
      this.componentDidMount();
    }
  };

  getDeadlineDays = (data) => {
    let nowDate = new Date().getTime();
    let deliveryDate = new Date(data.deliveryDate).getTime();

    let diffTime = deliveryDate - nowDate;
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  getDateBackgroundColor = (data) => {
    let diffDays = this.getDeadlineDays(data);

    if (data.done) {
      return "process-success-color-border p-2 my-2 clickable";
    } else if (diffDays > 10) {
      return "process-warning-color-border p-2 my-2 clickable";
    } else if (diffDays >= 0) {
      return "process-attention-color-border p-2 my-2 clickable";
    } else if (diffDays < 0) {
      return "process-danger-color-border p-2 my-2 clickable";
    }
  };

  filter = (properties) => (item) => {
    const {filterValue} = this.state;
    for (const property of properties) {
      if (item[property].toUpperCase().includes(filterValue.toUpperCase())) {
        return true;
      }
    }
    return false;
  };

  render() {
    const {
      filterValue,
      showModalInsertProcess,
      loading,
      done,
      totalItems,
      totalPages,
      page,
      fixedTotalItems,
      list,
      processTypes,
      decodedToken
    } = this.state;
    return (
      <Loading loading={loading}>
        <Header
          active="Processos"
          logged
          filter={{
            type: "text",
            className: "text-left",
            placeholder: "Buscar por ... ",
            name: "filterValue",
            value: filterValue,
            onChange: this.handleInputChange,
            onKeyPress: this.handleSearch,
            onClick: () => this.getByFilter(),
          }}
          buttons={[
            this.props.privileges?.processo?.create ? {
              icon: "fas fa-plus-circle",
              label: "Adicionar",
              onClick: () => this.setShowModalInsertProcess(true),
            } : null,
          ]}
        />

        <PageContainer fixedHeight className="p-3">
          <div className="d-flex flex-column align-items-center">
            <div className="switch-container mb-3">
              <BootstrapSwitchButton
                checked={!done}
                onlabel="Processos Abertos"
                onstyle="success"
                offlabel="Processos Fechados"
                offstyle="danger"
                style="w-100 flex-fill m-0"
                onChange={() => this.switchProcessDone(!done)}
              />
            </div>
          </div>

          <TableComponentWithPagination
            loading={loading}
            countLabel="processos"
            countLabelSingular="processo"
            startSorted="id"
            header={[
              {label: "#", sortProperty: "id"},
              {label: "Cliente", sortProperty: "customerName"},
              {label: "Protocolo", sortProperty: "protocol"},
              {label: "CPF", sortProperty: "customerCPF"},
              {label: "Status do processo", sortProperty: "processStatusName",},
              {label: "Tipo de tese", sortProperty: "processTypeName"},
              {label: "Célula Responsavel", sortProperty: "cellName"},
              {label: "Célula Criadora", sortProperty: "creatorCell"},
              decodedToken.is_admin && {label: "Remover"},
            ]}
            totalItems={totalItems}
            fixedTotalItems={fixedTotalItems}
            totalPages={totalPages}
            page={page}
            list={list}
            forEach={(item) => [
              {label: item.id},
              {label: item.customerName},
              {label: item.protocol},
              {label: item.customerCPF ? masks.CPF(item.customerCPF) : item.customerCPF},
              {label: item.processStatusName},
              {label: item.processTypeName},
              {label: item.cellName},
              {label: item.creatorCell},
              decodedToken.is_admin && {
                onClick: () => MessageBox.confirm('Deseja realmente remover o processo?', 'Confirma?', {
                  confirmButtonText: 'Sim',
                  cancelButtonText: 'Não',
                  type: 'warning'
                }).then(() => {
                  deleteProcess(item.id).then(resp => {
                    if (resp.ok) {
                      this.getByFilter();
                      this.props.onAddAlert('Processo removido com sucesso!', "success", true);
                    } else {
                      this.props.onAddAlert('Erro ao remover processo!', "danger", true);
                    }
                  })
                }).catch(e => {
                  console.log(e)
                  this.props.onAddAlert('Erro ao remover processo!', "danger");
                }),
                label: (<div className="text-center"><i className="fas fa-trash-alt is-required"></i></div>),
              },
            ]}
            setItemClassName={this.getDateBackgroundColor}
            onItemClick={(item) => this.props.history.push("/processo/" + item.id + "/processos")}
            onChangePage={this.pagination}
          />
        </PageContainer>
        {showModalInsertProcess && (
          <ModalInsertProcess
            show={showModalInsertProcess}
            onHide={() => this.setShowModalInsertProcess(false)}
            processTypes={processTypes}
          />
        )}
      </Loading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(TableCalendarPage);
