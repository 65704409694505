import { parse } from "date-fns";
import React from "react";
import { Row } from "react-bootstrap";
import { Input, InputYear, RadioGroup } from "../../../genericComponents";

function CustomerSurvey10({ serverData }) {
  const [deficiency, setDeficiency] = React.useState(false);
  const serverDeficiency = serverData?.deficiency;
  React.useEffect(() => {
    if (serverDeficiency === "Sim") {
      setDeficiency(true);
    }
  }, [serverDeficiency, setDeficiency]);
  return (
    <Row>
      <RadioGroup
        colProps={{ xs: 12 }}
        label="Possui alguma deficiência?"
        name="deficiency"
        options={["Sim", "Não"].map((item) => ({
          label: item,
          value: item,
        }))}
        required='O campo "Possui alguma deficiência?" é obrigatório.'
        inline
        onChange={(event, value) => {
          setDeficiency(value === "Sim");
        }}
      />
      {deficiency ? (
        <>
          <Input
            colProps={{ md: 6 }}
            label="Qual?"
            name="deficiencyName"
            required='O campo "Qual?" é obrigatório.'
            inputMaskProps={{
              placeholder: "Informe a deficiência",
            }}
            maxLength={60}
          />
          <InputYear
            colProps={{ md: 6 }}
            label="Desde quando?"
            name="deficiencyYearStart"
            required='O campo "Desde quando?" é obrigatório.'
            inputMaskProps={{ placeholder: "Desde quando possui deficiência?" }}
            minDate={parse("1900", "yyyy", new Date())}
            maxDate={parse(new Date().getFullYear(), "yyyy", new Date())}
          />
        </>
      ) : null}
    </Row>
  );
}

export default React.memo(CustomerSurvey10);
