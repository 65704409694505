import React, { Component } from "react";
import { Modal, Form, Button, Row, Col, Table } from "react-bootstrap";
import {
  putCellInsert,
  postCellUpdate,
} from "../../../services/RequestService";
import { connect } from "react-redux";
import { alertActions } from "../../../store/actions/AlertActions";
import "../../../pages/settings/permission/PermissionsPage.css";

class ModalAddRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.cell !== undefined ? props.cell.name : "",
      privileges: {},
      privilegesChoise: [],
      isNameValid: undefined,
      isPrivilegesValid: undefined,
    };
  }

  componentDidMount = () => {
    let privileges = this.props.privileges;
    // delete privileges["configuracoes/grupo_de_trabalho"];
    delete privileges["configuracoes/celulas"];
    // delete privileges["configuracoes/parceiros"];
    this.setState({ privileges });
  };

  isNameValid = () => {
    const { name } = this.state;
    let isNameValid = name !== undefined && name !== "";
    this.setState((state) => ({ ...state, isNameValid }));
  };

  isPrivilegesValid = () => {
    const { privilegesChoise } = this.state;
    this.setState({
      isPrivilegesValid: Object.values(privilegesChoise).length > 0,
    });
  };

  handleFieldChange = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  handlePrivileges = (e) => {
    e.persist();
    const { privileges } = this.state;
    const { value } = e.target;
    let privilegesChoise = this.state.privilegesChoise;
    Object.values(privileges).forEach((element) => {
      if (element.name === value) {
        if (!privilegesChoise.some((priv) => priv.name === value)) {
          privilegesChoise.push(element);
          delete privileges[element.name];
        }
      }
    });
    this.setState({ privilegesChoise });
  };

  changePrivileges = (item, value) => {
    const { privilegesChoise } = this.state;
    item[value] = !item[value];
    this.setState({ privilegesChoise });
  };

  removePrivilegeChoise = (item) => {
    const { privilegesChoise, privileges } = this.state;
    privileges[item.name] = item;
    privilegesChoise.splice(privilegesChoise.indexOf(item), 1);
    let isPrivilegesValid = privilegesChoise?.length > 0;
    this.setState({ privilegesChoise, privileges, isPrivilegesValid });
  };

  submit = () => {
    const { name, isNameValid } = this.state;
    const { cell, isIntern, cellAdmin } = this.props;
    this.isNameValid();
    let privilegesChoise = [];
    this.state.privilegesChoise.forEach((element) => {
      privilegesChoise.push({
        cellId: null,
        privilegeId: element.privilege.id,
        delete: element.delete !== null ? element.delete : false,
        insert: element.insert !== null ? element.insert : false,
        read: element.read !== null ? element.read : false,
        update: element.update !== null ? element.update : false,
      });
    });
    if (isNameValid && cell === undefined && privilegesChoise?.length > 0) {
      putCellInsert({
        name: name.toUpperCase(),
        masterCell: cellAdmin,
        isIntern: isIntern,
        privileges: privilegesChoise,
      }).then((res) => {
        if (res.ok) {
          this.props.onAddAlert("Célula adicionado.", "success", false);
          this.props.onHide(true);
        } else {
          this.props.onAddAlert(res.data.log, "danger", false);
        }
      });
    } else if (
      isNameValid &&
      cell !== undefined &&
      privilegesChoise?.length > 0
    ) {
      postCellUpdate({
        id: cell.id,
        name: name.toUpperCase(),
        privileges: privilegesChoise,
      }).then((res) => {
        if (res.ok) {
          this.props.onAddAlert("Célula atualizada.", "success", false);
          this.props.onHide(true);
        } else {
          this.props.onAddAlert(res.data.log, "danger", false);
        }
      });
    } else {
      this.props.onAddAlert(
        "Todos os campos devem ser preenchidos",
        "danger",
        false
      );
    }
  };

  render() {
    const {
      name,
      isNameValid,

      privilegesChoise,
      isPrivilegesValid,
    } = this.state;
    const { cell, show, onHide, isIntern, privileges } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        centered
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isIntern ? "Célula" : "Grupo de trabalho"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Nome da célula</Form.Label>
                <Form.Control
                  name="name"
                  onChange={this.handleFieldChange}
                  value={name}
                  onBlur={() => this.isNameValid()}
                  className={
                    isNameValid === undefined
                      ? ""
                      : isNameValid
                      ? "is-valid"
                      : "is-invalid"
                  }
                />
                <Form.Text className="is-required">
                  {isNameValid === undefined
                    ? ""
                    : isNameValid
                    ? ""
                    : "O campo nome não pode ser vazio"}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>
                  Privilégios <Form.Label className="is-required">*</Form.Label>
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={this.handlePrivileges}
                  onBlur={() => this.isPrivilegesValid()}
                >
                  <option />
                  {privileges &&
                    Object.values(privileges).map((value) => {
                      return <option key={value.id}> {value.name}</option>;
                    })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Privilégios escolhidos para célula</Form.Label>
                <Table
                  striped
                  bordered
                  hover
                  className="table table-bordered cell-table-responsible bms-color-text "
                  responsive={true}
                >
                  <thead className={"text-center"}>
                    <tr>
                      <th>Nome</th>
                      <th>Criar</th>
                      <th>Ver</th>
                      <th>Editar</th>
                      <th>Excluir</th>
                      <th>Remover da lista</th>
                    </tr>
                  </thead>
                  <tbody className={"text-center"}>
                    {privilegesChoise &&
                      Object.values(privilegesChoise).map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>
                              <Form.Check
                                readOnly={true}
                                checked={item.insert}
                                value={item.insert}
                                onClick={() =>
                                  this.changePrivileges(item, "insert")
                                }
                                disabled={item?.insert === null}
                              />
                            </td>
                            <td>
                              <Form.Check
                                readOnly={true}
                                checked={item.read}
                                value={item.read}
                                onClick={() =>
                                  this.changePrivileges(item, "read")
                                }
                                disabled={item?.read === null}
                              />
                            </td>
                            <td>
                              <Form.Check
                                readOnly={true}
                                checked={item.update}
                                value={item.update}
                                onClick={() =>
                                  this.changePrivileges(item, "update")
                                }
                                disabled={item?.update === null}
                              />
                            </td>
                            <td>
                              <Form.Check
                                readOnly={true}
                                checked={item.delete}
                                value={item.delete}
                                onClick={() =>
                                  this.changePrivileges(item, "delete")
                                }
                                disabled={item?.delete === null}
                              />
                            </td>
                            <td>
                              <i
                                onClick={() => this.removePrivilegeChoise(item)}
                                className="fas fa-trash-alt is-required clickable"
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Form.Text className="is-required">
                  {isPrivilegesValid === undefined
                    ? ""
                    : isPrivilegesValid
                    ? ""
                    : "A lista de privilégios não deve ser vazia"}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Col className=" p-0">
              <Button
                variant="secondary"
                className="w-100 button-without-rounded"
                onClick={onHide}
              >
                Voltar
              </Button>
            </Col>
            <Col className="p-0">
              <Button
                variant="primary"
                className="w-100 button-without-rounded"
                onClick={() => {
                  this.submit();
                }}
                disabled={!isNameValid || !isPrivilegesValid}
              >
                {cell === undefined ? "Adicionar" : "Atualizar"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalAddRole);
