import React from "react";
import { Row } from "react-bootstrap";
import { Input, RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { defaultThreeOptions } from "../models/selects";

function RenderHadRegime() {
  return (
    <Row>
      <Input
        label="Quais?"
        name={pensionFormNames.regimeOnDateOfDeathDescription}
        maxLength={100}
      />
    </Row>
  );
}

function PensionRegister6({ serverData }) {
  const [hadRegime, setHadRegime] = React.useState(null);
  const regimeOnDateOfDeath = serverData?.regimeOnDateOfDeath;
  React.useEffect(() => {
    if (regimeOnDateOfDeath === defaultThreeOptions[0]) {
      setHadRegime(true);
    }
  }, [regimeOnDateOfDeath, setHadRegime]);

  return (
    <>
      <Row>
        <RadioGroup
          label="O(A) falecido(a) contribuía para algum regime de previdência, na data do óbito?"
          name={pensionFormNames.regimeOnDateOfDeath}
          options={defaultThreeOptions.map((item) => ({
            label: item,
            value: item,
          }))}
          required={required.default}
          inline
          onChange={(event, value) => {
            setHadRegime(value === defaultThreeOptions[0]);
          }}
        />
      </Row>
      {hadRegime === true ? <RenderHadRegime /> : null}
    </>
  );
}

export default React.memo(PensionRegister6);
