import React from "react";
import "../styles/SingleProcess.css";
import { Header } from "../../../../genericComponents";
import styles from "../styles/SingleProcess.module.css";
import { getMenu } from "./menu";
import { useAlert } from "react-alert";

function MyProcessHeader(props) {
  const alert = useAlert();

  const { decodedToken, oneProcess, isUserView } = props;
  return decodedToken.is_user_and_customer && isUserView ? true : !decodedToken.is_customer ? (
    <>
      <div className={styles.largeScreenHeader}>
        <Header logged active={oneProcess?.done ? "Processo Finalizado" : "Processo"} />
      </div>
      <div className={styles.smallScreenHeader}>
        <Header logged active={oneProcess?.done ? "Processo Finalizado" : "Processo"} buttons={getMenu({ ...props, alert, origin: 'header' })} />
      </div>
    </>
  ) : (
    <Header logged active="Meus Processos" buttons={getMenu({ ...props, alert, origin: 'header' })} />
  );
}

export default MyProcessHeader;
