import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Row } from "react-bootstrap";
import { alertActions } from "../../../store/actions/AlertActions";
import { deleteTasktype } from "../../../services/RequestService";
import "../../../pages/settings/process/SettingsProcessPage.css";
import { Col } from "react-bootstrap";

function ModalRemoveTaskType({ show, onHide, taskType, onAddAlert }) {
  const [loading, setLoading] = React.useState(false);

  function confirmRemove() {
    setLoading(true);
    deleteTasktype({ id: taskType.id })
      .then((res) => {
        console.log(res);
        if (res.ok) {
          onAddAlert("Tipo de Tarefa removido com sucesso.", "success");
          onHide(true);
        } else {
          onAddAlert(res.data.log, "danger");
          onHide(true);
        }
      })
      .catch((e) => {
        onAddAlert("Ocorreu um erro ao tentar remover o tipo de tarefa.", "danger");
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Remoção de Tipo de Tarefa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você tem certeza que deseja excluir o tipo de tarefa: {taskType.name}?</p>
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Row className="w-100 m-0">
          <Col className=" p-0">
            <Button
              variant="secondary"
              className="w-100 button-without-rounded"
              onClick={() => onHide()}
            >
              Voltar
            </Button>
          </Col>
          <Col className="p-0">
            <Button
              variant="danger"
              className="w-100 button-without-rounded"
              disabled={loading}
              onClick={confirmRemove}
            >
              Remover
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, taskType, alertPriority) =>
      dispatch(alertActions.addAlert(text, taskType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalRemoveTaskType);
