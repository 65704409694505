import React from "react";
import { Spinner } from "react-bootstrap";

export default function RenderValue({ title, subtitle, large }) {
  const renderTitle = React.useMemo(() => {
    if (!title && title !== 0) return <Spinner animation="border" size="sm" />;
    return large ? (
      <h5 className="font-weight-bold m-0">{title}</h5>
    ) : (
      <h6 className="font-weight-bold m-0">{title}</h6>
    );
  }, [large, title]);

  return (
    <div>
      {renderTitle}
      {!subtitle && subtitle !== 0 ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <h6 className="font-weight-normal m-0">{subtitle}</h6>
      )}
    </div>
  );
}
