import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { alertActions } from "../../../store/actions/AlertActions";
import {
  getAllProcessType,
  getAllProcessStatus,
  getAllScenario,
  // getAllPaymentMethods,
} from "../../../services/RequestService.js";
import { connect } from "react-redux";
import "./SettingsProcessPage.css";
import ModalProcessType from "../../../components/settings/process/ModalProcessType";
import ModalProcessStatus from "../../../components/settings/process/ModalProcessStatus";
import ModalScenario from "../../../components/settings/process/ModalScenario";
import ModalScenarioDelete from "../../../components/settings/process/ModalScenarioDelete";
import ModalPaymentMethod from "../../../components/settings/process/ModalPaymentMethod";
import {
  Header,
  Loading,
  PageContainer,
  TableComponent,
} from "../../../genericComponents";
import ModalProcessStatusDelete from "../../../components/settings/process/ModalProcessStatusDelete";
import ModalProcessTypeDelete from "../../../components/settings/process/ModalProcessTypeDelete";

class SettingsProcessPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allProcessStatus: [],
      allProcessStatusList: null,
      currentAllProcessStatus: [],
      showModalProcessStatusDelete: false,
      selectedProcessStatus: null,
      showModalProcessTypeDelete: false,
      selectedProcessType: null,

      allProcessType: [],
      allProcessTypeList: null,
      currentAllProcessTypeList: [],

      allScenarios: [],
      allScenariosList: null,
      currentAllScenarios: [],

      allPaymentMethods: null,
      currentAllPaymentMethods: [],

      showModalProcessType: false,
      showModalProcessStatus: false,
      showModalEditProcessType: false,
      showModalScenario: false,
      showModalScenarioDelete: false,
      id: null,
      name: null,
      deadlineDays: null,
      parcel: "",
      typeOfPayment: "",
      payAtTheBeginning: "",
      value: "",
      description: "",
      contracts: [],

      loadingCount: null,

      // PAYMENT METHOD
      showEditPaymentMethodModal: false,
      paymentMethod: null,
    };
  }

  fetchRequests = () => {
    this.setState(
      (state) => ({
        loadingCount: !state.loadingCount ? 3 : state.loadingCount + 3,
      }),
      () => {
        getAllProcessType().then((res) => {
          if (res.ok) {
            for (let i = 0; i < res.data.length; i++) {
              let a = res.data[i];
              a["documentsLength"] = a.documentsType.length;
            }
            this.setState((state) => ({
              allProcessType: res.data,
              allProcessTypeList: res.data
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  documentsLength: Number(item.documentsLength),
                }))
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
              loadingCount: state.loadingCount - 1,
            }));
          }
        });
        getAllProcessStatus().then((res) => {
          if (res.ok) {
            this.setState((state) => ({
              allProcessStatus: res.data,
              allProcessStatusList: res.data
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                }))
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
              loadingCount: state.loadingCount - 1,
            }));
          }
        });
        getAllScenario().then((res) => {
          if (res.ok) {
            this.setState((state) => ({
              allScenarios: res.data,
              allScenariosList: res.data
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  description: item.description,
                }))
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
              loadingCount: state.loadingCount - 1,
            }));
          }
        });

        //   getAllPaymentMethods()
        //     .then((res) =>
        //       this.setState((state) => ({
        //         allPaymentMethods: res.data.sort((a, b) =>
        //           a.id > b.id ? 1 : -1
        //         ),
        //         loadingCount: state.loadingCount - 1,
        //       }))
        //     )
        //     .catch((e) => {
        //       // TODO error treatment
        //       console.log(e);
        //     });
      }
    );
  };

  componentDidMount = () => {
    this.fetchRequests();
  };

  setShowModalProcessType = (showModalProcessType, item, wasUpdated) => {
    let sd = {};
    if (item?.documentsType) {
      for (let i = 0; i < item.documentsType.length; i++) {
        let a = item.documentsType[i];
        sd[a.name] = a.id;
      }
    }
    this.setState({
      showModalProcessType: showModalProcessType,
      id: item?.id ?? null,
      name: item?.name ?? null,
      deadlineDays: item?.deadlineDays ?? null,
      parcel: item?.parcel ?? null,
      typeOfPayment: item?.typeOfPayment ?? null,
      payAtTheBeginning: item?.payAtTheBeginning ?? null,
      value: item?.value ?? null,
      contracts: item?.contracts ?? null,
      selectedDocuments: sd,
      isTicketParcel: item?.useTicket,
      ticketParcel: item?.ticketParcels,
      isCreditCard: item?.useCreditCard,
      creditCardParcel: item?.creditCardParcels,
    });
    if (!showModalProcessType && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowModalProcessStatus = (showModalProcessStatus, item, wasUpdated) => {
    this.setState({ showModalProcessStatus });
    if (!showModalProcessStatus && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowModalEditProcessStatus = (
    showModalEditProcessStatus,
    item,
    wasUpdated
  ) => {
    this.setState({
      showModalEditProcessStatus,
      id: item?.id ?? null,
      name: item?.name ?? null,
    });
    if (!showModalEditProcessStatus && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowModalProcessStatusDelete = (showModalProcessStatusDelete, selectedProcessStatus, wasUpdated) => {
    this.setState({
      showModalProcessStatusDelete,
      selectedProcessStatus
    });
    if (!showModalProcessStatusDelete && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowModalProcessTypeDelete = (showModalProcessTypeDelete, selectedProcessType, wasUpdated) => {
    this.setState({
      showModalProcessTypeDelete,
      selectedProcessType
    });
    if (!showModalProcessTypeDelete && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowModalScenario = (showModalScenario, item, wasUpdated) => {
    this.setState({
      showModalScenario,
      id: item?.id ?? null,
      name: item?.name ?? null,
      description: item?.description ?? null,
    });
    if (!showModalScenario && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowModalScenarioDelete = (showModalScenarioDelete, item, wasUpdated) => {
    this.setState({
      showModalScenarioDelete,
      id: item?.id ?? null,
      name: item?.name ?? null,
    });
    if (!showModalScenarioDelete && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowEditPaymentMethodModal = (
    showEditPaymentMethodModal,
    paymentMethod,
    wasUpdated
  ) => {
    this.setState({
      showEditPaymentMethodModal,
      paymentMethod,
    });

    if (!showEditPaymentMethodModal && wasUpdated) {
      this.componentDidMount();
    }
  };

  RenderCardTitle = ({ title, onButtonClick }) => {
    return (
      <div className="card-title-height">
        <Row>
          <Col>
            <h4>{title}</h4>
          </Col>
          {onButtonClick ? (
            <Col className="text-right my-2 ml-2">
              <Button variant="primary" onClick={onButtonClick}>
                <i className="fas fa-plus-circle pr-2" />
                <span>Adicionar</span>
              </Button>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  };

  render() {
    const {
      allProcessType,
      allProcessStatus,
      showModalProcessType,
      showModalProcessStatus,
      showModalEditProcessStatus,
      id,
      name,
      deadlineDays,
      selectedDocuments,
      parcel,
      typeOfPayment,
      payAtTheBeginning,
      value,
      isTicketParcel,
      ticketParcel,
      isCreditCard,
      creditCardParcel,
      description,
      contracts,
      allScenarios,
      showModalScenario,
      showModalScenarioDelete,
      loadingCount,
      // PAYMENT METHOD MODAL
      showEditPaymentMethodModal,
      paymentMethod,
      // DELETE PROCESS STATUS MODAL
      showModalProcessStatusDelete,
      selectedProcessStatus,
      // DELETE PROCESS TYPE MODAL
      showModalProcessTypeDelete,
      selectedProcessType,
    } = this.state;

    return (
      <Loading loading={loadingCount !== 0}>
        <Header active="Configurações de processos" logged />
        <PageContainer withoutCard>
          <Row className="m-n2">
            {/* Status de processo */}
            <Col xs={12} md={6} lg={12} xl={6} className="p-0">
              <div className="p-3 m-2 card card-conf-action-size">
                <this.RenderCardTitle
                  title="Status de processo"
                  onButtonClick={
                    this.props.privileges?.["configuracoes/processos"]?.create
                      ? () => this.setShowModalProcessStatus(true)
                      : null
                  }
                />

                <TableComponent
                  loading={loadingCount !== 0}
                  countLabel="status"
                  startSorted="id"
                  header={[
                    { label: "#", sortProperty: "id" },
                    { label: "Nome", sortProperty: "name" },
                    { label: "Remover" }
                  ]}
                  currentList={this.state.currentAllProcessStatus}
                  setCurrentList={(currentAllProcessStatus) =>
                    this.setState({ currentAllProcessStatus })
                  }
                  list={this.state.allProcessStatusList}
                  onItemClick={(listItem) => {
                    const item = allProcessStatus.find(
                      (it) => it.id === listItem.id
                    );
                    this.setShowModalEditProcessStatus(true, item);
                  }}
                  forEach={(item) => [
                    { label: item.id },
                    { label: item.name },
                    {
                      onClick: () =>
                        this.setShowModalProcessStatusDelete(true, item),
                      label: (
                        <div className="text-center">
                          <i className="fas fa-trash-alt is-required"></i>
                        </div>
                      ),
                    }
                  ]}
                />
              </div>
            </Col>

            {/* Tipo de tese */}
            <Col xs={12} md={6} lg={12} xl={6} className="p-0">
              <div className="p-3 m-2 card card-conf-action-size">
                <this.RenderCardTitle
                  title="Tipo de tese"
                  onButtonClick={
                    this.props.privileges?.["configuracoes/processos"]?.create
                      ? () => this.setShowModalProcessType(true)
                      : null
                  }
                />

                <TableComponent
                  loading={loadingCount !== 0}
                  countLabel="tipos"
                  countLabelSingular="tipo"
                  startSorted="id"
                  header={[
                    { label: "#", sortProperty: "id" },
                    { label: "Nome", sortProperty: "name" },
                    { label: "Documentos", sortProperty: "documentsLength" },
                    { label: "Remover" }
                  ]}
                  currentList={this.state.currentAllProcessType}
                  setCurrentList={(currentAllProcessType) =>
                    this.setState({ currentAllProcessType })
                  }
                  list={this.state.allProcessTypeList}
                  onItemClick={(listItem) => {
                    const item = allProcessType.find(
                      (it) => it.id === listItem.id
                    );
                    this.setShowModalProcessType(true, item);
                  }}
                  forEach={(item) => [
                    { label: item.id },
                    { label: item.name },
                    { label: item.documentsLength },
                    {
                      onClick: () =>
                        this.setShowModalProcessTypeDelete(true, item),
                      label: (
                        <div className="text-center">
                          <i className="fas fa-trash-alt is-required"></i>
                        </div>
                      ),
                    }
                  ]}
                />
              </div>
            </Col>
            <Col xs={12} md={6} lg={12} xl={6} className="p-0">
              <div className="p-3 m-2 card card-conf-action-size">
                <this.RenderCardTitle
                  title="Cenário"
                  onButtonClick={
                    this.props.privileges?.["configuracoes/processos"]?.create
                      ? () => this.setShowModalScenario(true)
                      : null
                  }
                />

                <TableComponent
                  loading={loadingCount !== 0}
                  countLabel="cenários"
                  countLabelSingular="cenário"
                  startSorted="id"
                  header={[
                    { label: "#", sortProperty: "id" },
                    { label: "Nome", sortProperty: "name" },
                    { label: "Descrição", sortProperty: "description" },
                    this.props.privileges?.["configuracoes/processos"]?.delete
                      ? { label: "Excluir" }
                      : null,
                  ]}
                  currentList={this.state.currentAllScenarios}
                  setCurrentList={(currentAllScenarios) =>
                    this.setState({ currentAllScenarios })
                  }
                  list={this.state.allScenariosList}
                  onItemClick={(listItem) => {
                    const item = allScenarios.find(
                      (it) => it.id === listItem.id
                    );
                    this.setShowModalScenario(true, item);
                  }}
                  forEach={(item) => [
                    { label: item.id },
                    { label: item.name },
                    { label: item.description },
                    this.props.privileges?.["configuracoes/processos"]?.delete
                      ? {
                        onClick: () =>
                          this.setShowModalScenarioDelete(true, item),
                        label: (
                          <div className="text-center">
                            <i className="fas fa-trash-alt is-required"></i>
                          </div>
                        ),
                      }
                      : null,
                  ]}
                />
              </div>
            </Col>

            {/* {this.props.decodedToken.is_admin ? (
              <Col xs={12} md={6} lg={12} xl={6} className="p-0">
                <div className="p-3 m-2 card card-conf-action-size">
                  <this.RenderCardTitle title="Métodos de Pagamento" />
                  <TableComponent
                    loading={loadingCount !== 0}
                    countLabel="métodos"
                    countLabelSingular="método"
                    startSorted="id"
                    header={[
                      { label: "#", sortProperty: "id" },
                      { label: "Nome", sortProperty: "name" },
                      { label: "Número de Parcelas", sortProperty: "parcels" },
                    ]}
                    currentList={this.state.currentAllPaymentMethods}
                    setCurrentList={(currentAllPaymentMethods) =>
                      this.setState({ currentAllPaymentMethods })
                    }
                    list={this.state.allPaymentMethods}
                    onItemClick={(item) =>
                      this.setShowEditPaymentMethodModal(true, item)
                    }
                    forEach={(item) => [
                      { label: item.id },
                      { label: item.name },
                      { label: item.parcels },
                    ]}
                  />
                </div>
              </Col>
            ) : null} */}
          </Row>

          {showModalProcessType && (
            <ModalProcessType
              show={showModalProcessType}
              onHide={(wasUpdated) =>
                this.setShowModalProcessType(false, null, wasUpdated)
              }
              id={id}
              name={name}
              selectedDocuments={selectedDocuments}
              deadlineDays={deadlineDays}
              parcel={parcel}
              typeOfPayment={typeOfPayment}
              payAtTheBeginning={payAtTheBeginning}
              value={value}
              contracts={contracts}
              isTicketParcel={isTicketParcel}
              ticketParcel={ticketParcel}
              isCreditCard={isCreditCard}
              creditCardParcel={creditCardParcel}
            />
          )}
          {showModalProcessStatus && (
            <ModalProcessStatus
              show={showModalProcessStatus}
              onHide={(wasUpdated) =>
                this.setShowModalProcessStatus(false, null, wasUpdated)
              }
            />
          )}
          {showModalEditProcessStatus && id && name && (
            <ModalProcessStatus
              show={showModalEditProcessStatus}
              onHide={(wasUpdated) =>
                this.setShowModalEditProcessStatus(false, null, wasUpdated)
              }
              id={id}
              name={name}
            />
          )}
          {showModalScenario && (
            <ModalScenario
              show={showModalScenario}
              onHide={(wasUpdated) =>
                this.setShowModalScenario(false, null, wasUpdated)
              }
              id={id}
              name={name}
              description={description}
            />
          )}
          {showModalScenarioDelete && id && name && (
            <ModalScenarioDelete
              show={showModalScenarioDelete}
              onHide={(wasUpdated) =>
                this.setShowModalScenarioDelete(false, null, wasUpdated)
              }
              id={id}
              name={name}
            />
          )}

          {showEditPaymentMethodModal && paymentMethod && (
            <ModalPaymentMethod
              show={showEditPaymentMethodModal}
              onHide={(wasUpdated) =>
                this.setShowEditPaymentMethodModal(false, null, wasUpdated)
              }
              paymentMethod={paymentMethod}
            />
          )}

          {showModalProcessStatusDelete && (
            <ModalProcessStatusDelete
              show={showModalProcessStatusDelete}
              onHide={(wasUpdated) =>
                this.setShowModalProcessStatusDelete(false, null, wasUpdated)
              }
              processStatus={selectedProcessStatus}
            />
          )}

          {showModalProcessTypeDelete && (
            <ModalProcessTypeDelete
              show={showModalProcessTypeDelete}
              onHide={(wasUpdated) =>
                this.setShowModalProcessTypeDelete(false, null, wasUpdated)
              }
              processType={selectedProcessType}
            />
          )}
        </PageContainer>
      </Loading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(SettingsProcessPage);
