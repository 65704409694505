/* eslint-disable react/style-prop-object */
import React, { Component } from "react";
import { Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { alertActions } from "../../../store/actions/AlertActions";

import {
  getCellByUserId,
  getCellForWorkgroup,
  getUserById,
  setUserCellManager,
} from "../../../services/RequestService.js";
import { connect } from "react-redux";

import "../cell/CellPage.css";
import ModalInfoCell from "../../../components/settings/cell/ModalInfoCell";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import ModalPartner from "../../../components/settings/partner/ModalPartner";
import ModalRemoveWorkGrupCell from "../../../components/settings/cell/ModalRemoveWorkGrupCell";
import {
  Header,
  Loading,
  PageContainer,
  TableComponent,
} from "../../../genericComponents";
import ModalUser from "../../../components/settings/user/ModalUser";
import "./WorkGroupPage.css";

class WorkGroupPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
      currentUsers: [],

      childCells: null,
      currentChildCells: [],

      currentPrivileges: [],

      allCell: [],
      cellSelected: undefined,
      allCellToTransfer: [],
      currentCell: null,
      showAddModal: false,
      showRemoveModal: false,
      showInfoModal: false,
      userCell: null,
      user: null,
      cellId: null,
      showModalAddUser: false,
      loading: true,
    };
  }

  fetchRequests = () => {
    this.setState(
      (state) => ({
        loadingCount: !state.loadingCount ? 2 : state.loadingCount + 2,
      }),
      async () => {
        const res = await getCellByUserId();
        if (res.ok) {
          let privileges = {};
          res.data.privileges.forEach((item) => {
            privileges[item.name] = item;
          });
        }
        getCellByUserId().then(async (res) => {
          if (res.ok) {
            let privileges = {};
            res.data.privileges.forEach((item) => {
              privileges[item.name] = item;
            });

            const { isIntern } = res.data;
            let res2 = null;
            if (isIntern) res2 = await getCellForWorkgroup();
            const cellData = isIntern ? res2.data : res.data.cells;

            if (isIntern) res2.data.privileges = privileges;
            else res.data.privileges = privileges;

            const dateTimeFormatter = new Intl.DateTimeFormat("pt-BR", {dateStyle: "short", timeStyle: "medium"});
            console.log(new Date().getTimezoneOffset());
            this.setState((state) => ({
              userCell: res.data,
              users: res.data.users?.map((item) => ({
                  id: item.id,
                  name: item.name,
                  type: item.isCellAdmin ? "Parceiro" : "Usuário",
                  isCellAdmin: item.isCellAdmin,
                  isCellManager: item.isCellManager,
                })).sort((a, b) => (a.id > b.id ? 1 : -1)),
              childCells: cellData.map((item) => ({
                  id: item.id,
                  name: item.name,
                  usersCount: item.users.length,
                  isIntern: item.isIntern,
                  editedAt: item.editedAt ? dateTimeFormatter.format(new Date(this.appendUtfTimeZone(item.editedAt))) : "Não editado ainda",
                  createdAt: item.createdAt ? dateTimeFormatter.format(new Date(this.appendUtfTimeZone(item.createdAt))) : ""
                })).sort((a, b) => (a.name > b.name ? 1 : -1)),
              allCell: cellData,
              loadingCount: state.loadingCount - 1,
            }));
          }
        });

        getUserById().then((res) => {
          if (res.ok) {
            this.setState((state) => ({
              user: res.data,
              loadingCount: state.loadingCount - 1,
            }));
          }
        });
      }
    );
  };

  componentDidMount = () => {
    this.fetchRequests();
  };

  setOpenAddModal = (showAddModal, cellSelected, wasUpdated) => {
    this.setState({ showAddModal, cellSelected });
    if (!showAddModal && wasUpdated) {
      this.componentDidMount();
    }
  };

  setOpenRemoveModal = (showRemoveModal, cellSelected, wasUpdated) => {
    const {
      allCell,
      // userCell
    } = this.state;
    var allCellToTransfer = allCell;
    if (showRemoveModal) {
      allCellToTransfer = allCellToTransfer.filter(
        (cell) => cell !== cellSelected
      );
    }
    this.setState({
      showRemoveModal,
      cellSelected,
      allCellToTransfer,
    });
    if (!showRemoveModal && wasUpdated) {
      this.componentDidMount();
    }
  };

  showModalAddUser = (show, wasUpdated) => {
    this.setState({ showModalAddUser: show });
    if (!show && wasUpdated) {
      this.componentDidMount();
    }
  };

  setShowModalInfo = (showInfoModal, currentCellId, wasUpdated) => {
    let currentCell;
    if (currentCellId) {
      const { allCell } = this.state;
      currentCell = allCell.find((it) => it.id === currentCellId);
    }
    this.setState({
      showInfoModal,
      currentCell,
    });
    if (!showInfoModal && wasUpdated) {
      this.componentDidMount();
    }
  };

  getPermissions = () => {
    const p = this.props.privileges;

    const canCreateWorkgroup = p["configuracoes/grupo_de_trabalho"]?.create;
    const canCreatePartner = p["configuracoes/parceiros"]?.create;
    const canUpdateWorkgroup = p["configuracoes/grupo_de_trabalho"]?.update;
    const canCreateUser = p["configuracoes/usuarios"]?.create;
    const canDeleteWorkgroup = p["configuracoes/grupo_de_trabalho"]?.delete;

    return {
      canCreateWorkgroup,
      canCreatePartner,
      canUpdateWorkgroup,
      canCreateUser,
      canDeleteWorkgroup,
    };
  };

  appendUtfTimeZone = (timeString) => {
    return timeString + "+0000";
  }

  render() {
    const {
      allCell,
      showAddModal,
      showRemoveModal,
      showModalAddUser,
      cellSelected,
      allCellToTransfer,
      userCell,
      user,
      showInfoModal,
      currentCell,
      loadingCount,
    } = this.state;

    const {
      canCreateWorkgroup,
      canCreatePartner,
      canUpdateWorkgroup,
      canCreateUser,
      canDeleteWorkgroup,
    } = this.getPermissions();

    const {
      is_cell_admin,
      //  isAdmin, isCellManager
    } = this.props.decodedToken;
    let isCellAdmin = is_cell_admin;
    return (
      <Loading loading={loadingCount !== 0}>
        <Header
          active="Configurações de Grupo de Trabalho"
          logged
          buttons={[
            (userCell?.["cellAdmin"] === null ||
              (userCell?.["cellAdmin"]?.["id"] === 1 && !userCell?.isIntern)) &&
            canCreateWorkgroup &&
            canCreatePartner
              ? {
                  onClick: () => this.setOpenAddModal(true),
                  icon: "fas fa-plus-circle",
                  label: "Adicionar",
                }
              : null,
          ]}
        />
        <PageContainer withoutCard>
          <Row className="workgroup-page-container">
            {userCell !== null && (
              <>
                <Col
                  xl={5}
                  className="m-2 p-3 card workgroup-page-left-card workgroup-page-card"
                >
                  <Form.Group>
                    <Form.Label>Minha célula</Form.Label>
                    <Form.Control value={userCell["name"]} readOnly={true} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>É interno?</Form.Label>
                    <Form.Control
                      value={userCell["isIntern"] ? "Sim" : "Não"}
                      readOnly={true}
                    />
                  </Form.Group>
                  <TableComponent
                    loading={loadingCount !== 0}
                    countLabel="usuários"
                    countLabelSingular="usuário"
                    emptyPlaceholder="Nenhum usuário encontrado."
                    header={[
                      { label: "Nome" },
                      { label: "Tipo" },
                      { label: "É administrador?" },
                      { label: "É gerente?" },
                    ]}
                    currentList={this.state.currentUsers}
                    setCurrentList={(currentUsers) =>
                      this.setState({ currentUsers })
                    }
                    list={this.state.users}
                    forEach={(item) => [
                      { label: item.name },
                      { label: item.type },
                      {
                        label: (
                          <BootstrapSwitchButton
                            checked={item["isCellAdmin"]}
                            onstyle="success"
                            offstyle="danger"
                            style="w-75 mx-3 disabled"
                            disabled
                            onlabel="Sim"
                            offlabel="Não"
                            size="sm"
                          />
                        ),
                      },
                      {
                        label: (
                          <div className="w-100">
                            <BootstrapSwitchButton
                              checked={item["isCellManager"]}
                              onstyle="success"
                              offstyle="danger"
                              style={`w-75 mx-3 ${
                                item["isCellAdmin"] ? "disabled" : ""
                              }`}
                              disabled={item["isCellAdmin"]}
                              onlabel="Sim"
                              offlabel="Não"
                              size="sm"
                              onChange={() => {
                                this.setState({ loading: true }, () => {
                                  setUserCellManager({
                                    userId: item["id"],
                                  }).then((res) => {
                                    if (res.ok) {
                                      item["isCellManager"] =
                                        !item["isCellManager"];
                                      this.setState({
                                        loading: false,
                                      });
                                    }
                                  });
                                });
                              }}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />

                  {isCellAdmin && (
                    <div className={"button-center"}>
                      <Button onClick={() => this.showModalAddUser(true)}>
                        Adicionar Usuário
                      </Button>
                    </div>
                  )}
                </Col>
                <Col className="m-2 p-3 card workgroup-page-card">
                  <Tabs
                    defaultActiveKey={
                      userCell?.cellAdmin !== null &&
                      userCell?.cellAdmin?.["name"] !== "ADMIN"
                        ? "Privilégios"
                        : "Células Filhas"
                    }
                    id="uncontrolled-tab-example"
                  >
                    <Tab
                      eventKey="Células Filhas"
                      title="Células Filhas"
                      disabled={
                        userCell?.cellAdmin !== null &&
                        userCell?.cellAdmin?.["name"] !== "ADMIN"
                      }
                    >
                      <TableComponent
                        containerClassName="mt-4"
                        loading={loadingCount !== 0}
                        countLabel="células"
                        countLabelSingular="célula"
                        startSorted="name"
                        emptyPlaceholder="Nenhuma célula encontrada."
                        header={[
                          { label: "#", sortProperty: "id" },
                          // PERMISSÃO - apenas se tiver permissão para editar grupo de trabalho OU criar usuário
                          canUpdateWorkgroup || canCreateUser
                            ? { label: "Editar" }
                            : null,
                          { label: "Nome", sortProperty: "name" },
                          { label: "Criado em", sortProperty: "createdAt" },
                          { label: "Alterado em", sortProperty: "editedAt" },
                          { label: "Usuários", sortProperty: "usersCount" },
                          // PERMISSÃO - apenas se tiver permissão para deletar grupo de trabalho
                          canDeleteWorkgroup ? { label: "Excluir" } : null,
                        ]}
                        currentList={this.state.currentChildCells}
                        setCurrentList={(currentChildCells) =>
                          this.setState({ currentChildCells })
                        }
                        list={this.state.childCells.filter(
                          (e) => e.id !== userCell.id && !e["isIntern"]
                        )}
                        forEach={(item) => [
                          { label: item.id },
                          // PERMISSÃO - apenas se tiver permissão para editar grupo de trabalho OU criar usuário
                          canUpdateWorkgroup || canCreateUser
                            ? {
                                onClick: () =>
                                  this.setShowModalInfo(true, item.id),
                                label: (
                                  <div className="text-center">
                                    <i className="fas fa-edit"></i>
                                  </div>
                                ),
                              }
                            : null,
                          { label: item.name },
                          { label: item.createdAt },
                          { label: item.editedAt },
                          { label: item.usersCount },
                          // PERMISSÃO - apenas se tiver permissão para deletar grupo de trabalho
                          canDeleteWorkgroup
                            ? {
                                onClick: () => {
                                  const selected = allCell.find(
                                    (it) => it.id === item.id
                                  );
                                  this.setOpenRemoveModal(true, selected);
                                },
                                label: (
                                  <div className="text-center">
                                    <i className="fas fa-trash-alt is-required"></i>
                                  </div>
                                ),
                              }
                            : null,
                        ]}
                      />
                    </Tab>
                    <Tab
                      eventKey="Privilégios"
                      title="Privilégios"
                      className="h-100"
                    >
                      <TableComponent
                        containerClassName="mt-4"
                        loading={loadingCount !== 0}
                        countLabel="privilégios"
                        countLabelSingular="privilégio"
                        emptyPlaceholder="Nenhum privilégio encontrado."
                        header={[
                          { label: "" },
                          { label: "Criar" },
                          { label: "Ler" },
                          { label: "Editar" },
                          { label: "Excluir" },
                        ]}
                        currentList={this.state.currentPrivileges}
                        setCurrentList={(currentPrivileges) =>
                          this.setState({ currentPrivileges })
                        }
                        list={Object.keys(userCell["privileges"]).map(
                          (item) => ({
                            name: item,
                            ...userCell["privileges"][item],
                          })
                        )}
                        forEach={(item) => [
                          { label: item.name },
                          {
                            label: (
                              <Form.Check
                                name={item.name + ".insert"}
                                value={!item.insert}
                                checked={item.insert}
                                disabled={true}
                              />
                            ),
                          },
                          {
                            label: (
                              <Form.Check
                                name={item.name + ".read"}
                                value={!item.read}
                                checked={item.read}
                                disabled={true}
                              />
                            ),
                          },
                          {
                            label: (
                              <Form.Check
                                name={item.name + ".update"}
                                value={!item.update}
                                checked={item.update}
                                disabled={true}
                              />
                            ),
                          },
                          {
                            label: (
                              <Form.Check
                                name={item.name + ".delete"}
                                value={!item.delete}
                                checked={item.delete}
                                disabled={true}
                              />
                            ),
                          },
                        ]}
                      />
                    </Tab>
                  </Tabs>
                </Col>
              </>
            )}

            {/* {
					showAddModal &&
					<ModalCell show={showAddModal} onHide={() => this.setOpenAddModal(false)}
						cell={cellSelected} isIntern={false} />
				} */}
            {showAddModal && (
              <ModalPartner
                show={showAddModal}
                onHide={(wasUpdated) =>
                  this.setOpenAddModal(false, null, wasUpdated)
                }
                isIntern={userCell.isIntern}
                cellId={userCell.id}
                privileges={userCell.privileges}
                globalPrivileges={this.props.privileges}
              />
            )}

            {showRemoveModal && (
              <ModalRemoveWorkGrupCell
                show={showRemoveModal}
                onHide={(wasUpdated) =>
                  this.setOpenRemoveModal(false, null, wasUpdated)
                }
                cell={cellSelected}
                isIntern={userCell.isIntern}
                allCell={allCellToTransfer}
                globalPrivileges={this.props.privileges}
              />
            )}
            {showModalAddUser && (
              <ModalUser
                show={showModalAddUser}
                onHide={(wasUpdated) =>
                  this.showModalAddUser(false, null, wasUpdated)
                }
                cellName={userCell.name}
                cellId={userCell.id}
                globalPrivileges={this.props.privileges}
              ></ModalUser>
            )}
            {showInfoModal && currentCell !== null && (
              <ModalInfoCell
                show={showInfoModal}
                onHide={(wasUpdated) =>
                  this.setShowModalInfo(false, null, wasUpdated)
                }
                cell={currentCell}
                user={user}
                isIntern={userCell.isIntern}
                isUserIntern={userCell.isIntern}
                globalPrivileges={this.props.privileges}
                from={"workGroup"}
              />
            )}
          </Row>
        </PageContainer>
      </Loading>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(WorkGroupPage);
