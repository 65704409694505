import React from "react";
import PensionRegister1 from "./PensionRegister1";
import PensionRegister2 from "./PensionRegister2";
import PensionRegister3 from "./PensionRegister3";
import PensionRegister4 from "./PensionRegister4";
import PensionRegister5 from "./PensionRegister5";
import PensionRegister6 from "./PensionRegister6";
import PensionRegister7 from "./PensionRegister7";
import PensionRegister8 from "./PensionRegister8";
import PensionRegister9 from "./PensionRegister9";
import PensionRegister10 from "./PensionRegister10";
import PensionRegister11 from "./PensionRegister11";
import PensionRegister12 from "./PensionRegister12";
import PensionRegister13 from "./PensionRegister13";
import PensionRegister14 from "./PensionRegister14";
import PensionRegister15 from "./PensionRegister15";
import PensionRegister16 from "./PensionRegister16";
import PensionRegister19 from "./PensionRegister19";
import PensionRegister17 from "./PensionRegister17";
import PensionRegister18 from "./PensionRegister18";
import PensionRegister20 from "./PensionRegister20";
import PensionRegister21 from "./PensionRegister21";
import { pensionFormNames } from "../models/pensionFormNames";
import { FormButton } from "../../../genericComponents";
import { FormFull } from "form-full";

function setActualValues(data) {
  if (data) {
    const actualValues = {
      [pensionFormNames.degreeOfKinship]:
        data[pensionFormNames.degreeOfKinship],
      [pensionFormNames.kinshipDocuments]:
        data[pensionFormNames.kinshipDocuments],
      [pensionFormNames.kinshipDocumentsDescription]:
        data[pensionFormNames.kinshipDocumentsDescription],
      [pensionFormNames.economicDependencyDocuments]:
        data[pensionFormNames.economicDependencyDocuments],
      [pensionFormNames.economicDependencyDocumentsDescription]:
        data[pensionFormNames.economicDependencyDocumentsDescription],
      [pensionFormNames.dateOfDeath]: data[pensionFormNames.dateOfDeath],
      [pensionFormNames.deathCertificate]:
        data[pensionFormNames.deathCertificate],
      [pensionFormNames.causeOfDeath]: data[pensionFormNames.causeOfDeath],
      [pensionFormNames.occurInTheExerciseOfFunction]:
        data[pensionFormNames.occurInTheExerciseOfFunction],
      [pensionFormNames.workingInDateOfDeath]:
        data[pensionFormNames.workingInDateOfDeath],
      [pensionFormNames.deceasedActivity]:
        data[pensionFormNames.deceasedActivity],
      [pensionFormNames.regimeOnDateOfDeath]:
        data[pensionFormNames.regimeOnDateOfDeath],
      [pensionFormNames.regimeOnDateOfDeathDescription]:
        data[pensionFormNames.regimeOnDateOfDeathDescription],
      [pensionFormNames.wasRetired]: data[pensionFormNames.wasRetired],
      [pensionFormNames.wasRetiredBenefitNumber]:
        data[pensionFormNames.wasRetiredBenefitNumber],
      [pensionFormNames.receivedBenefit]:
        data[pensionFormNames.receivedBenefit],
      [pensionFormNames.youGetSomeBenefit]:
        data[pensionFormNames.youGetSomeBenefit],
      [pensionFormNames.youGetSomeBenefitDescription]:
        data[pensionFormNames.youGetSomeBenefitDescription],
      [pensionFormNames.contactWay]: data[pensionFormNames.contactWay],
      [pensionFormNames.additionalInformation]:
        data[pensionFormNames.additionalInformation],
      [pensionFormNames.commitmentTerm]: data[pensionFormNames.commitmentTerm],
      [pensionFormNames.requestReason]: data[pensionFormNames.requestReason],
      [pensionFormNames.hasDisability]: data[pensionFormNames.hasDisability],
      [pensionFormNames.hasDependentsUnder18]:
        data[pensionFormNames.hasDependentsUnder18],
      [pensionFormNames.hasDependentsUnder18CPF]:
        data[pensionFormNames.hasDependentsUnder18CPF],
      [pensionFormNames.deceasedINSSPassword]:
        data[pensionFormNames.deceasedINSSPassword],
      [pensionFormNames.applicantINSSPassword]:
        data[pensionFormNames.applicantINSSPassword],
    };

    return actualValues;
  }
  return null;
}

function PensionRegisterForm({
  id,
  setInfos,
  invalidateInfos,
  setRef,
  serverData,
  disabledField,
}) {
  return (
    <FormFull
      onChange={invalidateInfos}
      formRef={setRef}
      onSubmit={setInfos}
      disabled={disabledField}
      actualValues={setActualValues(serverData)}
    >
      <div className="overflow-fix">
        <PensionRegister1 serverData={serverData} />
        <PensionRegister2 serverData={serverData} />
        <PensionRegister3 serverData={serverData} />
        <PensionRegister4 serverData={serverData} />
        <PensionRegister5 serverData={serverData} />
        <PensionRegister6 serverData={serverData} />
        <PensionRegister7 serverData={serverData} />
        <PensionRegister8 serverData={serverData} />
        <PensionRegister9 serverData={serverData} />
        <PensionRegister10 serverData={serverData} />
        <PensionRegister11 serverData={serverData} />
        <PensionRegister12 serverData={serverData} />
        <PensionRegister13 serverData={serverData} />
        <PensionRegister14 serverData={serverData} />
        <PensionRegister15 serverData={serverData} />
        <PensionRegister16 serverData={serverData} />
        <PensionRegister17 sercerData={serverData} />
        <PensionRegister18 sercerData={serverData} />
        <PensionRegister19 serverData={serverData} />
        <PensionRegister20 serverData={serverData} />
        <PensionRegister21 serverData={serverData} id={id} />

        <div className="flex-justify-end">
          <FormButton
            variant="primary"
            actionType="submit"
            disabled={disabledField}
            name="submit"
          >
            {id ? "Atualizar" : "Cadastrar"}
          </FormButton>
        </div>
      </div>
    </FormFull>
  );
}

export default React.memo(PensionRegisterForm);
