import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { reducerKeys } from "../../../../store/reducers/reducer";
import ClientsEntrance from "../charts/ClientsEntranceChart";
import {
  Card,
  CardTitle,
  RenderDoughnutValue,
  RenderItemContainer,
  RenderValue,
} from "../components";
import styles from "../GeneralPanelPage.module.css";

function SecondGroup({ colProps, infos, loading }) {
  return (
    <Card colProps={colProps}>
      <CardTitle title="ENTRADA DE CLIENTES" />
      <Row className="m-0">
        <div className={styles.doughnutContainer}>
          <ClientsEntrance chartData={loading ? null : infos.chartData} />
        </div>
        <Col xs className="pr-3">
          <RenderItemContainer className="flex-column">
            <RenderItemContainer withBorder className="w-100 p-2 px-3">
              <RenderValue
                subtitle={loading ? null : infos.allEntries}
                title="Todas as Entradas"
              />
            </RenderItemContainer>
            <Row className="w-100 d-flex justify-content-center px-3 pb-3 pt-2">
              <div className="d-flex flex-row flex-wrap justify-content-between pr-1 w-100">
                <RenderDoughnutValue
                  title="App"
                  subtitle={loading ? null : infos.appEntries}
                  dotColor="#3498DB"
                />
                <RenderDoughnutValue
                  title="Credenciados"
                  subtitle={loading ? null : infos.credentialEntries}
                  dotColor="#A7DCFF"
                />

                <RenderDoughnutValue
                  title="Web"
                  subtitle={loading ? null : infos.webEntries}
                  dotColor="#7683BF"
                />
                <RenderDoughnutValue
                  title="SAC"
                  subtitle={loading ? null : infos.sacEntries}
                  dotColor="#3E4B8B"
                />
              </div>
            </Row>
          </RenderItemContainer>
        </Col>
      </Row>
    </Card>
  );
}

function stateToProps(state) {
  const { secondGroupInfos, secondGroupLoading } =
    state[reducerKeys.generalPanel];
  return { infos: secondGroupInfos, loading: secondGroupLoading };
}

export default connect(stateToProps)(SecondGroup);
