import React from "react";
import styles from "../GeneralPanelPage.module.css";

export default function RenderItemContainer({
  children,
  className = "",
  withBorder,
}) {
  return (
    <div
      className={`${styles.itemContainer} ${
        withBorder ? styles.withLeftBorder : ""
      } d-flex align-items-center ${className}`}
    >
      {children}
    </div>
  );
}
