import React from "react";
import { Row } from "react-bootstrap";
import { Textarea } from "../../../genericComponents";

function CustomerSurvey15() {
  return (
    <Row>
      <Textarea
        label="Se você quiser inserir alguma informação adicional que possa orientar nossos consultores:"
        name="additionalInformation"
        maxLength={1000}
      />
    </Row>
  );
}

export default React.memo(CustomerSurvey15);
