import React from "react";
import { Row } from "react-bootstrap";
import { Input } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";

function PensionRegister15() {
  return (
    <Row>
      <Input
        label="Qual a causa do óbito"
        name={pensionFormNames.causeOfDeath}
        required={required.default}
        maxLength={255}
      />
    </Row>
  );
}

export default React.memo(PensionRegister15);
