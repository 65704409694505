import React from "react";
import { Row } from "react-bootstrap";
import { RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { defaultTwoOptions } from "../models/selects";

function PensionRegister8() {
  return (
    <Row>
      <RadioGroup
        label="O falecido estava recebendo benefício?"
        name={pensionFormNames.receivedBenefit}
        options={defaultTwoOptions.map((item) => ({
          label: item,
          value: item,
        }))}
        required={required.default}
        inline
      />
    </Row>
  );
}

export default React.memo(PensionRegister8);
