import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { isUserView } from "../../../../utils/storage";
import { ActionCard } from "../components";
import styles from "../styles/ActionList.module.css";
import genericStyles from "../styles/SingleProcess.module.css";

function ActionList({
  processInfo,
  done,
  processType,
  processStatus,
  decodedToken,
  privileges,
  setShowModalInsertAction,
}) {
  const privilegeActionCreate = React.memo(() => {
    return (
      !decodedToken.is_customer &&
      (privileges?.andamento_interno?.create ||
        privileges?.andamento_externo?.create)
    );
  }, [
    decodedToken.is_customer,
    privileges?.andamento_interno?.create,
    privileges?.andamento_externo?.create,
  ]);

  let index = 0, max = 0;
  return (
    <Card className={[genericStyles.cards, styles.card]}>
      <Form>
        <Row>
          <Col className="d-flex align-items-center">
            <span>
              <strong>Lista de Andamentos</strong>
              <small style={{fontSize: 10, marginLeft: 10}}>Clique no titulo para alterar </small>
            </span>
          </Col>
          {(decodedToken?.is_user_and_customer
            ? isUserView()
            : !decodedToken?.is_customer) &&
            privilegeActionCreate && (
              <Col className="text-right">
                <Button
                  variant="primary"
                  onClick={() => setShowModalInsertAction(true)}
                  disabled={processStatus === "AGUARDANDO ASSINATURA" || processType === "" || done}
                >
                  {"Inserir andamento"}
                </Button>
              </Col>
            )}
        </Row>
        <div className={"mt-2 mb-2 border-top"} />
        <div>
          {processInfo !== undefined && processInfo.length > 0 ? (
            processInfo.map((action, key) => {
              if (index++ > 9) max++
              return (
                <ActionCard
                  key={key}
                  index={'index-' + max}
                  action={action}
                  setShowModalInsertAction={setShowModalInsertAction}
                  title={`${processInfo.length - key}.${action.actionType.name}`}
                />
              );
            })
          ) : (
            <div className={"p-3 text-center"}>
              <span>
                Sua listagem de andamentos está vazia, aguarde até o retorno ou
                conclusão da etapa atual
              </span>
            </div>
          )}
        </div>
      </Form>
    </Card>
  );
}

export default ActionList;
