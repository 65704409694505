import caller from "../../helpers/caller";
import Parse from "../../helpers/Parse";

function parseFunction(item) {
  const data = {
    first: {
      allClients: item.first.allClients,
      registeredToday: item.first.registeredToday,
      registeredYesterday: item.first.registeredYesterday,
    },
    second: {
      allEntries: item.second.allEntries,
      appEntries: item.second.appEntries,
      webEntries: item.second.webEntries,
      credentialEntries: item.second.credentialEntries,
      sacEntries: item.second.sacEntries,
      chartData: item.second.chartData,
    },
    third: {
      totalProposals: item.third.totalProposals,
      submittedProposals: item.third.submittedProposals,
      acceptedProposals: item.third.acceptedProposals,
      proposalsInProgress: item.third.proposalsInProgress,
      rejectedProposals: item.third.rejectedProposals,
      chartData: item.third.chartData,
    },
    fourth: {
      totalContracts:
        item.fourth.submittedContracts +
        item.fourth.subscriptionPending +
        item.fourth.signedContracts +
        item.fourth.canceled,
      submittedContracts: item.fourth.submittedContracts,
      subscriptionPending: item.fourth.subscriptionPending,
      signedContracts: item.fourth.signedContracts,
      canceled: item.fourth.canceled,
      chartData: [
        item.fourth.submittedContracts,
        item.fourth.subscriptionPending,
        item.fourth.signedContracts,
        item.fourth.canceled,
      ],
    },

    fifth: {
      lateProcesses: item.fifth.lateProcesses,
      lateAmount: item.fifth.lateAmount,
    },
    sixth: item.sixth.map((el) => ({
      label: el.type,
      value: el.quantity,
    })),
    seventh: {
      marketedProducts: item.seventh.map((el) => ({
        type: el.type,
        dayQuantity: el.dayQuantity,
        monthQuantity: el.monthQuantity,
        prevMonthQuantity: el.prevMonthQuantity,
        quarterlyAverage: el.quarterlyAverage,
        total: el.total,
      })),
    },
  };

  return data;
}

export default async function getChatList(api) {
  const response = await caller(api.get, "/dashboard/get");
  const parse = new Parse(response.data, response.endpoint, parseFunction);
  return { ...response, data: parse.parse() };
}
