import React from "react";
import { Row } from "react-bootstrap";
import { InputPassword } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";

function PensionRegister18() {
  return (
    <Row>
      <InputPassword
        label="Senha do MEU INSS do requerente"
        name={pensionFormNames.applicantINSSPassword}
        maxLength={255}
        required={required.default}
      />
    </Row>
  );
}

export default React.memo(PensionRegister18);
