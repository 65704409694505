import React from "react";
import { Row } from "react-bootstrap";
import { Input, RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { defaultTwoOptions } from "../models/selects";

function RenderDocumentsDescription() {
  return (
    <Row>
      <Input
        label="Quais?"
        name={pensionFormNames.kinshipDocumentsDescription}
        maxLength={100}
      />
    </Row>
  );
}

function PensionRegister12({ serverData }) {
  const [hasDocuments, setHasDocuments] = React.useState(null);
  const kinshipDocuments = serverData?.kinshipDocuments;
  React.useEffect(() => {
    if (kinshipDocuments === defaultTwoOptions[0]) {
      setHasDocuments(true);
    }
  }, [kinshipDocuments, setHasDocuments]);

  return (
    <>
      <Row>
        <RadioGroup
          colProps={{ xs: 12 }}
          label="Você possui documentos, para comprovar a relação de parentesco?"
          name={pensionFormNames.kinshipDocuments}
          options={defaultTwoOptions.map((item) => ({
            label: item,
            value: item,
          }))}
          required={required.default}
          inline
          onChange={(event, value) => {
            setHasDocuments(value === defaultTwoOptions[0]);
          }}
        />
      </Row>
      {hasDocuments === true ? <RenderDocumentsDescription /> : null}
    </>
  );
}

export default React.memo(PensionRegister12);
