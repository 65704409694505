import React, { Component } from "react";
import { Modal, Table } from "react-bootstrap";
import { getCellByOutsourceWithUsers } from "../../../services/RequestService";
import styles from "../../../genericComponents/others/tableComponent/TableComponent.module.css";

class ModalPartnerCells extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cells: [],
      users: [],
      cellName: "",
      loading: false,
    };
  }

  componentDidMount = () => {
    const { id } = this.props;
    this.setState({ loading: true });
    getCellByOutsourceWithUsers({ outsourceId: id }).then((res) => {
      if (res.ok) {
        this.setState({ cells: res.data });
      }
      this.setState({ loading: false });
    });
  };

  selectCell = (cell) => {
    this.setState({ cellName: cell.name, users: cell.users });
  };

  render() {
    const { loading, cells, users, cellName } = this.state;
    const { show, onHide } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        centered
        size="lg"
        scrollable={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Vínculos"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{"Grupos de trabalho"}</h5>
          {loading ? null : cells?.length === 0 ? (
            <p className={styles.emptyTable}>{"Nenhum valor encontrado."}</p>
          ) : (
            <Table
              striped
              bordered
              className={`table-bordered bms-color-text my-0`}
            >
              <thead>
                <tr>
                  <th>id</th>
                  <th>Nome</th>
                </tr>
              </thead>
              <tbody>
                {cells?.map((item, key) => {
                  return (
                    <tr
                      key={key}
                      onClick={() => this.selectCell(item)}
                      className={styles.trClickable}
                    >
                      <td>{item.id}</td>
                      <td>{item.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          {cells?.length === 0 && users?.length === 0 ? null : (
            <div>
              <h5 className="pt-5">{`Usuários do grupo (${cellName})`}</h5>
              <Table
                striped
                bordered
                className={`table-bordered bms-color-text my-0`}
              >
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>CPF</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.cpf}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
          {/* </Loading> */}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ModalPartnerCells;
