import React, { useState, useRef, useEffect } from "react";
import { Loading, MessageBox, Layout, Form, Card, DatePicker, Table, Button } from 'element-react'
import CurrencyInput from "../../../components/fields/MoneyField";
import { useAlert } from "react-alert";
import SelectRemoteSearch from "../../../genericComponents/inputs/element/SelectRemoteSearch";
import { deleteDiamondRecharge, getComboListCustomer, getDiamondRechargeByDiamondId, postDiamondRecharge, putDiamondRecharge } from "../../../services/RequestService";
import { fn, masks } from "../../../utils";

export default function DiamondCard({ cardDiamondId }) {
  const alert = useAlert();
  const formRef = useRef();
  const rules = {
    dueDate: [{ type: 'date', required: true, message: 'Campo Obrigatório', trigger: 'blur' }],
    order: [{ type: 'date', required: true, message: 'Campo Obrigatório', trigger: 'blur' }],
    status: [{ type: 'enum', enum: ['true', 'false'], required: true, message: 'Campo Obrigatório', trigger: 'blur' }],
    cellId: [{ type: 'integer', required: true, message: 'Campo Obrigatório', trigger: 'blur' }]
  }

  const columns = [
    {
      label: "Ações", with: '80', render: (row) => (
        <>
          <Button type="danger" size="small" icon="delete2" onClick={() => removeRecharge(row.id)} />
          <Button type="primary" size="small" icon="edit" onClick={() => updateRecharge(row)} />
        </>
      )
    },
    { label: "Recarregar em", prop: "rechargeOn", render: (row) => masks.convertDateToBr(row.rechargeOn) },
    { label: "Valor", prop: "value", render: (row) => masks.money(row.value) },
    { label: "Cliente", prop: "indicatedCustomerId" },
    { label: "CPF", prop: "indicatedCustomerCpf", render: (row) => masks.CPF(row.indicatedCustomerCpf) },
  ]

  const model = {
    rechargeOn: new Date(),
    value: null,
    indicatedCustomerId: null
  }
  const [loading, setLoading] = useState(false)
  const [isLoad, setLoad] = useState(false)
  const [recharges, setRecharges] = useState([])
  const [form, setForm] = useState(model);

  useEffect(() => {
    if (!isLoad && cardDiamondId > 0) {
      getDiamondRechargeByDiamondId(cardDiamondId).then(({ data, status }) => {
        if (status === 200) setRecharges(data)
        setLoad(true)
      })
    }
  }, [cardDiamondId, recharges, setRecharges, isLoad]);

  function removeRecharge(id) {
    MessageBox.confirm('Deseja excluir esta recarga?', 'Continuar?', { confirmButtonText: 'Sim', cancelButtonText: 'Não', type: 'warning' }).then(async () => {
      setLoading(true)
      const { ok, data } = await deleteDiamondRecharge(id)
      if (ok) alert.success('Recarga excluida com sucesso!')
      else alert.error(data?.log)
      getDiamondRechargeByDiamondId(cardDiamondId).then(({ data, status }) => {
        if (status === 200) setRecharges(data)
        setLoading(false)
      })
    })
  }

  function updateRecharge(row) {
    row.rechargeOn = new Date(row.rechargeOn)
    setForm(row)
  }


  function handleSubmit(e) {
    e.preventDefault();
    if (!cardDiamondId) {
      alert.show('Cadastre o cartão antes da recarga', { type: "danger" });
      return
    }
    formRef.current.validate(async (valid) => {
      if (valid) {
        setLoading(true)
        const params = { ...form, cardDiamondId, value: fn.float(form.value) };
        if (!form.id) {
          const { data, ok } = await postDiamondRecharge(params)
          if (ok) {
            setForm({ ...form, id: data.id })
            alert.success('Recarga adicionada com sucesso');
          } else {
            alert.error('Erro ao adicionar recarga, ' + data?.log);
          }
        } else {
          const { ok, data } = await putDiamondRecharge(params, form.id)
          if (ok) {
            alert.success('Recarga alterada com sucesso');
          } else {
            alert.error('Erro ao alterar recarga, ' + data?.log);
          }
        }
        const { data, status } = await getDiamondRechargeByDiamondId(cardDiamondId)
        if (status === 200) setRecharges(data)
        setLoading(false)
        setForm(model)
      } else {
        return false;
      }
    });
  }

  function onChange(key, value) {
    setForm(Object.assign({}, form, { [key]: value }));
  }

  return (
    <Card className="box-card" header={(<div className="clearfix" >Inserir/Alterar Recargas</div>)}>
      {loading && (<Loading fullscreen={true} />)}
      <Form ref={formRef} model={form} rules={rules} labelPosition="top">
        <Layout.Row gutter="20">
          <Layout.Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item label="Data da Recarga" prop="rechargeOn">
              <DatePicker value={form.rechargeOn} placeholder="Selecione a data" format="dd/MM/yyyy" onChange={onChange.bind(this, 'rechargeOn')} />
            </Form.Item>
          </Layout.Col>
          <Layout.Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item label="Valor" prop="value">
              <CurrencyInput className={"form-control"} placeholder={"R$ 0,00"} type="text" value={form.value} onChange={(e) => onChange('value', e.target.value)} />
            </Form.Item>
          </Layout.Col>
          <Layout.Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item label="Cliente Indicado" prop="indicatedCustomerId">
              <SelectRemoteSearch ajaxFunction={getComboListCustomer} value={form.indicatedCustomerId} onChange={onChange.bind(this, 'indicatedCustomerId')} />
            </Form.Item>
          </Layout.Col>
          <Layout.Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item label="&nbsp;">
              <Button type="primary" onClick={handleSubmit.bind(this)}>Gravar</Button>
              <Button type="defaulr" onClick={() => setForm(model)}>Limpar</Button>
            </Form.Item>
          </Layout.Col>
        </Layout.Row>
      </Form>

      <Table style={{ width: '100%' }} columns={columns} data={recharges} emptyText={"Sem resultados"} stripe={true} />
    </Card>
  )
}