import React, { useRef, useState } from 'react'
import { Form, Select, Layout, Card, Button, Input, DatePicker } from 'element-react'
import './custom.css'

export default function DinamicFilter({ fields, actions, filterFn, afterClearFilterFn }) {
  const formRef = useRef();
  const [filters, setFilters] = useState([getFilterModel()])
  const joinList = [{ label: 'E', value: 'AND' }, { label: 'OU', value: 'OR' }]
  const comparisonList = [
    { label: 'Igual', value: 'EQUAL' },
    { label: 'Maior', value: 'GT' },
    { label: 'Maior Igual', value: 'GTE' },
    { label: 'Menor', value: 'LT' },
    { label: 'Menor Igual', value: 'LTE' },
    { label: 'Contido em', value: 'LIKE' },
    { label: 'Inicia com', value: 'START_WITH' },
    { label: 'Termina com', value: 'END_WITH' }
  ]

  function getFilterModel() {
    return Object.assign({}, { fieldKey: '', comparison: '', value: null, join: 'AND' });
  }

  function onChange(index, key, value) {
    const newFilters = filters.map((f, i) => {
      if (i === index) {
        if (key === 'fieldKey') {
          return Object.assign(getFilterModel(), { [key]: value })
        }
        return Object.assign(f, { [key]: value })
      }
      return f
    })
    setFilters(newFilters);
  }

  function addFilter() {
    filters.push(getFilterModel())
    setFilters([...filters])
  }

  function removeFilter(index) {
    if (filters.length > 1) {
      setFilters(filters.filter((f, i) => i !== index))
    }
  }

  function clearFilter() {
    setFilters([getFilterModel()])
    if (afterClearFilterFn) afterClearFilterFn()
  }

  function filter() {
    const p = filters.filter(v => (v.value || v.value === false) && v.comparison && v.fieldKey).map(f => {
      const type = fields.filter(e => e.key === f.fieldKey)[0]?.type || 'text'
      // eslint-disable-next-line default-case
      switch (type) {
        case 'number':
          return Object.assign(f, { value: parseFloat((f.value + '').replace('.', '').replace(',', '.')) })
        case 'date':
          if (f.value instanceof Date)
            return Object.assign(f, { value: f.value.toISOString().substring(0, 10) })
      }
      return f
    })
    if (p.length > 0) filterFn(p)
  }
  return (
    <Card className="box-card dinamic-filter" style={{ margin: '15px' }}>
      <small style={{ fontSize: '14px', color: '#48576a', lineHeight: 1 }}>Filtros</small>
      {filters.map((f, index) => {
        const type = fields.filter(e => e.key === f.fieldKey)[0]?.type || 'text'
        return (
          <Form className='dinamic-filter-form' key={index} ref={formRef} model={f} placement="left">
            {index > 0 && (
              <Form.Item style={{ width: '110px' }} prop='join'>
                <Select value={f.join} onChange={onChange.bind(this, index, 'join')}>
                  {joinList.map((el, key) => (<Select.Option key={key} label={el.label} value={el.value} />))}
                </Select>
              </Form.Item>
            )}
            <Layout.Row gutter="20">
              <Layout.Col xs={24} sm={24} md={6}>
                <Form.Item prop='fieldKey'>
                  <Select value={f.fieldKey} placeholder="Campo" clearable={true} filterable={true}
                    onChange={onChange.bind(this, index, 'fieldKey')}>
                    {fields.map(el => (<Select.Option key={el.key} label={el.label} value={el.key} />))}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={4}>
                <Form.Item prop='comparison'>
                  <Select value={f.comparison} placeholder="Comparação" clearable={true} onChange={onChange.bind(this, index, 'comparison')}>
                    {comparisonList.map(el => {
                      if (type === 'number' && ['END_WITH', 'START_WITH', 'LIKE'].includes(el.value)) return null
                      if (type === 'date' && ['EQUAL', 'END_WITH', 'START_WITH', 'LIKE'].includes(el.value)) return null
                      if (type === 'boolean' && !['EQUAL'].includes(el.value)) return null
                      if (type === 'text' && !['EQUAL', 'END_WITH', 'START_WITH', 'LIKE'].includes(el.value)) return null
                      return (<Select.Option key={el.value} label={el.label} value={el.value} />)
                    })}
                  </Select>
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={6}>
                <Form.Item prop="value">
                  {(type === 'date' ? (
                    <DatePicker format="dd/MM/yyyy" value={f.value} placeholder="Valor" onChange={onChange.bind(this, index, 'value')} />
                  ) : ((type === 'boolean' ? (
                    <Select value={f.value} placeholder="Valor" clearable={true} onChange={onChange.bind(this, index, 'value')}>
                      <Select.Option label="Sim" value={true} />
                      <Select.Option label="Não" value={false} />
                    </Select>
                  ) : (
                    <Input placeholder='Valor' value={f.value} onChange={onChange.bind(this, index, 'value')} />
                  ))))}
                </Form.Item>
              </Layout.Col>
              <Layout.Col xs={24} sm={24} md={8}>
                <Form.Item>
                  <Button icon='plus' type="primary" onClick={addFilter} />
                  <Button icon='delete' type="danger" onClick={removeFilter.bind(this, index)} />
                  {filters.length === index + 1 && (
                    <>
                      <Button type="info" onClick={filter} >Filtrar</Button>
                      <Button type="default" onClick={clearFilter} >Limpar</Button>
                      {actions}
                    </>
                  )}
                </Form.Item>
              </Layout.Col>
            </Layout.Row>
          </Form>
        )
      })
      }
    </Card >
  );
}