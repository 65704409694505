export const epiNames = [
  "earProtectors",
  "maskAndFilter",
  "gloves",
  "helmet",
  "belts",
  "glasses",
  "boots",
];

export const epiValues = [
  "Abafadores de ruídos ou Protetor auricular",
  "Máscara e filtro",
  "Luvas / Mangotes",
  "Capacete",
  "Cintos de segurança e cinturões",
  "Óculos de segurança / Viseira",
  "Botas / Sapatos / Botinas",
];

export const contactNames = ["Whatsapp", "SMS", "E-mail", "Telefone"];

export const dangerousNames = [
  "dangerousNoiseCompany",
  "dangerousNoiseMachine",
  "dangerousHeat",
  "dangerousCold",
  "dangerousElectricity",
  "dangerousChemicalAgents",
  "dangerousHeight",
  "dangerousGunBearing",
  "dangerousBiologicalAgents",
];
