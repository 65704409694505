import React, {useEffect, useRef, useState} from "react";
import {Layout, Loading, Button, Select, Form, Input, Card, Tag} from 'element-react';
import {useAlert} from "react-alert";
import {Header} from "../../../genericComponents";
import {
  getDocumentTemplates,
  getDocumentLayoutById,
  saveDocumentLayout,
  removeDocumentLayout,
  getAllDocumentType
} from "../../../services/RequestService";
import SimpleUpload from "../../../genericComponents/upload/SimpleUpload";

export default function DocumentGenerator() {
  const FRM = {id: null, name: null, defaultFile: null, documentTypeId: null, vars: []};
  const varsList = [
    {label: 'Nome', value: 'nome'},
    {label: 'CPF', value: 'cpf'},
    {label: 'RG', value: 'rg'},
    {label: 'E-mail', value: 'email'},
    {label: 'Telefone', value: 'telefone'},
    {label: 'Data Atual', value: 'dataAtual'},
    {label: 'Data Atual Extenso', value: 'dataAtualExtenso'},
    {label: 'Tipo Processo', value: 'tipoProcesso'},
    {label: 'Situação Processo', value: 'statusProcesso'},
    {label: 'ID Cliente', value: 'idCliente'},
    {label: 'Aniversário', value: 'aniversario'},
    {label: 'Data Entrega', value: 'dataEntrega'},
    {label: 'Endereço', value: 'endereco'},
    {label: 'Cidade', value: 'cidade'},
    {label: 'UF', value: 'estado'},
    {label: 'Gênero', value: 'genero'},
    {label: 'Nacionalidade', value: 'nacionalidade'},
    {label: 'Profissão', value: 'profissao'},
    {label: 'Estado Civil', value: 'estadoCivil'}
  ]
  const alert = useAlert();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [form, setForm] = useState({...FRM});

  useEffect(() => {
    const fetchData = async () => {
      const templatesRes = await getDocumentTemplates();
      if (templatesRes.ok) setTemplates(templatesRes.data);
      const documentTypesRes = await getAllDocumentType();
      if (documentTypesRes.ok) setDocumentTypes(documentTypesRes.data);
    };
    fetchData();
  }, []);

  const onFilter = async (key, id) => {
    if (id) {
      setLoading(true);
      const {ok, data} = await getDocumentLayoutById(id);
      if (ok && data) {
        data.vars = (data.vars || []).split(',');
        setForm(data);
      }
      setLoading(false);
    } else {
      clearForm();
    }
  };

  const clearForm = () => {
    setForm({...FRM});
  };

  const save = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {ok, data} = await saveDocumentLayout({...form, vars: form.vars.join()});
    if (ok) {
      alert.show("Template salvo com sucesso", {type: "success"});
      const t = templates.filter(t => t.value !== data.id);
      t.push({label: data.name, value: data.id});
      setTemplates(t);
      setForm({...form, id: data.id});
    } else {
      alert(data.log, {type: 'error'});
    }
    setLoading(false);
  };

  const remove = async () => {
    const {ok, data} = await removeDocumentLayout(form.id);
    if (ok) {
      alert.show("Template excluído com sucesso", {type: "success"});
      clearForm();
    } else {
      alert.show(data.log, {type: "error"});
    }
  };

  const onChange = (key, value) => {
    if (key === 'vars') {
      form.vars.push(value)
      setForm({...form, vars: [...new Set(form.vars)]})
      return
    }
    setForm(prevForm => ({...prevForm, [key]: value}));
  };

  return (
    <Loading loading={loading}>
      <Header active="Configuração Documento" logged/>
      <div style={{height: window.innerHeight - 100 + "px", overflowX: "auto"}}>
        <Form className='dinamic-filter-form' ref={formRef} model={form} labelWidth="200">
          <Card style={{margin: '15px'}} className="box-card" header={(
            <Layout.Row gutter="20">
              <Layout.Col xs={24} sm={16} md={16} style={{padding: '0'}}>Documento</Layout.Col>
              <Layout.Col xs={24} sm={16} md={8} style={{padding: '0'}}>
                <Select value={form.id} placeholder="Filtrar" clearable={true} filterable={true}
                        onChange={id => onFilter('id', id)}>
                  {(templates || []).map(el => (<Select.Option key={el.value} label={el.label} value={el.value}/>))}
                </Select>
              </Layout.Col>
            </Layout.Row>
          )}>
            <div style={{width: '50%', height: window.innerHeight - 250}}>
              <Form.Item label="Nome Documento" prop="name">
                <Input value={form.name} onChange={value => onChange('name', value)}/>
              </Form.Item>
              <Form.Item label="Tipo Documento" prop="documentTypeId">
                <Select value={form.documentTypeId} onChange={value => onChange('documentTypeId', value)}>
                  {(documentTypes || []).map(el => (<Select.Option key={el.id} label={el.name} value={el.id}/>))}
                </Select>
              </Form.Item>
              <Form.Item label="Documento" prop="defaultFile">
                <small>Antes de fazer upload substitua as variáveis dentro do documento por @@@@@, apenas
                  formato <b>.docx</b></small>
                <SimpleUpload value={form.defaultFile} onUpload={b64 => onChange('defaultFile', b64)} accept={".docx"}/>
              </Form.Item>
              <Form.Item label="Variaveis" prop="vars">
                <small>Selecione as variáveis na mesma ordem que devem ser substituídas dentro do documento</small>
                <Select onChange={value => onChange('vars', value)}>
                  {(varsList || []).map(el => (<Select.Option key={el.value} label={el.label} value={el.value}/>))}
                </Select>
                {(form.vars || []).map((el, k) => (
                  <Tag key={k} type="success" style={{margin: 3}}
                       closable={true}  onClose={() => setForm({...form, vars: form.vars.filter(f => f !== el)})}
                  >
                    {varsList.filter(vl => vl.value === el)[0].label}
                  </Tag>
                ))}
              </Form.Item>
              <Form.Item style={{marginTop: '10px'}}>
                <Button type="primary" disabled={!form.name || !form.defaultFile || !form.documentTypeId}
                        onClick={save}>Gravar</Button>
                {form.id && (<Button type="danger" onClick={remove}>Excluir</Button>)}
              </Form.Item>
            </div>
          </Card>
        </Form>
      </div>
    </Loading>
  );
}
