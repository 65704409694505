import React, { Component } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import FileBase64 from "react-file-base64";
import { connect } from "react-redux";
import { confirmBoletoPayment } from "../../services/RequestService";
import { alertActions } from "../../store/actions/AlertActions";

class ModalUploadTxtFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64Files: [],
      documentChoice: "",
      file: {},
      isFilenameValid: undefined,
      isDocumentChoiceValid: undefined,
      btnDisabled: true,
      showTableResponse: [],
    };
  }

  handleChooseFile = (files) => {
    console.log(files);
    files.forEach((e) => {
      let nameSplit = e.name.split(".");
      let name = "";
      for (let i = 0; i < nameSplit.length - 1; i++) {
        name = name + nameSplit[i];
      }
      if (
        nameSplit[nameSplit.length - 1] === "txt" ||
        nameSplit[nameSplit.length - 1] === "ret"
      ) {
        const base64Split = e.base64.split(",");

        this.setState((state) => ({
          ...state,
          base64Files: Array.prototype.concat(
            this.state.base64Files,
            base64Split[base64Split.length - 1]
          ),
          isFilenameValid: name !== "",
          btnDisabled: false,
        }));
      } else {
        this.props.onAddAlert(
          "Arquivo não suportado. Arquivos com formato txt são os unicos aceitos",
          "danger",
          true
        );
        this.setState((state) => ({
          ...state,
          btnDisabled: true,
        }));
      }
    });
  };

  sendFile = () => {
    if (!this.validFields) {
      return;
    }

    const { base64Files: base64 } = this.state;

    const request = base64;

    this.setState({ btnDisabled: true });

    confirmBoletoPayment(request).then((response) => {
      if (response.ok && response.data.length > 0) {
        this.setState({ showTableResponse: response.data });
      } else {
        this.props.onAddAlert(
          "Arquivo processado com sucesso, nenhuma das transações contidas neste arquivo de retorno foram encontradas no sistema!",
          "warning",
          true
        );
        this.setState({ btnDisabled: false });
      }
    });
  };

  validFields = () => {
    const { base64Files: base64, filename, documentChoice } = this.state;
    let valid = base64 !== "" && filename !== "" && documentChoice !== "";
    return valid;
  };

  render() {
    const { show, onHide } = this.props;
    const { btnDisabled, showTableResponse } = this.state;
    return (
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {showTableResponse.length === 0
              ? "Confirmar Pagamento de Boletos"
              : "Boletos Liquidados"}
          </Modal.Title>
        </Modal.Header>
        {showTableResponse.length === 0 ? (
          <>
            <Modal.Body>
              <FileBase64 multiple={true} onDone={this.handleChooseFile} />
            </Modal.Body>
            <Button onClick={this.sendFile} disabled={btnDisabled}>
              Confirmar
            </Button>
          </>
        ) : (
          <>
            <Modal.Body>
              <Table striped bordered className="mt-3">
                <thead>
                  <tr>
                    <th>Nosso Número</th>
                    <th style={{ textAlign: "right" }}>
                      Valor Pago Pelo Pagador
                    </th>
                    <th>Cliente</th>
                    <th>Situação(CNAB)</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {showTableResponse.map((row, key) => {
                    return (
                      <tr key={key}>
                        <td>{row.refTran}</td>
                        <td style={{ textAlign: "right" }}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(row.amount)}
                        </td>
                        <td>{row.customer}</td>
                        <td>{row.status}</td>
                        <td>{row.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Modal.Body>
          </>
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalUploadTxtFile);
