import React, { useState, useRef, useEffect } from "react";
import { Loading, Form, Card, Select, Layout, DatePicker, Radio, Button } from 'element-react'
import { getAllCells2Combobox, getDiamondByCustomer, postDiamond, putDiamond } from "../../../services/RequestService";
import RechargeDiamondCard from "./RechargeDiamondCard";
import { useAlert } from "react-alert";

export default function DiamondCard({ customerId }) {
  const alert = useAlert();
  const formRef = useRef();
  const rules = {
    dueDate: [{ type: 'date', required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    orderDate: [{ type: 'date', required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    status: [{ type: 'enum', enum: ['ATIVO', 'INATIVO'], required: true, message: 'Campo Obrigatório', trigger: 'change' }],
    cellId: [{ type: 'integer', required: true, message: 'Campo Obrigatório', trigger: 'change' }]
  }

  const [loading, setLoading] = useState(false)
  const [cells, setCells] = useState([])
  const [form, setForm] = useState({
    orderDate: new Date(),
    dueDate: null,
    status: null,
    cellId: null
  });

  useEffect(() => {
    if (cells.length === 0 && customerId > 0) {
      getAllCells2Combobox().then(({ data, ok }) => {
        if (ok) setCells(data)
      })
      getDiamondByCustomer(customerId).then(({ data, ok }) => {
        if (ok && data) {
          const { orderDate, dueDate, status, cellId, id } = data
          setForm({
            cellId, id, status,
            orderDate: new Date(orderDate),
            dueDate: new Date(dueDate)
          })
        }
      })
    }
  }, [cells, customerId, setCells, setForm]);

  function handleSubmit(e) {
    e.preventDefault();
    formRef.current.validate(async (valid) => {
      if (valid) {
        setLoading(true)
        if (!form.id) {
          const { data, ok } = await postDiamond({ ...form, customerId })
          if (ok) {
            setForm({ ...form, id: data.id })
            alert.success('Cartão adicionado com sucesso');
          } else {
            alert.error('Erro ao adicionar recarga, ' + data?.log);
          }
        } else {
          const { ok, data } = await putDiamond(form, form.id)
          if (ok) alert.success('Cartão alterado com sucesso');
          else alert.error('Erro ao alterar recarga, ' + data?.log);
        }
        setLoading(false)
      } else {
        return false;
      }
    })
  }

  function onChange(key, value) {
    setForm(Object.assign({}, form, { [key]: value }));
  }

  return (
    <Layout.Row gutter="20">
      {loading && (<Loading fullscreen={true} />)}
      <Layout.Col xs={24} sm={16} md={8} style={{ marginBottom: '10px' }}>
        <Card className="box-card" header={(<div className="clearfix" >Inserir/Alterar Recarga</div>)}>
          <Form ref={formRef} model={form} rules={rules} labelPosition="top">
            <Form.Item label="Data do Pedido" prop="orderDate">
              <DatePicker value={form.orderDate} placeholder="Selecione a data" format="dd/MM/yyyy" onChange={onChange.bind(this, 'orderDate')} />
            </Form.Item>
            <Form.Item label="Célula Responsável" prop="cellId">
              <Select value={form.cellId} placeholder="Selecione" clearable={true} filterable={true} onChange={onChange.bind(this, 'cellId')}>
                {cells.map(el => (<Select.Option key={el.value} label={el.label} value={el.value} />))}
              </Select>
            </Form.Item>
            <Form.Item label="Data de Vencimento" prop="dueDate">
              <DatePicker value={form.dueDate} placeholder="Selecione a data" format="dd/MM/yyyy" onChange={onChange.bind(this, 'dueDate')} />
            </Form.Item>
            <Form.Item label="Situação do Cartão" prop="status" style={{ width: '100%' }}>
              <Radio.Group value={form.status} onChange={onChange.bind(this, 'status')}>
                <Radio value="ATIVO">Ativo</Radio>
                <Radio value="INATIVO">Inativo</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={handleSubmit.bind(this)}>Gravar</Button>
            </Form.Item>
          </Form>
        </Card>
      </Layout.Col>
      <Layout.Col xs={24} sm={24} md={16}>
        <RechargeDiamondCard cardDiamondId={form?.id} />
      </Layout.Col>
    </Layout.Row>
  )
}