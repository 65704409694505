import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./SettingsActionPage.css";
import {
  getAllActionType,
  getAllDocumentSubTypes,
  getAllDocumentType,
  getAllProcessStatus,
  getAllTaskType,
} from "../../../services/RequestService";
import ModalActionType from "../../../components/settings/action/ModalActionType";
import ModalDocumentType from "../../../components/settings/action/ModalDocumentType";
import ModalTaskType from "../../../components/settings/action/ModalTaskType";
import {
  Header,
  Loading,
  PageContainer,
  TableComponent,
} from "../../../genericComponents";
import ModalRemoveDocumentSubType from "../../../components/settings/action/ModalRemoveDocumentSubType";
import ModalEditDocumentSubType from "../../../components/settings/action/ModalEditDocumentSubType";
import ModalRemoveActionType from "../../../components/settings/action/ModalRemoveActionType";
import ModalRemoveTaskType from "../../../components/settings/action/ModalRemoveTaskType";
import ModalRemoveDocumentType from "../../../components/settings/action/ModalRemoveDocumentType";
class SettingsActionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allActionType: [],
      allActionTypeList: null,
      currentAllActionType: [],

      allTaskType: [],
      allTaskTypeList: null,
      currentAllTaskTypeList: [],

      allDocumentSubTypes: null,
      currentAllDocumentSubType: [],

      allDocumentType: [],
      allDocumentTypeList: null,
      currentAllDocumentType: [],

      allProcessStatus: [],
      showModalActionType: false,
      showModalDocumentType: false,
      showModalTaskType: false,
      id: null,
      name: null,
      subTypes: null,
      processStatusId: null,
      user: null,
      cell: null,
      blockProcessDeadlineDate: null,
      unblockProcessDeadlineDate: null,
      timeCount: null,
      taskType: null,
      deadlineDays: null,
      alertDays: null,
      loadingCount: null,
      // ACTION TYPE
      showDeleteActionType: false,
      selectedActionType: null,
      // TASK TYPE
      showDeleteTaskType: false,
      selectedTaskType: null,
      // DOCUMENT TYPE
      showDeleteDocumentType: false,
      selectedDocumentType: null,
      // DOCUMENT SUB TYPE
      showRemoveDocumentSubType: false,
      showEditDocumentSubType: false,
      selectedSubType: null,
    };
  }

  fetchRequests = () => {
    this.setState(
      (state) => ({
        loadingCount: !state.loadingCount ? 5 : state.loadingCount + 5,
      }),
      () => {
        getAllActionType().then((res) => {
          if (res.ok) {
            this.setState((state) => ({
              allActionType: res.data,
              allActionTypeList: res.data
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  tasks: item.taskType.length,
                  processName: this.getProcessStatusName(item.processStatusId),
                  schduleName: this.getSchedulingName(item.user, item.cell),
                }))
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
              loadingCount: state.loadingCount - 1,
            }));
          }
        });
        getAllDocumentType().then((res) => {
          if (res.ok) {
            this.setState((state) => ({
              allDocumentType: res.data,
              allDocumentTypeList: res.data
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  subType: item.subTypes.map((el) => el.name).join(", "),
                  subTypes: item.subTypes,
                }))
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
              loadingCount: state.loadingCount - 1,
            }));
          }
        });
        getAllProcessStatus().then((res) => {
          if (res.ok) {
            this.setState((state) => ({
              allProcessStatus: res.data,
              loadingCount: state.loadingCount - 1,
            }));
          }
        });
        getAllTaskType().then((res) => {
          if (res.ok) {
            this.setState((state) => ({
              allTaskType: res.data,
              allTaskTypeList: res.data
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                  alertDays: item.alertDays,
                  deadlineDays: item.deadlineDays,
                  scheduleName: this.getSchedulingName(item.user, item.cell),
                }))
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
              loadingCount: state.loadingCount - 1,
            }));
          }
        });
        getAllDocumentSubTypes().then((res) => {
          this.setState((state) => ({
            allDocumentSubTypes: res.data.sort((a, b) =>
              a.id > b.id ? 1 : -1
            ),
            loadingCount: state.loadingCount - 1,
          }));
        });
      }
    );
  };

  componentDidMount = () => {
    this.fetchRequests();
  };

  setShowModalActionType = (showModalActionType, item, wasUpdated) => {
    this.setState({
      showModalActionType,
      id: item?.id ?? null,
      name: item?.name ?? null,
      blockProcessDeadlineDate: item?.blockProcessDeadlineDate ?? null,
      unblockProcessDeadlineDate: item?.unblockProcessDeadlineDate ?? null,
      timeCount: item?.timeCount ?? null,
      processStatusId: item?.processStatusId ?? null,
      user: item?.user ?? null,
      cell: item?.cell ?? null,
      taskType: item?.taskType ?? null,
    });
    if (!showModalActionType && wasUpdated) {
      this.fetchRequests();
    }
  };

  setShowModalDocumentType = (showModalDocumentType, item, wasUpdated) => {
    this.setState({
      showModalDocumentType,
      id: item?.id ?? null,
      name: item?.name ?? null,

      subTypes: item?.subTypes ?? null,
    });
    if (!showModalDocumentType && wasUpdated) {
      this.fetchRequests();
    }
  };

  setShowModalTaskType = (showModalTaskType, item, wasUpdated) => {
    this.setState({
      showModalTaskType,
      id: item?.id ?? null,
      name: item?.name ?? null,
      deadlineDays: item?.deadlineDays ?? null,
      alertDays: item?.alertDays ?? null,
      user: item?.user ?? null,
      cell: item?.cell ?? null,
    });
    if (!showModalTaskType && wasUpdated) {
      this.fetchRequests();
    }
  };

  setShowRemoveDocumentSubType = (
    showRemoveDocumentSubType,
    item,
    wasUpdated
  ) => {
    this.setState({
      showRemoveDocumentSubType,
      selectedSubType: item,
    });
    if (!showRemoveDocumentSubType && wasUpdated) {
      this.fetchRequests();
    }
  };

  setShowEditDocumentSubType = (showEditDocumentSubType, item, wasUpdated) => {
    this.setState({
      showEditDocumentSubType,
      selectedSubType: item,
    });
    if (!showEditDocumentSubType && wasUpdated) {
      this.fetchRequests();
    }
  };

  setShowDeleteActionType = (showDeleteActionType, selectedActionType, wasUpdated) => {
    this.setState({
      showDeleteActionType,
      selectedActionType
    });
    if (!showDeleteActionType && wasUpdated) {
      this.fetchRequests();
    }
  };

  setShowDeleteTaskType = (showDeleteTaskType, selectedTaskType, wasUpdated) => {
    this.setState({
      showDeleteTaskType,
      selectedTaskType
    });
    if (!showDeleteTaskType && wasUpdated) {
      this.fetchRequests();
    }
  };

  setShowDeleteDocumentType = (showDeleteDocumentType, selectedDocumentType, wasUpdated) => {
    console.log(selectedDocumentType);
    this.setState({
      showDeleteDocumentType,
      selectedDocumentType
    });
    if (!showDeleteDocumentType && wasUpdated) {
      this.fetchRequests();
    }
  };

  getProcessStatusName = (id) => {
    const processStatus = this.state.allProcessStatus;
    let i = 0;
    const iMax = processStatus.length;
    for (; i < iMax; i++) {
      if (processStatus[i].id === id) {
        return processStatus[i].name;
      }
    }
    return "";
  };

  getSchedulingName = (user, cell) => {
    if (user) return user.name;
    if (cell) return cell.name;
    return "";
  };

  RenderCardTitle = ({ title, onButtonClick }) => {
    return (
      <div>
        <Row>
          <Col>
            <h4>{title}</h4>
          </Col>
          {onButtonClick ? (
            <Col className="text-right my-2 ml-2">
              <Button variant="primary" onClick={onButtonClick}>
                <i className="fas fa-plus-circle pr-2" />
                <span>Adicionar</span>
              </Button>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  };

  render() {
    const {
      showModalActionType,
      showModalDocumentType,
      showModalTaskType,
      id,
      name,
      subTypes,
      allActionType,
      allDocumentType,
      allTaskType,
      processStatusId,
      user,
      cell,
      blockProcessDeadlineDate,
      unblockProcessDeadlineDate,
      timeCount,
      taskType,
      deadlineDays,
      alertDays,
      loadingCount,
      // ACTION TYPE
      showDeleteActionType,
      selectedActionType,
      // TASK TYPE
      showDeleteTaskType,
      selectedTaskType,
      // DOCUMENT TYPE
      showDeleteDocumentType,
      selectedDocumentType,
      // DOCUMENT SUB TYPE
      showRemoveDocumentSubType,
      showEditDocumentSubType,
      selectedSubType,
    } = this.state;
    return (
      <Loading loading={loadingCount !== 0}>
        <Header active="Configurações de andamentos" logged />
        <PageContainer withoutCard>
          <Row className="m-n2">
            {/* Tipo de Andamento */}
            <Col xs={12} md={6} lg={12} xl={6} className="p-0">
              <div className="p-3 m-2 card card-conf-action-size">
                <this.RenderCardTitle
                  title="Tipo de Andamento"
                  onButtonClick={
                    this.props.privileges?.["configuracoes/andamentos"]?.create
                      ? () => this.setShowModalActionType(true)
                      : null
                  }
                />

                <TableComponent
                  loading={loadingCount !== 0}
                  countLabel="tipos"
                  countLabelSingular="tipo"
                  startSorted="id"
                  header={[
                    { label: "#", sortProperty: "id" },
                    { label: "Nome", sortProperty: "name" },
                    { label: "Tarefas", sortProperty: "tasks" },
                    { label: "Mover status", sortProperty: "processName" },
                    { label: "Agenda", sortProperty: "schduleName" },
                    { label: "Remover" }
                  ]}
                  currentList={this.state.currentAllActionType}
                  setCurrentList={(currentAllActionType) =>
                    this.setState({ currentAllActionType })
                  }
                  list={this.state.allActionTypeList}
                  onItemClick={(listItem) => {
                    const item = allActionType.find(
                      (it) => it.id === listItem.id
                    );
                    this.setShowModalActionType(true, item);
                  }}
                  forEach={(item) => [
                    { label: item.id },
                    { label: item.name },
                    { label: item.tasks },
                    { label: item.processName },
                    { label: item.schduleName },
                    {
                      onClick: () =>
                        this.setShowDeleteActionType(true, item),
                      label: (
                        <div className="text-center">
                          <i className="fas fa-trash-alt is-required"></i>
                        </div>
                      ),
                    }
                  ]}
                />
              </div>
            </Col>

            {/* Tipo de tarefa */}
            <Col xs={12} md={6} lg={12} xl={6} className="p-0">
              <div className="p-3 m-2 card card-conf-action-size">
                <this.RenderCardTitle
                  title="Tipo de Tarefa"
                  onButtonClick={
                    this.props.privileges?.["configuracoes/andamentos"]?.create
                      ? () => this.setShowModalTaskType(true)
                      : null
                  }
                />

                <TableComponent
                  loading={loadingCount !== 0}
                  countLabel="tipos"
                  countLabelSingular="tipo"
                  startSorted="id"
                  header={[
                    { label: "#", sortProperty: "id" },
                    { label: "Nome", sortProperty: "name" },
                    { label: "Alertar", sortProperty: "alertDays" },
                    { label: "Entregar", sortProperty: "deadlineDays" },
                    { label: "Agenda", sortProperty: "scheduleName" },
                    { label: "Remover" }
                  ]}
                  currentList={this.state.currentAllTaskTypeList}
                  setCurrentList={(currentAllTaskTypeList) =>
                    this.setState({ currentAllTaskTypeList })
                  }
                  list={this.state.allTaskTypeList}
                  onItemClick={(listItem) => {
                    const item = allTaskType.find(
                      (it) => it.id === listItem.id
                    );
                    this.setShowModalTaskType(true, item);
                  }}
                  forEach={(item) => [
                    { label: item.id },
                    { label: item.name },
                    { label: item.alertDays },
                    { label: item.deadlineDays },
                    { label: item.scheduleName },
                    {
                      onClick: () =>
                        this.setShowDeleteTaskType(true, item),
                      label: (
                        <div className="text-center">
                          <i className="fas fa-trash-alt is-required"></i>
                        </div>
                      ),
                    }
                  ]}
                />
              </div>
            </Col>

            {/* Tipo de documento */}
            <Col xs={12} md={6} lg={12} xl={6} className="p-0">
              <div className="p-3 m-2 card card-conf-action-size">
                <this.RenderCardTitle
                  title="Tipo de Documento"
                  onButtonClick={
                    this.props.privileges?.["configuracoes/andamentos"]?.create
                      ? () => this.setShowModalDocumentType(true)
                      : null
                  }
                />

                <TableComponent
                  loading={loadingCount !== 0}
                  countLabel="tipos"
                  countLabelSingular="tipo"
                  startSorted="id"
                  header={[
                    { label: "#", sortProperty: "id" },
                    { label: "Nome", sortProperty: "name" },
                    { label: "Subtipos" },
                    { label: "Remover" }
                  ]}
                  currentList={this.state.currentAllDocumentTypeList}
                  setCurrentList={(currentAllDocumentTypeList) =>
                    this.setState({ currentAllDocumentTypeList })
                  }
                  list={this.state.allDocumentTypeList}
                  onItemClick={(listItem) => {
                    const item = allDocumentType.find(
                      (it) => it.id === listItem.id
                    );
                    this.setShowModalDocumentType(true, item);
                  }}
                  forEach={(item) => [
                    { label: item.id },
                    { label: item.name },
                    { label: item.subType },
                    {
                      onClick: () =>
                        this.setShowDeleteDocumentType(true, item),
                      label: (
                        <div className="text-center">
                          <i className="fas fa-trash-alt is-required"></i>
                        </div>
                      ),
                    }
                  ]}
                />
              </div>
            </Col>

            {/* Rules */}
            <Col xs={12} md={6} lg={12} xl={6} className="p-0">
              <div className="p-3 m-2 card card-conf-action-size">
                <this.RenderCardTitle
                  title="Subtipos de Documentos"
                  onButtonClick={() => this.setShowEditDocumentSubType(true)}
                />

                <TableComponent
                  loading={loadingCount !== 0}
                  countLabel="subtipos"
                  countLabelSingular="subtipo"
                  startSorted="id"
                  header={[
                    { label: "#", sortProperty: "id" },
                    { label: "Nome", sortProperty: "name" },
                    { label: "Remover" },
                  ]}
                  currentList={this.state.currentAllDocumentSubType}
                  setCurrentList={(currentAllDocumentSubType) =>
                    this.setState({ currentAllDocumentSubType })
                  }
                  list={this.state.allDocumentSubTypes}
                  onItemClick={(item) =>
                    this.setShowEditDocumentSubType(true, item)
                  }
                  forEach={(item) => [
                    { label: item.id },
                    { label: item.name },
                    {
                      onClick: () =>
                        this.setShowRemoveDocumentSubType(true, item),
                      label: (
                        <div className="text-center">
                          <i className="fas fa-trash-alt is-required"></i>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>

          {showModalActionType && (
            <ModalActionType
              show={showModalActionType}
              onHide={(wasUpdated) =>
                this.setShowModalActionType(false, null, wasUpdated)
              }
              id={id}
              name={name}
              taskType={taskType}
              processStatusId={processStatusId}
              user={user}
              cell={cell}
              blockProcessDeadlineDate={blockProcessDeadlineDate}
              unblockProcessDeadlineDate={unblockProcessDeadlineDate}
              timeCount={timeCount}
              privileges={this.props.privileges}
            />
          )}

          {showModalDocumentType && (
            <ModalDocumentType
              show={showModalDocumentType}
              onHide={(wasUpdated) =>
                this.setShowModalDocumentType(false, null, wasUpdated)
              }
              id={id}
              name={name}
              subTypes={subTypes}
              privileges={this.props.privileges}
            />
          )}

          {showModalTaskType && (
            <ModalTaskType
              show={showModalTaskType}
              onHide={(wasUpdated) =>
                this.setShowModalTaskType(false, null, wasUpdated)
              }
              id={id}
              name={name}
              deadlineDays={deadlineDays}
              alertDays={alertDays}
              privileges={this.props.privileges}
              user={user}
              cell={cell}
            />
          )}

          {showRemoveDocumentSubType && (
            <ModalRemoveDocumentSubType
              show={showRemoveDocumentSubType}
              subType={selectedSubType}
              onHide={(wasUpdated) =>
                this.setShowRemoveDocumentSubType(false, null, wasUpdated)
              }
            />
          )}

          {showEditDocumentSubType && (
            <ModalEditDocumentSubType
              show={showEditDocumentSubType}
              subType={selectedSubType}
              allSubtypes={this.state.allDocumentSubTypes}
              onHide={(wasUpdated) =>
                this.setShowEditDocumentSubType(false, null, wasUpdated)
              }
            />
          )}

          {showDeleteActionType && (
            <ModalRemoveActionType
              show={showDeleteActionType}
              actionType={selectedActionType}
              onHide={(wasUpdated) =>
                this.setShowDeleteActionType(false, null, wasUpdated)
              }
            />
          )}

          {showDeleteTaskType && (
            <ModalRemoveTaskType
              show={showDeleteTaskType}
              taskType={selectedTaskType}
              onHide={(wasUpdated) =>
                this.setShowDeleteTaskType(false, null, wasUpdated)
              }
            />
          )}

          {showDeleteDocumentType && (
            <ModalRemoveDocumentType
              show={showDeleteDocumentType}
              documentType={selectedDocumentType}
              onHide={(wasUpdated) =>
                this.setShowDeleteDocumentType(false, null, wasUpdated)
              }
            />
          )}
        </PageContainer>
      </Loading>
    );
  }
}

export default SettingsActionPage;
