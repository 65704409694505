import React from "react";
import { Row } from "react-bootstrap";
import { InputINSS, RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { defaultTwoOptions } from "../models/selects";

function RenderWasRetired() {
  return (
    <Row>
      <InputINSS
        label="Número do benefício (aposentadoria)"
        name={pensionFormNames.wasRetiredBenefitNumber}
      />
    </Row>
  );
}

function PensionRegister7({ serverData }) {
  const [wasRetired, setWasRetired] = React.useState(null);

  const serverWasRetired = serverData?.wasRetired;
  React.useEffect(() => {
    if (serverWasRetired === defaultTwoOptions[0]) {
      setWasRetired(true);
    }
  }, [serverWasRetired, setWasRetired]);

  return (
    <>
      <Row>
        <RadioGroup
          label="O(A) falecido(a) era aposentado(a)?"
          name={pensionFormNames.wasRetired}
          options={defaultTwoOptions.map((item) => ({
            label: item,
            value: item,
          }))}
          required={required.default}
          inline
          onChange={(event, value) => {
            setWasRetired(value === defaultTwoOptions[0]);
          }}
        />
      </Row>
      {wasRetired === true ? <RenderWasRetired /> : null}
    </>
  );
}

export default React.memo(PensionRegister7);
