import React from "react";
import { Row } from "react-bootstrap";
import { Input, RadioGroup } from "../../../genericComponents";
import { pensionFormNames } from "../models/pensionFormNames";
import { required } from "../models/required";
import { defaultThreeOptions } from "../models/selects";

function RenderYouGetSomeBenefit() {
  return (
    <Row>
      <Input
        label="Qual?"
        name={pensionFormNames.youGetSomeBenefitDescription}
        maxLength={100}
      />
    </Row>
  );
}

function PensionRegister16({ serverData }) {
  const serverYouGetSomeBenefit = serverData?.youGetSomeBenefit;
  const [youGetSomeBenefit, setYouGetSomeBenefit] = React.useState(
    !!serverYouGetSomeBenefit
  );
  React.useEffect(() => {
    if (serverYouGetSomeBenefit === defaultThreeOptions[0]) {
      setYouGetSomeBenefit(true);
    }
  }, [serverYouGetSomeBenefit, setYouGetSomeBenefit]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Você já recebe algum benefício do INSS? Ou de algum outro Regime da Previdência?"
          name={pensionFormNames.youGetSomeBenefit}
          options={defaultThreeOptions.map((item) => ({
            label: item,
            value: item,
          }))}
          required={required.default}
          inline
          onChange={(event, value) => {
            setYouGetSomeBenefit(value === defaultThreeOptions[0]);
          }}
        />
      </Row>
      {youGetSomeBenefit === true ? <RenderYouGetSomeBenefit /> : null}
    </>
  );
}

export default React.memo(PensionRegister16);
