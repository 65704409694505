import React, {useState} from "react";
import Input from "./Input";
import {validations} from "../../utils";

function InputCpfCnpj({inputMaskProps = {}, minDate, maxDate, validation, ...props}) {
  const MASK_CPF = '999.999.999-99';
  const MASK_CNPJ = '99.999.999/9999-99';
  const [maskType, setMaskType] = useState(MASK_CNPJ)

  return (
    <Input
      {...props}
      inputMaskProps={{
        mask: maskType,
        ...inputMaskProps,
      }}
      onFocus={() => setMaskType(MASK_CNPJ)}
      onChange={(e, value) => {
        if (!window.evt){
          const v = (value || '').replaceAll(/[^0-9]/g, '')
          setMaskType(v.length === 11 ? MASK_CPF : MASK_CNPJ)
          window.evt = true
        }
        if (props.onChange) return props.onChange(value)
      }}
      validation={(value, ffHandler) => {
        const v = (value || '').replaceAll(/[^0-9]/g, '')
        setMaskType(MASK_CNPJ)
        if (v.length === 11) {
          setMaskType(MASK_CPF)
          return validations.validateCPF(v)
        }
        return validations.validateCNPJ(v)
      }}
    />
  );
}

export default React.memo(InputCpfCnpj);
