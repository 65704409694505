import caller from "../../helpers/caller";
import Parse from "../../helpers/Parse";

function parseFunction(item) {
  return {
    id: item.id,
    name: item.name,
    parcels: item.parcels,
  };
}
export default async function getAllPaymentMethods(api, params) {
  const response = await caller(api.get, `/payment_method/get_all`, params);
  const parse = new Parse(response.data, response.endpoint, parseFunction);
  return { ...response, data: parse.parseArray() };
}
