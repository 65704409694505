import React from "react";
import { Row } from "react-bootstrap";
import { InputName, InputPhone, Select } from "../../../genericComponents";
import "./../SingleCustomerComp.css";

function BasicData3({ disabled, isCollaborator }) {
  return (
    <Row>
      <InputPhone
        colProps={{ xs: 12, sm: 5, md: 4, lg: 4 }}
        label="Whatsapp"
        name="cellphone"
        required="O campo deve ser preenchido."
        disabled={disabled}
      />

      <InputPhone
        colProps={{ xs: 12, sm: 5, md: 4, lg: 4 }}
        label="Número alternativo para contato"
        name="alternativePhone"
        disabled={disabled}
      />

      <InputName
        colProps={{ xs: 12, sm: 7, md: 8, lg: 4 }}
        label="Nome completo"
        name="fullname"
        maxLength={100}
        mask={(fullname) => fullname.toUpperCase()}
        maskToSubmit={(fullname) => fullname.toUpperCase()}
        required="O campo deve conter um nome completo valido. Com nome, sobrenome e não pode conter números."
        disabled={disabled || isCollaborator}
      />

      <Select
        colProps={{ xs: 12, lg: 4 }}
        label="Nacionalidade"
        name="nationality"
        required='O valor do campo "Nacionalidade" deve ser Brasileiro(a) ou Outro(a).'
        disabled={disabled}
        placeholder="Selecione uma nacionalidade"
        options={[
          {
            label: "Brasileiro(a)",
            value: "Brasileiro(a)",
          },
          {
            label: "Outro(a)",
            value: "Outro(a)",
          },
        ]}
      />
    </Row>
  );
}

export default React.memo(BasicData3);
