import React, { Component } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  deleteReport,
  getAllReports,
  postUpdateReport,
  putInsertReport,
} from "../../services/RequestService";

class ModalReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name === null ? "" : props.name,
      isNameValid: props.name === null ? undefined : true,
      isNameUnique: props.name === null ? undefined : true,
      allReports: [],
    };
  }

  componentDidMount = () => {
    getAllReports().then((res) => {
      if (res.ok) {
        let allReports = [];
        res.data.forEach((item) => {
          if (item.name !== this.props.name) {
            allReports.push(item.name);
          }
        });
        this.setState((state) => ({ ...state, allReports }));
      }
    });
  };

  handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    this.setState((state) => ({ ...state, [name]: value }));
  };

  isNameValid = () => {
    const { name } = this.state;
    let isNameValid = true,
      isNameUnique = true;
    if (name === "") {
      isNameValid = false;
    }
    getAllReports().then((res) => {
      if (res.ok) {
        res.data.forEach((item) => {
          if (item.name === name) {
            isNameUnique = false;
          }
        });
      }
    });
    this.setState((state) => ({ ...state, isNameValid, isNameUnique }));
  };

  isNameEditValid = () => {
    const { name } = this.state;
    let isNameValid = true,
      isNameUnique = true;
    if (name === "") {
      isNameValid = false;
    }
    getAllReports().then((res) => {
      if (res.ok) {
        let a = [];
        res.data.forEach((item) => {
          if (item.name !== this.props.name) {
            a.push(item.name);
          }
        });
        isNameUnique = a.find((e) => e === name) === undefined;
        this.setState({ isNameValid, isNameUnique });
      }
    });
  };

  isBtnDisabled = () => {
    const { allReports, name } = this.state;
    if (name === "") {
      return true;
    }
    return allReports.find((e) => e === name) !== undefined;
  };

  submit = () => {
    const { name } = this.state;
    const { id, modalType } = this.props;
    if (id === null) {
      putInsertReport({ name }).then((res) => {
        if (res.ok) {
          this.props.onHide();
        }
      });
    } else {
      if (modalType === "edit") {
        postUpdateReport({ id, name }).then((res) => {
          if (res.ok) {
            this.props.onHide();
          }
        });
      } else if (modalType === "delete") {
        deleteReport({ id }).then((res) => {
          if (res.ok) {
            this.props.onHide();
          }
        });
      }
    }
  };

  render() {
    const { name, isNameValid, isNameUnique } = this.state;
    const { show, onHide, modalType } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="sm" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "add"
              ? "Cadastrar "
              : modalType === "edit"
              ? "Atualizar "
              : modalType === "delete"
              ? "Deletar "
              : null}
            relatório
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "add" ? (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  Nome<Form.Label className="report-is-required">*</Form.Label>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  onBlur={() => this.isNameValid()}
                  className={
                    isNameValid === undefined
                      ? ""
                      : isNameUnique === undefined
                      ? ""
                      : isNameValid
                      ? isNameUnique
                        ? "is-valid"
                        : "is-invalid"
                      : "is-invalid"
                  }
                />
                <Form.Text className="report-is-required">
                  {isNameValid === undefined
                    ? ""
                    : isNameUnique === undefined
                    ? ""
                    : isNameValid
                    ? isNameUnique
                      ? ""
                      : "Esse nome já está cadastrado"
                    : "O campo não pode ser vazio"}
                </Form.Text>
              </Form.Group>
            </Form.Row>
          ) : modalType === "edit" ? (
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  Nome<Form.Label className="report-is-required">*</Form.Label>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  onBlur={() => this.isNameEditValid()}
                  className={
                    isNameValid === undefined
                      ? ""
                      : isNameUnique === undefined
                      ? ""
                      : isNameValid
                      ? isNameUnique
                        ? "is-valid"
                        : "is-invalid"
                      : "is-invalid"
                  }
                />
                <Form.Text className="report-is-required">
                  {isNameValid === undefined
                    ? ""
                    : isNameUnique === undefined
                    ? ""
                    : isNameValid
                    ? isNameUnique
                      ? ""
                      : "Esse nome já está cadastrado"
                    : "O campo não pode ser vazio"}
                </Form.Text>
              </Form.Group>
            </Form.Row>
          ) : (
            <Form.Row>
              <Col>
                <h6>Excluir o relatório {this.props.name}?</h6>
              </Col>
            </Form.Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={this.submit}
                disabled={this.isBtnDisabled()}
              >
                {modalType === "add"
                  ? "Cadastrar "
                  : modalType === "edit"
                  ? "Atualizar "
                  : modalType === "delete"
                  ? "Deletar "
                  : null}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalReport;
